import { matchPath } from 'react-router-dom';
/*
 * Business Operations
 */
import { env as getEnv, ENV_DEV, ENV_LOCAL, ENV_PRODUCTION, ENV_STAGING } from 'zo-data-layer/constants/env';

import { BookingType } from 'zo-data-layer/constants/booking';
import { SharePages } from 'zo-data-layer/constants/share';
import NavigationName from 'zo-data-layer/constants/NavigationName';
import { MyService } from 'zo-data-layer/dataobjects';

/* Support Email */
export const SUPPORT_EMAIL = 'support@findyourzo.com';
export const SUPPORT_SUBJECT = 'ZO App support request';

const BaseUrls = {
  [ENV_LOCAL]: 'http://dev.findyourzo.com',
  [ENV_DEV]: 'http://dev.findyourzo.com',
  [ENV_STAGING]: 'http://staging.findyourzo.com',
  [ENV_PRODUCTION]: 'https://findyourzo.com',
};

const LanguagePaths = {
  en: '',
  fr: '/fr',
  de: '/de',
  pt: '/pt',
};

const HelpCenterLanguagePaths = {
  en: '/en-us',
  fr: '/fr',
  de: '/de',
  pt: '/pt-br',
};

export const getUrlConstants = ({ locale, env = getEnv() }) => {
  let baseUrl = BaseUrls[env];
  if (!Object.keys(BaseUrls).includes(env)) {
    baseUrl = BaseUrls[ENV_LOCAL];
  }

  let languagePath = LanguagePaths[locale];
  if (!(locale in LanguagePaths)) {
    console.warn(`Unsupported locale ${locale} defaulting to 'en'`);
    languagePath = LanguagePaths.en;
  }

  let hclanguagePath = HelpCenterLanguagePaths[locale];
  if (!(locale in HelpCenterLanguagePaths)) {
    console.warn(`Unsupported locale ${locale} defaulting to 'en'`);
    hclanguagePath = HelpCenterLanguagePaths.en;
  }

  let zendeskUrl = 'https://staging-support.findyourzo.com/hc/en-us';
  if (env === ENV_PRODUCTION) {
    zendeskUrl = 'https://support.findyourzo.com/hc/en-us';
  }
  //TODO: Update T&C and Privacy Policy urls when available
  return {
    baseUrl: `${baseUrl}${languagePath}`,
    termsUrl: `${baseUrl}${languagePath}/terms-of-use`,
    privacyUrl: `${baseUrl}${languagePath}/privacy-policy`,
    zendeskUrl,
    supportUrl: `https://support.findyourzo.com/hc${hclanguagePath}`,
  };
};

export const AppLinks = {
  discover: '/',
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  resetPassword: '/auth/reset',
  confirmRegister: '/auth/confirm',
  filter: '/filter',
  allFilter: '/filter/all',
  newFilter: '/filter/new',
  todayFilter: '/filter/today',
  upcomingFilter: '/filter/upcoming',
  category: '/category/:categoryId',
  publicServiceInfo: '/service/public/',
  serviceInfo: '/service/',
  serviceDetails: '/service/details/',
  bookingSuccess: '/service/details/success/',
  waitlistSuccess: '/service/waitlist/success/',
  inventorySuccess: '/service/inventory/success/',
  scheduledSuccess: '/service/scheduled/success/',
  myAccountBooking: '/my-account/booking',
  myAccountBookingDetails: '/my-account/booking/:bookingType/:bookingId',
  myAccount: '/my-account/',
  myProfile: '/my-account/my-profile',
  myPaymentMethods: '/my-account/my-payment-methods',
  myAlerts: '/my-account/my-alerts',
  myAlertDetails: '/my-account/my-alerts/alert/:alertId',
  changePassword: '/my-account/change-password',
  leaderView: '/admin',
};

const pathNameMappings = {
  [NavigationName.ServiceFilter]: [
    AppLinks.filter,
    AppLinks.allFilter,
    AppLinks.newFilter,
    AppLinks.todayFilter,
    AppLinks.upcomingFilter,
  ],
  [NavigationName.Category]: [AppLinks.category],
  [NavigationName.Service]: [
    AppLinks.serviceInfo,
    AppLinks.publicServiceInfo,
    AppLinks.serviceDetails,
    AppLinks.bookingSuccess,
    AppLinks.inventorySuccess,
    AppLinks.waitlistSuccess,
  ],
  [NavigationName.Bookings]: [AppLinks.myAccountBooking, AppLinks.myAccountBookingDetails],
  [NavigationName.Profile]: [
    AppLinks.myAccount,
    AppLinks.myProfile,
    AppLinks.myPaymentMethods,
    AppLinks.myAlerts,
    AppLinks.myAlertDetails,
    AppLinks.changePassword,
  ],
  [NavigationName.Login]: [AppLinks.login, AppLinks.forgotPassword, AppLinks.resetPassword],
  [NavigationName.Signup]: [AppLinks.register, AppLinks.confirmRegister],
  [NavigationName.Discover]: [AppLinks.discover],
};

const sharePageMappings = {
  [SharePages.Service]: [AppLinks.serviceInfo],
  [SharePages.PublicService]: [AppLinks.publicServiceInfo],
  [SharePages.BookingDetails]: [AppLinks.serviceDetails],
  [SharePages.BookingSuccess]: [AppLinks.bookingSuccess, AppLinks.inventorySuccess, AppLinks.waitlistSuccess],
  [SharePages.MyBookings]: [AppLinks.myAccountBooking],
  [SharePages.MyBookingDetails]: [AppLinks.myAccountBookingDetails],
};

export function pathNameToNavigationName(pathName: string) {
  for (const [navigationName, appLinks] of Object.entries(pathNameMappings)) {
    for (const appLink of appLinks) {
      if (matchPath(pathName, { path: appLink })) {
        return navigationName;
      }
    }
  }
  return NavigationName.Other;
}

export function pathNameToSharePage(pathName: string) {
  for (const [sharePage, appLinks] of Object.entries(sharePageMappings)) {
    for (const appLink of appLinks) {
      if (matchPath(pathName, { path: appLink })) {
        return sharePage;
      }
    }
  }
}

export const myAccountPageNames = {
  myProfile: 'my-profile',
  myAlerts: 'my-alerts',
  myBookings: 'booking',
  myPaymentMethods: 'my-payment-methods',
  myPassword: 'change-password',
};

// maps AppLinks.category
export function generateCategoryLink(categoryId: string | number, adminOnly = false) {
  if (adminOnly) {
    return `/admin/category/${categoryId}`;
  }
  return `/category/${categoryId}`;
}
// maps AppLinks.myAccountBookingDetails
export const generateMyAccountBookingDetailsLink = (bookingType: BookingType, bookingId: number) =>
  `${AppLinks.myAccountBooking}/${bookingType}/${bookingId}`;
// maps AppLinks.bookingDetailsSuccess
export const generateSuccessPageLink = (service: MyService, bookingId: string | number, bookingType: BookingType) => {
  const base = service.isAdminOnly ? AppLinks.leaderView : '';
  if (bookingType === BookingType.Inventory) {
    return `${base}${AppLinks.inventorySuccess}${bookingId}`;
  }
  if (bookingType === BookingType.Booking) {
    return `${base}${AppLinks.bookingSuccess}${bookingId}`;
  }
  if (bookingType === BookingType.Waitlist) {
    return `${base}${AppLinks.waitlistSuccess}${bookingId}`;
  }
  if (bookingType === BookingType.ScheduledService) {
    return `${base}${AppLinks.scheduledSuccess}${bookingId}`;
  }
  console.error('Dont know how to make a success page link for' + service.serviceType);
};
// maps AppLinks.serviceInfo with optional category from route
export const generateServiceLink = (service: MyService, categoryId?: string | number) =>
  `${service.isAdminOnly ? '/admin' : ''}${AppLinks.serviceInfo}${service.enrollmentId}/${
    categoryId ? categoryId : ''
  }`;
export const generateServiceDetailsLink = (service: MyService, slotId?: string | number) =>
  `${service.isAdminOnly ? '/admin' : ''}${AppLinks.serviceDetails}${service.enrollmentId}${
    slotId ? `/${slotId}` : ''
  }`;
// maps AppLinks.myAlertDetails
export const generateMyAlertDetailsLink = (alertId: number) => `${AppLinks.myAlerts}/alert/${alertId}`;
