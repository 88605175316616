import { brand, neutral, status, statusBackground, stateColors, miscColors } from './colors';
import { primaryFont, typeScale } from './typography';

export const defaultTheme = {
  colors: {
    primaryColorLight: brand.lightGreen,
    primaryColor: brand.brightGreen,
    primaryColorDark: brand.darkGreen,
    primaryColorDarker: brand.hello,
    secondaryColor: brand.blue,
    black: neutral.black,
    grey: neutral.grey,
    white: neutral.white,
    disabled: 'rgba(218, 218, 218, 0.25)',
    lightGrey: neutral.lightGrey,
    darkGrey: neutral.darkGrey,
    textOnDisabled: neutral.lightGrey,
    backgroundColor: neutral.white,
    logoColor: neutral.white,
    navbarBackground: neutral.white,
    footerBackground: brand.brightGreen,
    hover: stateColors.hover,
    selected: stateColors.selected,
    divider: miscColors.divider,
    leaderColor: neutral.white,
  },
  primaryFont: primaryFont,
  status: {
    info: status.info,
    warning: status.warning,
    error: status.error,
    success: status.success,
    waitlist: status.waitlist,
  },
  statusBackground: {
    info: statusBackground.info,
    warning: statusBackground.warning,
    error: statusBackground.error,
    success: statusBackground.success,
  },
  spacing: {
    layoutPaddingXs: '0.5rem',
    layoutPaddingSm: '1rem',
    layoutPadding: '2rem',
    layoutPaddingLg: '3rem',
    layoutPaddingXl: '5rem',
    smallScreenPadding: '0 1rem',
    footerPadding: '0 4rem',
    routeWrapperSidePadding: '8rem',
    asideRightContentPadding: '0 5rem 0 8rem',
  },
  size: {
    containerMaxWidth: '1180px',
    minScreenWidth: '70vw',
    maxLogoSize: '8.82rem',
    footerLogoSize: '8.82rem',
    seeAllServicesBlockHeight: '16.75rem',
    footerHeight: '8rem',
    pageContainerRegisterVerticalPadding: '18rem 0 15rem',
    pageContainerServiceVerticalPadding: '8rem 0 24.75rem',
    pageContainerLoginVerticalPadding: '18rem 0 27.75rem',
    pageContainerVerticalPadding: '8rem 0',
    pageContainerPublicServiceVerticalPadding: '5rem 0',
    pageContainerMobileRegisterVerticalPadding: '18rem 0 0',
    pageContainerMobileServiceVerticalPadding: '8rem 0 0',
    pageContainerMobileLoginVerticalPadding: '18rem 0 0',
    pageContainerMobileVerticalPadding: '9rem 0 0',
    pageContainerMobilePublicServiceVerticalPadding: '6rem 0 0',
    navbarHeight: '5.875rem',
    shortNavbarHeight: '5.875rem',
    extendedNavbarHeight: '17.525rem',
    extendedNavbarHeightLg: '28.5rem',
    extendedNavbarHeightMd: '24rem',
    servicePagesNavbarHeight: '10.125rem',
    asidePadding: '9.875rem',
    toastHeight: '3rem',
    toastHeightMobile: '4.5rem',
    iconSize: '0.875rem',
    asideIconSize: '1.6rem',
    carouselChevronSize: '1rem',
    serviceIconSize: '1.2rem',
    serviceDetailIconSize: '1.2rem',
    virtualIconCorner: '1.2rem',
    buttonIconSize: '1.75rem',
    selectIconSize: '3rem',
    successIconSize: '4.125rem',
    asideLink: '1.2rem',
    notReadIconSize: '0.5rem',
    serviceTileWidth: '23%',
    quantityIconSize: '1.7rem',
    twoColumnGapWidth: '6.5rem',
    registerInfoIcon: '1.5rem',
    closeIcon: '1.5rem',
  },
  fontSizes: {
    footer: typeScale.footerText,
  },
  zIndex: {
    zNegative: '-1',
    z0: '0',
    z1: '1',
    z2: '200',
    z3: '300',
    z4: '400',
    navbar: '500',
    modal: '600',
    toast: '700',
  },
  animations: {
    transition: 'opacity 0.2s linear, background-color 0.2s linear, color 0.2s linear',
    display: 'translateY(0)',
    hide: 'translateY(-7rem)',
  },
};
