import { defineMessages, MessageDescriptor } from 'react-intl';

const allServices: MessageDescriptor = {
  id: 'tileSection.allServices',
  defaultMessage: 'All Services',
};

const discover: MessageDescriptor = {
  id: 'tileSection.discover',
  defaultMessage: 'Discover',
};

const seeMoreToday: MessageDescriptor = {
  id: 'tileSection.seeMoreToday',
  defaultMessage: 'See All Services Happening Today',
};

const seeMoreUpcoming: MessageDescriptor = {
  id: 'tileSection.seeMoreUpcoming',
  defaultMessage: 'See All Upcoming Services',
};

const seeMoreAll: MessageDescriptor = {
  id: 'tileSection.seeMoreAll',
  defaultMessage: 'See All Services',
};

const sorryThereAreNone: MessageDescriptor = {
  id: 'tileSection.sorryThereAreNone',
  defaultMessage: 'Sorry, there are no services here',
};

export default defineMessages({
  allServices,
  discover,
  seeMoreToday,
  seeMoreUpcoming,
  seeMoreAll,
  sorryThereAreNone,
});
