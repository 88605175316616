/** External Dependencies **/
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

/** Interal Dependencies */
import { Modals } from 'constants/Layouts';
import Messages from './Messages';

/** Data layer */
import { openModal } from 'zo-data-layer/actions';

/** Components */
import ModalContainer from 'components/ModalContainer';

declare global {
  interface Document {
    documentMode?: any;
  }
}

export const BrowserUnsupported = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { userAgent } = navigator;
  const isInternetExplorer = userAgent.includes('MSIE') || userAgent.includes('Trident') || !!document.documentMode;
  const supportedBrowsers = ['Google Chrome', 'Mozilla Firefox', 'Microsoft Edge'];

  useEffect(() => {
    if (isInternetExplorer) {
      dispatch(openModal(Modals.BrowserUnsupported));
    }
  }, [isInternetExplorer, dispatch]);

  const renderSupportedBrowsers = () => supportedBrowsers.map((browser) => <li>{browser}</li>);

  return (
    <ModalContainer name={Modals.BrowserUnsupported}>
      <div>
        <h2>{intl.formatMessage(Messages.problem)}</h2>
        <p>{intl.formatMessage(Messages.solution)}</p>
        <ul>{renderSupportedBrowsers()}</ul>
      </div>
    </ModalContainer>
  );
};

export default BrowserUnsupported;
