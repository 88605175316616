import styled from 'styled-components';
import { typeScale } from 'styles';

export const LinkButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  font-size: ${typeScale.font14};
`;
