import { defineMessages, MessageDescriptor } from 'react-intl';

const upcomingServices: MessageDescriptor = {
  id: 'discover.upcomingServices',
  defaultMessage: 'Upcoming Services',
};

const discover: MessageDescriptor = {
  id: 'discover.discover',
  defaultMessage: 'Discover',
};

export default defineMessages({
  discover,
  upcomingServices,
});
