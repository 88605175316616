/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/** Internal Dependencies */
import useBooleanEffect from 'hooks/useBooleanEffect';

/** Data layer */
import { MyBookingsGetActions, MyWaitlistGetActions } from 'zo-data-layer/actions';
import { MyBookingsGetSelectors } from 'zo-data-layer/selectors';
import { BookingType } from 'zo-data-layer/constants/booking';
import EventBooking from 'zo-data-layer/dataobjects/GenericEventBooking';

/** Components */
import SuccessPage from './SuccessPage';

type RouteParams = {
  bookingId: string;
};

type SelectorProps = {
  isBookingSuccess: boolean;
  booking: EventBooking;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  isBookingSuccess: MyBookingsGetSelectors.isSuccess(),
  booking: MyBookingsGetSelectors.getBooking(),
});

const BookingSuccessPage: FC = () => {
  const { isBookingSuccess, booking } = useSelector(mapReduxState);
  const { bookingId } = useParams<RouteParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MyBookingsGetActions.request(parseInt(bookingId, 10)));
  }, [bookingId, dispatch]);

  useBooleanEffect(isBookingSuccess, () => {
    dispatch(MyWaitlistGetActions.reset());
  });

  return (
    <SuccessPage
      currentBooking={booking}
      bookingType={BookingType.Booking}
      successId={bookingId}
      isSuccess={isBookingSuccess}
    />
  );
};

export default BookingSuccessPage;
