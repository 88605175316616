/**
 * Data Layer Composition Root.
 *
 * This is where all services, sagas, and reducers should be declared,
 * initialized and combined to build the Redux Store.
 */

/** External Dependencies **/
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form/immutable';

/** Interal Dependencies */
import ErrorReporter from './analytics/error.analytics';
import AnalyticsReporter from './analytics/firebase.analytics';
import { Token } from './auth';
import { redirectToLogin, redirectToHome } from './utils/navigation';

/** Data layer **/
import { LogoutActionTypes } from 'zo-data-layer/constants/actions';
import {
  bindHttp,
  MeServiceRaw,
  LocationService,
  AuthService,
  LeaderServiceRaw,
  BuildingServiceRaw,
  PromoCodeServiceRaw,
  MyPaymentSourcesServiceRaw,
  CompanyEnduserServiceRaw,
  MyServicesService,
  MyCategoriesService,
  MyBookingsService,
  MyWaitlistService,
  MyInventoryBookingService,
  MyActivitiesService,
  CampusesService,
  RegionsService,
} from 'zo-data-layer/services';
import {
  forkAll,
  MeSagas,
  AuthSagas,
  LeaderSagas,
  BuildingSagas,
  MyAccountSagas,
  MyServicesSagas,
  MyCategoriesSagas,
  LocationSagas,
  LayoutSagasWeb,
  EndUserPromoCodeSagas,
  EndUserCompaniesSagas,
  MyPaymentSourcesSagas,
  MyBookingsSagas,
  MyWaitlistSagas,
  MyInventorySagas,
  AnalyticsSagas,
  MyActivitiesSagas,
  CampusesSagas,
  RegionsSagas,
} from 'zo-data-layer/sagas';
import {
  me,
  location,
  auth,
  users,
  leader,
  regions,
  campuses,
  companies,
  partners,
  bookings,
  myServices,
  myCategories,
  serviceBooking,
  adminUsers,
  enrollments,
  buildingsAndFloors,
  languageProviderReducer,
  promocodeValidation,
  enduserCompaniesReducer as enduserCompanies,
  myPaymentSourcesReducer,
  myBookings,
  myWaitlist,
  myInventory,
  myactivities,
  enduserCampuses,
  enduserRegions,
  myServicesFilters,
} from 'zo-data-layer/reducers';
import { TransformMyZO, setErrorReporter } from 'zo-data-layer/transformers';

/**
 * Local Web Sagas
 */
import SideEffectSagas from './sagas/sideEffects.saga';

/**
 * Http library
 */
import { request as http, requestAndIgnore401 } from './generic.service';

/**
 * Local Reducer
 */
import layoutReducer from './reducers/layout.reducer';

// Set error reporter
setErrorReporter(ErrorReporter);

const logout = () => {
  Token.destroy();
  redirectToLogin();
  return Promise.resolve(undefined);
};

/**
 * Initialize all the saga collection by injecting dependencies into new instances
 * Raw services should be bound to the generic http library before injection
 */
const authSagas = new AuthSagas({
  // Ignore 401 errors from auth sagas as they represent logged out scenarios
  service: new AuthService(requestAndIgnore401),
  saveToken: (token) => Promise.resolve(Token.set(token)),
  getToken: () => Promise.resolve(Token.get()),
  getBackupToken: () => Promise.resolve(Token.getBackupToken()),
  toHome: () => Promise.resolve(redirectToHome()),
  toLogin: () => Promise.resolve(redirectToLogin()),
  logout,
});
const meSagas = new MeSagas({
  service: bindHttp(MeServiceRaw, http),
});
const myAccountSagas = new MyAccountSagas({
  service: bindHttp(MeServiceRaw, http),
  logout,
});
const locationSagas = new LocationSagas(new LocationService(http));
const layoutSagas = new LayoutSagasWeb({
  service: bindHttp(MeServiceRaw, http),
  error: ErrorReporter,
});
const buildingSagas = new BuildingSagas({
  service: bindHttp(BuildingServiceRaw, http),
});
const leaderSagas = new LeaderSagas({
  service: bindHttp(LeaderServiceRaw, http),
  transformer: TransformMyZO,
});
const analyticsSagas = new AnalyticsSagas(AnalyticsReporter);
const enduserPromoCodeSagas = new EndUserPromoCodeSagas({
  service: bindHttp(PromoCodeServiceRaw, http),
});
const myPaymentSourcesSagas = new MyPaymentSourcesSagas({
  service: bindHttp(MyPaymentSourcesServiceRaw, http),
});
const myServicesSagas = new MyServicesSagas(new MyServicesService(http));
const endUserCompaniesSagas = new EndUserCompaniesSagas({
  service: bindHttp(CompanyEnduserServiceRaw, http),
});
const myBookingsSagas = new MyBookingsSagas(new MyBookingsService(http));
const myWaitlistSagas = new MyWaitlistSagas(new MyWaitlistService(http));
const myInventorySagas = new MyInventorySagas(new MyInventoryBookingService(http));
const myActivitiesSagas = new MyActivitiesSagas(new MyActivitiesService(http));
const myCategoriesSagas = new MyCategoriesSagas(new MyCategoriesService(http));
const campusesSagas = new CampusesSagas(new CampusesService(http));
const regionsSagas = new RegionsSagas(new RegionsService(http));

// local sagas
const sideEffectsSagas = new SideEffectSagas();

/**
 * Declare the sagas to be mounted
 *
 * Each saga collection should be declared in one array element
 *  the first element is the saga collection instance
 *  the second element is an array naming all the specific sagas to be mounted
 */
const sagas = [
  [authSagas, ['authWeb', 'registration']],
  [meSagas, ['getMe']],
  [myAccountSagas, ['enduserUpdateMyAccount', 'enduserEditPassword', 'enduserDeleteMyAccount']],
  [locationSagas, ['getLocation']],
  [myServicesSagas, ['myServices']],
  [myCategoriesSagas, ['myCategories']],
  [myBookingsSagas, ['myBookings']],
  [myWaitlistSagas, ['myWaitlist']],
  [myInventorySagas, ['myInventory']],
  [myActivitiesSagas, ['myActivities']],
  [enduserPromoCodeSagas, ['promoCodeEndUser']],
  [myPaymentSourcesSagas, ['myPaymentSources']],
  [endUserCompaniesSagas, ['fetchCompaniesByEmail']],
  [layoutSagas, ['onRouteChange']],
  [buildingSagas, ['getBuildingsAndFloors']],
  [leaderSagas, ['leaderFetchMyEnrollments', 'leaderContactZoService']],
  [analyticsSagas, ['track']],
  [campusesSagas, ['campuses']],
  [regionsSagas, ['regions']],
  // local sagas
  [sideEffectsSagas, ['sideEffects']],
];

/**
 * Root saga
 */
const webSagas = function* webSagas() {
  yield forkAll(sagas);
};
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create browser history
export const history = createBrowserHistory();

/**
 * Root reducer
 */
const appReducer = combineReducers({
  router: connectRouter(history),
  app: combineReducers({
    me,
    location,
    auth,
    leader,
    bookings,
    myServices,
    myCategories,
    serviceBooking,
    buildingsAndFloors,
    promocodeValidation,
    mypaymentsources: myPaymentSourcesReducer,
    companies: enduserCompanies,
    myBookings,
    myWaitlist,
    myInventory,
    myactivities,
    campuses: enduserCampuses,
    regions: enduserRegions,
    myServicesFilters,
  }),
  admin: combineReducers({
    users,
    regions,
    partners,
    campuses,
    companies,
    adminUsers,
    enrollments,
  }),
  form: formReducer,
  layout: layoutReducer,
  language: languageProviderReducer,
});

export type AppState = ReturnType<typeof appReducer>;

const rootReducer = (state, action) => {
  if ([LogoutActionTypes.success, LogoutActionTypes.failure].includes(action.type)) {
    state = undefined;
  }
  return appReducer(state, action);
};

let storeRef;
export const getStore = () => storeRef;

/**
 * Build a function that will create the Redux store
 */
export default function configureStore(initialState = {}) {
  // declare Redux middleware
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  // apply middleware
  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.APP_ENV !== 'production' &&
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  // initialize store
  // TODO: Improve the type of the initial state
  const store = createStore(rootReducer, (fromJS(initialState) as unknown) as AppState, composeEnhancers(...enhancers));

  // attach sagas
  sagaMiddleware.run(webSagas);

  storeRef = store;
  // Redux store to be injected into the <Provider />
  return store;
}
