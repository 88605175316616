/** External Dependencies **/
import React, { createContext, Component } from 'react';
import { createIntl, RawIntlProvider, createIntlCache, IntlShape } from 'react-intl';
import navigatorLanguages from 'navigator-languages';

/** Data layer */
import * as dataLayerTranslations from 'zo-data-layer/translations';
import { SupportedLanguage } from 'zo-data-layer/constants';

/** Internal Dependencies */
import en from '../localization/messages/en.json';
import fr from '../localization/messages/fr.json';
import de from '../localization/messages/de.json';
import pt from '../localization/messages/pt.json';

type ContextValue = {
  changeLocale: (locale: SupportedLanguage) => void;
  currentLocale: SupportedLanguage;
  locale: SupportedLanguage;
};

export const LocaleContext = createContext<ContextValue>({
  changeLocale: (locale: SupportedLanguage) => {
    /* default function */
  },
  currentLocale: SupportedLanguage.en,
  locale: SupportedLanguage.en, // everyone seems to prefer this
});

// prevents memory leak
const cache = createIntlCache();
const messages: Record<SupportedLanguage, Record<string, string>> = {
  [SupportedLanguage.en]: { ...dataLayerTranslations.En, ...en },
  [SupportedLanguage.fr]: { ...dataLayerTranslations.Fr, ...fr },
  [SupportedLanguage.de]: { ...dataLayerTranslations.De, ...de },
  [SupportedLanguage.pt]: { ...dataLayerTranslations.Pt, ...pt },
};

type State = {
  intl: IntlShape;
  currentLocale: SupportedLanguage;
};

// sometimes languages have a region code (e.g. en_GB), turn that into just 'en'
export const normalizeLanguage = (language: string) => language.toLowerCase().split(/[_-]+/)[0];

export const chooseLanguage = (appMessages: Record<string, Record<string, string>>): SupportedLanguage => {
  const userPreferredLanguages = navigatorLanguages().map(normalizeLanguage);
  // languages in order of user preference, single language for backwards compat
  const locale = userPreferredLanguages.find((normalizedLocale: string) =>
    Object.prototype.hasOwnProperty.call(appMessages, normalizedLocale)
  );
  return (locale as SupportedLanguage) || SupportedLanguage.en;
};

class IntlContainer extends Component<Record<string, unknown>, State> {
  constructor(props: Record<string, unknown>) {
    super(props);

    const initialLocale = chooseLanguage(messages);
    const intl = this.createIntl(initialLocale);
    this.state = { intl, currentLocale: initialLocale };
  }

  createIntl = (locale: SupportedLanguage): IntlShape => createIntl({ locale, messages: messages[locale] }, cache);

  handleChangeLocale = (locale: SupportedLanguage): void => {
    const intl = this.createIntl(locale);
    this.setState({ intl, currentLocale: locale });
  };

  render() {
    const { intl, currentLocale } = this.state;
    const { children } = this.props;
    return (
      <RawIntlProvider value={intl}>
        <LocaleContext.Provider value={{ changeLocale: this.handleChangeLocale, currentLocale, locale: currentLocale }}>
          {children}
        </LocaleContext.Provider>
      </RawIntlProvider>
    );
  }
}

export default IntlContainer;
