/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { List, Map } from 'immutable';
import { useIntl } from 'react-intl';
import { DateTime } from 'luxon';

/** Data layer */
import { Timeslot } from 'zo-data-layer/dataobjects';

/** Components */
import AvailabilityTimeSlot from './AvailabilityTimeSlot';

/** Styling */
import { mediaQueries, typeScale } from 'styles';

type Props = {
  timeSlots: Map<string, List<Timeslot>>;
  selectedDay: string;
};

const AvailabilityTable: FC<Props> = ({ timeSlots, selectedDay }) => {
  const { locale } = useIntl();
  const table = timeSlots.get(selectedDay);
  const formattedDay = DateTime.fromJSDate(new Date(selectedDay)).setLocale(locale).toFormat('EEEE, LLLL dd, yyyy');

  return (
    <>
      <TableHeader>{formattedDay}</TableHeader>
      <Table>{table && table.map((slot: Timeslot) => <AvailabilityTimeSlot key={slot.id} slot={slot} />)}</Table>
    </>
  );
};

export default AvailabilityTable;

const TableHeader = styled.div`
  display flex;
  justify-content: center;
  padding: 2rem 0 1rem;
  font-size: ${typeScale.font14};

  @media (min-width: ${mediaQueries.m_mobile}) {
    font-size: 1rem;
  }
`;

const Table = styled.div``;
