/** External Dependencies */
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

/** Internal Dependencies */
import * as Routes from 'constants/routes';
import Messages from './Messages';
import {
  isLoginPage,
  isRegisterPage,
  isServiceDetailsRoute,
  isServiceDetailsCategoryRoute,
  isPublicPage,
} from 'utils/navigation';

/** Components */
import ZoLogo from '../ZoLogo';
import LoginFooterBlock from '../FooterBlocks/LoginFooterBlock';
import SeeAllServicesFooterBlock from '../FooterBlocks/SeeAllServicesFooterBlock';
import RegisterFooterBlock from '../FooterBlocks/RegisterFooterBlock';

/** Styling */
import { typeScale, mediaQueries, defaultTheme } from 'styles';

const Footer = () => {
  const { locale } = useIntl();
  const location = useLocation();

  const renderFooterBlock = () => {
    if (isPublicPage(location)) return;
    if (isLoginPage(location)) return <LoginFooterBlock />;
    if (isRegisterPage(location)) return <RegisterFooterBlock />;
    if (isServiceDetailsRoute(location) || isServiceDetailsCategoryRoute(location))
      return <SeeAllServicesFooterBlock />;
  };

  return (
    <Wrapper>
      {renderFooterBlock()}
      <Content>
        <ZoLogo color={defaultTheme.colors.white} />
        <Links>
          <Link data-testid="FooterLink" target="_blank" href={Routes.getUrlConstants({ locale }).termsUrl}>
            <FormattedMessage {...Messages.termsAndConditions} />
          </Link>
          <Link data-testid="FooterLink" target="_blank" href={Routes.getUrlConstants({ locale }).privacyUrl}>
            <FormattedMessage {...Messages.privacyPolicy} />
          </Link>
          <Link data-testid="FooterLink" target="_blank" href={Routes.getUrlConstants({ locale }).supportUrl}>
            <FormattedMessage {...Messages.support} />
          </Link>
        </Links>
        <Trademark>© 2020 Tishman Speyer</Trademark>
      </Content>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;

  @media (min-width: ${mediaQueries.m_mobile}) {
    position: absolute;
    bottom: 0;
  }
`;

const Content = styled.div`
  background: ${({ theme }) => theme.colors.footerBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.size.footerHeight};
  padding: 0 1rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: ${({ theme }) => theme.spacing.footerPadding};
  }
`;

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const Link = styled.a`
  margin: 0 1rem;
  font-size: ${typeScale.font12};
  padding-bottom: 1rem;
  color: ${({ color, theme }) => color || theme.colors.white};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  @media (min-width: ${mediaQueries.m_mobile}) {
    font-size: ${typeScale.font14};
    padding-bottom: 0;
  }
`;

const Trademark = styled.div`
  font-size: ${typeScale.font12};
  color: ${({ color, theme }) => color || theme.colors.white};
`;
