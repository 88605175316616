/** External Dependencies **/
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

/** Styling **/
import { mediaQueries } from '../../styles';

interface IBlock {
  children?: React.ReactNode;
}

const Block: FunctionComponent<IBlock> = ({ children }) => <BlockWrapper>{children}</BlockWrapper>;

const BlockWrapper = styled.div`
  @media (min-width: ${mediaQueries.m_mobile}) {
    margin: 13vh 0 0 5rem;
    width: 26rem;
  }
  z-index: ${({ theme }) => theme.zIndex.z3};
  display: flex;
  height: max-content;
  margin: 2rem 1rem;
  flex-direction: column;
  align-items: center;
`;

export const DesktopOnlyBlock = styled(BlockWrapper)`
  display: none;

  @media (min-width: ${mediaQueries.m_mobile}) {
    display: flex;
  }
`;

export const BlockHeader = styled.h2`
  padding-left: 0.5rem;
  text-align: center;
  font-weight: 400;
  color: ${(props) => props.theme.colors.secondaryColor};
`;

export const BlockText = styled.p`
  color: ${(props) => props.theme.colors.primaryColorDark};
  text-align: center;
  padding: 1rem 0 0rem 0.5rem;
  line-height: 2rem;
  font-weight: 400;
`;

export const BlockLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primaryColor};
  text-align: center;
  padding-bottom: 1rem;
  line-height: 2rem;
  font-weight: 400;
`;

export const BlockDivider = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
`;

export default Block;
