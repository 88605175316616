/** External Dependencies */
import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTime } from 'luxon';
import { List } from 'immutable';

/** Internal Dependencies */
import Messages from './Messages';
import CurrentServiceContext from './CurrentServiceContext';

/** Data layer */
import { MyServiceListAvailabilitiesActions } from 'zo-data-layer/actions';
import { MyServiceListAvailabilitesSelectors } from 'zo-data-layer/selectors';
import MyService from 'zo-data-layer/dataobjects/MyService';
import { Timeslot } from 'zo-data-layer/dataobjects';

/** Components */
import AvailabilityCarousel from './AvailabilityCarousel';
import AvailabilityTable from './AvailabilityTable';

/** Styling */
import { miscColors, mediaQueries } from 'styles';

type Props = {
  service: MyService;
};

type SelectorProps = {
  availabilities: List<Timeslot>;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  availabilities: MyServiceListAvailabilitesSelectors.getResults(),
});

const AvailabilityPicker: FC<Props> = ({ service }) => {
  const { availabilities } = useSelector(mapReduxState);
  const dispatch = useDispatch();
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const { locale } = useIntl();

  useEffect(() => {
    dispatch(MyServiceListAvailabilitiesActions.request({ id: service.enrollmentId }));
  }, [dispatch, service.enrollmentId]);

  const groupedAvailabilities = availabilities
    .filter((timeslot) => !timeslot.isCancelled)
    .groupBy((avail) => DateTime.fromJSDate(avail.startTime).startOf('day').toISO());
  const daySlots = groupedAvailabilities.keySeq().toArray();
  const prettyDaySlots = daySlots.map((slot) => DateTime.fromISO(slot).setLocale(locale).toFormat('EEE, LLL dd'));

  return (
    <Wrapper id="availabilities">
      <h3>
        <FormattedMessage {...Messages.availableTimes} />
      </h3>
      <Availabilities>
        <CurrentServiceContext.Provider value={service}>
          <CarouselWrapper>
            <AvailabilityCarousel
              daySlots={prettyDaySlots}
              selectedIndex={selectedDateIndex}
              setIndex={setSelectedDateIndex}
            />
          </CarouselWrapper>
          <AvailabilityTable timeSlots={groupedAvailabilities} selectedDay={daySlots[selectedDateIndex]} />
        </CurrentServiceContext.Provider>
      </Availabilities>
    </Wrapper>
  );
};

export default AvailabilityPicker;

const Wrapper = styled.div`
  padding-top: 1rem;
  padding-right: 1rem;
  border-top: 1px solid ${miscColors.divider};
  max-width: 14rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    max-width: 55%;
  }
`;

const Availabilities = styled.div``;

const CarouselWrapper = styled.div`
  padding: 0 2rem;
`;
