/** External Dependencies */
import React, { FC, useContext, createRef } from 'react';
import styled from 'styled-components';
import { NavLink, useParams, useHistory } from 'react-router-dom';

/** Internal Dependencies */
import { generateCategoryLink, AppLinks } from 'constants/routes';
import { LocaleContext } from 'containers/IntlContainer';
import { useCategories } from 'hooks/useCategories';
import useResize from 'hooks/useResize';

/** Components */
import { CancelIcon } from '../Icons';
import ChevronLeft from 'components/Icons/ChevronLeft';
import ChevronRight from 'components/Icons/ChevronRight';

/** Data layer */
import { MyCategory } from 'zo-data-layer/dataobjects';

/** Styling */
import { defaultTheme } from 'styles/themes';
import { neutral, brand, miscColors } from 'styles/colors';
import { mediaQueries, typeScale } from 'styles';
import { useBooleanEffect, useDevicePixelRatio } from 'hooks';
import { redirectToHome } from 'utils/navigation';
import CloudinaryImage from 'components/CloudinaryImage';

type RouteParams = {
  categoryId: string;
};

type CategoryNavigationProps = {
  adminOnly?: boolean;
};

const CategoryNavigationBar: FC<CategoryNavigationProps> = ({ adminOnly = false }) => {
  const history = useHistory();
  const { categoryId } = useParams<RouteParams>();
  const { currentLocale: locale } = useContext(LocaleContext);
  const { categories, isCategoriesLoaded } = useCategories(adminOnly);
  const navRef = createRef<HTMLDivElement>();
  const { showScrollButton } = useResize(navRef);

  let selectedCategory: MyCategory;
  if (categoryId) {
    selectedCategory = categories.find((category) => category.id === parseInt(categoryId,10));
  }
  useBooleanEffect(categoryId && isCategoriesLoaded && !selectedCategory, redirectToHome);

  const handleCloseButtonClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    e.preventDefault();
    e.stopPropagation();
    history.push(adminOnly ? AppLinks.leaderView : AppLinks.discover);
  };

  const handleNav = (direction: 'right' | 'left') => {
    if (direction === 'left') {
      return navRef.current ? (navRef.current.scrollLeft -= 200) : null;
    } else {
      return navRef.current ? (navRef.current.scrollLeft += 200) : null;
    }
  };

  const devicePixelRatio = useDevicePixelRatio();

  return (
    <>
      <MenuContainer showButtons={showScrollButton}>
        {showScrollButton && (
          <Button onClick={() => handleNav('left')} onKeyPress={() => handleNav('right')} role="button" tabIndex={0}>
            <ChevronLeft
              color={showScrollButton ? neutral.black : null}
              width={defaultTheme.size.carouselChevronSize}
            />
          </Button>
        )}
        <CategorySlider ref={navRef}>
          <CategorySliderContentContainer itemCount={categories.size}>
            {categories.map((category) => {
              const isSelected = category.id === parseInt(categoryId,10);
              return (
                <CategoryLink key={category.id} to={generateCategoryLink(category.id, adminOnly)} adminOnly={adminOnly}>
                  <CategoryIconContainer className={`icon ${isSelected ? 'active' : null}`}>
                    <CloudinaryImage
                      publicId={category.iconId}
                      width={40}
                      transformation={{ width: 40 * devicePixelRatio, height: 40 * devicePixelRatio, crop: 'fill' }}
                      colorTransformation={{ effect: 'colorize', color: isSelected ? brand.brightGreen : 'white' }}
                      formatTransformation={{ fetchFormat: 'png' }}
                      renderPlaceholder={() => (
                        <Placeholder width="2.5rem" color={isSelected ? brand.brightGreen : 'white'} />
                      )}
                    />
                  </CategoryIconContainer>

                  <CategoryTitle>{category.translationFor(locale)}</CategoryTitle>
                  {isSelected && (
                    <CloseButton onClick={handleCloseButtonClick}>
                      <CancelIcon width="1rem" color={brand.brightGreen} />
                    </CloseButton>
                  )}
                </CategoryLink>
              );
            })}
          </CategorySliderContentContainer>
        </CategorySlider>
        {showScrollButton && (
          <Button onClick={() => handleNav('right')} onKeyPress={() => handleNav('right')} role="button" tabIndex={0}>
            <ChevronRight
              color={showScrollButton ? neutral.black : null}
              width={defaultTheme.size.carouselChevronSize}
            />
          </Button>
        )}
      </MenuContainer>
      {!!selectedCategory && <SelectedCategoryHeader>{selectedCategory.translationFor(locale)}</SelectedCategoryHeader>}
    </>
  );
};

type Props = {
  showButtons?: boolean;
};

interface PlaceholderProps {
  width: number | string;
  color: string;
}

const Placeholder = ({ width, color }: PlaceholderProps) => (
  <svg width={width} height={width} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.73047" y="4.80762" width="20.5385" height="18.3846" rx="0.5" stroke={color} stroke-linejoin="round" />
    <path
      d="M3.73047 5.30762C3.73047 5.03148 3.95433 4.80762 4.23047 4.80762H23.7689C24.0451 4.80762 24.2689 5.03147 24.2689 5.30762V10.2692H3.73047V5.30762Z"
      stroke={color}
      stroke-linejoin="round"
    />
    <line
      x1="18.8848"
      y1="7.03857"
      x2="18.8848"
      y2="2.65396"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="9.11523"
      y1="2.65381"
      x2="9.11523"
      y2="7.03842"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const SelectedCategoryHeader = styled.h1`
  color: ${({ theme }) => theme.colors.primaryColor};
  padding-top: 0.5rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding-top: 1.5rem;
  }
`;

const MenuContainer = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  margin: ${({ showButtons }) => (showButtons ? '-1rem 0 1rem' : '0 3rem')};

  @media (min-width: ${mediaQueries.m_tablet}) {
    margin: ${({ showButtons }) => (showButtons ? '0 -3rem' : '0 -0.5rem')};
  }
`;

const CategorySlider = styled.div`
  display: flex;
  scroll-behavior: smooth;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  justify-content: flex-start;
`;

const CategorySliderContentContainer = styled.div<{ itemCount: number }>`
  min-width: 100%;
  display: flex;
  justify-content: ${({ itemCount = 0 }) => (itemCount >= 7 ? 'space-between' : 'start')};
  gap: 2rem;
`;

const CategoryTitle = styled.p`
  white-space: normal;
  text-align: center;
  margin: 0.5rem 0;
  font-size: ${typeScale.font14};
`;

const CloseButton = styled.span`
  display: flex;
  align-contents: center;
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 0.5em;
  right: 1em;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border-radius: 35px;
  width: 50px;
  height: 43px;
  opacity: 1;
  cursor: pointer;
  margin-top: 2rem;
`;

type LinkProps = {
  adminOnly: boolean;
};

const CategoryLink = styled(NavLink)<LinkProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7rem;
  margin-top 0.5rem;
  z-index: 99;
  cursor: pointer;
  color: ${({ theme }) => `${theme.colors.black}`};

  &:focus {
    outline: none;
  }

  & .icon {
    background: ${({ theme }) => theme.colors.primaryColor};
  }

  &:focus-visible .icon {
    outline: 1px solid ${miscColors.inactiveGrey};
    outline-offset: 4px;
    outline-radius: 12px;
    background: ${({ theme }) => theme.colors.primaryColorDark};
  }

  &:hover .icon {
    background: ${({ theme }) => theme.colors.primaryColorDark};
  }

  &:active .icon {
    background: ${({ theme }) => theme.colors.primaryColorDarker};
  }

  & .icon.active {
    background: ${({ theme }) => theme.colors.primaryColorLight};
  }
`;

const CategoryIconContainer = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s ease;
`;

export default CategoryNavigationBar;
