/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/** Data layer */
import { MyScheduledServiceBookingCreateActions, MyScheduledServiceBookingGetActions } from 'zo-data-layer/actions';
import { BookingType } from 'zo-data-layer/constants/booking';
import { MyScheduledServiceBookingGetSelectors } from 'zo-data-layer/selectors';

/** Components */
import SuccessPage from './SuccessPage';
import ScheduledServiceBooking from 'zo-data-layer/dataobjects/ScheduledServiceBooking';

type RouteParams = {
  bookingId: string;
};

type SelectorProps = {
  isSuccess: boolean;
  booking: ScheduledServiceBooking;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  isSuccess: MyScheduledServiceBookingGetSelectors.isSuccess(),
  booking: MyScheduledServiceBookingGetSelectors.getBooking(),
});

const ScheduledSuccessPage: FC = () => {
  const { isSuccess, booking } = useSelector(mapReduxState);
  const { bookingId } = useParams<RouteParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MyScheduledServiceBookingGetActions.request(parseInt(bookingId, 10)));
  }, [bookingId, dispatch]);

  useEffect(
    () => () => {
      dispatch(MyScheduledServiceBookingCreateActions.reset());
    },
    [dispatch]
  );

  return (
    <SuccessPage
      currentBooking={booking}
      bookingType={BookingType.ScheduledService}
      successId={bookingId}
      isSuccess={isSuccess}
    />
  );
};

export default ScheduledSuccessPage;
