import { useDispatch, useSelector } from 'react-redux';
import { MyCategoriesGetSelectors } from 'zo-data-layer/selectors';
import { MyCategory } from 'zo-data-layer/dataobjects';
import { useEffect } from 'react';
import { MyCategoriesGetActions } from 'zo-data-layer/actions';

const memoizedSelectors = {
  category: MyCategoriesGetSelectors.getCategory(),
  isLoading: MyCategoriesGetSelectors.isLoading(),
  isLoaded: MyCategoriesGetSelectors.isSuccess(),
  isFailed: MyCategoriesGetSelectors.isFailed(),
};

export function useCategory(categoryId?: string | number) {
  const dispatch = useDispatch();

  const category: MyCategory = useSelector(memoizedSelectors.category);
  const isLoading: boolean = useSelector(memoizedSelectors.isLoading);
  const isLoaded: boolean = useSelector(memoizedSelectors.isLoaded);
  const isFailed: boolean = useSelector(memoizedSelectors.isFailed);

  useEffect(() => {
    if (categoryId && category?.id !== categoryId) {
      dispatch(MyCategoriesGetActions.request(categoryId));
    }
  }, [category, categoryId, dispatch]);

  return { category, isLoading, isLoaded, isFailed };
}
