import { defineMessages, MessageDescriptor } from 'react-intl';

const signUpToday: MessageDescriptor = {
  id: 'forms.signUpToday',
  defaultMessage: 'Sign up for ZO Today!',
};

const register: MessageDescriptor = {
  id: 'forms.register',
  defaultMessage: 'Register',
};

const verifyEmail: MessageDescriptor = {
  id: 'forms.verifyEmail',
  defaultMessage: 'Verify Email',
};

const alreadyHaveAccount: MessageDescriptor = {
  id: 'forms.alreadyHaveAccount',
  defaultMessage: 'Already have an account',
};

const needHelp: MessageDescriptor = {
  id: 'forms.needHelp',
  defaultMessage: 'Need Help?',
};

const contactSupport: MessageDescriptor = {
  id: 'forms.contactSupport',
  defaultMessage: 'Contact Support',
};

const signIn: MessageDescriptor = {
  id: 'forms.signIn',
  defaultMessage: 'Sign in',
};

export default defineMessages({
  signUpToday,
  register,
  verifyEmail,
  alreadyHaveAccount,
  needHelp,
  contactSupport,
  signIn,
});
