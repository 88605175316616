/** External Dependencies */
import React, { FC } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components';

/** Components */
import Button from 'components/Button';

/** Styling */
import { mediaQueriesNumbers } from 'styles/mediaQueries';
import { defaultTheme } from 'styles/themes';

type Props = {
  daySlots: string[];
  setIndex: (index: number) => void;
  selectedIndex: number;
};

const responsive = {
  desktop: {
    breakpoint: { max: mediaQueriesNumbers.desktop, min: mediaQueriesNumbers.laptop },
    items: 4,
  },
  laptop: {
    breakpoint: { max: mediaQueriesNumbers.laptop, min: mediaQueriesNumbers.m_tablet },
    items: 3,
  },
  tablet: {
    breakpoint: { max: mediaQueriesNumbers.m_tablet, min: mediaQueriesNumbers.s_tablet },
    items: 2,
  },
  mobile: {
    breakpoint: { max: mediaQueriesNumbers.s_tablet, min: 0 },
    items: 1,
  },
};

const AvailabilityCarousel: FC<Props> = ({ daySlots, selectedIndex, setIndex }) => {
  const handleClick = (index: number) => {
    setIndex(index);
  };

  return (
    <StyledCarousel partialVisible={false} responsive={responsive} arrows={true} renderButtonGroupOutside>
      {daySlots.map((slot: string, index: number) => (
        <DayWrapper key={slot}>
          <StyledButton title={slot} onClick={() => handleClick(index)} isActive={selectedIndex === index} />
        </DayWrapper>
      ))}
    </StyledCarousel>
  );
};

export default AvailabilityCarousel;

const StyledCarousel = styled(Carousel)`
  display: flex;
  position: inherit;
  padding-bottom: 1rem;

  .react-multiple-carousel__arrow--left {
    left: calc(-2% + 1px);
    background: none;

    ::before {
      color: black;
    }
  }

  .react-multiple-carousel__arrow--right {
    right: calc(43% + 1px);
    background: none;

    ::before {
      color: black;
    }
  }
`;

type ButtonProps = {
  isActive: boolean;
};

const StyledButton = styled(Button)<ButtonProps>`
  color: ${({ isActive }) => (isActive ? defaultTheme.colors.white : defaultTheme.colors.primaryColor)};
  background: ${({ isActive }) => (isActive ? defaultTheme.colors.primaryColor : defaultTheme.colors.white)};
  border: 1px solid ${defaultTheme.colors.primaryColor};
  padding: 0;
  min-width: 6.5rem;
  height: 2.5rem;
`;

const DayWrapper = styled.div`
  margin-right: 1rem;
`;
