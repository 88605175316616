/** External Dependencies */
import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { FieldElement, Message } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import pt from 'date-fns/locale/pt';
import de from 'date-fns/locale/de';

/** Components */
import { Div, Label, ErrorDiv, Compactable } from 'components/Input';
import CalendarIcon from 'components/Icons/CalendarIcon';

/** Styling */
import { typeScale } from '../../styles';
import { defaultTheme } from '../../styles/themes';

registerLocale('en', en);
registerLocale('fr', fr);
registerLocale('pt', pt);
registerLocale('de', de);

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | JSX.Element;
  error?: Message;
  innerRef?: (ref: FieldElement<any>) => void;
  locale?: string;
  selected?: string;
  compact?: boolean;
};

const DatePicker: FC<Props> = ({ id, label, error, innerRef, locale, onChange, selected, compact, ...inputProps }) => (
  <Div>
    <StyledLabel compact={compact} htmlFor={id}>
      <LabelWrapper compact={compact}>{label}</LabelWrapper>
      <InputWrapper>
        <StyledDatePicker
          id={id}
          ref={innerRef}
          locale={locale}
          placeholder={typeof label === 'string' ? label : ''}
          selected={selected}
          onChange={onChange}
          dateFormat="yyyy-MM-dd"
          isClearable
          {...inputProps}
        />
        <IconWrapper>
          <CalendarIcon width={defaultTheme.size.serviceDetailIconSize} />
        </IconWrapper>
      </InputWrapper>
    </StyledLabel>
    {error ? <ErrorDiv>{error}</ErrorDiv> : null}
  </Div>
);

export default DatePicker;

const StyledLabel = styled(Label)`
  position: relative;
`;

const LabelWrapper = styled.div<Compactable>`
  display: flex;
  margin-top: ${({ compact }) => (compact ? null : '1rem')};
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const IconWrapper = styled.div``;

export const StyledDatePicker = styled(ReactDatePicker)`
  width: 100%;
  font-size: ${typeScale.paragraph};
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  transition: border-color 1s ease, color 1s ease;
  padding: 0.5rem 0;
  :active {
    color: ${(props) => props.theme.colors.black};
  }
  :hover:enabled {
    border-bottom: 1px solid ${(props) => props.theme.colors.black};
  }
  :focus {
    outline: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.black};
    font-size: ${typeScale.paragraph} !important;
  }
  :focus,
  :not(:placeholder-shown) ~ ${Label} {
  }
  :placeholder-shown ~ ${Label} {
    opacity: 0;
    bottom: 1rem;
  }

  :disabled {
    background: none;
    opacity: 0.5;

    + label {
      opacity: 0.5;
    }
  }
`;
