/** External Dependencies */
import React, { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

/** Internal Dependencies */
import Messages from './Messages';

/** Components */
import { TileTitle, TileInfo, TileSubInfo, InfoPoint, StyledTileProps } from '../Tile';
import { CalendarIcon, ClockIcon, LocationIcon, WaitlistOnIcon, SuccessIcon, QuantityIcon } from '../Icons';
import { LocaleContext } from 'containers/IntlContainer';

/** Data layer */
import GenericEventBooking from 'zo-data-layer/dataobjects/GenericEventBooking';

/** Styling */
import { typeScale, mediaQueries, status, brand, miscColors } from 'styles';
import ShareButton from 'components/ShareButton';
import GenericTimeBasedBooking from 'zo-data-layer/dataobjects/GenericTimeBasedBooking';

type Props = {
  booking: GenericTimeBasedBooking;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const inPersonEventLocationRow = (booking: GenericEventBooking) => (
  <GreyInfoPoint>
    <LocationIcon />
    {booking.locationName}
  </GreyInfoPoint>
);

const locationRow = (booking: GenericTimeBasedBooking) => {
  if (booking.isEventBooking() && !booking.isVirtual()) {
    return inPersonEventLocationRow(booking);
  }
};

const BookingTile: FC<Props> = ({ booking, onClick }) => {
  const isOnWaitlist = booking.isEventWaitlist();
  const { locale } = useContext(LocaleContext);

  return (
    <StyledBookingTile>
      <StyledBookingTileInfo onClick={onClick} cursor={onClick ? 'pointer' : 'auto'} isOnWaitlist={isOnWaitlist}>
        <StyledBookingTileTitle>{booking.title}</StyledBookingTileTitle>
        <TileSubInfo>
          <GreyInfoPoint>
            <CalendarIcon />
            {booking.formattedDay(locale)}
          </GreyInfoPoint>
          <GreyInfoPoint>
            <ClockIcon />
            {booking.formattedTime(locale)}
          </GreyInfoPoint>
          <GreyInfoPoint>
            <QuantityIcon />
            {booking.quantity}
          </GreyInfoPoint>
          {locationRow(booking)}
          <BlackBoldInfoPoint>
            {isOnWaitlist ? <WaitlistOnIcon /> : <SuccessIcon />}
            {isOnWaitlist ? <FormattedMessage {...Messages.onWaitlist} /> : <FormattedMessage {...Messages.booked} />}
          </BlackBoldInfoPoint>
        </TileSubInfo>
      </StyledBookingTileInfo>
      <ShareButton service={booking} />
    </StyledBookingTile>
  );
};

const StyledBookingTile = styled.div<StyledTileProps>`
  position: relative;
  border-bottom: 1px solid ${miscColors.divider};
  display: flex;
  align-items: flex-start;

  @media (min-width: ${mediaQueries.m_mobile}) {
    z-index: ${({ theme }) => theme.zIndex.z3};
    margin: 1rem 1rem 0 0;
    padding-bottom: 1.4rem;
  }
  width: 100%;
  margin: 1rem 0;
`;

const StyledBookingTileTitle = styled(TileTitle)`
  margin-bottom: 0.5rem;
  height: 2.4rem;
  font-size: ${typeScale.font18};
`;

const StyledBookingTileInfo = styled(TileInfo)`
  padding: 0.5rem 1rem;
  border-left: 6px solid ${(props) => (props.isOnWaitlist ? status.waitlist : brand.success)};
  cursor: ${({ cursor }) => cursor};
  flex-grow: 1;
`;

const BlackBoldInfoPoint = styled(InfoPoint)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
`;

const GreyInfoPoint = styled(InfoPoint)`
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export default BookingTile;
