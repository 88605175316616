import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';

import useMemoizedObject from './useMemoizedObject';

interface IPaginatedAction {
  pageSize?: number;
  page?: number;
  appendResults?: boolean;
}

type RequestAction<P extends IPaginatedAction> = (params: P) => AnyAction;
type UsePaginationOptions = {
  initPage?: number;
  pageSize?: number;
  totalCount: number;
};

/**
 * all the params passed to this hook should be immutable
 * otherwise, it would cause fetchFirstPage and fetchNextPage being re-declared on render
 */
function usePagination<P extends IPaginatedAction>(
  requestAction: RequestAction<P>,
  actionParams: P,
  { initPage = 1, pageSize = 10, totalCount }: UsePaginationOptions
) {
  const [page, setPage] = useState(initPage);
  const dispatch = useDispatch();
  const hasNext = page * pageSize < totalCount;

  const memoizedObject = useMemoizedObject<P>(actionParams);

  const fetchFirstPage = useCallback(() => {
    dispatch(requestAction({ ...memoizedObject, pageSize, page: initPage, appendResults: false }));
    setPage(1);
  }, [memoizedObject, dispatch, initPage, pageSize, requestAction]);

  const fetchNextPage = () => {
    if (hasNext) {
      dispatch(requestAction({ ...actionParams, pageSize, page: page + 1, appendResults: true }));
      setPage(page + 1);
    }
  };

  return { fetchFirstPage, fetchNextPage, page, hasNext };
}

export default usePagination;
