import { defineMessages, MessageDescriptor } from 'react-intl';

const newServices: MessageDescriptor = {
  id: 'discover.newServices',
  defaultMessage: 'Trending',
};

export default defineMessages({
  newServices,
});
