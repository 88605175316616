/** External Dependencies */
import React, { FC, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';

/** Internal dependencies */
import Messages from './Messages';
import { AppLinks } from 'constants/routes';
import { Modals } from 'constants/Layouts';

/** Styling */
import { mediaQueries, miscColors, defaultTheme, typeScale, neutral } from 'styles';

/** Data layer */
import { CurrencyTypes } from 'zo-data-layer/constants';
import { PromoCodeCalculator } from 'zo-data-layer/utils/calculators';
import { openModal } from 'zo-data-layer/actions';
import { Timeslot } from 'zo-data-layer/dataobjects';

/** Components */
import CreditCardSelect from '../CreditCard/CreditCardSelect';
import ServiceInfoBlock from './ServiceInfoBlock';
import { ChevronLeft, ChevronRight } from '../Icons';
import QuantityPicker from './QuantityPicker';
import SubmitBookingButton from './SubmitBookingButton';
import AspectRatioContainer from '../AspectRatioContainer';
import CloudinaryImage from '../CloudinaryImage';
import VirtualIcon from 'components/Icons/VirtualIcon';
import ShareButton from 'components/ShareButton';
import { CurrentServiceContext, CurrentServiceInfo } from '../../contexts';
import { ScheduledAvailability } from 'zo-data-layer/dataobjects/ScheduledAvailabilityCollection';
import FormattedPrice from '../FormattedPrice';

type Props = {
  quantity: number;
  setQuantity?: (quantity: number) => void;
  selectedCardID: string;
  setSelectedCardID: (ID: string) => void;
  promocode?: string;
  promocodeData?;
  timeSlot?: Timeslot;
  availability?: ScheduledAvailability;
};

const ServiceDetails: FC<Props> = ({
  quantity,
  setQuantity,
  selectedCardID,
  setSelectedCardID,
  promocode,
  promocodeData,
  timeSlot,
  availability,
}) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { service } = useContext<CurrentServiceInfo>(CurrentServiceContext);

  const getOriginalPrice = () => {
    if (service.isScheduledService && availability) {
      return service.scheduledPrice(availability) * quantity;
    }
    return service.originalPrice * quantity;
  };

  const getDecimalPrice = () => {
    if (service.isScheduledService && availability) {
      return service.scheduledDecimalPrice(availability) * quantity;
    }
    return service.decimalPrice * quantity;
  };

  const currency = service.currency || CurrencyTypes.usd;

  const originalPrice = getOriginalPrice();

  const decimalPrice = getDecimalPrice();

  const getBookingPrice = () => {
    if (service.isScheduledService && availability) {
      return service.scheduledDecimalPrice(availability);
    }
    return service.decimalPrice;
  };

  const getAmountPromoSaved = () => PromoCodeCalculator.calculateAmountSaved(originalPrice, promocodeData);

  const finalPrice = useMemo(() => {
    if (promocodeData) {
      return PromoCodeCalculator.calculateNewTotal(originalPrice, promocodeData);
    }
    return decimalPrice;
  }, [decimalPrice, originalPrice, promocodeData]);

  const isFree = !service.paymentRequired || finalPrice === 0;
  const isPaymentRequired = service.paymentRequired && !isFree;

  useEffect(() => {
    if (isFree) {
      setSelectedCardID(undefined);
    }
  }, [quantity, isFree, setSelectedCardID]);

  const isTimeslotWaitlist = timeSlot && timeSlot.remainingCapacity < 1;
  const isWaitlistSpotAvailable = timeSlot && timeSlot.remainingCapacity > 0 && service.isWaitlist;
  const notWaitlist = !service.isWaitlist || isWaitlistSpotAvailable;

  const showQuantityPicker = !isTimeslotWaitlist && service.isEvent && notWaitlist;
  const showPaymentMethods = !isTimeslotWaitlist && notWaitlist && !isFree;
  const showPromoCode = !isTimeslotWaitlist && notWaitlist && isPaymentRequired;

  // TODO: Update all these in the data-layer once styling is finalized
  const CloudinaryConfigs = { aspectRatio: 2.6, quality: 'auto:best', crop: 'crop' };

  return (
    <ServiceDetailsWrapper>
      <BackToOverview>
        <ChevronLeft color={defaultTheme.colors.white} width={defaultTheme.size.iconSize} />
        <Link to={`${AppLinks.serviceInfo}${service.enrollmentId}`}>
          <LinkSpan className="bold">
            <FormattedMessage {...Messages.backToOverview} />
          </LinkSpan>
        </Link>
      </BackToOverview>
      <Columns>
        <LeftColumn>
          <Header>
            <ServiceDetailsHeader>
              <FormattedMessage {...Messages.bookingDetails} />
            </ServiceDetailsHeader>
            <SubHeader>
              {service.isVirtual() && (
                <LiveStream className="bold purple">
                  <VirtualIconWrapper>
                    <VirtualIcon color={neutral.black} />
                  </VirtualIconWrapper>
                  {service.isZoom ? (
                    <FormattedMessage {...Messages.zoomEvent} />
                  ) : (
                    <FormattedMessage {...Messages.livestream} />
                  )}
                </LiveStream>
              )}
              <InfoPoint>
                <ServiceHeaderContainer>
                  <ServiceTitleHeader>{service.title}</ServiceTitleHeader>
                  <ShareButton service={service} />
                </ServiceHeaderContainer>
                <PartnerText>
                  <FormattedMessage {...Messages.providedBy} /> {service.partner}
                </PartnerText>
              </InfoPoint>
            </SubHeader>
          </Header>
          <InfoContent>
            <ServiceInfoBlock
              service={service}
              availability={availability}
              timeSlot={timeSlot}
              showWaitlist
              scheduledPrice={decimalPrice}
            />
            {showQuantityPicker && <QuantityPicker service={service} quantity={quantity} setQuantity={setQuantity} />}
            {showPaymentMethods && (
              <CreditCardSelect selectedCardID={selectedCardID} setSelectedCardID={setSelectedCardID} />
            )}
          </InfoContent>
        </LeftColumn>
        <RightColumn>
          <AspectRatioContainer ratio={2.6} isCenter>
            <ServiceImage
              className="roundUpperCorners"
              publicId={service.cloudinaryPublicId}
              transformation={CloudinaryConfigs}
            />
          </AspectRatioContainer>
          <InfoContent>
            <InfoPoint>
              <OrderSummaryHeader>
                <FormattedMessage {...Messages.orderSummary} />
              </OrderSummaryHeader>
            </InfoPoint>
            <PricePoint className="darkGrey">
              {service.title} {showPaymentMethods}
              <span>
                {!isPaymentRequired && <FormattedMessage {...Messages.free} />}
                {isPaymentRequired && <FormattedPrice price={getBookingPrice()} currency={currency} />}
              </span>
            </PricePoint>
            <PricePoint className="darkGrey">
              <FormattedMessage {...Messages.quantity} />
              <span>{quantity}</span>
            </PricePoint>
            <PricePoint className="darkGrey">
              <FormattedMessage {...Messages.subtotal} />
              <span>
                {!isPaymentRequired && <FormattedMessage {...Messages.free} />}
                {isPaymentRequired && <FormattedPrice price={decimalPrice} currency={currency} />}
              </span>
            </PricePoint>
            {promocode && (
              <PricePoint className="darkGrey">
                {promocode}
                <span>
                  -<FormattedPrice price={getAmountPromoSaved()} currency={currency} />
                </span>
              </PricePoint>
            )}
            <InfoDivider />
            <TotalPricePoint className="bold purple">
              <FormattedMessage {...Messages.total} />
              <span>
                {isFree && <FormattedMessage {...Messages.free} />}
                {!isFree && <FormattedPrice price={finalPrice} currency={currency} />}
              </span>
            </TotalPricePoint>
          </InfoContent>
          <PromoCodeWrapper>
            {showPromoCode && (
              <>
                <InfoPoint>
                  <OrderSummaryHeader>
                    <FormattedMessage {...Messages.promoCode} />
                  </OrderSummaryHeader>
                </InfoPoint>
                <PricePoint className="darkGrey">
                  <FormattedMessage {...Messages.haveAPromoCode} />
                  <PromoCodeSpan
                    role="button"
                    tabIndex={0}
                    onClick={() => dispatch(openModal(Modals.Promocode))}
                    onKeyPress={() => dispatch(openModal(Modals.Promocode))}
                  >
                    <FormattedMessage {...Messages.apply} />
                    <IconWrapper>
                      <ChevronRight />
                    </IconWrapper>
                  </PromoCodeSpan>
                </PricePoint>
              </>
            )}
          </PromoCodeWrapper>
          <SubmitBookingButton
            selectedCardID={selectedCardID}
            service={service}
            isFree={isFree}
            timeSlot={service.isInventory ? null : timeSlot}
            quantity={service.isInventory ? null : quantity}
            promocode={promocode}
            stripe={stripe}
            availability={availability}
          />
        </RightColumn>
      </Columns>
    </ServiceDetailsWrapper>
  );
};

export default ServiceDetails;

const ServiceDetailsWrapper = styled.div`
  animation: opacityChange 1s ease-in-out both;
  overflow: hidden;
  width: 100%;
  padding: 2rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 0;
  }
`;

const ServiceHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BackToOverview = styled.div`
  position: absolute;
  display: flex;
  font-weight: 400;
  top: -5rem;
  z-index: ${({ theme }) => theme.zIndex.navbar};
  margin-left: 1rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    margin-left: 0;
  }
`;

const LiveStream = styled.div`
  display: flex;
  vertical-align: center;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    flex-direction: row;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${mediaQueries.m_mobile}) {
    width: 50%;
    padding-right: ${defaultTheme.size.twoColumnGapWidth};
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${mediaQueries.m_mobile}) {
    width 50%;
    padding-left: ${defaultTheme.size.twoColumnGapWidth};
  }
`;

const Header = styled.div`
  width: 100%;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${miscColors.divider};
`;

const ServiceDetailsHeader = styled.h1`
  margin-top: 0rem;
`;

const SubHeader = styled.div``;

const ServiceTitleHeader = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  flex-grow: 1;
`;

const PartnerText = styled.div`
  font-size: ${typeScale.font12};
  color: black;
`;

const LinkSpan = styled.span`
  padding-left: 1rem;
  font-size: ${typeScale.font14};
  color: white;
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
`;

const InfoPoint = styled.span`
  line-height: 2rem;
`;

const ServiceImage = styled(CloudinaryImage)`
  width: 100%;
  object-fit: cover;
`;

const OrderSummaryHeader = styled.p`
  font-weight: 400;
  margin-top: 2rem;
`;

const PricePoint = styled.span`
  line-height: 2rem;
  display: flex;
  justify-content: space-between;
`;

const TotalPricePoint = styled(PricePoint)`
  font-size: 1.25rem;
`;

const InfoDivider = styled.div`
  margin: 1rem 0rem;
  border-bottom: 1px solid ${miscColors.divider};
`;

const PromoCodeWrapper = styled.div`
  padding: 2rem 0;
`;

const PromoCodeSpan = styled.span`
  display: flex;
  cursor: pointer;
`;

const IconWrapper = styled.span`
  padding-left: 0.4rem;
`;

const VirtualIconWrapper = styled.div`
  padding-right: 0.5rem;
  padding-top: 0.1rem;
`;
