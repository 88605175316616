import { Map } from 'immutable';

export default class PaymentSourceObject {
  private paymentSourceInfo: Map<string, any>;
  constructor(paymentSourceInfo: Map<string, any>) {
    this.paymentSourceInfo = paymentSourceInfo;
  }

  get id(): string {
    return this.paymentSourceInfo.get('id');
  }

  get last4(): string {
    return this.paymentSourceInfo.getIn(['card', 'last4']) as string;
  }

  get brand(): string {
    return this.paymentSourceInfo.getIn(['card', 'brand']) as string;
  }
}
