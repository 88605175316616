import React from 'react';

import { MyService } from 'zo-data-layer/dataobjects';

export type CurrentServiceInfo = {
  service: MyService;
  isLeaderSection?: boolean;
};

export const CurrentServiceContext = React.createContext<CurrentServiceInfo>({
  service: undefined,
  isLeaderSection: false,
});
