/** External Dependencies **/
import React, { FC } from 'react';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

/** Interal Dependencies */
import Messages from './Messages';

/** Data layer */
import { ForgotPasswordSelectors } from 'zo-data-layer/selectors';
import { forgotPassword } from 'zo-data-layer/actions';

/** Components */
import Button from 'components/Button';
import Overlay, { Size as OverlaySize } from 'components/Overlay';
import { ForgotPasswordForm } from 'components/Forms/ForgotPasswordForm/ForgotPasswordForm';

/** Styling */
import { typeScale } from 'styles';

type Props = {
  requestForgotPassword: (data: { email: string }) => AnyAction;
  isLoading: boolean;
};

const ForgotPasswordPage: FC<Props> = ({ requestForgotPassword, isLoading }) => (
  <>
    <Overlay size={OverlaySize.Small}>
      <ForgotPasswordForm requestForgotPassword={requestForgotPassword} isLoading={isLoading} />
      <Link to="/login">
        <StyledButton title={<FormattedMessage {...Messages.backToLogin} />} buttonType="link" />
      </Link>
    </Overlay>
  </>
);

type SelectorProps = {
  isLoading: boolean;
};

const mapStateToProps = createStructuredSelector<any, SelectorProps>({
  isLoading: ForgotPasswordSelectors.isLoading(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  requestForgotPassword: (data: { email: string }) => dispatch(forgotPassword(data.email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);

const StyledButton = styled(Button)`
  margin: 2rem 0 1rem;
  font-size: ${typeScale.font17};
`;
