import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { Map } from 'immutable';

import env, { isProduction, isTest } from 'zo-data-layer/constants/env';
import {
  getServiceAnalyticsAttributes,
  getCategoryAnalyticsAttributes,
  getUserProperties,
  MeAnalyticsType,
  ServiceAnalyticsType,
  CategoryAnalyticsType,
  UserProperties,
  NavigationEventParams,
} from 'zo-data-layer/utils/analytics';
import { AnalyticsEvents } from 'zo-data-layer/constants';

import { pathNameToNavigationName } from '../constants/routes';

const productionFirebaseConfig = {
  apiKey: 'AIzaSyDCfzjsWdglXABboY1lt8GWWBfrN1wR6ik',
  authDomain: 'top-of-the-rock.firebaseapp.com',
  databaseURL: 'https://top-of-the-rock.firebaseio.com',
  projectId: 'top-of-the-rock',
  storageBucket: 'top-of-the-rock.appspot.com',
  messagingSenderId: '551733145887',
  appId: '1:551733145887:web:5c9f23eba2a80b6cfe402a',
  measurementId: 'G-21P56B5WXC',
};

const stagingFirebaseConfig = {
  apiKey: 'AIzaSyDCfzjsWdglXABboY1lt8GWWBfrN1wR6ik',
  authDomain: 'top-of-the-rock.firebaseapp.com',
  databaseURL: 'https://top-of-the-rock.firebaseio.com',
  projectId: 'top-of-the-rock',
  storageBucket: 'top-of-the-rock.appspot.com',
  messagingSenderId: '551733145887',
  appId: '1:551733145887:web:c72b7d1937b09bb1fe402a',
  measurementId: 'G-7FQG0FJDHF',
};

if (!isTest() && firebase.analytics.isSupported()) {
  firebase.initializeApp(isProduction() ? productionFirebaseConfig : stagingFirebaseConfig);
  firebase.analytics();
}

export type AnalyticsEventParams = {
  eventName: AnalyticsEvents;
  category?: string;
  action?: string;
  label?: string;
  value?: any;
  [key: string]: string | number | boolean;
};

interface IAnalyticsReporter {
  logNavigationEvent(NavigationEventParams);
  logEvent(eventParams: AnalyticsEventParams);
  setUserProperties(userProperties: UserProperties);
  setUserId(userId: number);
}

class DevAnalyticsReporter implements IAnalyticsReporter {
  logNavigationEvent(navigationEventParams: NavigationEventParams) {
    console.log('Analytics', 'Navigation Event', navigationEventParams);
  }

  logEvent(params) {
    console.log('Analytics', 'Tracking event', {
      ...params,
      app_version: process.env.REACT_APP_VERSION,
      app_name: process.env.REACT_APP_NAME,
    });
  }

  setUserProperties(userProperties) {
    console.log('Analytics', 'SetUserProperties', userProperties);
  }

  setUserId(userId) {
    console.log('Analytics', 'setUserId', userId);
  }
}

class FirebaseAnalyticsReporter {
  logNavigationEvent(navigationEventParams: NavigationEventParams) {
    firebase.analytics().setCurrentScreen(window.location.pathname);
    this.logEvent({ eventName: AnalyticsEvents.NavigationEvent, ...navigationEventParams });
  }

  logEvent({ eventName, category, action, label, value, ...rest }: AnalyticsEventParams) {
    firebase.analytics().logEvent(eventName, {
      category: category || 'User',
      action: action || eventName,
      label,
      value: value || JSON.stringify(rest),
      app_version: process.env.REACT_APP_VERSION,
      app_name: process.env.REACT_APP_NAME,
      ...rest,
    });
  }

  setUserProperties(userProperties) {
    firebase.analytics().setUserProperties(userProperties);
  }

  setUserId(userId) {
    firebase.analytics().setUserId(userId);
  }
}

const paymentProps = ({ service, me, paymentType, ...params }) => ({
  ...params,
  ...getServiceAnalyticsAttributes(service, me),
  paymentType,
});

const bookedEventProps = ({ booking, ...params }) => ({
  ...params,
  id: booking.get('id'),
  booking_title: booking.getIn(['occurrence', 'event', 'title']),
});

class AnalyticsReporter {
  private reporter: IAnalyticsReporter;

  constructor() {
    if (!firebase.analytics.isSupported()) {
      this.reporter = new DevAnalyticsReporter();
    } else {
      switch (env()) {
        case 'production':
        case 'staging':
          this.reporter = new FirebaseAnalyticsReporter();
          break;
        default:
          this.reporter = new DevAnalyticsReporter();
          break;
      }
    }
  }

  logNavigationEvent(isLoggedIn: boolean) {
    const pathName = window.location.pathname;
    this.reporter.logNavigationEvent({
      navigationName: pathNameToNavigationName(pathName),
      isMobile: false,
      isWeb: true,
      webRouteName: pathName,
      isLoggedIn,
    });
  }

  logEvent(eventParams: AnalyticsEventParams) {
    this.reporter.logEvent(eventParams);
  }

  logServiceEvent(eventName: AnalyticsEvents, service: ServiceAnalyticsType, me: MeAnalyticsType, params = {}) {
    this.logEvent({ eventName, ...getServiceAnalyticsAttributes(service, me), ...params });
  }

  logCategoryEvent(category: CategoryAnalyticsType) {
    this.logEvent({ eventName: AnalyticsEvents.CategoryViewed, ...getCategoryAnalyticsAttributes(category) });
  }

  logPaymentEvent(
    eventName: AnalyticsEvents,
    service: ServiceAnalyticsType,
    me: MeAnalyticsType,
    paymentType,
    params = {}
  ) {
    this.logEvent({ eventName, ...paymentProps({ service, me, paymentType, ...params }) });
  }

  logBookingEvent(eventName: AnalyticsEvents, booking: Map<string, any>, params = {}) {
    this.logEvent({ eventName, ...bookedEventProps({ booking, ...params }) });
  }

  setAllUserProperties(userData: Map<string, any>) {
    this.reporter.setUserId(userData.get('id'));
    this.reporter.setUserProperties(getUserProperties(userData));
  }
}

export default new AnalyticsReporter();
