/** External Dependencies **/
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
/** Internal Dependencies */
import Messages from './Messages';
import { Modals } from 'constants/Layouts';
import { redirectToHome, redirectToLeaderView } from 'utils/navigation';
/** Data layer */
import { openModal } from 'zo-data-layer/actions';
import { BookingType } from 'zo-data-layer/constants/booking';
import { ServiceType } from 'zo-data-layer/constants/services';
/** Components */
import CalendarIcon from 'components/Icons/CalendarIcon';
import CancelIcon from 'components/Icons/CancelIcon';
import SuccessfulCheckmarkIcon from 'components/Icons/SuccessfulCheckmarkIcon';
import SuccessfulWaitlistIcon from 'components/Icons/SuccessfulWaitlistIcon';
import BookingInfoBlock from 'components/ServiceBookingFlow/BookingInfoBlock';
import LoadingIndicator from 'components/LoadingIndicator';
import ModalContainer from 'components/ModalContainer';
import AddToCalendar from 'components/AddToCalendar';
import CancelBookingModal from 'components/CancelBookingModal';
import VirtualIcon from 'components/Icons/VirtualIcon';
import AspectRatioContainer from 'components/AspectRatioContainer';
import CloudinaryImage from 'components/CloudinaryImage';
import Button from 'components/Button';
/** Styling */
import { brand, defaultTheme, mediaQueries, miscColors, neutral, typeScale } from 'styles';
import VideoModal from 'components/VideoModal';
import { PlayIcon } from 'components/Icons';
import { isAfter, isBefore, sub } from 'date-fns';
import { useAnalytics } from '../../analytics';
import AnalyticsEvents from 'zo-data-layer/constants/AnalyticsEvents';
import GenericEventBooking from 'zo-data-layer/dataobjects/GenericEventBooking';
import { InventoryBooking } from 'zo-data-layer/dataobjects';
import ShareButton from 'components/ShareButton';
import GenericTimeBasedBooking from 'zo-data-layer/dataobjects/GenericTimeBasedBooking';

const renderIcon = (bookingType: BookingType) => {
  if (bookingType === BookingType.Waitlist) {
    return <SuccessfulWaitlistIcon />;
  } else {
    return <SuccessfulCheckmarkIcon />;
  }
};

const renderHeader = (bookingType: BookingType) => {
  if (bookingType === BookingType.Waitlist) {
    return <FormattedMessage {...Messages.youreOnTheWaitlist} />;
  } else {
    return <FormattedMessage {...Messages.youreBooked} />;
  }
};

// TODO: Update all these in the data-layer once styling is finalized
const CloudinaryConfigs = { aspectRatio: 2.4, quality: 'auto:best', crop: 'crop' };

type BookingDetailsProps = {
  currentBooking: any;
  bookingType: BookingType;
};

const BookingDetails: FC<BookingDetailsProps> = ({ currentBooking, bookingType }) => {
  const showLivestream = () => {
    if (currentBooking.serviceType === ServiceType.Event && currentBooking.isVirtual()) {
      return (
        <LiveStream className="bold purple">
          <IconWrapper>
            <VirtualIcon color={neutral.black} />
          </IconWrapper>
          {currentBooking.isZoom ? (
            <FormattedMessage {...Messages.zoomEvent} />
          ) : (
            <FormattedMessage {...Messages.livestream} />
          )}
        </LiveStream>
      );
    }
  };

  return (
    <LeftColumn>
      <IconColumn>
        <SuccessIconWrapper>{renderIcon(bookingType)}</SuccessIconWrapper>
      </IconColumn>
      <LeftColumnContent>
        <Header>
          <BookingDetailsHeader>
            <h1 style={{ margin: '0.5rem 0 1rem' }}>{renderHeader(bookingType)}</h1>
          </BookingDetailsHeader>
          {showLivestream()}
        </Header>
        <SubHeader>
          <InfoPoint>
            <ServiceTitleHeader>{currentBooking.title}</ServiceTitleHeader>
            <ShareButton service={currentBooking} />
          </InfoPoint>
          <InfoPoint>
            <FormattedMessage {...Messages.providedBy} /> {currentBooking.partner}
          </InfoPoint>
        </SubHeader>
        <HeaderDivider />
        <BookingInfoBlockWrapper>
          <BookingInfoBlock booking={currentBooking} />
        </BookingInfoBlockWrapper>
      </LeftColumnContent>
    </LeftColumn>
  );
};

type ActionsProps = {
  onClickCancel: () => void;
  onClickAddToCalendar: () => void;
  onClickWatch: () => void;
  isVimeoLivestream: boolean;
  isAbleToLivestream?: boolean;
  livestreamLink?: string;
};

const Actions: FC<ActionsProps> = ({
  onClickCancel,
  onClickAddToCalendar,
  onClickWatch,
  isVimeoLivestream,
  isAbleToLivestream,
  livestreamLink,
}) => (
  <BookingInfoBlockWrapper>
    <InfoHeader className="bold">
      <FormattedMessage {...Messages.actions} />
    </InfoHeader>
    {isAbleToLivestream && (
      <InfoPoint>
        <ButtonContainer>
          <StyledButton
            buttonType={!isVimeoLivestream ? 'a' : undefined}
            icon={<PlayIcon color={neutral.white} fill={neutral.white} />}
            title={<FormattedMessage {...Messages.join} />}
            onClick={onClickWatch}
            linkTo={livestreamLink}
          />
        </ButtonContainer>
      </InfoPoint>
    )}
    <InfoPoint>
      <CalendarIcon width={defaultTheme.size.serviceDetailIconSize} color={brand.brightGreen} />
      <IconPoint>
        <ActionButton className="bold purple" onClick={onClickAddToCalendar}>
          <FormattedMessage {...Messages.addToCalendar} />
        </ActionButton>
      </IconPoint>
    </InfoPoint>
    <InfoPoint>
      <IconWrapper>
        <CancelIcon width={defaultTheme.size.serviceDetailIconSize} color={miscColors.cancelIcon} />
      </IconWrapper>
      <IconPoint>
        <ActionButton className="bold cancelOrange" onClick={onClickCancel}>
          <FormattedMessage {...Messages.cancelBooking} />
        </ActionButton>
      </IconPoint>
    </InfoPoint>
  </BookingInfoBlockWrapper>
);

type Props = {
  // todo: fix this, |s of complex types are lame. We should refactor to provide a concrete type
  currentBooking: GenericTimeBasedBooking | InventoryBooking;
  bookingType: BookingType;
  successId: string;
  isSuccess: boolean;
};

const SuccessPage: FC<Props> = ({ currentBooking, bookingType, successId, isSuccess }) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const eventBooking: GenericEventBooking = currentBooking as GenericEventBooking;
  const inventoryBooking: InventoryBooking = currentBooking as InventoryBooking;

  const isTimeBasedBooking = [BookingType.Waitlist, BookingType.Booking, BookingType.ScheduledService].includes(
    bookingType
  );
  const isInventoryBooking = bookingType === BookingType.Inventory;

  const handleClickAddToCalendar = () => {
    dispatch(openModal(Modals.Calendar));
  };

  const handleClickCancel = () => {
    dispatch(openModal(Modals.CancelBooking));
  };

  const handleClickWatch = () => {
    dispatch(openModal(Modals.Video));
  };

  const logVideoAnalytics = () => {
    analytics.logServiceEvent(AnalyticsEvents.VideoViewed, eventBooking);
  };

  const onWaitlist = bookingType === BookingType.Waitlist;

  const isAbleToLivestream = () => {
    const currentTime = new Date();
    const joinTime = sub(eventBooking.startTime, { minutes: 15 });
    return (
      currentBooking.isVirtual() &&
      isAfter(currentTime, joinTime) &&
      isBefore(currentTime, eventBooking.endTime) &&
      !onWaitlist
    );
  };

  const renderSuccessState = () => (
    <Wrapper>
      <BookingDetailsWrapper>
        <Columns>
          <BookingDetails currentBooking={currentBooking} bookingType={bookingType} />
          <RightColumn>
            <AspectRatioContainer ratio={2.4} isCenter>
              <StyledCloudinaryImage
                className="roundUpperCorners"
                publicId={currentBooking.cloudinaryPublicId}
                transformation={CloudinaryConfigs}
              />
            </AspectRatioContainer>
            {isTimeBasedBooking && (
              <Actions
                onClickCancel={handleClickCancel}
                onClickAddToCalendar={handleClickAddToCalendar}
                onClickWatch={eventBooking.isVimeoLivestream ? handleClickWatch : null}
                isVimeoLivestream={eventBooking.isVimeoLivestream}
                isAbleToLivestream={isAbleToLivestream()}
                livestreamLink={eventBooking.isVirtual() ? eventBooking.joiningEvent() : undefined}
              />
            )}
            <AccessText className="bold">
              <FormattedMessage {...Messages.accessHundreds} />
            </AccessText>
            <Button
              className="primaryColor"
              title={<FormattedMessage {...Messages.continueBrowsing} />}
              onClick={eventBooking.isAdminOnly ? redirectToLeaderView : redirectToHome}
            />
          </RightColumn>
        </Columns>
      </BookingDetailsWrapper>
      <ModalContainer name={Modals.CancelBooking}>
        <CancelBookingModal bookingType={bookingType} bookingId={successId} />
      </ModalContainer>
      {isTimeBasedBooking && (
        <>
          <ModalContainer name={Modals.Calendar}>
            <AddToCalendar booking={eventBooking} />
          </ModalContainer>
          <ModalContainer name={Modals.Video} fullModal opacity={0.9} onOpen={logVideoAnalytics}>
            <VideoModal videoId={eventBooking.eventVimeoVideoId} hasLivestreamChat={eventBooking.hasLivestreamChat} />
          </ModalContainer>
        </>
      )}
    </Wrapper>
  );

  const renderLoadingState = () => {
    if (isInventoryBooking && !inventoryBooking?.isLoaded) {
      return (
        <Wrapper>
          <BookingDetailsWrapper>
            <Column>
              <FormattedMessage {...Messages.pleaseCheckEmail} />
            </Column>
          </BookingDetailsWrapper>
        </Wrapper>
      );
    }
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );
  };

  return isSuccess ? renderSuccessState() : renderLoadingState();
};

export default SuccessPage;

const ActionButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  font-size: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: ${defaultTheme.size.minScreenWidth};

  @media (min-width: ${mediaQueries.m_mobile}) {
    flex-direction: row;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const BookingDetailsWrapper = styled.div`
  animation: opacityChange 1s ease-in-out both;
  overflow: hidden;
  width: 100%;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 0;
  }
`;

const Column = styled.div`
  display: flex;
  justify-content: center;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${mediaQueries.m_mobile}) {
    flex-direction: row;
    padding: 2rem 0;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: ${defaultTheme.size.twoColumnGapWidth};
  width: 50%;
`;

const IconColumn = styled.div`
  padding-right: 2rem;
`;

const SuccessIconWrapper = styled.div`
  display: flex;
`;

const LeftColumnContent = styled.div``;

const Header = styled.div`
  width: 100%;
`;

const BookingDetailsHeader = styled.div`
  display: flex;
  align-items: center;
`;

const SubHeader = styled.div`
  padding: 1rem 0 2rem;
`;

const LiveStream = styled.div`
  display: flex;
  vertical-align: center;
`;

const HeaderDivider = styled.div`
  border-bottom: 1px solid ${miscColors.divider};
`;

const StyledCloudinaryImage = styled(CloudinaryImage)`
  width: 100%;
  object-fit: cover;
`;

const AccessText = styled.div`
  padding: 4rem 0 2rem;
`;

const RightColumn = styled.div`
  padding-left: ${defaultTheme.size.twoColumnGapWidth};
  width: 50%;
`;

const BookingInfoBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 3rem 0;
`;

const InfoHeader = styled.div`
  margin-bottom: 1.5rem;
`;

const InfoPoint = styled.span`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  font-size: ${typeScale.font12};
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 20em;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const IconPoint = styled.span`
  padding-left: 0.5rem;
`;

const ServiceTitleHeader = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  flex-grow: 1;
`;

const IconWrapper = styled.span`
  padding-right: 0.5rem;
`;
