/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { defaultTheme } from 'styles/themes';
import { neutral } from 'styles/colors';

type Props = {
  width?: string;
  color?: string;
};

const VirtualIcon: FC<Props> = ({ width = defaultTheme.size.iconSize, color = neutral.white }) => (
  <svg width={width} height={width} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1.5" width="17" height="12" rx="1.5" stroke={color} />
    <line x1="3.5" y1="16.5" x2="14.5" y2="16.5" stroke={color} strokeLinecap="round" />
    <line x1="10.5" y1="13" x2="10.5" y2="16" stroke={color} />
    <line x1="7.5" y1="13" x2="7.5" y2="16" stroke={color} />
  </svg>
);

export default VirtualIcon;
