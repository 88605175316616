import { defineMessages, MessageDescriptor } from 'react-intl';

const seeDetails: MessageDescriptor = {
  id: 'tile.seeDetails',
  defaultMessage: 'See Details',
};

const learnMore: MessageDescriptor = {
  id: 'tile.learnMore',
  defaultMessage: 'Learn More',
};

const soldOut: MessageDescriptor = {
  id: 'tile.soldOut',
  defaultMessage: 'Sold Out',
};

const waitList: MessageDescriptor = {
  id: 'tile.waitList',
  defaultMessage: 'Waitlist Available',
};

const multipleTimes: MessageDescriptor = {
  id: 'tile.multipleTimes',
  defaultMessage: 'Multiple Times',
};

const free: MessageDescriptor = {
  id: 'tile.free',
  defaultMessage: 'Free',
};

const accessibleAnytime: MessageDescriptor = {
  id: 'tile.accessibleAnytime',
  defaultMessage: 'Accessible Anytime',
};

const watchNow: MessageDescriptor = {
  id: 'tile.watchNow',
  defaultMessage: 'Watch Now',
};

const openOperatingHours: MessageDescriptor = {
  id: 'tile.openOperatingHours',
  defaultMessage: 'Open {operatingHours}',
};

const percentOccupancy: MessageDescriptor = {
  id: 'tile.percentOccupancy',
  defaultMessage: '{percent} Occupancy',
};

const spaceFull: MessageDescriptor = {
  id: 'tile.spaceFull',
  defaultMessage: 'Space full',
};

const seat: MessageDescriptor = {
  id: 'tile.seat',
  defaultMessage: '{numberOfSeats, plural, one {# Seat} other {# Seats}}',
};

const multiplePrices: MessageDescriptor = {
  id: 'tile.multiplePrices',
  defaultMessage: 'Multiple prices available',
};

export default defineMessages({
  seeDetails,
  learnMore,
  soldOut,
  waitList,
  multipleTimes,
  free,
  accessibleAnytime,
  watchNow,
  openOperatingHours,
  percentOccupancy,
  spaceFull,
  seat,
  multiplePrices,
});
