/** External Dependencies */
import * as React from 'react';
import { ButtonGroupProps, ArrowProps } from 'react-multi-carousel/lib/types';
import styled from 'styled-components';

/** Internal Dependencies */
import { neutral } from 'styles/colors';

/** Components */
import ChevronLeft from 'components/Icons/ChevronLeft';
import ChevronRight from 'components/Icons/ChevronRight';

/** Styling */
import { defaultTheme } from 'styles/themes';
import { mediaQueries } from 'styles/mediaQueries';

interface CustomArrowProps extends ButtonGroupProps, ArrowProps {}

const CustomLeftArrow = ({ onClick, carouselState }: CustomArrowProps) => {
  const { currentSlide } = carouselState;
  return (
    <Arrow onClick={onClick} onKeyPress={onClick} role="button" tabIndex={0}>
      <ChevronLeft
        color={currentSlide === 0 ? neutral.grey : neutral.black}
        width={defaultTheme.size.carouselChevronSize}
      />
    </Arrow>
  );
};

const CustomRightArrow = ({ onClick, carouselState }: CustomArrowProps) => {
  const { currentSlide, slidesToShow, totalItems } = carouselState;

  return (
    <Arrow onClick={onClick} onKeyPress={onClick} role="button" tabIndex={0}>
      <ChevronRight
        color={currentSlide === totalItems - slidesToShow ? neutral.grey : neutral.black}
        width={defaultTheme.size.carouselChevronSize}
      />
    </Arrow>
  );
};

const CustomButtonGroup = ({ next, previous, carouselState }: CustomArrowProps) => (
  <ButtonGroup>
    <CustomLeftArrow onClick={() => previous()} carouselState={carouselState} />
    <CustomRightArrow onClick={() => next()} carouselState={carouselState} />
  </ButtonGroup>
);

const ButtonGroup = styled.div<ButtonGroupProps>`
  position: absolute;
  display: flex;
  justify-content: space-between;
  height: 0;
  top: 30%;
  width: 100%;
  left: 0%;

  @media (min-width: ${mediaQueries.m_tablet}) {
    width: 110%;
    left: -5%;
  }
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  width: 43px;
  height: 43px;
  opacity: 1;
  cursor: pointer;
`;

export { CustomLeftArrow, CustomRightArrow, CustomButtonGroup };
