/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from 'styles/colors';
import { defaultTheme } from 'styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const CostIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1" fill={color}>
        <rect x="2" y="4" width="24" height="20" rx="1" />
      </mask>
      <rect x="2" y="4" width="24" height="20" rx="1" stroke={color} strokeWidth="1.5" />
      <line x1="3" y1="10.25" x2="25" y2="10.25" stroke={color} strokeWidth="1.5" />
      <rect
        x="17.625"
        y="18.625"
        width="4.75"
        height="1.75"
        fill={color}
        stroke={color}
        strokeWidth="0.75"
        strokeLinejoin="round"
      />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default CostIcon;
