/** External Dependencies */
import React, { FC, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { DateTime } from 'luxon';

/** Internal Dependencies */
import { useQuery, useBooleanEffect } from '../../../hooks';
import Messages from '../Messages';
import { redirectToHome } from 'utils/navigation';
import { useAuthenticated } from 'auth';

/** Data layer */
import { ConfirmAccountActions, CompleteProfileActions } from 'zo-data-layer/actions';
import { ConfirmAccountSelectors, MeSelectors } from 'zo-data-layer/selectors';
import { LocaleOptions } from 'zo-data-layer/constants';

/** Components */
import { OverlayHeader } from '../../Overlay';
import Tabs from '../../Tabs';
import SecurityForm, { FormData as SecurityFormData } from './SecurityForm';
import ProfileForm, { FormData as ProfileFormData } from './ProfileForm';
import { CheckIcon } from '../../Icons';

/** Styles */
import { mediaQueries } from 'styles';

const defaultSecurityValues: SecurityFormData = {
  password: '',
  passwordConfirm: '',
};

const defaultProfileValues: ProfileFormData = {
  company: undefined,
  phone: '',
  marketingOptedIn: 'yes',
  marketingOptedInVia: 'registration form',
  marketingOptedInWording: '',
  acceptedLegal: false,
  languagePreference: LocaleOptions[0],
  building: undefined,
  floor: undefined,
  jobTitle: '',
  birthday: null,
};

const formatDataBeforeSubmit = (
  intl: IntlShape,
  {
    languagePreference,
    company,
    building,
    floor,
    marketingOptedIn,
    birthday,
    jobTitle,
    phone,
    ...rest
  }: ProfileFormData
) => {
  const marketingOptedInWording = `${intl.formatMessage(Messages.marketingOptedIn)} - ${intl.formatMessage(
    marketingOptedIn === 'yes' ? Messages.optInYes : Messages.optInNo
  )}`;

  const formattedBirthday = birthday ? DateTime.fromJSDate(new Date(birthday)).toFormat('yyyy-MM-dd') : undefined;
  const formattedJobTitle = jobTitle || undefined;
  const formattedPhone = phone || undefined;

  return {
    ...rest,
    languagePreference: languagePreference?.value,
    company: company?.value,
    building: building?.value,
    floor: floor?.value,
    marketingOptedIn: marketingOptedIn === 'yes',
    marketingOptedInWording,
    // Django DateField throws an error when it's an empty string, blank=True does not work either.
    birthday: formattedBirthday,
    jobTitle: formattedJobTitle,
    phone: formattedPhone,
  };
};

type SelectorProps = {
  isAccountConfirmed: boolean;
  isLoading: boolean;
  isFailed: boolean;
  completedProfile: Date | null;
};

const mapStateToProps = createStructuredSelector<any, SelectorProps>({
  isAccountConfirmed: ConfirmAccountSelectors.isSuccess(),
  isLoading: ConfirmAccountSelectors.isLoading(),
  isFailed: ConfirmAccountSelectors.isFailed(),
  completedProfile: MeSelectors.completedProfile(),
});

const ConfirmRegisterForm: FC = () => {
  const [securityFormData] = useState<SecurityFormData>(defaultSecurityValues);
  const [profileFormData] = useState<ProfileFormData>(defaultProfileValues);
  const { isLoading, isFailed, completedProfile } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const accessToken = useAuthenticated();
  const intl = useIntl();

  const query = useQuery();
  const name = query.get('name');
  const registerToken = query.get('token');
  const email = query.get('email');

  const tabIndex = accessToken && !completedProfile ? 1 : 0;

  useBooleanEffect(isFailed, () => {
    const resetConfirmAccount = () => dispatch(ConfirmAccountActions.reset());
    setTimeout(resetConfirmAccount, 1000);
  });

  const handleSubmitSecurityForm = ({ password }: SecurityFormData) => {
    dispatch(ConfirmAccountActions.request({ token: registerToken, password, email }));
  };

  const handleSubmitProfileForm = (data: ProfileFormData) => {
    const formattedData = formatDataBeforeSubmit(intl, { ...profileFormData, ...data });
    dispatch(CompleteProfileActions.request(formattedData));
  };

  useBooleanEffect(Boolean(completedProfile), () => {
    redirectToHome();
  });

  const tabs = [
    {
      title: (
        <>
          {tabIndex === 1 && <Checkmark />}
          <FormattedMessage {...Messages.security} />,
        </>
      ),
      render: () => <SecurityForm onSubmit={handleSubmitSecurityForm} defaultValues={securityFormData} email={email} />,
      isClickable: false,
    },
    {
      title: <FormattedMessage {...Messages.profile} />,
      render: () => (
        <ProfileForm onSubmit={handleSubmitProfileForm} defaultValues={profileFormData} isLoading={isLoading} />
      ),
      isClickable: false,
    },
  ];

  return (
    <Wrapper>
      <OverlayHeader>
        <FormattedMessage values={{ name }} {...Messages.welcomeUser} />
      </OverlayHeader>
      <Tabs tabIndex={tabIndex} tabs={tabs} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 1rem;
  margin-bottom: 3rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 0;
    margin-bottom: 0;
  }
`;

const Checkmark = styled(CheckIcon)`
  margin-right: 0.5rem;
`;

export default ConfirmRegisterForm;
