/*
 * Generic Service
 *
 */

/** External Dependencies **/
import 'whatwg-fetch';

/** Data Layer **/
import {
  MessagePassingException,
  throwNetworkTimeoutError,
  throwTypedErrorFromJson,
} from 'zo-data-layer/transformers/error.transformers';
import { AuthService } from 'zo-data-layer/services';
import { LogoutActions } from 'zo-data-layer/actions';

/** Interal Dependencies **/
import { Token } from './auth';
import { redirectToLogin } from './utils/navigation';
import { NETWORK_ERROR } from 'zo-data-layer/constants/actions';
import { JsonResult } from 'zo-data-layer/services/service.helpers';
import { AuthToken } from 'zo-data-layer/selectors';
import { MessageDescriptor } from 'react-intl';
import { getStore } from './compositionRoot';

const sessionExpiredMessage: MessageDescriptor = {
  id: 'loginPage.sessionExpired',
  defaultMessage: 'Your session has expired. Please login.',
};

/**
 * Parses the JSON returned by a network request
 */
async function parseJSON(response: Response): Promise<JsonResult> {
  if (response.text) {
    const text = await response.text();
    return text ? JSON.parse(text) : {};
  }
  console.error('Attempted to parse JSON from non-JSON response. Failing.');
  throw new MessagePassingException(NETWORK_ERROR);
}

async function requestDontRefresh(url: RequestInfo, options: RequestInit): Promise<JsonResult> {
  return requestWithToken(url, options, { shouldRetry: false });
}

export async function requestAndIgnore401(url: RequestInfo, options: RequestInit): Promise<JsonResult> {
  return requestWithToken(url, options, { ignore401: true });
}

export async function request(url: RequestInfo, options: RequestInit): Promise<JsonResult> {
  return requestWithToken(url, options);
}

/**
 * Token refresh service used for refreshing tokens, during 401 errors.
 * Sets shouldRetry to false, becase we don't get stuck in an infinite loop
 */
const tokenRefreshService = new AuthService(requestDontRefresh);

function crashGracefullyAndLogOutUser() {
  Token.destroy();
  getStore().dispatch(LogoutActions.success()); // blow up the redux store
  redirectToLogin();
  throw new MessagePassingException(sessionExpiredMessage); // display a nice message
}

async function refreshToken(): Promise<AuthToken> {
  const token = Token.get();
  const refreshedToken = await tokenRefreshService.refreshToken(token);
  Token.set(refreshedToken);
  return Token.get();
}

let refreshTokenPromise = undefined;

async function concurrentSafeRefresh(): Promise<AuthToken> {
  if (refreshTokenPromise) {
    return refreshTokenPromise;
  } else {
    refreshTokenPromise = refreshToken();
    const token = await refreshTokenPromise;
    refreshTokenPromise = undefined;
    return token;
  }
}
/**
 * Checks if a network request is successful (between 200 to 300), and returns sucessful response.
 * For 401 timeout errors, we check if we should ignore them (comes from auth saga service requests while logged-out). If so, just return the error.
 * Otherwise, check whether we should refresh the token (false when a user has just refreshed their token and it is still bad or they
 * have been punted from the system)
 */
async function checkStatus(
  response: Response,
  url: RequestInfo,
  opts: RequestInit,
  { shouldRetry = true, ignore401 = false }: AdditionalOptions = {}
): Promise<Response> {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (!ignore401 && response.status === 401) {
    if (shouldRetry) {
      const token = await concurrentSafeRefresh();
      const newOpts = {
        ...opts,
        headers: {
          Authorization: `${token.token_type} ${token.access_token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await fetch(url, newOpts);
      return await checkStatus(response, url, newOpts, { shouldRetry: false });
    }
    crashGracefullyAndLogOutUser();
  }
  await parseError(response);
  return response; // unreachable, but it makes TS happy.
}

const parseError = (response: Response) =>
  parseJSON(response).then((jsonData) => throwTypedErrorFromJson(jsonData, response.url));

export const URIEncode = (params: { [key: string]: string | number | boolean }): string =>
  Object.keys(params)
    .filter((k) => params[k] !== undefined && params[k] !== null)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

/**
 * Catch unexpected fetch Errors (usually a result of the API being down)
 *
 * "expected" errors (thrown by parseError() above) are passed through
 * to be handled by the sagas.
 */
const unexpectedFetchErrors = (error: Error): Error => {
  if (error.message === 'Failed to fetch') {
    throwNetworkTimeoutError();
  }
  throw error;
};

async function requestRaw(
  url: RequestInfo,
  options: { [key: string]: any },
  additionalOpts: AdditionalOptions
): Promise<JsonResult> {
  try {
    const response = await fetch(url, options);
    const statusChecked = await checkStatus(response, url, options, additionalOpts);
    return parseJSON(statusChecked);
  } catch (e) {
    unexpectedFetchErrors(e);
    return {};
  }
}

type AdditionalOptions = {
  shouldRetry?: boolean;
  ignore401?: boolean;
};

/**
 * Attempt to fetch the token and make a request with it
 * Also accepts additional options for extended request handling.
 */
function requestWithToken(
  url: RequestInfo,
  options: RequestInit,
  additionalOpts: AdditionalOptions = {}
): Promise<JsonResult> {
  const defaultOptions = {
    method: 'GET',
    credentials: 'include',
    headers: {},
  };
  if (Token.isNotNull()) {
    const token = Token.get();
    defaultOptions.headers = {
      Authorization: `${token.token_type} ${token.access_token}`,
      'Content-Type': 'application/json',
    };
  }
  const finalOpts = { ...defaultOptions, ...options };
  return requestRaw(url, finalOpts, additionalOpts);
}
