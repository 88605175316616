/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';
/** Interal Dependencies */
import { useBooleanEffect, usePrevious, useSticky } from 'hooks';
import { useAnalytics } from 'analytics';
import Messages from './Messages';
import { navigateToAvailabilities, redirectToHome } from 'utils/navigation';
import { generateServiceLink } from 'constants/routes';
import { Modals } from 'constants/Layouts';
/** Data layer */
import { MyServicesGetActions, openModal } from 'zo-data-layer/actions';
import { MyServicesGetSelectors, MyServiceListAvailabilitesSelectors } from 'zo-data-layer/selectors';
import AnalyticsEvents from 'zo-data-layer/constants/AnalyticsEvents';
import MyService from 'zo-data-layer/dataobjects/MyService';
import { ServiceType } from 'zo-data-layer/constants/services';
import { addHttps } from 'zo-data-layer/utils/patterns';
import { Timeslot } from 'zo-data-layer/dataobjects';
/** Components */
import Overlay, { Size as OverlaySize } from 'components/Overlay';
import ServiceInfoWrapper from 'components/ServiceBookingFlow/ServiceInfoWrapper';
import ServiceInfoCTA from 'components/ServiceBookingFlow/ServiceInfoCTA';
import LoadingIndicator from 'components/LoadingIndicator';
import Button from 'components/Button';
import PlayIcon from 'components/Icons/PlayIcon';
import ModalContainer from 'components/ModalContainer';
import VideoModal from 'components/VideoModal';
/** Styling */
import { mediaQueries, neutral } from 'styles';
import { useCategory } from 'hooks/useCategory';

type RouteParams = {
  enrollmentId: string;
  slotId: string;
  categoryId?: string;
};

type SelectorProps = {
  service: MyService;
  isSuccess: boolean;
  timeSlots: List<Timeslot>;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  service: MyServicesGetSelectors.getService(),
  isSuccess: MyServicesGetSelectors.isSuccess(),
  timeSlots: MyServiceListAvailabilitesSelectors.getResults(),
});

const ServiceInfoPage: FC = () => {
  const { isSticky, elementRef } = useSticky();
  const { enrollmentId } = useParams<RouteParams>();
  const analytics = useAnalytics();
  const { service, isSuccess, timeSlots } = useSelector(mapReduxState);
  const prevIsSuccess = usePrevious(isSuccess);
  const dispatch = useDispatch();
  const { categoryId } = useParams<RouteParams>();
  const { category } = useCategory(parseInt(categoryId));
  const enrollmentIdIsValid = /^[0-9a-zA-Z\-_]{12}$/.test(enrollmentId) || !isNaN(parseInt(enrollmentId, 10));

  useBooleanEffect(!enrollmentIdIsValid, redirectToHome);

  useEffect(() => {
    if (enrollmentIdIsValid) {
      dispatch(MyServicesGetActions.request(enrollmentId));
    }
  }, [dispatch, enrollmentId, enrollmentIdIsValid]);

  useBooleanEffect(prevIsSuccess === false && isSuccess, () =>
    analytics.logServiceEvent(AnalyticsEvents.ServiceViewed, service, { isPublic: false })
  );

  const handleOnCtaClick = () => {
    analytics.logServiceCtaClicked(service);
  };

  const handleClickWatch = () => {
    dispatch(openModal(Modals.Video));
  };

  const logVideoAnalytics = () => {
    analytics.logServiceEvent(AnalyticsEvents.VideoViewed, service);
  };

  const handleTimeSlot = () => timeSlots.find((slot) => slot.id === service.firstAvailableOccurrenceId);

  const renderCTA = () => {
    if (service.isSoldOut) return <ServiceInfoCTA service={service} fullWidth={true} />;

    switch (service.serviceType) {
      case ServiceType.SSO:
      case ServiceType.Link:
        return (
          <a href={addHttps(service.link)} target="_blank" rel="noopener noreferrer">
            <ServiceInfoCTA service={service} fullWidth={true} />
          </a>
        );
      case ServiceType.Event:
        if (service.isMultipleDates)
          return (
            <Button title={<FormattedMessage {...Messages.checkAvailabilites} />} onClick={navigateToAvailabilities} />
          );
        return (
          <Link to={`${generateServiceLink(service)}/${service.firstAvailableOccurrenceId}`} onClick={handleOnCtaClick}>
            <ServiceInfoCTA service={service} fullWidth={true} timeSlot={handleTimeSlot()} />
          </Link>
        );
      case ServiceType.Inventory:
        return (
          <Link to={`${generateServiceLink(service)}/${service.serviceId}`} onClick={handleOnCtaClick}>
            <ServiceInfoCTA service={service} fullWidth={true} />
          </Link>
        );
      case ServiceType.Video:
        return (
          <Button
            title={
              <StyledMessage>
                <FormattedMessage {...Messages.watch} />
              </StyledMessage>
            }
            icon={<PlayIcon color={neutral.white} fill={neutral.white} />}
            onClick={handleClickWatch}
          />
        );
      default:
        return;
    }
  };

  const SuccessState = () => (
    <PageWrapper>
      <ContentWrapper>
        <SubHeader className={isSticky ? 'sticky' : ''}>
          <LeftColumn>
            <ServiceTitle>{service.title}</ServiceTitle>
          </LeftColumn>
          <RightColumn>{renderCTA()}</RightColumn>
        </SubHeader>
        <Overlay size={OverlaySize.Large}>
          <ServiceInfoWrapper
            service={service}
            elementRef={elementRef}
            category={category}
            isLeaderSection={service.isAdminOnly}
            timeSlot={handleTimeSlot()}
          />
        </Overlay>

        {service.serviceType === ServiceType.Video && (
          <ModalContainer name={Modals.Video} fullModal opacity={0.9} onOpen={logVideoAnalytics}>
            <VideoModal videoId={service.vimeoVideoId} hasLivestreamChat={service.hasLivestreamChat} />
          </ModalContainer>
        )}
      </ContentWrapper>
    </PageWrapper>
  );

  const LoadingState = () => (
    <ContentWrapper>
      <Overlay size={OverlaySize.Large}>
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      </Overlay>
    </ContentWrapper>
  );

  return isSuccess ? SuccessState() : LoadingState();
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubHeader = styled.nav`
  position: fixed;
  display: none;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundColor};
  width: 100%;
  height: ${({ theme }) => theme.size.navbarHeight};
  z-index: ${({ theme }) => theme.zIndex.z4};
  padding: ${({ theme }) => `${theme.spacing.layoutPaddingSm} 1rem`};

  &.sticky {
    display: flex;
    animation: subHeaderLower 0.5s ease-in-out both;
  }

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: ${({ theme }) => `${theme.spacing.layoutPaddingSm} 14%`};
  }
`;

const LeftColumn = styled.div`
  width: 70%;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const RightColumn = styled.div`
  width: 30%;
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ServiceTitle = styled.h3`
  padding-right: 0.5rem;
`;

const StyledMessage = styled.div`
  padding-left: 0.5rem;
  letter-spacing: 0.15em;
`;

export default ServiceInfoPage;
