import { defineMessages, MessageDescriptor } from 'react-intl';

const newServices: MessageDescriptor = {
  id: 'discover.newServices',
  defaultMessage: 'Trending',
};

const todayServices: MessageDescriptor = {
  id: 'discover.todayServices',
  defaultMessage: "Today's Services",
};

const upcomingServices: MessageDescriptor = {
  id: 'discover.upcomingServices',
  defaultMessage: 'Upcoming Services',
};

const allServices: MessageDescriptor = {
  id: 'discover.allServices',
  defaultMessage: 'All Services',
};

const discover: MessageDescriptor = {
  id: 'discover.discover',
  defaultMessage: 'Discover',
};

export default defineMessages({
  discover,
  newServices,
  todayServices,
  upcomingServices,
  allServices,
});
