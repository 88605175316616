/** External Dependencies **/
import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  videoId: number;
  hasLivestreamChat?: boolean;
};

const VideoModal: FC<Props> = ({ videoId, hasLivestreamChat }) => (
  <ModalWrapper>
    <VideoIframe
      title="vimeo-player"
      src={`https://player.vimeo.com/video/${videoId}`}
      frameBorder="0"
      allowFullScreen
      hasLivestreamChat={hasLivestreamChat}
    />
    {hasLivestreamChat && (
      <ChatIframe title="vimeo-chat" src={`https://vimeo.com/live-chat/${videoId}`} frameBorder="0" />
    )}
  </ModalWrapper>
);

export default VideoModal;

const ModalWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

interface VideoIframeProps {
  hasLivestreamChat?: boolean;
}

const VideoIframe = styled.iframe<VideoIframeProps>`
  width: ${({ hasLivestreamChat }) => (hasLivestreamChat ? '70%' : '100%')};
`;

const ChatIframe = styled.iframe`
  flex-grow: 1;
  margin: 8em 0;
`;
