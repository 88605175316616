/** External Dependencies */
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

/** Internal Dependencies */
import Messages from '../Messages';

/** Components */
import { OverlaySubHeader, OverlayText, OverlayDescription } from '../../Overlay';
import Input from '../../Input';
import Button from '../../Button';

/** Styling */
import { typeScale } from 'styles';

export type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  company: {
    value: number;
    label: string;
  };
};

type Props = {
  onSubmit: (data: FormData) => void;
  defaultValues?: FormData;
  isLoading?: boolean;
};

const RegisterForm: FC<Props> = ({ onSubmit, defaultValues, isLoading = false }) => {
  const { register, handleSubmit, errors } = useForm<FormData>({ defaultValues });
  const intl = useIntl();

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <OverlaySubHeader>
          <FormattedMessage {...Messages.register} />
        </OverlaySubHeader>
        <OverlayText className="darkGrey">
          <FormattedMessage {...Messages.enterInformationBelow} />
        </OverlayText>

        <Input
          id="firstName"
          name="firstName"
          label={intl.formatMessage(Messages.firstName)}
          error={errors?.firstName?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          })}
        />

        <Input
          id="lastName"
          name="lastName"
          label={intl.formatMessage(Messages.lastName)}
          error={errors?.lastName?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          })}
        />

        <Input
          id="email"
          name="email"
          label={intl.formatMessage(Messages.companyEmailAddress)}
          error={errors?.email?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          })}
        />

        <DescriptionHeader>
          <FormattedMessage {...Messages.registerDescriptionHeader} />
        </DescriptionHeader>
        <OverlayDescription className="darkGrey">
          <FormattedMessage {...Messages.registerDescription} />
        </OverlayDescription>

        <StyledButton title={<FormattedMessage {...Messages.getStarted} />} type="submit" isLoading={isLoading} />
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.layoutPaddingSm};
`;

const DescriptionHeader = styled(OverlayDescription)`
  padding: 2rem 0 0rem;
`;

const StyledButton = styled(Button)`
  font-size: ${typeScale.font17};
`;

export default RegisterForm;
