import styled from 'styled-components';
import BaseLoadingIndicator from 'components/LoadingIndicator';
import React from 'react';

const LoadingIndicatorContainer = styled.div`
  margin-bottom: 2rem;
`;

const FilterPagesLoadingIndicator = () => (
  <LoadingIndicatorContainer>
    <BaseLoadingIndicator />
  </LoadingIndicatorContainer>
);

export default FilterPagesLoadingIndicator;
