/** External Dependencies **/
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

/** Interal Dependencies */
import Messages from './Messages';
import { getUrlConstants, AppLinks } from 'constants/routes';
import { useBooleanEffect } from 'hooks';

/** Data layer */
import { register } from 'zo-data-layer/actions';
import { RegisterSelectors } from 'zo-data-layer/selectors';

/** Components */
import Overlay, { OverlayHeader, Size as OverlaySize } from 'components/Overlay';
import RegisterForm, { FormData } from 'components/Forms/RegisterForm/RegisterForm';
import VerifyEmail from 'components/Forms/RegisterForm/VerifyEmail';
import Tabs from 'components/Tabs';
import { CheckIcon } from 'components/Icons';

/** Styles */
import { mediaQueries } from 'styles';

type RegisterData = {
  email: string;
  firstName: string;
  lastName: string;
};

type Props = {
  register: (data: RegisterData) => AnyAction;
  isSuccess: boolean;
  isLoading: boolean;
};

const formatDataBeforeSubmit = (formData: FormData, locale = 'en'): RegisterData => ({
  ...formData,
});

const RegisterPage: FC<Props> = ({ register, isSuccess, isLoading }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [verifiedEmail, setVerifiedEmail] = useState('');
  const { locale } = useIntl();

  useBooleanEffect(isSuccess, () => {
    setTabIndex(1);
  });

  const handleSubmit = (formData: FormData) => {
    register(formatDataBeforeSubmit(formData, locale));
    setVerifiedEmail(formData.email);
  };

  const tabs = [
    {
      title: (
        <>
          {tabIndex === 1 && <Checkmark />}
          <FormattedMessage {...Messages.register} />
        </>
      ),
      render: () => <RegisterForm onSubmit={handleSubmit} isLoading={isLoading} />,
      isClickable: false,
    },
    {
      title: <FormattedMessage {...Messages.verifyEmail} />,
      render: () => <VerifyEmail email={verifiedEmail} />,
      isClickable: false,
    },
  ];

  return (
    <>
      <Wrapper>
        <Overlay size={OverlaySize.Small}>
          <OverlayHeader>
            <FormattedMessage {...Messages.signUpToday} />
          </OverlayHeader>
          <Tabs tabs={tabs} tabIndex={tabIndex} setTabIndex={setTabIndex} />
          <LinkMessages>
            <LinkMessage>
              <FormattedMessage {...Messages.needHelp} />
              &nbsp;
              <SupportLink href={getUrlConstants({ locale }).zendeskUrl} target="_blank" rel="noopener noreferrer">
                <FormattedMessage {...Messages.contactSupport} />
              </SupportLink>
            </LinkMessage>
            <LinkMessage>
              <FormattedMessage {...Messages.alreadyHaveAccount} />
              &nbsp;
              <SupportLink href={AppLinks.login}>
                <FormattedMessage {...Messages.signIn} />
              </SupportLink>
            </LinkMessage>
          </LinkMessages>
        </Overlay>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 0;
  }
`;

const LinkMessages = styled.div`
  padding: 2rem 0;
`;

const LinkMessage = styled.div`
  padding: 1rem 0;
  text-align: center;
`;

const SupportLink = styled.a`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primaryColor};
`;

const Checkmark = styled(CheckIcon)`
  margin-right: 0.5rem;
`;

type SelectorProps = {
  isSuccess: boolean;
  isLoading: boolean;
};

const mapStateToProps = createStructuredSelector<any, SelectorProps>({
  isSuccess: RegisterSelectors.isSuccess(),
  isLoading: RegisterSelectors.isLoading(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  register: (data: RegisterData) => dispatch(register(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
