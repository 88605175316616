export const primaryFont = 'Questrial';
export const primaryFontMedium = primaryFont;
export const primaryFontBold = primaryFont;

export const typeScale = {
  hero: '3rem',
  header0: '3.5rem',
  header1: '2.5rem',
  header2: '2rem',
  header3: '1.5rem',
  header4: '1.125rem',
  header5: '1rem',
  paragraph: '1rem',
  font18: '1.125rem',
  font17: '1.0625rem',
  font14: '0.875rem',
  font12: '0.75rem',
  buttonText: '0.875rem',
  helperText: '0.86rem',
  copyrightText: '0.7rem',
  footerText: '0.8rem',
};
