/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const OutlookIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <svg width={width} height={width} viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.0164 48C23.0164 48 21.3364 47.6955 19.9849 47.6955C18.7669 47.6955 16.9384 48 16.9384 48L17.3209 27.6105C14.4844 22.719 6.17288 7.821 0.984375 0C3.59438 0.594 4.68788 0.5535 7.31288 0L7.35338 0.0705C10.6579 5.922 15.7114 14.079 19.9849 21.1485C24.2044 14.205 30.8449 3.36 32.6404 0C34.6804 0.54 36.7339 0.516 39.0169 0C36.6184 3.234 27.8824 18.3435 22.6114 27.6105L23.0014 48H23.0164Z"
      fill={color}
    />
  </svg>
);

export default OutlookIcon;
