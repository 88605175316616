/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const SuccessfulCheckmarkIcon: FC<Props> = ({ width = defaultTheme.size.successIconSize }) => (
  <svg width={width} height={width} viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.2" cx="33" cy="33" r="33" fill="#85CCAB" />
    <circle cx="32.9999" cy="33.0004" r="22.7143" fill="#85CCAB" />
    <path d="M26.0818 33.6126L30.603 39.1228L40.7757 26.8779" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default SuccessfulCheckmarkIcon;
