/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const AppleIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <svg width={width} height={width} viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.0344 21.1109C28.0156 17.9141 29.4641 15.5047 32.3891 13.7281C30.7531 11.3844 28.2781 10.0953 25.0156 9.84688C21.9266 9.60313 18.5469 11.6469 17.3094 11.6469C16.0016 11.6469 13.0109 9.93125 10.6578 9.93125C5.80156 10.0063 0.640625 13.8031 0.640625 21.5281C0.640625 23.8109 1.05781 26.1688 1.89219 28.5969C3.00781 31.7938 7.02969 39.6266 11.225 39.5C13.4187 39.4484 14.9703 37.9438 17.825 37.9438C20.5953 37.9438 22.0297 39.5 24.4766 39.5C28.7094 39.4391 32.3469 32.3188 33.4062 29.1125C27.7297 26.4359 28.0344 21.275 28.0344 21.1109ZM23.1078 6.81406C25.4844 3.99219 25.2688 1.42344 25.1984 0.5C23.0984 0.621875 20.6703 1.92969 19.2875 3.5375C17.7641 5.2625 16.8687 7.39531 17.0609 9.8C19.3297 9.97344 21.4016 8.80625 23.1078 6.81406Z"
      fill={color}
    />
  </svg>
);

export default AppleIcon;
