import { useHistory } from 'react-router-dom';
import { FC, useEffect } from 'react';
import AnalyticsReporter from '../analytics/firebase.analytics';
import { Allow } from '../auth';

const AnalyticsService: FC = () => {
  const history = useHistory();

  useEffect(() => {
    const unregisterHistory = history.listen(() => {
      AnalyticsReporter.logNavigationEvent(Allow.authenticated);
    });
    //log the initial url that was landed on as well.
    AnalyticsReporter.logNavigationEvent(Allow.authenticated);
    return unregisterHistory;
  });

  return null;
};

export default AnalyticsService;
