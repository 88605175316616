/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import { Message } from 'react-hook-form';

/** Styling */
import { typeScale, neutral } from 'styles';

/** Components */
import { ErrorDiv } from 'components/Input';

type Props = ReactSelectProps & {
  name: string;
  id: string;
  label?: JSX.Element;
  error?: Message;
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    border: 'none',
    borderBottom: `1px solid ${neutral.grey}`,
  }),
  valueContainer: (styles) => ({ ...styles, paddingLeft: '0' }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const Select: FC<Props> = ({ label, error, ...rest }) => (
  <Wrapper>
    {!!label && <Label>{label}</Label>}
    <ReactSelect styles={customStyles} {...rest} />
    <ErrorDiv>{error}</ErrorDiv>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  margin-top: -1rem;
  margin-bottom: 2rem;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${typeScale.helperText};
`;

export default Select;
