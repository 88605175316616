/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';

/** Internal Dependencies */
import { leaderServices } from 'utils/selectors';
import { PageSize, ResultSetNames } from 'constants/Services';
import { mediaQueries } from 'styles';
import Messages from './Messages';
import { Token } from 'auth';

/** Data layer **/
import { MyServicesListActions, MyServicesListRequest } from 'zo-data-layer/actions';
import { getCmsRoute } from 'zo-data-layer/constants/api';
import MyService from 'zo-data-layer/dataobjects/MyService';
import { ImmutableState } from 'zo-data-layer/utils/selectors';

/** Components **/
import LoadingIndicator from 'components/LoadingIndicator';
import TileSection from 'components/TileSection';
import Button from 'components/Button';
import Row from 'components/Row';
import PeopleIcon from 'components/Icons/PeopleIcon';
import ZoForBusinessLogo from 'components/Icons/ZoForBusinessLogo';

/** Styling */
import { defaultTheme } from 'styles/themes';
import CategoryNavigationBar from '../../components/CategoryNavigationBar';
import { usePagination } from 'hooks';
import FilterPagesLoadingIndicator from 'components/FilterPagesLoadingIndicator';

type SelectorProps = {
  services: List<MyService>;
  isLoaded: boolean;
  isLoading: boolean;
  totalCount: number;
};

const mapReduxState = createStructuredSelector<ImmutableState, SelectorProps>({
  services: leaderServices.getServices(),
  isLoaded: leaderServices.isSuccess(),
  isLoading: leaderServices.isLoading(),
  totalCount: leaderServices.getCount(),
});

const LeaderPage: FC = () => {
  const { services, isLoaded, totalCount, isLoading } = useSelector(mapReduxState);

  const isSuccess = isLoaded || totalCount > 0;

  const { fetchFirstPage, fetchNextPage, hasNext } = usePagination<MyServicesListRequest>(
    MyServicesListActions.request,
    { leader: true, resultSetName: ResultSetNames.leader, showHidden: true },
    { totalCount, pageSize: PageSize.filterPageSize }
  );

  useEffect(fetchFirstPage, []);

  const handleRedirectToCMS = () => {
    const tokenString = btoa(JSON.stringify(Token.get()));
    const cmsRoute = `${getCmsRoute()}/auth/receive-token?token=${tokenString}`;
    window.open(cmsRoute);
  };

  return (
    <Wrapper>
      <Header>
        <ZOIconWrapper>
          <ZoForBusinessLogo color="black" />
        </ZOIconWrapper>
      </Header>
      <SubHeaderRow>
        <SubHeader>
          <FormattedMessage {...Messages.corporateResourcesForLeader} />
        </SubHeader>
        <StyledButton
          title={<FormattedMessage {...Messages.manageUsers} />}
          fullWidth={false}
          buttonType="link"
          icon={<PeopleIcon width="1rem" />}
          onClick={handleRedirectToCMS}
        />
      </SubHeaderRow>
      <CategoryNavigationBar adminOnly />
      {!isSuccess && <LoadingIndicator />}
      {isSuccess && (
        <TileSectionWrapper>
          <InfiniteScroll
            dataLength={services.size}
            next={fetchNextPage}
            hasMore={hasNext || isLoading}
            loader={<FilterPagesLoadingIndicator />}
          >
            <TileSection
              title={<FormattedMessage {...Messages.servicesAndInformation} />}
              subtitle={<FormattedMessage {...Messages.seeWhatsHappeningAtYourBuilding} />}
              tiles={services}
              isLoaded={isSuccess}
              isLeaderSection
            />
          </InfiniteScroll>
        </TileSectionWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.size.containerMaxWidth};
  min-width: 70vw;
  z-index: ${({ theme }) => theme.zIndex.z3};
  padding: ${defaultTheme.spacing.smallScreenPadding};
  @media (min-width: ${mediaQueries.m_tablet}) {
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  height: 4rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    color: ${({ theme }) => theme.colors.black};
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
`;

const ZOIconWrapper = styled.div`
  height: 4rem;
  width: 8rem;
  margin-right: 1rem;
`;

const TileSectionWrapper = styled.div`
  margin-top: 2.5rem;
`;

const SubHeaderRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 2rem;
  display: block;

  @media (min-width: ${mediaQueries.m_mobile}) {
    display: flex;
    margin-left: 0;
  }
`;

const SubHeader = styled.p`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 0;
  @media (min-width: ${mediaQueries.m_mobile}) {
    margin: 0;
  }
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  margin: 0 0 1rem 0.5rem;
  padding: 0;
  border: none;
  background: none;

  & mark {
    color: ${({ theme }) => theme.colors.grey};
  }

  &:hover {
    border: none;
    background: none;
  }
`;

export default LeaderPage;
