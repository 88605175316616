/** External Dependencies */
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

/** Internal Dependencies */
import Messages from './Messages';
import { Modals } from 'constants/Layouts';

/** Components */
import Button from 'components/Button';
import TrashIcon from 'components/Icons/TrashIcon';

/** Styling */
import { ProfileData } from 'zo-data-layer/selectors';
import { useDispatch } from 'react-redux';
import { openModal } from 'zo-data-layer/actions';
import { miscColors, neutral } from 'styles';

type Props = {
  profileData: ProfileData;
};

const MyProfile: FC<Props> = ({ profileData }) => {
  const {
    fullName,
    email,
    languagePreference,
    jobTitle,
    phone,
    campusName,
    companyName,
    floor,
    birthday,
    marketingOptedIn,
  } = profileData;
  const dispatch = useDispatch();
  const handleOpenDeleteModal = () => dispatch(openModal(Modals.DeleteAccount));

  const renderBirthday = () => {
    if (birthday) return birthday;
    return (
      <GreyText>
        <FormattedMessage {...Messages.notSpecified} />
      </GreyText>
    );
  };

  const renderJobTitle = () => {
    if (jobTitle) return jobTitle;
    return (
      <GreyText>
        <FormattedMessage {...Messages.notSpecified} />
      </GreyText>
    );
  };

  const renderPhone = () => {
    if (phone) return phone;
    return (
      <GreyText>
        <FormattedMessage {...Messages.notSpecified} />
      </GreyText>
    );
  };

  return (
    <ProfileWrapper>
      <Columns>
        <Column>
          <FieldHeader>
            <FormattedMessage {...Messages.name} />
          </FieldHeader>
          <Field>{fullName}</Field>
          <FieldHeader>
            <FormattedMessage {...Messages.emailAddress} />
          </FieldHeader>
          <Field>{email}</Field>
          <FieldHeader>
            <FormattedMessage {...Messages.languagePreference} />
          </FieldHeader>
          <Field>{languagePreference}</Field>
          <FieldHeader>
            <FormattedMessage {...Messages.buildingCampus} />
          </FieldHeader>
          <Field>{campusName}</Field>
          <FieldHeader>
            <FormattedMessage {...Messages.birthday} />
          </FieldHeader>
          <Field>{renderBirthday()}</Field>
        </Column>
        <Column>
          <FieldHeader>
            <FormattedMessage {...Messages.phoneNumber} />
          </FieldHeader>
          <Field>{renderPhone()}</Field>
          <FieldHeader>
            <FormattedMessage {...Messages.company} />
          </FieldHeader>
          <Field>{companyName}</Field>
          <FieldHeader>
            <FormattedMessage {...Messages.jobTitle} />
          </FieldHeader>
          <Field>{renderJobTitle()}</Field>
          <FieldHeader>
            <FormattedMessage {...Messages.floor} />
          </FieldHeader>
          <Field>{floor}</Field>
          <FieldHeader>
            <FormattedMessage {...Messages.marketingOptedIn} />
          </FieldHeader>
          <Field>
            {marketingOptedIn ? (
              <FormattedMessage {...Messages.optInYes} />
            ) : (
              <FormattedMessage {...Messages.optInNo} />
            )}
          </Field>
        </Column>
      </Columns>
      <FooterWrapper>
        <DeleteButton
          title={<FormattedMessage {...Messages.deleteMyAccount} />}
          onClick={handleOpenDeleteModal}
          fullWidth={false}
          icon={<TrashIcon color={miscColors.cancelIcon} />}
          isIconRight
        />
      </FooterWrapper>
    </ProfileWrapper>
  );
};

const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 77%;
`;

const Columns = styled.div`
  display: flex;
  height: 100%;
  padding: 1rem 0;
`;

const Column = styled.div`
  width: 50%;
  margin-right: 2rem;
`;

const DeleteButton = styled(Button)`
  background: none;
  color: ${miscColors.cancelIcon};
  padding-left: 0;

  & > span {
    padding-left: 0;
  }
`;

const FieldHeader = styled.div`
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${miscColors.divider};
`;

const Field = styled.div`
  padding: 1rem 0 2rem 0;
  color: ${neutral.darkGrey};
`;

const GreyText = styled.div`
  color: ${neutral.grey};
`;

const FooterWrapper = styled.div``;

export default MyProfile;
