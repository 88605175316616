/** External Dependencies **/
import { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { Map } from 'immutable';

/** Data layer */
import { LoginSelectors, MeSelectors } from 'zo-data-layer/selectors';
import { FetchMeActions } from 'zo-data-layer/actions';

import { AccessToken } from '../utils/authentication';
import AnalyticsReporter from '../analytics/firebase.analytics';
import { LocaleContext } from '../containers/IntlContainer';
import { SupportedLanguage } from 'zo-data-layer/constants';

type StateProps = {
  isLoginSuccessful: boolean;
  isMeFetched: boolean;
  myData: Map<string, any>;
  myLanguage: string;
};

type DispatchProps = {
  fetchMe: () => AnyAction;
};

/**
 * LoginService ensures that /me is fetched after page refresh or when the user logs in successfully
 */
class LoginService extends Component<StateProps & DispatchProps, any> {
  static contextType = LocaleContext;

  componentDidMount() {
    // this is for page refresh when we have an existing token
    // ensures we have fresh data for the current user
    if (AccessToken.isAuthenticated()) {
      this.props.fetchMe();
    }
  }

  componentDidUpdate(prevProps: StateProps) {
    //get current user details after successful login
    if (!prevProps.isLoginSuccessful && this.props.isLoginSuccessful) {
      this.props.fetchMe();
    }
    if (!prevProps.isMeFetched && this.props.isMeFetched) {
      AnalyticsReporter.setAllUserProperties(this.props.myData);
    }
    if (prevProps.myLanguage !== this.props.myLanguage) {
      this.context.changeLocale(this.props.myLanguage as SupportedLanguage);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = createStructuredSelector<any, StateProps>({
  isLoginSuccessful: LoginSelectors.isSuccess(),
  isMeFetched: MeSelectors.isSuccess(),
  myData: MeSelectors.myData(),
  myLanguage: MeSelectors.myLanguagePreference(),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMe: FetchMeActions.request,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginService);
