/** External Dependencies */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/** Internal Dependencies */
import { AppLinks } from 'constants/routes';
import Messages from './Messages';
import { redirectToLogin } from 'utils/navigation';
import * as Routes from 'constants/routes';
import { LocaleContext } from 'containers/IntlContainer';

/** Data layer **/
import { LogoutActions, FetchMeActions } from 'zo-data-layer/actions';
import { MeSelectors } from 'zo-data-layer/selectors';

import { AsideLink, AsideA } from 'components/Aside/AsideLink';
import MyBookingsSection from 'pages/MyPages/MyBookingsSection';
import MyBookingDetails from './MyBookingDetails';
import MyPageContainer from './MyPageContainer';
import ProfileIcon from 'components/Icons/ProfileIcon';
import PrivacyIcon from 'components/Icons/PrivacyIcon';
import CostIcon from 'components/Icons/CostIcon';
import BellIcon from 'components/Icons/BellIcon';
import CalendarIcon from 'components/Icons/CalendarIcon';
import SupportIcon from 'components/Icons/SupportIcon';
import MyProfileSection from './MyProfileSection';
import ChangeMyPasswordSection from './ChangeMyPasswordSection';
import MyPaymentMethodsSection from './MyPaymentMethodsSection';
import MyAlertsSection from './MyAlertsSection';
import MyAlertDetails from './MyAlertDetails';
import Button from 'components/Button';

/** Styling */
import { defaultTheme, neutral } from 'styles';
import LoadingIndicator from 'components/LoadingIndicator';

type Props = {
  bookingsCount: number;
  isMeLoaded: boolean;
  isMeLoading: boolean;
  fetchMe: () => AnyAction;
  logout: () => AnyAction;
};

class MyAccount extends Component<Props> {
  static contextType = LocaleContext;

  componentDidMount() {
    const { fetchMe, isMeLoaded, isMeLoading } = this.props;
    if (!isMeLoaded && !isMeLoading) {
      fetchMe();
    }
  }

  handleClickSignOut = () => {
    this.props.logout();
    redirectToLogin();
  };

  renderSideHeader = () => <FormattedMessage {...Messages.yourAccount} />;

  renderSideFooter = () => (
    <SignOutButton title={<FormattedMessage {...Messages.signOut} />} onClick={this.handleClickSignOut} fullWidth />
  );

  renderSideNavLinks = () => {
    const { isMeLoading, bookingsCount } = this.props;
    const { currentLocale } = this.context;

    const isActive = (path: string) => window.location.pathname.startsWith(path);

    return (
      <>
        <AsideLink
          to={AppLinks.myProfile}
          icon={
            <ProfileIcon
              color={isActive(AppLinks.myProfile) ? neutral.white : neutral.grey}
              width={defaultTheme.size.asideIconSize}
            />
          }
        >
          <FormattedMessage {...Messages.profile} />
        </AsideLink>
        <AsideLink
          to={AppLinks.myAlerts}
          icon={
            <BellIcon
              color={isActive(AppLinks.myAlerts) ? neutral.white : neutral.grey}
              width={defaultTheme.size.asideIconSize}
            />
          }
        >
          <FormattedMessage {...Messages.alerts} />
        </AsideLink>
        <AsideLink
          to={AppLinks.myAccountBooking}
          icon={
            <CalendarIcon
              color={isActive(AppLinks.myAccountBooking) ? neutral.white : neutral.grey}
              width={defaultTheme.size.asideIconSize}
            />
          }
        >
          {isMeLoading ? (
            <LoadingIndicator isInverted />
          ) : (
            <>
              <FormattedMessage {...Messages.bookings} />
              <Count isActive={isActive(AppLinks.myAccountBooking)}> &nbsp;| {bookingsCount}</Count>
            </>
          )}
        </AsideLink>
        <AsideLink
          to={AppLinks.myPaymentMethods}
          icon={
            <CostIcon
              color={isActive(AppLinks.myPaymentMethods) ? neutral.white : neutral.grey}
              width={defaultTheme.size.asideIconSize}
            />
          }
        >
          <FormattedMessage {...Messages.paymentMethods} />
        </AsideLink>
        <AsideLink
          to={AppLinks.changePassword}
          icon={
            <PrivacyIcon
              color={isActive(AppLinks.changePassword) ? neutral.white : neutral.grey}
              width={defaultTheme.size.asideIconSize}
            />
          }
        >
          <FormattedMessage {...Messages.changePassword} />
        </AsideLink>
        <AsideA
          href={Routes.getUrlConstants({ locale: currentLocale }).supportUrl}
          icon={<SupportIcon color={neutral.grey} width={defaultTheme.size.asideIconSize} padding="0 0.7rem 0 0" />}
        >
          <FormattedMessage {...Messages.support} />
        </AsideA>
      </>
    );
  };

  renderRoutes = () => (
    <Switch>
      <Route path={AppLinks.myAccountBooking} exact component={MyBookingsSection} />
      <Route path={AppLinks.myAccountBookingDetails}>
        <MyBookingDetails />
      </Route>
      <Route path={AppLinks.myProfile} exact component={MyProfileSection} />
      <Route path={AppLinks.myAlerts} exact component={MyAlertsSection} />
      <Route path={AppLinks.myAlertDetails}>
        <MyAlertDetails />
      </Route>
      <Route path={AppLinks.myPaymentMethods} exact component={MyPaymentMethodsSection} />
      <Route path={AppLinks.changePassword} exact component={ChangeMyPasswordSection} />
    </Switch>
  );

  render() {
    return (
      <MyPageContainer
        sideHeader={this.renderSideHeader()}
        sideNavLinks={this.renderSideNavLinks()}
        routes={this.renderRoutes()}
        sideFooter={this.renderSideFooter()}
      />
    );
  }
}

type CountProps = {
  isActive: boolean;
};

const Count = styled.span<CountProps>`
  color: ${({ isActive, theme }) => (isActive ? theme.colors.white : theme.colors.grey)};
`;

const SignOutButton = styled(Button)`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  border: 1px solid white;
`;

type SelectorProps = {
  bookingsCount: number;
  isMeLoaded: boolean;
  isMeLoading: boolean;
};

const mapStateToProps = createStructuredSelector<any, SelectorProps>({
  bookingsCount: MeSelectors.myUpcomingBookingsCount(),
  isMeLoaded: MeSelectors.isSuccess(),
  isMeLoading: MeSelectors.isLoading(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      logout: LogoutActions.request,
      fetchMe: FetchMeActions.request,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
