export const ServicePageSizes = {
  carousel: 6,
  section: 4,
};

export const ResultSetNames = {
  all: 'all',
  new: 'new',
  today: 'today',
  upcoming: 'upcoming',
  allDefault: 'allDefault',
  newDefault: 'newDefault',
  todayDefault: 'todayDefault',
  upcomingDefault: 'upcomingDefault',
  upcomingScheduled: 'upcomingScheduled',
  byCategory: 'byCategory',
  leader: 'leader',
};

export const PageSize = {
  filterPageSize: 16,
  alertsPageSize: 50,
};
