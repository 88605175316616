/** External Dependencies */
import React, { FC, useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { List, Map } from 'immutable';

/** Internal dependencies */
import { history } from 'compositionRoot';
import { useBooleanEffect } from 'hooks';
import { generateSuccessPageLink } from 'constants/routes';
import { IPromoCodeData } from 'interfaces';
import { CurrentServiceContext, CurrentServiceInfo } from 'contexts';

/** Data layer */
import {
  MyBookingsCreateActions,
  MyServiceListAvailabilitiesActions,
  MyWaitlistCreateActions,
} from 'zo-data-layer/actions';
import { MyEventBookingCreateSelectors, MyWaitlistBookingCreateSelectors } from 'zo-data-layer/selectors';
import { BookingType } from 'zo-data-layer/constants/booking';
import { MyServiceListAvailabilitesSelectors } from 'zo-data-layer/selectors';

/** Components */
import ServiceDetails from './ServiceDetails';
import { Timeslot } from 'zo-data-layer/dataobjects';
import EventWaitlist from 'zo-data-layer/dataobjects/EventWaitlist';
import { ImmutableState } from 'zo-data-layer/utils/selectors';
import EventBooking from 'zo-data-layer/dataobjects/EventBooking';

type EventServiceDetails = {
  promocode?: string;
  promocodeData?: Map<string, IPromoCodeData>;
  slotId: string;
};

type Selectors = {
  isEventBookingSuccess: boolean;
  isWaitlistSuccess: boolean;
  timeSlots: List<Timeslot>;
  booking: EventBooking;
  waitlist: EventWaitlist;
};

const structuredSelector = createStructuredSelector<ImmutableState, Selectors>({
  isEventBookingSuccess: MyEventBookingCreateSelectors.isSuccess(),
  isWaitlistSuccess: MyWaitlistBookingCreateSelectors.isSuccess(),
  timeSlots: MyServiceListAvailabilitesSelectors.getResults(),
  booking: MyEventBookingCreateSelectors.getBooking(),
  waitlist: MyWaitlistBookingCreateSelectors.getBooking(),
});

const EventServiceDetails: FC<EventServiceDetails> = ({ promocodeData, promocode, slotId }) => {
  const dispatch = useDispatch();

  /**
   * Reset state on unmount so that next event confirmation doesn't skip to
   * success of previous event.
   * */
  useEffect(
    () => () => {
      dispatch(MyWaitlistCreateActions.reset());
      dispatch(MyBookingsCreateActions.reset());
    },
    [dispatch]
  );

  const { isEventBookingSuccess, isWaitlistSuccess, timeSlots, booking, waitlist } = useSelector(structuredSelector);

  const { service } = useContext<CurrentServiceInfo>(CurrentServiceContext);

  useEffect(() => {
    dispatch(MyServiceListAvailabilitiesActions.request({ id: service.enrollmentId }));
  }, [dispatch, service.enrollmentId]);

  const selectedSlot = timeSlots.find((slot) => parseInt(slotId, 10) === slot.id);

  const navigateToSuccessPage = useCallback(
    (bookingId, bookingType) => {
      history.push(generateSuccessPageLink(service, bookingId, bookingType));
    },
    [service]
  );

  useBooleanEffect(isEventBookingSuccess, () => {
    navigateToSuccessPage(booking.id, BookingType.Booking);
  });

  useBooleanEffect(isWaitlistSuccess, () => {
    navigateToSuccessPage(waitlist.id, BookingType.Waitlist);
  });

  const [quantity, setQuantity] = useState(1);
  const [selectedCardID, setSelectedCardID] = useState(undefined);

  return (
    <ServiceDetails
      quantity={quantity}
      setQuantity={setQuantity}
      selectedCardID={selectedCardID}
      setSelectedCardID={setSelectedCardID}
      promocode={promocode}
      promocodeData={promocodeData}
      timeSlot={selectedSlot || timeSlots[0]}
    />
  );
};

export default EventServiceDetails;
