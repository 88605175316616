/** External Dependencies */
import React, { FC, useCallback, useState, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ErrorDiv } from 'components/Input';

/** Internal dependencies */
import Messages from './Messages';
import useUnmountEffect from '../../hooks/useUnmountEffect';
import { useAnalytics } from '../../analytics';

/** Data layer */
import {
  MyBookingsCreateActions,
  MyInventoryCreateActions,
  MyWaitlistCreateActions,
  displayToast,
  MyScheduledServiceBookingCreateActions,
} from 'zo-data-layer/actions';
import {
  MyEventBookingCreateSelectors,
  MyWaitlistBookingCreateSelectors,
  CreateInventoryBookingSelectors,
} from 'zo-data-layer/selectors';
import MyService from 'zo-data-layer/dataobjects/MyService';
import { Timeslot } from 'zo-data-layer/dataobjects';
import { ServiceType, ToastTypes } from 'zo-data-layer/constants';
import { OrBooleanSelectors } from 'zo-data-layer/utils/selectors';
import AnalyticsEvents from 'zo-data-layer/constants/AnalyticsEvents';

/** Components */
import Button from '../Button';

/** Styling */
import { typeScale } from 'styles';
import { ScheduledAvailability } from 'zo-data-layer/dataobjects/ScheduledAvailabilityCollection';

type Props = {
  selectedCardID?: string;
  service: MyService;
  isFree: boolean;
  timeSlot?: Timeslot;
  quantity?: number;
  promocode: string;
  stripe: any;
  availability?: ScheduledAvailability;
};

type SelectorProps = {
  isLoading: boolean;
  isAuthRequired: boolean;
  paymentIntentClientSecretEvent: string;
  paymentIntentClientSecretInventory: string;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  isAuthRequired: OrBooleanSelectors(
    MyEventBookingCreateSelectors.isAuthenticationRequired(),
    CreateInventoryBookingSelectors.isAuthenticationRequired()
  ),
  isLoading: OrBooleanSelectors(
    MyEventBookingCreateSelectors.isLoading(),
    MyWaitlistBookingCreateSelectors.isLoading(),
    CreateInventoryBookingSelectors.isLoading()
  ),
  paymentIntentClientSecretEvent: MyEventBookingCreateSelectors.getClientSecret(),
  paymentIntentClientSecretInventory: CreateInventoryBookingSelectors.getClientSecret(),
});

enum PaymentAbandonedActionTypes {
  PaymentInitiated = 'PaymentInitiated',
  UnmountComponent = 'UnmountComponent',
}

// a small reducer to handle when the user leaves the page so we can log the analytics event
// see: https://stackoverflow.com/questions/60456803/how-to-access-state-when-component-unmount-with-react-hooks
function generateUnmountReducer(analytics, service: MyService) {
  function reducer(isPaymentInitiated: boolean, action: PaymentAbandonedActionTypes) {
    switch (action) {
      case PaymentAbandonedActionTypes.PaymentInitiated:
        return true;
      case PaymentAbandonedActionTypes.UnmountComponent:
        if (!isPaymentInitiated) {
          analytics.logPaymentEvent(AnalyticsEvents.PaymentAbandoned, service);
        }
        break;
    }
  }
  return reducer;
}

const SubmitBookingButton: FC<Props> = ({
  selectedCardID,
  service,
  timeSlot,
  quantity,
  promocode,
  isFree,
  stripe,
  availability,
}) => {
  const { isLoading, isAuthRequired, paymentIntentClientSecretEvent, paymentIntentClientSecretInventory } = useSelector(
    mapReduxState
  );
  const dispatch = useDispatch();

  const analytics = useAnalytics();
  const unmountReducer = generateUnmountReducer(analytics, service);
  const dispatchBookingInitiated = useReducer(unmountReducer, false)[1];

  useEffect(() => {
    if (service.paymentRequired) {
      analytics.logPaymentEvent(AnalyticsEvents.PaymentAttempted, service);
    }
    // eslint-disable-next-line
  }, []);
  useUnmountEffect(() =>
    //log an analytics event if they came to this page but never decided to pay
    //we are returning just a cleanup function for this effect to launch when they leave this page.
    dispatchBookingInitiated(PaymentAbandonedActionTypes.UnmountComponent)
  );

  const [isStripeError, setStripeError] = useState(false);
  const [shouldRenderError, setRenderError] = useState(false);

  const renderError = useCallback(() => {
    const isCardSelected = selectedCardID && selectedCardID !== 'new';
    const isWaitlist = service?.allowWaitlist && timeSlot?.remainingCapacity < 1;
    return !isCardSelected && shouldRenderError && !isFree && !isWaitlist ? (
      <FormattedMessage {...Messages.missingPaymentMethod} />
    ) : (
      ''
    );
  }, [isFree, selectedCardID, shouldRenderError, service, timeSlot]);

  const authenticatePaymentMethod = useCallback(async () => {
    setStripeError(false);
    const result = await stripe.handleCardAction(
      service.isEvent ? paymentIntentClientSecretEvent : paymentIntentClientSecretInventory
    );

    if (result.error) {
      dispatch(displayToast({ id: result.error.code, defaultMessage: result.error.message }, ToastTypes.error));
      setStripeError(true);
      renderError();
      return;
    }
    if (service.isEvent) {
      dispatch(
        MyBookingsCreateActions.request({
          occurrence: timeSlot.id,
          quantity: quantity,
          stripePaymentIntent: result.paymentIntent.id,
        })
      );
    } else {
      dispatch(
        MyInventoryCreateActions.request({
          inventory: service.serviceId,
          stripePaymentIntent: result.paymentIntent.id,
        })
      );
    }
  }, [
    stripe,
    service,
    paymentIntentClientSecretEvent,
    paymentIntentClientSecretInventory,
    dispatch,
    renderError,
    timeSlot,
    quantity,
  ]);

  useEffect(() => {
    if (paymentIntentClientSecretEvent || paymentIntentClientSecretInventory) {
      authenticatePaymentMethod();
    }
  }, [authenticatePaymentMethod, paymentIntentClientSecretEvent, paymentIntentClientSecretInventory]);

  const handleSubmit = () => {
    dispatchBookingInitiated(PaymentAbandonedActionTypes.PaymentInitiated);
    setRenderError(true);

    if (service.allowWaitlist && timeSlot.remainingCapacity < 1) {
      dispatch(MyWaitlistCreateActions.request({ occurrence: timeSlot.id }));
    } else {
      if (service.isInventory) {
        dispatch(
          MyInventoryCreateActions.request({
            inventory: service.serviceId,
            stripePaymentMethod: selectedCardID,
            promocode: promocode,
          })
        );
      } else if (service.isScheduledService && availability) {
        dispatch(
          MyScheduledServiceBookingCreateActions.request({
            scheduledService: service.serviceId,
            startTime: `${availability.date}T${availability.start_time}`,
            endTime: `${availability.date}T${availability.end_time}`,
            stripePaymentMethod: selectedCardID,
            promocode: promocode,
          })
        );
      } else {
        dispatch(
          MyBookingsCreateActions.request({
            occurrence: timeSlot.id,
            quantity: quantity,
            stripePaymentMethod: selectedCardID,
            promocode: promocode,
          })
        );
      }
      if (isAuthRequired) {
        authenticatePaymentMethod();
      }
    }
  };

  let submitButtonMessage = Messages.confirmBooking;
  if (timeSlot && timeSlot.remainingCapacity < 1 && service.allowWaitlist) {
    submitButtonMessage = Messages.joinWaitlist;
  } else if (
    service.serviceType !== ServiceType.Inventory &&
    !service.isScheduledService &&
    (!timeSlot || timeSlot.remainingCapacity < 1)
  ) {
    submitButtonMessage = Messages.soldOut;
  }

  return (
    <div>
      <StyledButton
        title={<FormattedMessage {...submitButtonMessage} />}
        type="submit"
        onClick={handleSubmit}
        isLoading={!isStripeError && isLoading}
      />
      <ErrorMessage>{renderError()}</ErrorMessage>
    </div>
  );
};

export default SubmitBookingButton;

const StyledButton = styled(Button)`
  font-size: ${typeScale.font17};
  margin-top: 1rem;
`;

const ErrorMessage = styled(ErrorDiv)`
  text-align: center;
`;
