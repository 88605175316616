/** External Dependencies **/
import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** Internal Dependencies */
import Messages from './Messages';
import { useBooleanEffect } from 'hooks';

/** Data layer **/
import { closeModal } from 'zo-data-layer/actions';
import { ModalSelectors } from 'zo-data-layer/selectors';

/** Styling */
import { CancelIcon } from '../Icons';
import { neutral, mediaQueries, defaultTheme } from 'styles';

type Props = {
  name: string;
  fullModal?: boolean;
  opacity?: number;
  centered?: boolean;
  onOpen?: () => void;
};

const ModalContainer: FC<Props> = ({ name, children, fullModal, opacity, centered, onOpen }) => {
  const dispatch = useDispatch();

  const close = () => dispatch(closeModal(name));
  const modalSelectors = useMemo(() => ModalSelectors(name), [name]);
  const isOpen: boolean = useSelector(modalSelectors.isOpen());

  useEffect(() => {
    document.addEventListener('keydown', closeOnEsc);

    return () => {
      document.removeEventListener('keydown', closeOnEsc);
    };
  });

  const handleClose = () => {
    close();
  };

  const closeOnEsc = (event) => {
    if (event.keyCode === 27) {
      handleClose();
    }
  };

  useBooleanEffect(isOpen, () => {
    if (onOpen) onOpen();
  });

  if (!isOpen) return null;

  const renderContent = () => {
    if (fullModal) {
      return (
        <>
          <CloseButton fullModal onClick={handleClose}>
            <StyledMessage>
              <FormattedMessage {...Messages.close} />
            </StyledMessage>
            <CancelIcon color={neutral.grey} width={defaultTheme.size.closeIcon} />
          </CloseButton>
          <FullModalDiv>{children}</FullModalDiv>
        </>
      );
    }
    return (
      <ContentDiv centered={centered}>
        {children}
        <CloseButton onClick={handleClose}>
          <CancelIcon color={neutral.darkGrey} width={defaultTheme.size.closeIcon} />
        </CloseButton>
      </ContentDiv>
    );
  };

  return (
    <Wrapper>
      <BackgroundOverlay onClick={handleClose} opacity={opacity} />
      {renderContent()}
    </Wrapper>
  );
};

export default ModalContainer;

type CloseButtonProps = {
  fullModal?: boolean;
};

type BackgroundOverlayProps = {
  opacity?: number;
};

const CloseButton = styled.div<CloseButtonProps>`
  position: absolute;
  display: flex;
  top: ${({ fullModal }) => (fullModal ? '5rem' : '1.5rem')};
  right: 1.5rem;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.modal};
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const BackgroundOverlay = styled.div<BackgroundOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, ${({ opacity = 0.4 }) => opacity});
`;

interface ContentProps {
  centered: boolean;
}

const ContentDiv = styled.div<ContentProps>`
  max-width: 34.6875rem;
  min-width: 90%;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: ${({ centered }) => (centered ? '50%' : '20%')};
  left: 50%;
  right: auto;
  bottom: auto;
  transform: ${({ centered }) => (centered ? 'translate(-50%, -50%)' : 'translateX(-50%)')};
  padding: 3rem 2rem;
  background-color: white;
  border-radius: 4px;

  @media (min-width: ${mediaQueries.m_mobile}) {
    min-width: 34.6875rem;
  }
`;

const FullModalDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;

  @media (min-width: ${mediaQueries.m_mobile}) {
    width: 80%;
  }
`;

const StyledMessage = styled.div`
  padding-left: 0.5rem;
  padding-right: 1rem;
  font-weight: 400;
  color: white;
  letter-spacing: 0.15em;
`;
