/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { mediaQueries, defaultTheme, miscColors, typeScale } from 'styles';

export enum Size {
  Small,
  Medium,
  Large,
}

interface IOverlay {
  children?: React.ReactNode;
  size?: Size;
}

interface IChildrenWrapper {
  size?: Size;
}

const Overlay: FC<IOverlay> = ({ children, size = Size.Small }) => (
  <ColumnOverlayWrapper size={size}>
    <ChildrenWrapper size={size}>{children}</ChildrenWrapper>
  </ColumnOverlayWrapper>
);

const ChildrenWrapper = styled.div<Pick<IChildrenWrapper, 'size'>>`
  width: 100%;
  min-height: inherit;
  display: ${({ size }) => (size === Size.Large ? 'flex' : 'block')};
  align-self: ${({ size }) => (size === Size.Large ? 'flex-start' : 'auto')};
`;

const OverlayWrapper = styled.div<Pick<IOverlay, 'size'>>`
  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: ${(props) => (props.size === Size.Large ? '0rem' : '2rem')};
    height: max-content;
    margin: ${({ size }) => (size === Size.Large ? '4rem' : '0 0 3rem 0')};
    z-index: ${({ theme }) => theme.zIndex.z3};
    min-width: ${({ size }) => (size === Size.Large ? defaultTheme.size.minScreenWidth : '0')};
    width: ${({ size }) => {
      switch (size) {
        case Size.Medium:
          return '42rem';
        case Size.Small:
        default:
          return '29rem';
      }
    }};
  }

  height: 100%;
  background-color: ${(props) => props.theme.colors.backgroundColor};
  color: ${(props) => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${(props) => props.theme.colors.backgroundColor};
`;

const ColumnOverlayWrapper = styled(OverlayWrapper)`
  margin-top: 0;
  flex-direction: row;
  justify-content: space-around;
`;

export const OverlayHeader = styled.h1`
  padding-bottom: 0.5rem;
`;

export const OverlaySubHeader = styled.h3`
  padding-bottom: 0;
  margin-bottom: 0;
`;

export const OverlayText = styled.p`
  padding-bottom: 0.5rem;
  line-height: 1.5rem;
`;

export const OverlayDescription = styled.p`
  padding-bottom: 1rem;
  font-size: ${typeScale.font12};
  line-height: ${typeScale.font14};
`;

export const OverlayDivider = styled.div`
  margin: 1rem 0;
  border-bottom: 1px solid ${miscColors.divider};
`;

export default Overlay;
