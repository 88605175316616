/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from 'styles/colors';
import { defaultTheme } from 'styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const ProfileIcon: FC<Props> = ({ color = neutral.white, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.25018 21.9834L6.23326 22.75H7H21H21.7667L21.7498 21.9834C21.6997 19.7113 20.9216 17.5622 19.5222 15.9201C18.0712 14.2176 16.1125 13.25 14 13.25C11.8875 13.25 9.92878 14.2176 8.47785 15.9201C7.0784 17.5622 6.30035 19.7113 6.25018 21.9834Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M17.0046 5.99545L17.5349 5.46512L17.0046 5.99545C17.8088 6.79971 18.25 7.86482 18.25 9C18.25 10.1352 17.8088 11.2003 17.0046 12.0046C16.2003 12.8088 15.1352 13.25 14 13.25C12.8648 13.25 11.7997 12.8088 10.9954 12.0046C10.1912 11.2003 9.75 10.1352 9.75 9C9.75 7.86482 10.1912 6.79971 10.9954 5.99545C11.7997 5.19118 12.8648 4.75 14 4.75C15.1352 4.75 16.2003 5.19118 17.0046 5.99545Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default ProfileIcon;
