/** External Dependencies */
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { List } from 'immutable';
import { useHistory } from 'react-router-dom';

/** Internal Dependencies */
import Messages from './Messages';
import { PageSize } from 'constants/Services';
import { generateMyAlertDetailsLink } from 'constants/routes';

/* Data layer **/
import { MyActivitiesListSelectors } from 'zo-data-layer/selectors';
import { MyActivitiesListActions } from 'zo-data-layer/actions';

/** Components */
import { RouteWrapper } from './MyPageContainer';
import AlertTile from 'components/AlertTile';
import { UserActivity } from 'zo-data-layer/dataobjects';

type SelectorProps = {
  myActivities: List<UserActivity>;
  count: number;
};

const memoizedSelectors = createStructuredSelector<any, SelectorProps>({
  myActivities: MyActivitiesListSelectors.getActivities(),
  count: MyActivitiesListSelectors.getCount(),
});

const MyAlerts: FC = () => {
  const { myActivities, count } = useSelector(memoizedSelectors);
  const { push } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MyActivitiesListActions.request({ pageSize: PageSize.alertsPageSize }));
  }, [dispatch]);

  const renderAlerts = () => {
    if (count > 0) {
      return myActivities.map((activity) => (
        <AlertTile
          alert={activity}
          key={activity.id}
          onClick={() => {
            push(generateMyAlertDetailsLink(activity.id));
          }}
        />
      ));
    }
    return (
      <div>
        <FormattedMessage {...Messages.noNewAlerts} />
      </div>
    );
  };

  return (
    <RouteWrapper>
      <div>{renderAlerts()}</div>
    </RouteWrapper>
  );
};

export default MyAlerts;
