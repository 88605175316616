import { useEffect } from 'react';

const useUnmountEffect = (unmountFunction: () => void) => {
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return unmountFunction;
    // eslint-disable-next-line
  }, []);
};

export default useUnmountEffect;
