import styled from 'styled-components';

const FullScreenContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.backgroundColor};
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

export default FullScreenContainer;
