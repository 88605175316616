/** External Dependencies */
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** Internal Dependencies */
import Messages from '../Messages';

/** Components */
import { OverlaySubHeader, OverlayText } from '../../Overlay';

type Props = {
  email: string;
};

const VerifyEmail = ({ email }) => (
  <Wrapper>
    <OverlaySubHeader>
      <FormattedMessage {...Messages.emailVerification} />
    </OverlaySubHeader>
    <OverlayText className="darkGrey">
      <FormattedMessage {...Messages.emailVerificationSent} />
    </OverlayText>
    <EmailText>{email}</EmailText>
    <OverlayText className="darkGrey">
      <FormattedMessage {...Messages.emailVerificationInstructions} />
    </OverlayText>
  </Wrapper>
);

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.layoutPaddingSm};
`;

const EmailText = styled(OverlayText)`
  color: ${({ theme }) => theme.colors.primaryColor};
  font-weight: 400;
`;

export default VerifyEmail;
