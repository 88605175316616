import { defineMessages } from 'react-intl';

export default defineMessages({
  checkAvailabilites: {
    id: 'serviceInfo.checkAvailabilites',
    defaultMessage: 'Check Availabilities',
  },
  watch: {
    id: 'serviceInfo.watch',
    defaultMessage: 'Watch',
  },
});
