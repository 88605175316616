/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { defaultTheme } from '../../styles/themes';
import { status } from '../../styles';

type Props = {
  color?: string;
  width?: string;
};

const WaitlistOnIcon: FC<Props> = ({ width = defaultTheme.size.iconSize, color = status.waitlist }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 5.3L8.15385 8L13 2" stroke={color} strokeLinecap="round" />
      <line x1="0.5" y1="4" x2="4.5" y2="4" stroke={color} strokeLinecap="round" />
      <line x1="0.5" y1="8" x2="4.5" y2="8" stroke={color} strokeLinecap="round" />
      <line x1="0.5" y1="12" x2="9.5" y2="12" stroke={color} strokeLinecap="round" />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default WaitlistOnIcon;
