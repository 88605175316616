/** External Dependencies */
import styled from 'styled-components';

/** Styling */
import { defaultTheme } from '../../styles';

type MarginSize = 'xs' | 's' | 'm' | 'l';

type Props = {
  marginSize?: MarginSize;
};

const getMarginSize = (marginSize: MarginSize = 'xs') => {
  switch (marginSize) {
    case 'xs':
      return defaultTheme.spacing.layoutPaddingXs;
    case 's':
      return defaultTheme.spacing.layoutPaddingSm;
    case 'l':
      return defaultTheme.spacing.layoutPaddingLg;
    case 'm':
    default:
      return defaultTheme.spacing.layoutPadding;
  }
};

const Row = styled.div<Props>`
  display: flex;
  align-items: center;
  overflow: auto;
  margin-left: ${({ marginSize }) => `-${getMarginSize(marginSize)}`};
  margin-right: ${({ marginSize }) => `-${getMarginSize(marginSize)}`};

  & > * {
    flex-shrink: 0;
    margin-left: ${({ marginSize }) => getMarginSize(marginSize)};
    margin-right: ${({ marginSize }) => getMarginSize(marginSize)};
  }
`;

export default Row;
