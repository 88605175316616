/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'immutable';

/** Interal Dependencies */
import { PageSize, ResultSetNames } from '../../constants/Services';
import Messages from './Messages';
import { allListSelectors } from '../../utils/selectors';
import { usePagination } from 'hooks';

/** Data layer */
import { MyServicesListActions, MyServicesListRequest } from 'zo-data-layer/actions';
import { MyService } from 'zo-data-layer/dataobjects';
import { ImmutableState } from 'zo-data-layer/utils/selectors';

/** Components */
import TileSection from '../../components/TileSection';
import FilterPagesContainer from '../../components/FilterPagesContainer';
import FilterPagesLoadingIndicator from 'components/FilterPagesLoadingIndicator';

type Props = SelectorProps;

const { REACT_APP_SERVICES_CATEGORIES_IDS } = process.env;
const servicesCategory = REACT_APP_SERVICES_CATEGORIES_IDS ? REACT_APP_SERVICES_CATEGORIES_IDS : null;

const AllFilterPage: FC<Props> = ({ services, totalCount, isLoading }) => {
  const { fetchFirstPage, fetchNextPage, hasNext } = usePagination<MyServicesListRequest>(
    MyServicesListActions.request,
    { all: true, resultSetName: ResultSetNames.all, showHidden: true, enrolled: true, category: servicesCategory},
    { totalCount, pageSize: PageSize.filterPageSize }
  );

  const isLoaded = totalCount > 0;

  useEffect(fetchFirstPage, []);

  return (
    <FilterPagesContainer name="all" isLoaded={isLoaded}>
      <InfiniteScroll
        dataLength={services.size}
        next={fetchNextPage}
        hasMore={hasNext || isLoading}
        loader={<FilterPagesLoadingIndicator />}
      >
        <TileSection title={<FormattedMessage {...Messages.allServices} />} tiles={services} isLoaded={isLoaded} />
      </InfiniteScroll>
    </FilterPagesContainer>
  );
};

type SelectorProps = {
  totalCount: number;
  services: List<MyService>;
  isLoading: boolean;
};

const mapStateToProps = createStructuredSelector<ImmutableState, SelectorProps>({
  totalCount: allListSelectors.getCount(),
  isLoading: allListSelectors.isLoading(),
  services: allListSelectors.getServices(),
});

export default connect(mapStateToProps)(AllFilterPage);
