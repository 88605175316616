import React, { FC } from 'react';
import { defaultTheme, neutral } from 'styles';

type Props = {
  color?: string;
  width?: string;
};

const SuitcaseIcon: FC<Props> = ({ width = defaultTheme.size.iconSize, color = neutral.white }) => (
  <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.5" y="7" width="17" height="12" rx="1" stroke={color} />
    <path
      d="M3.5 8C3.5 7.44772 3.94772 7 4.5 7H19.5C20.0523 7 20.5 7.44772 20.5 8V11L12.1168 11.9863C12.0392 11.9954 11.9608 11.9954 11.8832 11.9863L3.5 11V8Z"
      stroke={color}
    />
    <path d="M8 5C8 4.44772 8.44772 4 9 4H15C15.5523 4 16 4.44772 16 5V7H8V5Z" stroke={color} />
  </svg>
);

export default SuitcaseIcon;
