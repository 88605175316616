/** External Dependencies **/
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { CloudinaryContext } from 'cloudinary-react';
import { ThemeProvider } from 'styled-components';

/** Interal Dependencies */
import { AppLinks } from './constants/routes';
import configureStore, { history } from './compositionRoot';
import { Token } from './auth';

/** Data layer */
import { CloudName } from 'zo-data-layer/constants/cloudinary';

/** Pages */
import DiscoverPage from './pages/DiscoverPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import RegisterPage from './pages/RegisterPage';
import ConfirmRegisterPage from './pages/ConfirmRegisterPage';
import AllFilterPage from './pages/AllFilterPage';
import NewFilterPage from './pages/NewFilterPage';
import TodayFilterPage from './pages/TodayFilterPage';
import UpcomingFilterPage from './pages/UpcomingFilterPage';
import ServiceInfoPage from './pages/ServiceInfoPage';
import ServiceDetailsPage from './pages/ServiceDetailsPage';
import BookingSuccessPage from './pages/SuccessPages/BookingSuccessPage';
import WaitlistSuccessPage from './pages/SuccessPages/WaitlistSuccessPage';
import InventorySuccessPage from './pages/SuccessPages/InventorySuccessPage';
import CategoryFilterPage from './pages/CategoryFilterPage';
import YourZoPage from './pages/MyPages/MyAccountPage';
import LeaderPage from './pages/LeaderPage';
import LeaderCategoryFilterPage from './pages/LeaderCategoryFilterPage';
import PublicServiceInfoPage from 'pages/ServiceInfoPage/public';

/** Components */
import IntlContainer from './containers/IntlContainer';
import StripeProvider from './containers/StripeProvider';
import PagesContainer from './components/PagesContainer';
import AuthRoute from './components/Routes/AuthRoute';
import AdminRoute from 'components/Routes/AdminRoute';
import BrowserUnsupported from 'components/BrowserUnsupported';

/** Services */
import OneSignalService from './services/OneSignalService';
import LoginService from './services/LoginService';
import ScrollToTop from './services/ScrollToTopService';

/** Styling */
import { GlobalStyle, defaultTheme } from './styles';
import AnalyticsService from './services/AnalyticsServices';
import ZendeskWidget from './components/ZendeskWidget';
import ScheduledSuccessPage from 'pages/SuccessPages/ScheduledSuccessPage';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState);

const Routes = (): JSX.Element => (
  <IntlContainer>
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <Provider store={store}>
        <CloudinaryContext cloudName={CloudName}>
          <LoginService />
          <OneSignalService />
          <StripeProvider>
            <ConnectedRouter history={history}>
              <AnalyticsService />
              <ScrollToTop />
              <BrowserUnsupported />
              <PagesContainer>
                <Switch>
                  <Route
                    path={AppLinks.login}
                    render={() => (!Token.isAuthenticated() ? <LoginPage /> : <Redirect to={AppLinks.discover} />)}
                  />
                  <Route path={AppLinks.forgotPassword} component={ForgotPasswordPage} />
                  <Route path={AppLinks.resetPassword} component={ResetPasswordPage} />
                  <Route path={AppLinks.register} component={RegisterPage} />
                  <Route path={AppLinks.confirmRegister} component={ConfirmRegisterPage} />
                  <AuthRoute exact path={AppLinks.discover} component={DiscoverPage} />
                  <AuthRoute path={AppLinks.allFilter} component={AllFilterPage} />
                  <AuthRoute path={AppLinks.newFilter} component={NewFilterPage} />
                  <AuthRoute path={AppLinks.upcomingFilter} component={UpcomingFilterPage} />
                  <AuthRoute path={AppLinks.todayFilter} component={TodayFilterPage} />
                  <AuthRoute path={AppLinks.category} component={CategoryFilterPage} />
                  <AuthRoute path={`${AppLinks.bookingSuccess}:bookingId`} component={BookingSuccessPage} />
                  <AuthRoute path={`${AppLinks.waitlistSuccess}:bookingId`} component={WaitlistSuccessPage} />
                  <AuthRoute path={`${AppLinks.scheduledSuccess}:bookingId`} component={ScheduledSuccessPage} />
                  <Route path={`${AppLinks.publicServiceInfo}:publicUuid`} component={PublicServiceInfoPage} />
                  <AuthRoute path={`${AppLinks.serviceDetails}:enrollmentId/:slotId`} component={ServiceDetailsPage} />
                  <AuthRoute path={`${AppLinks.serviceDetails}:enrollmentId`} component={ServiceDetailsPage} />
                  <AuthRoute path={`${AppLinks.inventorySuccess}:enrollmentId`} component={InventorySuccessPage} />
                  <AuthRoute path={`${AppLinks.serviceInfo}:enrollmentId/:categoryId`} component={ServiceInfoPage} />
                  <AuthRoute path={`${AppLinks.serviceInfo}:enrollmentId`} component={ServiceInfoPage} />
                  <AuthRoute
                    path={`${AppLinks.myAccount}:myAccountRoute/:myAccountSubRoute/:myAccountSubRouteID`}
                    component={YourZoPage}
                  />
                  <AuthRoute path={`${AppLinks.myAccount}:myAccountRoute`} component={YourZoPage} />
                  <AdminRoute
                    path={`${AppLinks.leaderView}${AppLinks.bookingSuccess}:bookingId`}
                    component={BookingSuccessPage}
                  />
                  <AdminRoute
                    path={`${AppLinks.leaderView}${AppLinks.waitlistSuccess}:bookingId`}
                    component={WaitlistSuccessPage}
                  />
                  <AdminRoute
                    path={`${AppLinks.leaderView}${AppLinks.serviceDetails}:enrollmentId/:slotId`}
                    component={ServiceDetailsPage}
                  />
                  <AdminRoute
                    path={`${AppLinks.leaderView}${AppLinks.serviceDetails}:enrollmentId`}
                    component={ServiceDetailsPage}
                  />
                  <AdminRoute
                    path={`${AppLinks.leaderView}${AppLinks.inventorySuccess}:enrollmentId`}
                    component={InventorySuccessPage}
                  />
                  <AdminRoute
                    path={`${AppLinks.leaderView}${AppLinks.scheduledSuccess}:bookingId`}
                    component={ScheduledSuccessPage}
                  />
                  <AdminRoute
                    path={`${AppLinks.leaderView}${AppLinks.serviceInfo}:enrollmentId/:categoryId`}
                    component={ServiceInfoPage}
                  />
                  <AdminRoute
                    path={`${AppLinks.leaderView}${AppLinks.serviceInfo}:enrollmentId`}
                    component={ServiceInfoPage}
                  />
                  <AdminRoute
                    path={`${AppLinks.leaderView}/category/:categoryId`}
                    component={LeaderCategoryFilterPage}
                  />
                  <AdminRoute path={AppLinks.leaderView} component={LeaderPage} />
                  <Route path="*">404</Route> {/* temporary 404 page */}
                </Switch>
              </PagesContainer>
              <ZendeskWidget />
            </ConnectedRouter>
          </StripeProvider>
        </CloudinaryContext>
      </Provider>
    </ThemeProvider>
  </IntlContainer>
);

export default Routes;
