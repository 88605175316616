/** External Dependencies **/
import React, { FC } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';

/** Internal Dependencies */
import Messages from './Messages';
import { Modals } from '../../constants/Layouts';
import { goBack, redirectToHome } from '../../utils/navigation';
import { useBooleanEffect } from '../../hooks';

/** Data layer **/
import {
  MyWaitlistDeleteActions,
  MyBookingsDeleteActions,
  closeModal,
  MyScheduledServiceBookingDeleteActions,
} from 'zo-data-layer/actions';
import {
  MyBookingsDeleteSelectors,
  MyScheduledServiceBookingDeleteSelectors,
  MyWaitlistDeleteSelectors,
} from 'zo-data-layer/selectors';
import { BookingType } from 'zo-data-layer/constants/booking';
import { OrBooleanSelectors } from 'zo-data-layer/utils/selectors';

/** Components */
import Button from '../Button';

/** Styling */
import { mediaQueries } from '../../styles';

type Props = {
  bookingType: BookingType;
  bookingId: string;
  backOnCancel?: boolean;
};

type SelectorProps = {
  isCancelledSuccess: boolean;
  isLoading: boolean;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  isCancelledSuccess: OrBooleanSelectors(
    MyBookingsDeleteSelectors.isSuccess(),
    MyWaitlistDeleteSelectors.isSuccess(),
    MyScheduledServiceBookingDeleteSelectors.isSuccess()
  ),
  isLoading: OrBooleanSelectors(
    MyBookingsDeleteSelectors.isLoading(),
    MyWaitlistDeleteSelectors.isLoading(),
    MyScheduledServiceBookingDeleteSelectors.isLoading()
  ),
});

const CancelBookingModal: FC<Props> = ({ bookingType, bookingId, backOnCancel = false }) => {
  const { isCancelledSuccess, isLoading } = useSelector(mapReduxState);
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (bookingType === BookingType.Waitlist) {
      dispatch(MyWaitlistDeleteActions.request(parseInt(bookingId, 10)));
    } else if (bookingType === BookingType.ScheduledService) {
      dispatch(MyScheduledServiceBookingDeleteActions.request(parseInt(bookingId, 10)));
    } else {
      dispatch(MyBookingsDeleteActions.request(parseInt(bookingId, 10)));
    }
  };

  const close = () => dispatch(closeModal(Modals.CancelBooking));

  useBooleanEffect(isCancelledSuccess, () => {
    close();
    dispatch(MyBookingsDeleteActions.reset());
    dispatch(MyWaitlistDeleteActions.reset());
    dispatch(MyScheduledServiceBookingDeleteActions.reset());
    backOnCancel ? goBack() : redirectToHome();
  });

  return (
    <div>
      <HeaderSpan>
        <Header>
          <FormattedMessage {...Messages.cancel} />
        </Header>
        <p className="darkGrey">
          <FormattedMessage {...Messages.areYouSure} />
        </p>
      </HeaderSpan>
      <ButtonWrapper>
        <Button title={<FormattedMessage {...Messages.no} />} onClick={close} className="clearBackground" />
        <Button title={<FormattedMessage {...Messages.yes} />} onClick={onSubmit} isLoading={isLoading} />
      </ButtonWrapper>
    </div>
  );
};

export default CancelBookingModal;

const HeaderSpan = styled.span`
  display: inline-block;

  @media (min-width: ${mediaQueries.m_mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.h3`
  margin-top: 0;
  padding-right: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 2rem;

  button:first-child {
    margin-right: 1rem;
  }
`;
