/** External Dependencies */
import React, { FC } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

/** Styling */
import { mediaQueries } from 'styles';

type Props = {
  icon?: JSX.Element;
  href?: string;
};

export const AsideLink: FC<Props & NavLinkProps> = ({ icon, children, ...linkProps }) => (
  <StyledLink {...linkProps}>
    {icon}
    <Container>{children}</Container>
  </StyledLink>
);

export const AsideA: FC<Props> = ({ icon, href, children }) => (
  <StyledA href={href} target="_blank">
    {icon}
    <Container>{children}</Container>
  </StyledA>
);

const StyledLink = styled(NavLink)`
  display: flex;
  color: ${({ theme }) => theme.colors.grey};
  margin: 1rem 0;
  font-size: 1.5rem;

  padding: ${({ theme }) => `calc(${theme.spacing.layoutPaddingXs} / 2) calc(${theme.spacing.layoutPaddingSm} / 2)`};
  box-sizing: border-box;

  @media (min-width: ${mediaQueries.m_mobile}) {
    font-size: ${({ theme }) => theme.size.asideLink};
    padding: ${({ theme }) => `${theme.spacing.layoutPaddingXs} ${theme.spacing.layoutPaddingSm}`};
  }

  &.active {
    color: ${({ theme }) => theme.colors.white};
    border-left: 2px solid ${({ theme }) => theme.colors.white};
    padding-left: 0.875rem;
  }
`;

const StyledA = styled.a`
  display: flex;
  color: ${({ theme }) => theme.colors.grey};
  margin: 1rem 0;
  font-size: 1.5rem;

  padding: ${({ theme }) => `calc(${theme.spacing.layoutPaddingXs} / 2) calc(${theme.spacing.layoutPaddingSm} / 2)`};
  box-sizing: border-box;

  @media (min-width: ${mediaQueries.m_mobile}) {
    font-size: ${({ theme }) => theme.size.asideLink};
    padding: ${({ theme }) => `${theme.spacing.layoutPaddingXs} ${theme.spacing.layoutPaddingSm}`};
  }
`;

const Container = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.4rem;
`;
