import { defineMessages, MessageDescriptor } from 'react-intl';

const cancel: MessageDescriptor = {
  id: 'forms.cancel',
  defaultMessage: 'Cancel',
};

const no: MessageDescriptor = {
  id: 'forms.no',
  defaultMessage: 'No',
};

const yes: MessageDescriptor = {
  id: 'forms.yes',
  defaultMessage: 'Yes',
};

const areYouSure: MessageDescriptor = {
  id: 'forms.areYouSure',
  defaultMessage: 'Are you sure you want to cancel this booking?',
};

export default defineMessages({
  cancel,
  no,
  yes,
  areYouSure,
});
