import { defineMessages, MessageDescriptor } from 'react-intl';

const backToOverview: MessageDescriptor = {
  id: 'bookingDetails.backToOverview',
  defaultMessage: 'Back to Overview',
};

const bookingDetails: MessageDescriptor = {
  id: 'bookingDetails.bookingDetails',
  defaultMessage: 'Booking Details',
};

const quantity: MessageDescriptor = {
  id: 'bookingDetails.quantity',
  defaultMessage: 'Quantity',
};

const maxAvailable: MessageDescriptor = {
  id: 'bookingDetails.maxAvailable',
  defaultMessage: 'Max Available',
};

const providedBy: MessageDescriptor = {
  id: 'bookingDetails.providedBy',
  defaultMessage: 'Provided by',
};

const orderSummary: MessageDescriptor = {
  id: 'bookingDetails.orderSummary',
  defaultMessage: 'Order Summary',
};

const subtotal: MessageDescriptor = {
  id: 'bookingDetails.Subtotal',
  defaultMessage: 'Subtotal',
};

const total: MessageDescriptor = {
  id: 'bookingDetails.total',
  defaultMessage: 'Total',
};

const confirmBooking: MessageDescriptor = {
  id: 'bookingDetails.confirmBooking',
  defaultMessage: 'Confirm Booking',
};

const paymentMethod: MessageDescriptor = {
  id: 'bookingDetails.paymentMethod',
  defaultMessage: 'Payment Method',
};

const promoCode: MessageDescriptor = {
  id: 'creditCard.promoCode',
  defaultMessage: 'Promo Code',
};

const missingPaymentMethod: MessageDescriptor = {
  id: 'creditCard.missingPayment',
  defaultMessage: 'Please add a payment method',
};

const haveAPromoCode: MessageDescriptor = {
  id: 'bookingDetails.haveAPromoCode',
  defaultMessage: 'Have a promo code?',
};

const apply: MessageDescriptor = {
  id: 'forms.apply',
  defaultMessage: 'Apply',
};

const providedByPartner: MessageDescriptor = {
  id: 'serviceInfo.providedByPartner',
  defaultMessage: 'Provided by {service.partner}',
};

const home: MessageDescriptor = {
  id: 'serviceInfo.home',
  defaultMessage: 'Home',
};

const linkBack: MessageDescriptor = {
  id: 'serviceInfo.linkBack',
  defaultMessage: 'Access hundred of events and services through your ZO Membership',
};

const seeAllServices: MessageDescriptor = {
  id: 'serviceInfo.seeAllServices',
  defaultMessage: 'See all Services',
};

const visitSite: MessageDescriptor = {
  id: 'serviceInfo.visitSite',
  defaultMessage: 'VISIT SITE',
};

const joinWaitlist: MessageDescriptor = {
  id: 'serviceInfo.joinWaitlist',
  defaultMessage: 'JOIN WAITLIST',
};

const soldOut: MessageDescriptor = {
  id: 'serviceInfo.soldOut',
  defaultMessage: 'SOLD OUT',
};

const virtualEvent: MessageDescriptor = {
  id: 'serviceInfo.virtualEvent',
  defaultMessage: 'Virtual Event',
};

const waitlistAvailable: MessageDescriptor = {
  id: 'serviceInfo.waitlistAvailable',
  defaultMessage: 'Waitlist Available',
};

const onWaitlist: MessageDescriptor = {
  id: 'serviceInfo.onWaitlist',
  defaultMessage: 'On Waitlist',
};

const checkAvailabilites: MessageDescriptor = {
  id: 'serviceInfo.checkAvailabilites',
  defaultMessage: 'Check Availabilities',
};

const multipleTimesAvailable: MessageDescriptor = {
  id: 'serviceInfo.multipleTimesAvailable',
  defaultMessage: 'Multiple Times Available',
};

const onlyDays: MessageDescriptor = {
  id: 'serviceInfo.onlyDays',
  defaultMessage: 'Only days with availabilities shown',
};

const virtualLivestream15Minutes: MessageDescriptor = {
  id: 'serviceInfo.virtualLivestream15Minutes',
  defaultMessage: 'Join at {time}',
};

const occupancy: MessageDescriptor = {
  id: 'serviceInfo.occupancy',
  defaultMessage: 'Current occupancy: {percent}',
};

const updatedAt: MessageDescriptor = {
  id: 'serviceInfo.lastUpdated',
  defaultMessage: 'Last updated: {date}',
};

const spaceInformation: MessageDescriptor = {
  id: 'serviceInfo.spaceInformation',
  defaultMessage: 'Space Information',
};

const selectDuration: MessageDescriptor = {
  id: 'serviceInfo.selectDuration',
  defaultMessage: 'Select a Duration',
};

const minute: MessageDescriptor = {
  id: 'serviceInfo.minute',
  defaultMessage: '{minute} minutes',
};

export default defineMessages({
  backToOverview,
  bookingDetails,
  quantity,
  maxAvailable,
  orderSummary,
  subtotal,
  total,
  confirmBooking,
  joinWaitlist,
  providedBy,
  paymentMethod,
  promoCode,
  missingPaymentMethod,
  haveAPromoCode,
  apply,
  providedByPartner,
  home,
  linkBack,
  seeAllServices,
  visitSite,
  soldOut,
  virtualEvent,
  waitlistAvailable,
  onWaitlist,
  checkAvailabilites,
  multipleTimesAvailable,
  onlyDays,
  virtualLivestream15Minutes,
  occupancy,
  updatedAt,
  spaceInformation,
  selectDuration,
  minute,
  watch: {
    id: 'serviceInfo.watch',
    defaultMessage: 'Watch',
  },
  free: {
    id: 'tile.free',
    defaultMessage: 'Free',
  },
  livestream: {
    id: 'myBookingDetails.livestream',
    defaultMessage: 'LIVESTREAM',
  },
  zoomEvent: {
    id: 'myBookingDetails.zoomEvent',
    defaultMessage: 'ZOOM EVENT',
  },
  businessHome: {
    id: 'nav.zoBusiness',
    defaultMessage: 'ZO for Business',
  },
});
