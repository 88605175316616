import { defineMessages, MessageDescriptor } from 'react-intl';

const todayServices: MessageDescriptor = {
  id: 'discover.todayServices',
  defaultMessage: "Today's Services",
};

export default defineMessages({
  todayServices,
});
