/** External Dependencies **/
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

/**Interal Dependencies */

/** Data layer */
import { MeSelectors } from 'zo-data-layer/selectors';
import { getStripeToken } from 'zo-data-layer/constants/stripe';

const memoizedSelectors = {
  isLoaded: MeSelectors.isSuccess(),
  stripeAccount: MeSelectors.myCampusStripeAccountId(),
};

const StripeProvider: FC = ({ children }) => {
  const [options, setStripeOptions] = useState({});
  const [stripe, setStripe] = useState(null);
  const isLoaded: boolean = useSelector(memoizedSelectors.isLoaded);
  const stripeAccount = useSelector(memoizedSelectors.stripeAccount);

  useEffect(() => {
    if (stripe === null && isLoaded) {
      if (stripeAccount) {
        setStripeOptions(stripeAccount);
      }
      setStripe(loadStripe(getStripeToken(), options));
    }
  }, [isLoaded, options, stripe, stripeAccount]);

  return (
    <Elements stripe={stripe}>
      <>{children}</>
    </Elements>
  );
};

export default StripeProvider;
