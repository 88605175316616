import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LinkButton } from './LinkButton';
import Messages from './Messages';

interface Props {
  onClickFilter(): void;
  onClickClear(): void;
  resultCount: number;
  filterCount?: number;
}

export const CampusFiltersBar = ({ resultCount, filterCount, onClickFilter, onClickClear }: Props) => (
  <Wrapper>
    <EventCount>
      <FormattedMessage {...Messages.eventCount} values={{ eventCount: resultCount }} />
    </EventCount>
    <ButtonContainer>
      {filterCount > 0 ? (
        <LinkButton onClick={onClickClear}>
          <FormattedMessage {...Messages.clear} />
        </LinkButton>
      ) : null}
      <LinkButton onClick={onClickFilter}>
        <FormattedMessage {...Messages.cta} />
        {filterCount ? ` (${filterCount})` : null}
      </LinkButton>
    </ButtonContainer>
  </Wrapper>
);

const EventCount = styled.span``;

const Wrapper = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.size.navbarHeight};
  padding-top: 2em;
  z-index: ${({ theme }) => theme.zIndex.z4};
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  padding-bottom: 1em;
  padding-right: 3em;
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  gap: 1.5em;
  display: flex;
`;
