/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { brand } from 'styles';

type Tab = {
  title: JSX.Element;
  render: () => JSX.Element;
  isClickable?: boolean;
};

type Props = {
  tabs: Tab[];
  tabIndex: number;
  setTabIndex?: (tabIndex: number) => void;
};

const Tabs: FC<Props> = ({ tabs, tabIndex, setTabIndex }) => (
  <div>
    <Menu>
      {tabs.map(({ title, isClickable = true }, index) => (
        <MenuItem
          key={index}
          isSelected={index === tabIndex}
          isClickable={isClickable}
          onClick={() => {
            if (!isClickable) return;
            setTabIndex?.(index);
          }}
        >
          {title}
        </MenuItem>
      ))}
    </Menu>
    <Content>{tabs[tabIndex].render()}</Content>
  </div>
);

const Menu = styled.div`
  display: flex;
  margin: 0 -0.25rem 1rem;
`;

type MenuItemProps = {
  isSelected: boolean;
  isClickable: boolean;
};

const MenuItem = styled.div<MenuItemProps>`
  position: relative;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  user-select: none;
  text-align: center;
  flex: 1;
  margin: 0 0.25rem;
  padding: ${({ theme }) => theme.spacing.layoutPaddingXs} 0;
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.primaryColor : theme.colors.lightGrey)};
  border-bottom-style: solid;
  border-bottom-width: 0.25rem;
  border-bottom-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.primaryColor : theme.colors.lightGrey)};

  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -0.5rem;
    border-width: ${({ isSelected }) => (isSelected ? '0.5rem' : '0')};
    border-style: solid;
    border-color: ${brand.brightGreen} transparent transparent;
  }
`;

const Content = styled.div`
  padding: 0;
`;

export default Tabs;
