import { defineMessages, MessageDescriptor } from 'react-intl';

const soldOut: MessageDescriptor = {
  id: 'serviceInfo.soldOut',
  defaultMessage: 'SOLD OUT',
};

const availableTimes: MessageDescriptor = {
  id: 'availability.availableTimes',
  defaultMessage: 'Available Times',
};

const waitlist: MessageDescriptor = {
  id: 'availability.waitlist',
  defaultMessage: 'Waitlist Available',
};

const available: MessageDescriptor = {
  id: 'availability.available',
  defaultMessage: 'Available',
};

const unavailable: MessageDescriptor = {
  id: 'availability.unavailable',
  defaultMessage: 'Unavailable',
};

const book: MessageDescriptor = {
  id: 'availability.book',
  defaultMessage: 'Book',
};

const join: MessageDescriptor = {
  id: 'availability.join',
  defaultMessage: 'Join',
};

export default defineMessages({
  soldOut,
  availableTimes,
  waitlist,
  available,
  unavailable,
  book,
  join,
});
