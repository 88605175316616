/** External Dependencies */
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** Interal dependencies */
import Messages from './Messages';
import { ServiceType } from 'zo-data-layer/constants';

/** Styling */
import { defaultTheme, miscColors, neutral } from 'styles';

/** Components */
import { CalendarIcon, ClockIcon, CostIcon, LocationIcon } from '../Icons';
import WaitlistIcon from '../Icons/WaitlistIcon';
import InfoIcon from '../Icons/InfoIcon';
import { LocaleContext } from 'containers/IntlContainer';
import FormattedPrice from '../FormattedPrice';

/** Data layer */
import MyService from 'zo-data-layer/dataobjects/MyService';
import Timeslot from 'zo-data-layer/dataobjects/Timeslot';
import { ScheduledAvailability } from 'zo-data-layer/dataobjects/ScheduledAvailabilityCollection';
import { formatTimeStr } from 'zo-data-layer/utils/time';

type Props = {
  service: MyService;
  // For scheduled services
  availability?: ScheduledAvailability;
  // Inventories don't have timeslots
  timeSlot?: Timeslot;
  // In some places, we don't want to show the waitlist indicator
  showWaitlist?: boolean;
  // In some places, we don't want to show 'Multiple Times Available'
  showMultipleTimeSlots?: boolean;
  // In some places, we don't want to show the availability text
  hideAvailability?: boolean;
  scheduledPrice?: number;
};

const ServiceInfoBlock: FC<Props> = ({
  service,
  availability,
  showWaitlist,
  timeSlot,
  showMultipleTimeSlots,
  hideAvailability,
  scheduledPrice,
}) => {
  const { locale } = useContext(LocaleContext);
  const timezone = service.timezone;

  const renderOnWaitlistOrAvailableWaitlist = () => {
    if (hideAvailability) return;

    if (showMultipleTimeSlots) {
      return (
        <Infotext>
          <InfoIcon width={defaultTheme.size.serviceDetailIconSize} />
          <span style={{ paddingLeft: '0.5rem' }}>
            <FormattedMessage {...Messages.onlyDays} />
          </span>
        </Infotext>
      );
    }
    if (!showWaitlist) return;

    if (service.allowWaitlist && timeSlot && timeSlot.remainingCapacity < 1) {
      return (
        <InfoPoint style={{ paddingTop: '1rem' }}>
          <WaitlistIcon width={defaultTheme.size.serviceDetailIconSize} />
          <WaitlistSpan>
            <FormattedMessage {...Messages.waitlistAvailable} />
          </WaitlistSpan>
        </InfoPoint>
      );
    }
  };

  const renderDateAndTime = () => {
    if (service.isSoldOut || service.serviceType === ServiceType.Inventory || service.serviceType === ServiceType.Video)
      return;
    if (showMultipleTimeSlots) {
      return (
        <InfoPoint>
          <CalendarIcon width={defaultTheme.size.serviceDetailIconSize} />
          <IconPoint>
            <FormattedMessage {...Messages.multipleTimesAvailable} />
          </IconPoint>
        </InfoPoint>
      );
    }
    if (availability && service.isScheduledService) {
      return (
        <>
          <InfoPoint>
            <CalendarIcon width={defaultTheme.size.serviceDetailIconSize} />
            <IconPoint>{availability.date}</IconPoint>
          </InfoPoint>
          <InfoPoint>
            <ClockIcon width={defaultTheme.size.serviceDetailIconSize} />
            <IconPoint>
              {formatTimeStr(availability.start_time, timezone, locale)}
              &nbsp;-&nbsp;
              {formatTimeStr(availability.end_time, timezone, locale)}
            </IconPoint>
          </InfoPoint>
        </>
      );
    }
    return (
      <>
        <InfoPoint>
          <CalendarIcon width={defaultTheme.size.serviceDetailIconSize} />
          <IconPoint>{timeSlot ? timeSlot.formattedDay(timezone, locale) : service.formattedDay(locale)}</IconPoint>
        </InfoPoint>
        <InfoPoint>
          <ClockIcon width={defaultTheme.size.serviceDetailIconSize} />
          <IconPoint>{timeSlot ? timeSlot.formattedTime(timezone, locale) : service.formattedTime(locale)}</IconPoint>
        </InfoPoint>
      </>
    );
  };

  const renderPrettyPrice = () => {
    const isScheduledServiceFree = service.isScheduledService ? scheduledPrice === 0 : service.decimalPrice === 0;
    const isFree = isScheduledServiceFree || service.isFree;
    const localizedPrice = isFree ? (
      <FormattedMessage {...Messages.free} />
    ) : (
      <FormattedPrice
        price={service.isScheduledService ? scheduledPrice : service.decimalPrice}
        currency={service.currency}
      />
    );

    return (
      <InfoPoint>
        <CostIcon width={defaultTheme.size.serviceDetailIconSize} />
        <IconPoint>{localizedPrice}</IconPoint>
      </InfoPoint>
    );
  };

  const renderLocation = () => {
    if (
      service.isSoldOut ||
      service.serviceType === ServiceType.Inventory ||
      service.serviceType === ServiceType.Video ||
      service.isScheduledService ||
      service.isVirtual()
    )
      return;

    return (
      <InfoContent>
        <LocationPoint>
          <LocationIcon width={defaultTheme.size.serviceDetailIconSize} />
          <IconPoint>{service.locationName}</IconPoint>
        </LocationPoint>
        <SubLocationPoint>{service.locationAddress && <span>{service.locationAddress}</span>}</SubLocationPoint>
        <SubLocationPoint>{service.locationFloor && <span>{service.locationFloor}</span>}</SubLocationPoint>
        <SubLocationPoint>{service.locationRoom && <span>{service.locationRoom}</span>}</SubLocationPoint>
      </InfoContent>
    );
  };

  return (
    <>
      {renderDateAndTime()}
      {renderPrettyPrice()}
      {renderLocation()}
      {renderOnWaitlistOrAvailableWaitlist()}
    </>
  );
};

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`;

const LocationPoint = styled.span`
  display: flex;
  align-items: center;
  color: ${neutral.darkGrey};
`;

const IconPoint = styled.span`
  padding-left: 0.5rem;
  color: ${neutral.darkGrey};
`;

const InfoPoint = styled(LocationPoint)`
  padding-bottom: 0.6rem;
  color: ${neutral.darkGrey};
`;

const Infotext = styled.div`
  display: flex;
  border-top: 1px ${miscColors.divider} solid;
  padding-top: 1rem;
  margin-top: 1rem;
  font-size: 0.9rem;
  align-items: center;
  color: ${neutral.darkGrey};
`;

const SubLocationPoint = styled(InfoPoint)`
  padding-left: 2.1rem;
  padding-bottom: 0.3rem;
  color: ${neutral.darkGrey};
`;

const WaitlistSpan = styled.span`
  padding-left: 0.5rem;
  font-weight: 400;
`;

export default ServiceInfoBlock;
