import { useRef } from 'react';
import { isEqual } from 'lodash';

/**
 * this hook will memoize value that's passed into
 * and return the old object address if new value is deeply equal to the old value
 * otherwise return new value
 */
const useMemoizedObject = <T = any>(value: T): T => {
  const ref = useRef<T>(value);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export default useMemoizedObject;
