import React, { FC } from 'react';

type Props = {
  width?: string;
  checked?: boolean;
};

const CheckboxIcon: FC<Props> = ({ width = 24, checked }) => (
  <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" fill={checked ? '#3E3E3E' : ''} stroke="black" />
    {checked ? (
      <svg viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 10L5.6145 13.976C6.37301 14.8103 7.67095 14.853 8.48265 14.0703L21 2"
          stroke="white"
          stroke-width="3"
          stroke-linejoin="round"
        />
      </svg>
    ) : null}
  </svg>
);

export default CheckboxIcon;
