/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** Internal dependencies */
import Messages from './Messages';

/** Styling */
import { neutral } from 'styles/colors';

/** Data layer */
import MyService from 'zo-data-layer/dataobjects/MyService';
import FormattedPrice from 'components/FormattedPrice';

type Props = {
  service: MyService;
  setSelectedDuration: (value: number) => void;
};

const DurationPicker: FC<Props> = ({ service, setSelectedDuration }) => {
  const updateDuration = (event) => {
    setSelectedDuration(Number(event.target.value));
  };

  const renderDuration = (duration) => {
    if (service.paymentRequired && duration.price > 0) {
      return (
        <>
          {`${service.formattedDuration(duration.minutes)} / `}
          <FormattedPrice price={duration.price / 100} currency={service.currency} />
        </>
      );
    }
    return service.formattedDuration(duration.minutes);
  };

  const sortedDurations = service.durations.sort((a, b) => a.minutes - b.minutes);

  return (
    <Container onChange={updateDuration}>
      <Subheader>
        <FormattedMessage {...Messages.selectDuration} />
      </Subheader>
      {sortedDurations.map((duration, index) => (
        <RadioButtonRow key={index}>
          <RadioButton type="radio" value={duration.minutes} name="duration" />
          {renderDuration(duration)}
        </RadioButtonRow>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`;

const Subheader = styled.h4``;

const RadioButtonRow = styled.div`
  margin-bottom: 0.5rem;
  color: ${neutral.darkGrey};
  font-size: 1rem;
`;

const RadioButton = styled.input`
  margin-right: 0.5rem;
`;

export default DurationPicker;
