/** External Dependencies */
import React, { FC, useRef, useLayoutEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';

/** Internal Dependencies */
import Messages from 'pages/AllFilterPage/Messages';
import { useCarouselServices } from 'hooks/useCarouselServices';
import { useCategories } from 'hooks/useCategories';

/** Components */
import TileCarousel from '../TileCarousel';
import LoadingIndicator from '../LoadingIndicator';
import CategoryNavigationBar from '../CategoryNavigationBar';

/** Styling */
import { mediaQueries } from 'styles';
import { defaultTheme } from 'styles/themes';

/** Data layer */
import { MeSelectors } from 'zo-data-layer/selectors';
import { LocaleContext } from 'containers/IntlContainer';

type Props = {
  isLoaded: boolean;
  name: 'all' | 'upcoming' | 'new' | 'today' | 'discover';
  children?: React.ReactNode;
};

type SelectorProps = {
  firstName: string;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  firstName: MeSelectors.myFirstName(),
});

const FilterPagesContainer: FC<Props> = ({ isLoaded, children }) => {
  const { carouselServices, isAllLoaded, category } = useCarouselServices();
  const { isCategoriesLoaded, categories } = useCategories();
  const { firstName } = useSelector(mapReduxState);
  const location = useLocation();
  const { currentLocale: locale } = useContext(LocaleContext);
  const categoryName = categories.find(({ id }) => id === category)?.translationFor(locale);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isLoaded && ref.current && location.hash === '#results') {
      // Timeout fixes inconsistent scrolling depths
      setTimeout(() => ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' }), 100);
    }
  }, [isLoaded, location]);

  const renderContent = () => {
    if (isLoaded) return <ContentWrapper ref={ref}>{children}</ContentWrapper>;
    return (
      <CenteredLoadingIndicator>
        <LoadingIndicator />
      </CenteredLoadingIndicator>
    );
  };

  return (
    <Body>
      <Hero>
        <HeroText className="hero">
          <FormattedMessage {...Messages.hello} />, {firstName}
        </HeroText>
      </Hero>
      {isCategoriesLoaded ? <CategoryNavigationBar /> : <LoadingIndicator />}
      {isAllLoaded ? <TileCarousel title={categoryName} carouselServices={carouselServices} /> : <LoadingIndicator />}
      {renderContent()}
    </Body>
  );
};

type BodyProps = {
  showAnimation?: boolean;
  isLoaded?: boolean;
};

const CenteredLoadingIndicator = styled.div`
  padding: 2rem 0;
`;

const Body = styled.div<BodyProps>`
  z-index: ${({ theme }) => theme.zIndex.z3};
  width: 100%;
  max-width: ${({ theme }) => theme.size.containerMaxWidth};
  min-width: 70vw;

  @media (min-width: ${mediaQueries.m_mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
`;

const ContentWrapper = styled.div`
  padding: ${defaultTheme.spacing.smallScreenPadding};

  @media (min-width: ${mediaQueries.m_tablet}) {
    padding: 0;
  }
`;

const Hero = styled.span`
  padding: ${defaultTheme.spacing.smallScreenPadding};
  margin: 0 1rem 4.5rem;

  @media (min-width: ${mediaQueries.m_tablet}) {
    padding: 0;
    margin: 0 0 4.5rem;
  }
`;

const HeroText = styled.h1`
  color: ${defaultTheme.colors.primaryColor};
  margin: 0 1rem;

  @media (min-width: ${mediaQueries.m_tablet}) {
    margin: 0;
  }
`;

export default FilterPagesContainer;
