import React, { createContext, PropsWithChildren, useContext, useMemo, useReducer } from 'react';
import { Campus, Region } from 'zo-data-layer/dataobjects';

type CampusFiltersProviderProps = PropsWithChildren<{
  category: number;
  initialRegions: Region[];
  initialCampuses: Campus[];
  onApply(regions: Region[], campuses: Campus[]): void;
  onClear(): void;
}>;

interface CampusFiltersState {
  regions: Region[];
  campuses: Campus[];
}

type CampusFiltersAction =
  | {
      type: 'SetRegions';
      payload: Region[];
    }
  | {
      type: 'SetCampuses';
      payload: Campus[];
    }
  | { type: 'ClearRegions' }
  | { type: 'ClearCampuses' }
  | { type: 'ClearAll' };

interface CampusFiltersValue extends CampusFiltersState {
  setRegions(newRegions: Region[]): void;
  setCampuses(newCampuses: Campus[]): void;
  clearRegions(): void;
  clearCampuses(): void;
  clearAll(): void;
  applyNewFilters(regions: Region[], campuses: Campus[]): void;
  clearAppliedFilters(): void;
  category: number;
}

const CampusFiltersContext = createContext(undefined as CampusFiltersValue | undefined);

export const CampusFiltersProvider = ({
  children,
  category,
  initialRegions,
  initialCampuses,
  onApply,
  onClear,
}: CampusFiltersProviderProps) => {
  const [{ regions, campuses }, dispatch] = useReducer(campusFiltersReducer, {
    regions: initialRegions,
    campuses: initialCampuses,
  });
  const value = useMemo(
    (): CampusFiltersValue => ({
      category,
      regions,
      campuses,
      applyNewFilters: onApply,
      clearAppliedFilters: onClear,
      setRegions(newRegions: Region[]) {
        dispatch({ type: 'SetRegions', payload: newRegions });
      },
      setCampuses(newCampuses: Campus[]) {
        dispatch({ type: 'SetCampuses', payload: newCampuses });
      },
      clearRegions() {
        dispatch({ type: 'ClearRegions' });
      },
      clearCampuses() {
        dispatch({ type: 'ClearCampuses' });
      },
      clearAll() {
        dispatch({ type: 'ClearAll' });
      },
    }),
    [campuses, category, onApply, onClear, regions]
  );

  return <CampusFiltersContext.Provider value={value}>{children}</CampusFiltersContext.Provider>;
};

const campusFiltersReducer = (state: CampusFiltersState, action: CampusFiltersAction): CampusFiltersState => {
  switch (action.type) {
    case 'SetRegions': {
      return { ...state, regions: action.payload, campuses: [] };
    }

    case 'SetCampuses': {
      return { ...state, campuses: action.payload };
    }

    case 'ClearRegions': {
      return { ...state, regions: [] };
    }

    case 'ClearCampuses': {
      return { ...state, campuses: [] };
    }

    case 'ClearAll': {
      return {
        regions: [],
        campuses: [],
      };
    }
  }
};

export const useCampusFilters = () => {
  const campusFilters = useContext(CampusFiltersContext);
  if (!campusFilters) {
    throw new Error('This element must be a child of CampusFiltersProvider');
  }

  return campusFilters;
};
