/** External Dependencies */
import React, { FC } from 'react';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/** Internal Dependencies */
import Messages from './Messages';
import { Modals } from 'constants/Layouts';
import { HiddenCardNumbers } from 'constants/Stripe';
import { mapSelector, mapPaymentSource } from 'utils/selectors';
import useBooleanEffect from 'hooks/useBooleanEffect';

/** Data layer */
import { ListPaymentSourcesSelectors } from 'zo-data-layer/selectors';
import { openModal, MyPaymentSourcesListActions } from 'zo-data-layer/actions';

/** Components */
import PlusSignIcon from 'components/Icons/PlusSignIcon';
import TrashIcon from 'components/Icons/TrashIcon';
import LoadingIndicator from 'components/LoadingIndicator';
import CreditCardIcon from 'components/CreditCard/CreditCardIcon';
import PaymentSourceObject from 'utils/PaymentSourceObject';
import { RouteWrapper } from './MyPageContainer';

/** Styling */
import { miscColors } from 'styles';

type SelectorProps = {
  paymentSources: any[];
  isPaymentSourcesLoaded: boolean;
  isPaymentSourcesLoading: boolean;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  paymentSources: mapSelector(ListPaymentSourcesSelectors.getData(), mapPaymentSource),
  isPaymentSourcesLoaded: ListPaymentSourcesSelectors.isSuccess(),
  isPaymentSourcesLoading: ListPaymentSourcesSelectors.isLoading(),
});

const MyPaymentMethods: FC = () => {
  const { paymentSources, isPaymentSourcesLoaded, isPaymentSourcesLoading } = useSelector(mapReduxState);

  const dispatch = useDispatch();
  const loadPaymentSources = () => dispatch(MyPaymentSourcesListActions.request());
  const handleOpenAddCardModal = () => dispatch(openModal(Modals.AddCard));
  const handleOpenDeleteCardModal = () => dispatch(openModal(Modals.ConfirmDeleteCard));

  useBooleanEffect(!isPaymentSourcesLoading && !isPaymentSourcesLoaded, () => {
    loadPaymentSources();
  });

  const history = useHistory();

  const handleClick = (id: string) => {
    history.replace({ search: `id=${id}` });
    handleOpenDeleteCardModal();
  };

  const renderPaymentSources = () => {
    if (!isPaymentSourcesLoaded) return <LoadingIndicator />;

    return (
      <PaymentMethods>
        {paymentSources.map((paymentSource: PaymentSourceObject) => (
          <PaymentMethod key={paymentSource.id}>
            <RightColumn>
              <CreditCardIcon brand={paymentSource.brand} />
              <span className="darkGrey">
                {HiddenCardNumbers} {paymentSource.last4}
              </span>
            </RightColumn>
            <LeftColumn onClick={() => handleClick(paymentSource.id)}>
              <TrashIcon color={miscColors.cancelIcon} />
            </LeftColumn>
          </PaymentMethod>
        ))}
        <NewPaymentMethod>
          <NewCardOption onClick={handleOpenAddCardModal}>
            <PlusSignIcon />
            <CustomLabel>
              <FormattedMessage {...Messages.addNewCard} />
            </CustomLabel>
          </NewCardOption>
        </NewPaymentMethod>
      </PaymentMethods>
    );
  };

  return <RouteWrapper>{renderPaymentSources()}</RouteWrapper>;
};

const PaymentMethods = styled.div``;

const PaymentMethod = styled.div`
  display: flex;
  padding: 1.5rem 0;
  margin-right: 3rem;
  border-bottom: 1px solid ${miscColors.divider}};
`;

const NewPaymentMethod = styled(PaymentMethod)`
  margin-top: 1rem;
  border-bottom: none;
`;

const RightColumn = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
`;

type CardOptionProps = {
  onClick?: any;
};

const LeftColumn = styled.span<CardOptionProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const NewCardOption = styled.span<CardOptionProps>`
  cursor: pointer;
`;

const CustomLabel = styled.label`
  color: ${({ theme }) => theme.colors.primaryColor};
  cursor: pointer;
  font-weight: 400;
`;

export default MyPaymentMethods;
