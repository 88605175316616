/** External Dependencies */
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

/** Internal Dependencies */
import Messages from '../Messages';

/** Components */
import { OverlaySubHeader, OverlayText } from '../../Overlay';
import Input from '../../Input';
import Button from '../../Button';

/** Styling */
import { typeScale } from 'styles';

export type FormData = {
  password: string;
  passwordConfirm: string;
};

type Props = {
  onSubmit: (data: FormData) => void;
  defaultValues?: FormData;
  email: string;
};

const SecurityForm: FC<Props> = ({ onSubmit, email, defaultValues }) => {
  const { register, handleSubmit, watch, errors, formState } = useForm<FormData>({
    mode: 'onChange',
    defaultValues,
  });
  const intl = useIntl();

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <OverlaySubHeader>
          <FormattedMessage {...Messages.makeAccountSecure} />
        </OverlaySubHeader>
        <OverlayText>
          <FormattedMessage {...Messages.addPasswordForSecurity} />
        </OverlayText>

        <input
          type="text"
          name="email"
          value={email}
          autoComplete="username email"
          style={{ display: 'none' }}
          readOnly
        />

        <Input
          id="password"
          name="password"
          type="password"
          autoComplete="new-password"
          label={intl.formatMessage(Messages.password)}
          error={errors?.password?.message}
          hideable
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
            minLength: {
              value: 8,
              message: <FormattedMessage {...Messages.atLeastEightCharacters} />,
            },
          })}
        />

        <Input
          id="passwordConfirm"
          name="passwordConfirm"
          type="password"
          autoComplete="new-password"
          label={intl.formatMessage(Messages.confirmPassword)}
          error={errors?.passwordConfirm?.message}
          hideable
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
            validate: (value) => value === watch('password') || <FormattedMessage {...Messages.passwordsDoNotMatch} />,
          })}
        />

        <StyledButton
          title={<FormattedMessage {...Messages.continueButton} />}
          type="submit"
          isDisabled={!formState.isValid}
        />
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.layoutPaddingSm};
`;

const StyledButton = styled(Button)`
  font-size: ${typeScale.font17};
  margin-top: 2rem;
`;

export default SecurityForm;
