/* External Dependencies **/
import React, { FC, Fragment, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { List } from 'immutable';
import { useHistory } from 'react-router-dom';

/* Data layer **/
import {
  MyBookingsAndWaitlistsSelectors,
  MyBookingsCreateResultsSelectors,
  MyScheduledServiceBookingsCreateResultsSelectors,
  MyWaitlistCreateResultsSelectors,
} from 'zo-data-layer/selectors';
import GenericTimeBasedBooking from 'zo-data-layer/dataobjects/GenericTimeBasedBooking';
import {
  MyBookingsListActions,
  MyScheduledServiceBookingsListActions,
  MyWaitlistListActions,
} from 'zo-data-layer/actions';

/* Internal Dependencies **/
import Messages from './Messages';
import { generateMyAccountBookingDetailsLink } from 'constants/routes';

/* Components **/
import BookingTile from 'components/BookingTile';
import { RouteWrapper } from './MyPageContainer';
import { LocaleContext } from 'containers/IntlContainer';

/** Styling */
import { miscColors } from 'styles';

const upcomingEventBookingsSelectorSet = MyBookingsCreateResultsSelectors('upcoming');
const upcomingWaitlistsSelectorSet = MyWaitlistCreateResultsSelectors('upcoming');
const upcomingScheduledServiceBookingsSelectorSet = MyScheduledServiceBookingsCreateResultsSelectors(
  'upcomingScheduled'
);
const upcomingBookingsSelectorSet = MyBookingsAndWaitlistsSelectors(
  upcomingEventBookingsSelectorSet,
  upcomingWaitlistsSelectorSet,
  upcomingScheduledServiceBookingsSelectorSet
);

const memoizedSelectors = {
  upcomingBookings: upcomingBookingsSelectorSet.getBookings(),
  count: upcomingBookingsSelectorSet.getCount(),
  isSuccess: upcomingBookingsSelectorSet.isSuccess(),
  isLoading: upcomingBookingsSelectorSet.isLoading(),
};

interface UpcomingBookingsByDate {
  data: List<GenericTimeBasedBooking>;
  date: string;
}

const MyBookingsSection: FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { locale } = useContext(LocaleContext);

  const upcomingBookingsCount: number = useSelector(memoizedSelectors.count);
  const upcomingBookings: List<GenericTimeBasedBooking> = useSelector(memoizedSelectors.upcomingBookings);

  useEffect(() => {
    dispatch(
      MyBookingsListActions.request(
        { refunded: false, cancelled: false, upcoming: true },
        { resultSetName: 'upcoming' }
      )
    );
    dispatch(MyWaitlistListActions.request({ active: true, upcoming: true }, { resultSetName: 'upcoming' }));
    dispatch(
      MyScheduledServiceBookingsListActions.request(
        { refunded: false, cancelled: false, upcoming: true },
        { resultSetName: 'upcomingScheduled' }
      )
    );
  }, [dispatch]);

  const upcomingBookingsByDate = upcomingBookings
    .groupBy((booking) => booking.formattedDayWithWeekday(locale))
    .map((bookings, date) => ({ data: bookings, date }))
    .valueSeq()
    .toJS() as UpcomingBookingsByDate[];

  const renderBookings = () => {
    if (upcomingBookingsCount > 0) {
      return upcomingBookingsByDate.map((groupedBookings) => (
        <Fragment key={groupedBookings.date}>
          <TilesWrapper>
            <SectionHeader>{groupedBookings.date}</SectionHeader>
            <Tiles>
              {(groupedBookings.data || List()).map((booking: GenericTimeBasedBooking) => (
                <BookingTile
                  key={booking.id}
                  booking={booking}
                  onClick={() => {
                    push(generateMyAccountBookingDetailsLink(booking.bookingType, booking.id));
                  }}
                />
              ))}
            </Tiles>
          </TilesWrapper>
        </Fragment>
      ));
    }
    return <FormattedMessage {...Messages.noUpcomingBookings} />;
  };

  return <RouteWrapper>{renderBookings()}</RouteWrapper>;
};

const TilesWrapper = styled.div`
  margin-top: 2rem;
`;

const SectionHeader = styled.p`
  padding: 1.5rem 1rem;
  margin: 0;
  background: ${miscColors.backgroundGrey};
`;

const Tiles = styled.div`
  display: flex;
  flex-direction: column;

  > div:last-child {
    border-bottom: none;
  }
`;

export default MyBookingsSection;
