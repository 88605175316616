import { takeLatest, put } from 'redux-saga/effects';

import {
  MyBookingsDelete,
  MyBookingsCreate,
  MyWaitlistCreate,
  MyWaitlistDelete,
  UpdateMyAccountActionTypes,
  MyPaymentSourcesCreate,
  MyPaymentSourcesDelete,
  MyActivitiesMarkRead,
  MyScheduledServiceBookingCreate,
  MyScheduledServiceBookingDelete,
  CompleteProfileActionTypes,
} from 'zo-data-layer/constants/actions';

import {
  MyBookingsListActions,
  MyWaitlistListActions,
  FetchMeActions,
  MyPaymentSourcesListActions,
  MyActivitiesListActions,
  MyScheduledServiceBookingsListActions,
} from 'zo-data-layer/actions';

import { ResultSetNames } from '../constants/Services';

export default class SideEffectsSagas {
  sideEffects = function* sideEffects(self) {
    yield takeLatest(MyBookingsCreate.success, self._refetchMe.bind(null, self));
    yield takeLatest(MyWaitlistCreate.success, self._refetchMe.bind(null, self));
    yield takeLatest(MyScheduledServiceBookingCreate.success, self._refetchMe.bind(null, self));
    yield takeLatest(MyBookingsDelete.success, self._refetchMe.bind(null, self));
    yield takeLatest(MyBookingsDelete.success, self._refetchUpcomingBookings.bind(null, self));
    yield takeLatest(MyWaitlistDelete.success, self._refetchMe.bind(null, self));
    yield takeLatest(MyWaitlistDelete.success, self._refetchUpcomingWaitlists.bind(null, self));
    yield takeLatest(MyScheduledServiceBookingDelete.success, self._refetchMe.bind(null, self));
    yield takeLatest(MyScheduledServiceBookingDelete.success, self._refetchUpcomingScheduledServices.bind(null, self));

    yield takeLatest(UpdateMyAccountActionTypes.success, self._refetchMe.bind(null, self));
    yield takeLatest(CompleteProfileActionTypes.success, self._refetchMe.bind(null, self));

    yield takeLatest(MyPaymentSourcesCreate.success, self._loadPaymentSources.bind(null, self));
    yield takeLatest(MyPaymentSourcesDelete.success, self._loadPaymentSources.bind(null, self));

    yield takeLatest(MyActivitiesMarkRead.success, self._refetchAlerts.bind(null, self));
  };

  _refetchUpcomingBookings = function* _refetchUpcomingBookings() {
    // refetch upcoming bookings
    yield put(
      MyBookingsListActions.request(
        { refunded: false, cancelled: false, upcoming: true },
        { resultSetName: ResultSetNames.upcoming }
      )
    );
  };

  _refetchUpcomingWaitlists = function* _refetchUpcomingWaitlists() {
    // refetch upcoming waitlists
    yield put(
      MyWaitlistListActions.request({ active: true, upcoming: true }, { resultSetName: ResultSetNames.upcoming })
    );
  };

  _refetchUpcomingScheduledServices = function* _refetchUpcomingScheduledServices() {
    // refetch upcoming scheduled service bookings
    yield put(
      MyScheduledServiceBookingsListActions.request(
        { refunded: false, cancelled: false, upcoming: true },
        { resultSetName: ResultSetNames.upcomingScheduled }
      )
    );
  };

  _refetchMe = function* _refetchMe() {
    yield put(FetchMeActions.request());
  };

  _loadPaymentSources = function* _loadPaymentSources() {
    yield put(MyPaymentSourcesListActions.request());
  };

  _refetchAlerts = function* _refetchAlerts() {
    yield put(MyActivitiesListActions.request());
  };
}
