export const mediaQueries = {
  // iPhone5
  xs_mobile: '320px',
  // iPhone 6/7/8
  s_mobile: '375px',
  // iPhone 6/7/8 Plus
  m_mobile: '511px',
  // iPad
  s_tablet: '1010px',
  // iPad Pro
  m_tablet: '1347px',
  // laptop
  laptop: '1684px',
  // desktop
  desktop: '3000px',
};

export const mediaQueriesNumbers = {
  // iPhone5
  xs_mobile: 320,
  // iPhone 6/7/8
  s_mobile: 375,
  // iPhone 6/7/8 Plus
  m_mobile: 511,
  // iPad
  s_tablet: 1010,
  // iPad Pro
  m_tablet: 1347,
  // laptop
  laptop: 1684,
  // desktop
  desktop: 3000,
};
