/** External Dependencies */
import React from 'react';
import styled from 'styled-components';

/** Internal Dependencies */
import ErrorReporter from 'analytics/error.analytics';

/** Assets */
import AmericanExpress from 'assets/images/AmericanExpress.png';
import Diners from 'assets/images/Diners.png';
import Discover from 'assets/images/Discover.png';
import JCB from 'assets/images/JCB.png';
import MasterCard from 'assets/images/Mastercard.png';
import UnionPay from 'assets/images/UnionPay.png';
import VISA from 'assets/images/VISA.png';

/** Data layer */
import { CCBrands } from 'zo-data-layer/constants/stripe';

const CreditCardIcon = ({ brand }) => {
  switch (brand) {
    case CCBrands.Visa:
      return (
        <CCDiv>
          <CCImage id={CCBrands.Visa} alt={CCBrands.Visa} src={VISA} />
        </CCDiv>
      );
    case CCBrands.Mastercard:
      return (
        <CCDiv>
          <CCImage id={CCBrands.Mastercard} alt={CCBrands.Mastercard} src={MasterCard} />
        </CCDiv>
      );
    case CCBrands.JCB:
      return (
        <CCDiv>
          <CCImage id={CCBrands.JCB} alt={CCBrands.JCB} src={JCB} />
        </CCDiv>
      );
    case CCBrands.Discover:
      return (
        <CCDiv>
          <CCImage id={CCBrands.Discover} alt={CCBrands.Discover} src={Discover} />
        </CCDiv>
      );
    case CCBrands.Amex:
      return (
        <CCDiv>
          <CCImage id={CCBrands.Amex} alt={CCBrands.Amex} src={AmericanExpress} />
        </CCDiv>
      );
    case CCBrands.Diners:
      return (
        <CCDiv>
          <CCImage id={CCBrands.Diners} alt={CCBrands.Diners} src={Diners} />
        </CCDiv>
      );
    case CCBrands.Unionpay:
      return (
        <CCDiv>
          <CCImage id={CCBrands.Unionpay} alt={CCBrands.Unionpay} src={UnionPay} />
        </CCDiv>
      );
    default:
      ErrorReporter.captureMessage(`Unhandled Card Brand ${brand}`);
      return <></>;
  }
};

export default CreditCardIcon;

const CCDiv = styled.div`
  padding-right 1rem;
  width: 4rem;
`;

const CCImage = styled.img`
  max-width: 100%;
  height: auto;
`;
