import { ChevronRight } from 'components/Icons';
import { Set } from 'immutable';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { neutral, typeScale } from 'styles';
import { selectCampusesFilters, selectRegionFilters } from 'zo-data-layer/selectors';
import { useCampusFilters } from './CampusFilters';
import { FilterButton } from './FilterButton';
import { FilterList } from './FilterList';
import { FilterListContainer } from './FilterListContainer';
import Messages from './Messages';

interface CampusFiltersSelectFilterPageProps {
  selectRegions(): void;
  selectCampuses(): void;
  close(): void;
}

export const CampusFiltersSelectFilterPage = ({
  selectRegions,
  selectCampuses,
  close,
}: CampusFiltersSelectFilterPageProps) => {
  const {
    regions: selectedRegions,
    campuses: selectedCampuses,
    category,
    applyNewFilters,
    clearAppliedFilters,
  } = useCampusFilters();
  const prevSelectedRegions = Array.from(useSelector(selectRegionFilters(category)));
  const prevSelectedCampuses = Array.from(useSelector(selectCampusesFilters(category)));

  const renderButtons = () => {
    const hasPrevSelectedRegions =
      prevSelectedRegions.length > 0 &&
      Set(selectedRegions.map((region) => region.id)).equals(Set(prevSelectedRegions.map((region) => region.id)));

    const hasPrevSelectedCampuses =
      prevSelectedCampuses.length > 0 &&
      Set(selectedCampuses.map((campus) => campus.id)).equals(Set(prevSelectedCampuses.map((campus) => campus.id)));

    const hasNewSelectedRegions = !Set(selectedRegions.map((region) => region.id)).equals(
      Set(prevSelectedRegions.map((region) => region.id))
    );

    const hasNewSelectedCampuses = !Set(selectedCampuses.map((campus) => campus.id)).equals(
      Set(prevSelectedCampuses.map((campus) => campus.id))
    );

    if (hasNewSelectedRegions || hasNewSelectedCampuses) {
      return (
        <FilterButton
          onClick={() => {
            applyNewFilters(selectedRegions, selectedCampuses);
          }}
          titleMessage={Messages.apply}
        />
      );
    }

    if (hasPrevSelectedRegions || hasPrevSelectedCampuses) {
      return <FilterButton onClick={clearAppliedFilters} titleMessage={Messages.clearSelection} />;
    }
  };

  return (
    <FilterListContainer>
      <FilterList>
        <li>
          <FilterItem
            onClick={selectRegions}
            nameMessage={Messages.region}
            values={selectedRegions.map((region) => region.name).join(', ')}
          />
        </li>
        <li>
          <FilterItem
            onClick={selectCampuses}
            nameMessage={Messages.campus}
            values={selectedCampuses.map((campus) => campus.name).join(', ')}
          />
        </li>
      </FilterList>

      {renderButtons()}
    </FilterListContainer>
  );
};

interface FilterItemProps {
  nameMessage: MessageDescriptor;
  values: string;
  onClick(): void;
}

const FilterItemWrapper = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 1em 1.5em;
`;

const FilterItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${typeScale.font17};
`;

const FilterItemValues = styled.div`
  width: auto;
  text-align: left;
  padding-top: 0.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FilterItem = ({ nameMessage, values, onClick }: FilterItemProps) => (
  <FilterItemWrapper onClick={onClick}>
    <FilterItemTitleWrapper>
      <span>
        <FormattedMessage {...nameMessage} />
      </span>
      <ChevronRight color={neutral.darkGrey} width="1rem" />
    </FilterItemTitleWrapper>
    {values ? <FilterItemValues>{values}</FilterItemValues> : null}
  </FilterItemWrapper>
);
