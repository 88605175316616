import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import React from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes';

ReactDOM.render(<Routes />, document.querySelector('#root'));
