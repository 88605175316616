/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from 'styles/colors';
import { defaultTheme } from 'styles/themes';

type Props = {
  color?: string;
  width?: string;
  fill?: string;
};

const PlayIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize, fill = 'none' }) => (
  <svg width={width} height={width} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 3.95429L7 20.0457C7 20.449 7.45299 20.6864 7.7846 20.4568L19.4062 12.4111C19.6934 12.2123 19.6934 11.7877 19.4062 11.5889L7.7846 3.54319C7.45299 3.31361 7 3.55095 7 3.95429Z"
      stroke={color}
    />
  </svg>
);

export default PlayIcon;
