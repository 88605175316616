/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from 'styles/colors';
import { defaultTheme } from 'styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const ClockIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="6.5" stroke={color} />
      <path d="M7 4V7L9 9" stroke={color} strokeLinecap="round" />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default ClockIcon;
