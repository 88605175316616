/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { status } from '../../styles/colors';

type Props = {
  color?: string;
  width?: string;
};

export const LoadingImage: FC<Props> = ({ color = status.loading, width = '155' }) => (
  <Div>
    <svg width={width} height={width} viewBox="0 0 155 154" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.2" cx="77.084" cy="77" r="77" fill={color} />
      <circle opacity="0.4" cx="77.084" cy="77" r="53" fill={color} />
      <path
        d="M65.6405 87.8C65.7305 87.8 65.8205 87.791 65.9105 87.7775L73.4795 86.45C73.5695 86.432 73.655 86.3915 73.718 86.324L92.7935 67.2485C92.8352 67.2069 92.8683 67.1574 92.8909 67.103C92.9135 67.0485 92.9251 66.9902 92.9251 66.9313C92.9251 66.8723 92.9135 66.814 92.8909 66.7595C92.8683 66.7051 92.8352 66.6556 92.7935 66.614L85.3145 59.1305C85.229 59.045 85.1165 59 84.995 59C84.8735 59 84.761 59.045 84.6755 59.1305L65.6 78.206C65.5325 78.2735 65.492 78.3545 65.474 78.4445L64.1465 86.0135C64.1027 86.2546 64.1184 86.5027 64.1921 86.7363C64.2658 86.97 64.3953 87.1822 64.5695 87.3545C64.8665 87.6425 65.24 87.8 65.6405 87.8ZM68.6735 79.952L84.995 63.635L88.2935 66.9335L71.972 83.2505L67.9715 83.957L68.6735 79.952ZM93.644 91.58H60.524C59.7275 91.58 59.084 92.2235 59.084 93.02V94.64C59.084 94.838 59.246 95 59.444 95H94.724C94.922 95 95.084 94.838 95.084 94.64V93.02C95.084 92.2235 94.4405 91.58 93.644 91.58Z"
        fill="white"
      />
    </svg>
  </Div>
);

const Div = styled.div`
  padding: 1rem;
`;
