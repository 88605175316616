/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** Internal Dependencies */
import Messages from './Messages';

/** Data layer */
import { ServiceType } from 'zo-data-layer/constants/services';
import MyService from 'zo-data-layer/dataobjects/MyService';
import { Timeslot } from 'zo-data-layer/dataobjects';

/** Components */
import Button from '../Button';
import { ExternalLinkIcon } from '../Icons';

/** Styling */
import { neutral, defaultTheme } from 'styles';

interface ICTAButton {
  service: MyService;
  fullWidth: boolean;
  onClick?: () => void;
  timeSlot?: Timeslot;
}

const ServiceInfoCTA: FC<ICTAButton> = ({ service, fullWidth, onClick, timeSlot }) => {
  const renderEventCTAMessage = () => {
    const isWaitlistSpotAvailable = timeSlot && timeSlot.remainingCapacity > 0;

    if (service.isSoldOut) {
      return (
        <CTAText>
          <FormattedMessage {...Messages.soldOut} />
        </CTAText>
      );
    }
    if (service.isWaitlist && !isWaitlistSpotAvailable) {
      return (
        <CTAText>
          <FormattedMessage {...Messages.joinWaitlist} />
        </CTAText>
      );
    }
    return <CTAText>{service.cta}</CTAText>;
  };

  const renderInventoryCTAMessage = () => {
    if (service.isSoldOut) {
      return (
        <CTAText>
          <FormattedMessage {...Messages.soldOut} />
        </CTAText>
      );
    }
    return <CTAText>{service.cta}</CTAText>;
  };

  const renderScheduledServiceCTAMessage = () => (
    <CTAText>{service.cta || <FormattedMessage {...Messages.checkAvailabilites} />}</CTAText>
  );

  const renderIcon = () => {
    if (service.serviceType === ServiceType.SSO || service.serviceType === ServiceType.Link) {
      return <ExternalLinkIcon color={neutral.white} width={defaultTheme.size.serviceIconSize} />;
    }
    return null;
  };

  const renderTitle = () => {
    if (service.isEvent) {
      return renderEventCTAMessage();
    }
    if (service.isScheduledService) {
      return renderScheduledServiceCTAMessage();
    }
    return renderInventoryCTAMessage();
  };

  return (
    <StyledButton
      buttonType="link"
      className="primaryColor"
      title={renderTitle()}
      fullWidth={fullWidth}
      isDisabled={service.isSoldOut}
      icon={renderIcon()}
      onClick={onClick}
    />
  );
};

const StyledButton = styled(Button)`
  margin-top: 0;
`;

const CTAText = styled.span``;

export default ServiceInfoCTA;
