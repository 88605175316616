import { useEffect, useState, useCallback } from 'react';
import debounce from 'debounce-promise';

const useResize = (elementRef) => {
  const [showScrollButton, setShowScrollButton] = useState(true);

  const handleResize = useCallback(() => {
    const container = elementRef.current;
    if (container) {
      const clientWidth = container.clientWidth;
      const scrollWidth = container.scrollWidth - 3;

      setShowScrollButton(clientWidth < scrollWidth);
    }
  }, [elementRef]);

  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize, 10);
    window.addEventListener('resize', debouncedHandleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [handleResize]);

  return { showScrollButton };
};

export default useResize;
