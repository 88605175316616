/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

/** Internal Dependencies */
import Messages from './Messages';
import {
  isLeaderRoute,
  isFilterRoute,
  isServiceRoute,
  isDiscoverRoute,
  isServiceBookedSuccessRoute,
  isWaitlistBookedSuccessRoute,
  isPublicPage,
  isLeaderHome,
} from 'utils/navigation';
import ZOBanner from 'assets/images/ZOBanner.png';

/** Data layer **/
import { MeSelectors } from 'zo-data-layer/selectors';

/** Components */
import ZoLogo from '../ZoLogo';
import Button from '../Button';
import Row from '../Row';

/** Styling */
import { mediaQueries, defaultTheme, brand, status } from 'styles';
import { AppLinks } from 'constants/routes';

import { openModal } from 'zo-data-layer/actions';
import { Modals } from 'constants/Layouts';
import { SuitcaseIcon } from 'components/Icons';

type Props = {
  isLoggedIn: boolean;
};

type SelectorProps = {
  bookingsCount: number;
  fullName: string;
  isStaff: boolean;
  isLeader: boolean;
  completedProfile: Date | null;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  bookingsCount: MeSelectors.myUpcomingBookingsCount(),
  fullName: MeSelectors.myFullName(),
  isStaff: MeSelectors.isStaff(),
  isLeader: MeSelectors.isLeader(),
  completedProfile: MeSelectors.completedProfile(),
});

const Header: FC<Props> = ({ isLoggedIn }) => {
  const dispatch = useDispatch();
  const { bookingsCount, fullName, isStaff, isLeader, completedProfile } = useSelector(mapReduxState);

  const location = useLocation();

  const leaderButtonMessage = isLeaderRoute(location) ? Messages.home : Messages.zoBusiness;
  const showExtendedHeader = isDiscoverRoute(location) || isFilterRoute(location);

  const getNavbarHeight = () => {
    if (!isLoggedIn) return defaultTheme.size.shortNavbarHeight;
    if (isLoggedIn && isLeaderHome(location)) {
      return defaultTheme.size.extendedNavbarHeightMd;
    }
    if (isLoggedIn && showExtendedHeader) {
      return defaultTheme.size.extendedNavbarHeightLg;
    }
    if (isServiceBookedSuccessRoute(location)) {
      return defaultTheme.size.navbarHeight;
    }
    if (isServiceRoute(location)) {
      return defaultTheme.size.servicePagesNavbarHeight;
    }
    return defaultTheme.size.extendedNavbarHeight;
  };

  const getAnimation = () => {
    if (isLoggedIn && isFilterRoute(location)) return 'headerLower 2s ease';
    return 'none';
  };

  const getHeaderBannerColor = (location) => {
    if (isWaitlistBookedSuccessRoute(location)) {
      return `12px solid ${status.waitlist}`;
    }
    if (isServiceBookedSuccessRoute(location)) {
      return `12px solid ${brand.success}`;
    }
    return 'none';
  };

  const handleSignInButtonClick = () => {
    dispatch(openModal(Modals.SignIn));
  };

  return (
    <NavWrapper
      isLeaderRoute={isLeaderRoute(location)}
      showExtendedHeader={showExtendedHeader}
      navbarHeight={getNavbarHeight()}
      animation={getAnimation()}
    >
      <Nav
        isAuthenticated={isLoggedIn}
        isPublicPage={isPublicPage(location)}
        isLeaderRoute={isLeaderRoute(location)}
        headerBannerColor={getHeaderBannerColor(location)}
      >
        <ZoLogo />
        {isLoggedIn && completedProfile && (
          <Row marginSize="s">
            <Link to={AppLinks.myProfile}>
              <StyledButton title={fullName} buttonType="counter" fullWidth={false} count={bookingsCount} showDot />
            </Link>
            {(isStaff || isLeader) && (
              <Link to={isLeaderRoute(location) ? AppLinks.discover : AppLinks.leaderView}>
                <StyledButton
                  title={<FormattedMessage {...leaderButtonMessage} />}
                  fullWidth={false}
                  buttonType="link"
                  icon={<SuitcaseIcon color="black" width="1.4rem" />}
                />
              </Link>
            )}
          </Row>
        )}
        {!isLoggedIn && isPublicPage(location) && (
          <Row>
            <BlackBorderButton titleMessage={Messages.signUp} fullWidth={false} linkTo={AppLinks.register} />
            <BlackBorderButton titleMessage={Messages.signIn} fullWidth={false} onClick={handleSignInButtonClick} />
          </Row>
        )}
      </Nav>
      {!isLoggedIn && !isPublicPage(location) && (
        <ZOBannerWrapper>
          <ZOBannerImage alt="Find Your ZO" src={ZOBanner} />
        </ZOBannerWrapper>
      )}
    </NavWrapper>
  );
};

const BlackBorderButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.black};
  a {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  padding: 0;
  border: none;
  background: none;

  & mark {
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.5;
  }

  &:hover,
  &:focus {
    border: none;
    background: none;
    color: ${({ theme }) => theme.colors.primaryColorDark};

    mark {
      color: ${({ theme }) => theme.colors.primaryColorDark};
      opacity: 1;
    }
  }
`;

type WrapperProps = {
  showExtendedHeader: boolean;
  navbarHeight: string;
  animation: string;
  isLeaderRoute: boolean;
};

const NavWrapper = styled.div<WrapperProps>`
  position: absolute;
  width: 100%;
  display: flex;
  padding-top: ${({ navbarHeight }) => navbarHeight};
  background: ${({ theme }) => theme.colors.navbarBackground};
  @media (min-width: ${mediaQueries.m_mobile}) {
    animation: ${({ animation }) => animation};
    animation-fill-mode: forwards;
  }
`;

type NavProps = {
  headerBannerColor: string;
  isAuthenticated: boolean;
  isLeaderRoute: boolean;
  isPublicPage: boolean;
};

const Nav = styled.nav<NavProps>`
  padding: 1rem;
  position: ${({ isAuthenticated, isPublicPage }) => (isAuthenticated || isPublicPage ? 'fixed' : 'absolute')};
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.navbarBackground};
  width: 100%;
  height: ${({ theme }) => theme.size.navbarHeight};
  z-index: ${({ theme }) => theme.zIndex.navbar};
  border-bottom: ${({ headerBannerColor }) => headerBannerColor};

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 1rem 2rem;
  }
`;

const ZOBannerWrapper = styled.div`
  display: contents;
`;

const ZOBannerImage = styled.img`
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  object-fit: cover;
  height: 11.25rem;
`;

export default Header;
