/** External Dependencies */
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** Internal Dependencies */
import Messages from './Messages';
import { generateServiceDetailsLink } from 'constants/routes';
import { history } from 'compositionRoot';
import CurrentServiceContext from './CurrentServiceContext';

/** Data layer */
import { Timeslot } from 'zo-data-layer/dataobjects';

/** Components */
import Button from 'components/Button';
import { LocaleContext } from 'containers/IntlContainer';

/** Styling */
import { brand, neutral, miscColors, mediaQueries, typeScale } from 'styles';

type Props = {
  slot: Timeslot;
};

const AvailabilityTimeSlot: FC<Props> = ({ slot }) => {
  const service = useContext(CurrentServiceContext);
  const timezone = service.timezone;
  const { locale } = useContext(LocaleContext);

  const isSlotWaitlist = slot.remainingCapacity < 1 && service.allowWaitlist;
  const isSlotSoldOut = slot.remainingCapacity < 1 && !service.allowWaitlist;

  const renderTitle = () => {
    if (slot.remainingCapacity > 0) return <FormattedMessage {...Messages.available} />;
    if (service.allowWaitlist) return <FormattedMessage {...Messages.waitlist} />;
    return <FormattedMessage {...Messages.unavailable} />;
  };

  const renderButtonCTA = () => {
    if (slot.remainingCapacity > 0) return <FormattedMessage {...Messages.book} />;
    if (service.allowWaitlist) return <FormattedMessage {...Messages.join} />;
    return <FormattedMessage {...Messages.soldOut} />;
  };

  const navigateToBooking = () => history.push(generateServiceDetailsLink(service, slot.id));

  const getStatusColor = () => {
    if (isSlotWaitlist) return brand.orange;
    if (isSlotSoldOut) return miscColors.inactiveGrey;
    return brand.success;
  };

  const getStatusTextColor = () => {
    if (isSlotWaitlist) return neutral.black;
    if (isSlotSoldOut) return miscColors.inactiveGrey;
    return brand.brightGreen;
  };

  return (
    <Wrapper>
      <TimeSlotRow statusColor={getStatusColor()}>
        <LeftColumn>{slot.formattedTime(timezone, locale)}</LeftColumn>
        <RightColumn>
          <Status statusTextColor={getStatusTextColor()}>{renderTitle()}</Status>
          <StyledButton
            title={renderButtonCTA()}
            className="clearBackground"
            isDisabled={isSlotSoldOut}
            onClick={navigateToBooking}
          />
        </RightColumn>
      </TimeSlotRow>
    </Wrapper>
  );
};

export default AvailabilityTimeSlot;

const Wrapper = styled.div`
  padding: 1rem 0;
  border-top: 1px solid ${miscColors.divider};
`;

type SlotProps = {
  statusColor: string;
};

const TimeSlotRow = styled.div<SlotProps>`
  padding: 0.5rem;
  border-left: 4px ${({ statusColor }) => statusColor} solid;
  display: flex;
  justify-content: space-between;
  font-size: ${typeScale.font12};

  @media (min-width: ${mediaQueries.m_mobile}) {
    font-size: ${typeScale.font14};
  }
`;

const StyledButton = styled(Button)`
  padding: 0.8rem;
  width: 7.5rem;
  margin: 0;
  border: 1px grey solid;
`;

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
`;

const RightColumn = styled.div`
  display: flex;
  align-items: center;
`;

type StatusProps = {
  statusTextColor: string;
};

const Status = styled.div<StatusProps>`
  padding-right: 1rem;
  font-weight: 400;
  color: ${({ statusTextColor }) => statusTextColor};
`;
