/**
 * Storage is an abstraction of localStorage. Mostly for the purposes
 * of dealing with compatibility issues as well as inevitably switching
 * the implementation.
 */
import { AuthToken } from 'zo-data-layer/selectors';

class Storage {
  storage: globalThis.Storage | DummyStorage;

  /**
   * Constructor, starts the storage implementation.
   */
  constructor() {
    try {
      this.storage = localStorage;
    } catch (e) {
      console.warn('Local Storage is disabled');
      this.storage = new DummyStorage();
    }
  }

  /**
   * Returns the value for the given key or null if the key does not exist.
   *
   * @param key
   *
   * @returns {Object|null}
   */
  get(key: string) {
    try {
      const value = this.storage.getItem(key);

      if (value === null) {
        return null;
      }

      return JSON.parse(value);
    } catch (err) {
      console.warn('Failed to fetch from local storage');
      return null;
    }
  }

  /**
   * Adds the key to storage with the given value, if the key
   * already exists the value for that key will be updated.
   *
   * @param {string}  key
   * @param {*}       value
   *
   * @returns {boolean|null}
   */
  set(key: string, value: AuthToken | string): boolean | null {
    try {
      this.storage.setItem(key, JSON.stringify(value));
      return true;
    } catch (err) {
      console.warn(err);
      return null;
    }
  }

  /**
   * Checks if the given key exists.
   */
  has(key: string): boolean {
    return this.get(key) !== null;
  }

  /**
   * Removes an item from storage.
   */
  remove(key: string): Storage {
    this.storage.removeItem(key);
    return this;
  }

  /**
   * Empties the storage of all keys.
   */
  clear(): Storage {
    this.storage.clear();
    return this;
  }

  /**
   * Returns the number of data items currently in storage.
   */
  length(): number {
    return this.storage.length;
  }
}

class DummyStorage {
  getItem = () => null;
  setItem = () => null;
  removeItem = () => null;
  clear = () => null;
  length = 0;
}

export default Storage;
