import { defineMessages, MessageDescriptor } from 'react-intl';

const title: MessageDescriptor = { id: 'filters.title', defaultMessage: 'Filter' };
const cta: MessageDescriptor = { id: 'filters.cta', defaultMessage: 'More locations' };
const region: MessageDescriptor = { id: 'filters.region', defaultMessage: 'Region' };
const campus: MessageDescriptor = { id: 'filters.campus', defaultMessage: 'Campus' };
const clear: MessageDescriptor = { id: 'filters.clear', defaultMessage: 'Clear' };
const clearSelection: MessageDescriptor = {
  id: 'filters.clearSelection',
  defaultMessage: 'Clear selection',
};
const apply: MessageDescriptor = { id: 'filters.apply', defaultMessage: 'Apply' };
const eventCount: MessageDescriptor = {
  id: 'filters.eventCount',
  defaultMessage: 'Showing {eventCount, plural, one {# result} other {# results}}',
};

export default defineMessages({
  title,
  cta,
  region,
  campus,
  clear,
  clearSelection,
  apply,
  eventCount,
});
