/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/** Internal Dependencies */
import useBooleanEffect from 'hooks/useBooleanEffect';

/** Data layer */
import { MyBookingsGetActions, MyWaitlistGetActions, MyInventoryCreateActions } from 'zo-data-layer/actions';
import { BookingType } from 'zo-data-layer/constants/booking';
import { CreateInventoryBookingSelectors } from 'zo-data-layer/selectors';
import { InventoryBooking } from 'zo-data-layer/dataobjects';

/** Components */
import SuccessPage from './SuccessPage';

type RouteParams = {
  bookingId: string;
};

type SelectorProps = {
  isInventorySuccess: boolean;
  inventory: InventoryBooking;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  isInventorySuccess: CreateInventoryBookingSelectors.isSuccess(),
  inventory: CreateInventoryBookingSelectors.getData(),
});

const InventorySuccessPage: FC = () => {
  const { isInventorySuccess, inventory } = useSelector(mapReduxState);
  const { bookingId } = useParams<RouteParams>();
  const dispatch = useDispatch();

  useBooleanEffect(isInventorySuccess, () => {
    dispatch(MyBookingsGetActions.reset());
    dispatch(MyWaitlistGetActions.reset());
  });

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(MyInventoryCreateActions.reset());
    };
  }, [dispatch]);

  return (
    <SuccessPage
      currentBooking={inventory}
      bookingType={BookingType.Inventory}
      successId={bookingId}
      isSuccess={isInventorySuccess}
    />
  );
};

export default InventorySuccessPage;
