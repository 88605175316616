/** External Dependencies **/
import React, { FC, useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { List, Set } from 'immutable';
import InfiniteScroll from 'react-infinite-scroll-component';

/** Interal Dependencies */
import { ResultSetNames, PageSize } from 'constants/Services';
import { servicesByCategoryListSelectors } from 'utils/selectors';
import { useBooleanEffect, usePagination, usePrevious } from 'hooks';
import { redirectToHome } from 'utils/navigation';
import { useAnalytics } from 'analytics';

/** Data layer */
import { MyServicesListActions, MyServicesListRequest } from 'zo-data-layer/actions';
import { Region, Campus, MyService } from 'zo-data-layer/dataobjects';
import { selectRegionFilters, selectCampusesFilters } from 'zo-data-layer/selectors';
import { MyServiceFiltersRegionChange, MyServiceFiltersCampusChange } from 'zo-data-layer/constants/actions';
import { ImmutableState } from 'zo-data-layer/utils/selectors';

/** Styling */
import { mediaQueries } from 'styles';
import { defaultTheme } from 'styles/themes';

/** Components */
import TileSection from 'components/TileSection';
import CategoryNavigationBar from 'components/CategoryNavigationBar';
import LoadingIndicator from 'components/LoadingIndicator';
import { useCategory } from 'hooks/useCategory';
import { CampusFiltersBar, CampusFiltersModal } from 'components/CampusFilters';
import FilterPagesLoadingIndicator from 'components/FilterPagesLoadingIndicator';

type RouteParams = {
  categoryId: string;
};

type SelectorProps = {
  services: List<MyService>;
  totalCount: number;
  isSuccess: boolean;
  isLoading: boolean;
};

const memoizedSelectors = createStructuredSelector<ImmutableState, SelectorProps>({
  services: servicesByCategoryListSelectors.getServices(),
  totalCount: servicesByCategoryListSelectors.getCount(),
  isSuccess: servicesByCategoryListSelectors.isSuccess(),
  isLoading: servicesByCategoryListSelectors.isLoading(),
});

const CategoryFilterPage: FC = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { services, totalCount, isSuccess, isLoading } = useSelector(memoizedSelectors);
  const { categoryId } = useParams<RouteParams>();
  const prevCategoryId = usePrevious(categoryId);
  const categoryIdInt = parseInt(categoryId, 10);
  const isBadCategory = isNaN(categoryIdInt) || !categoryIdInt;
  const { category } = useCategory(categoryIdInt);
  // Ref prevents multiple logCategoryEvent calls for the same category
  const categoryViewedRef = useRef<boolean>(false);

  const [isFiltersModalOpen, setFiltersModalOpen] = useState(false);
  const regions = Array.from(useSelector(selectRegionFilters(categoryIdInt)));
  const regionSet = Set(regions.map((r) => r.id));
  const prevRegionSet = usePrevious(regionSet);
  const campuses = Array.from(useSelector(selectCampusesFilters(categoryIdInt)));
  const campusSet = Set(campuses.map((c) => c.id));
  const prevCampusSet = usePrevious(campusSet);

  const { fetchFirstPage, fetchNextPage, hasNext } = usePagination<MyServicesListRequest>(
    MyServicesListActions.request,
    {
      all: true,
      category: categoryIdInt,
      resultSetName: ResultSetNames.byCategory,
      showHidden: true,
      regions: Array.from(regionSet),
      campuses: Array.from(campusSet),
    },
    { totalCount, pageSize: PageSize.filterPageSize }
  );

  const isLoaded = isSuccess || totalCount > 0;

  const clearFilters = useCallback(() => {
    dispatch({
      type: MyServiceFiltersRegionChange,
      payload: { category: categoryIdInt, values: [] },
    });
    dispatch({
      type: MyServiceFiltersCampusChange,
      payload: { category: categoryIdInt, values: [] },
    });
  }, [categoryIdInt, dispatch]);

  const applyFilters = useCallback(
    (regions: Region[], campuses: Campus[]) => {
      dispatch({
        type: MyServiceFiltersRegionChange,
        payload: { category: categoryIdInt, values: regions },
      });
      dispatch({
        type: MyServiceFiltersCampusChange,
        payload: { category: categoryIdInt, values: campuses },
      });
    },
    [categoryIdInt, dispatch]
  );

  const onClickClear = useCallback(() => {
    clearFilters();
    setFiltersModalOpen(false);
  }, [clearFilters]);

  const onClickApply = useCallback(
    (regions: Region[], campuses: Campus[]) => {
      applyFilters(regions, campuses);
      setFiltersModalOpen(false);
    },
    [applyFilters]
  );

  // go home if it's a bad category
  useBooleanEffect(isBadCategory, redirectToHome);

  // fetchFirstPage is going to change if the regions or campuses change
  useEffect(fetchFirstPage, [fetchFirstPage]);

  useEffect(() => {
    if (
      (!isBadCategory && (!isLoaded || prevCategoryId !== categoryId)) ||
      !regionSet.equals(prevRegionSet) ||
      !campusSet.equals(prevCampusSet)
    ) {
      categoryViewedRef.current = false;
    }
  }, [campusSet, categoryId, isBadCategory, isLoaded, prevCampusSet, prevCategoryId, prevRegionSet, regionSet]);

  useBooleanEffect(category && !categoryViewedRef.current, () => {
    analytics.logCategoryEvent(category);
    categoryViewedRef.current = true;
  });

  const renderContent = () => {
    if (isLoaded) {
      return (
        <>
          <CampusFiltersBar
            resultCount={totalCount}
            filterCount={regionSet.size + campusSet.size}
            onClickClear={onClickClear}
            onClickFilter={() => setFiltersModalOpen(true)}
          />
          <InfiniteScroll
            dataLength={services.size}
            next={fetchNextPage}
            hasMore={hasNext || isLoading}
            loader={<FilterPagesLoadingIndicator />}
          >
            <TileSection tiles={services} isLoaded={isLoaded} categoryId={categoryId} />
          </InfiniteScroll>
        </>
      );
    }
    return (
      <CenteredLoadingIndicator>
        <LoadingIndicator />
      </CenteredLoadingIndicator>
    );
  };

  return (
    <Wrapper>
      <CategoryNavigationBar />
      {renderContent()}
      <CampusFiltersModal
        category={categoryIdInt}
        isOpen={isFiltersModalOpen}
        onClear={onClickClear}
        onApply={onClickApply}
        initialCampuses={campuses}
        initialRegions={regions}
        onClose={() => setFiltersModalOpen(false)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: ${({ theme }) => theme.zIndex.z3};
  width: 100%;
  max-width: ${({ theme }) => theme.size.containerMaxWidth};
  padding: ${defaultTheme.spacing.smallScreenPadding};

  @media (min-width: ${mediaQueries.m_tablet}) {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 0;
  }
`;

const CenteredLoadingIndicator = styled.div`
  padding: 2rem 0;
`;

export default CategoryFilterPage;
