/** External Dependencies */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dispatch, bindActionCreators, AnyAction } from 'redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

/** Components */
import { OverlaySubHeader, OverlayText } from '../../Overlay';
import Button from '../../Button';
import DatePicker from '../../DatePicker';
import Input from '../../Input';
import RadioButtons from '../../RadioButtons';
import Select from '../../Select';
import Checkbox from '../../Checkbox';
import LoadingIndicator from 'components/LoadingIndicator';

/** Data layer */
import {
  FetchBuildingsAndFloorsActions,
  FetchCompaniesByEmailActions,
  FetchCompaniesByEmailRequest,
} from 'zo-data-layer/actions';
import { BuildingsAndFloorsFetchSelectors, MeSelectors, FetchCompaniesByEmailSelectors } from 'zo-data-layer/selectors';
import { isValidPhone } from 'zo-data-layer/schema/helpers';
import { LocaleOptions, SupportedLanguage } from 'zo-data-layer/constants';

/** Internal Dependencies */
import Messages from '../Messages';
import { getUrlConstants } from 'constants/routes';
import { useAuthenticated } from 'auth';

/** Styling */
import { typeScale } from 'styles';

export type FormData = {
  company: {
    value: string;
    label: string;
    campus: string;
  };
  building: {
    value: number;
    label: string;
  };
  jobTitle: string;
  floor: {
    value: number;
    label: string;
  };
  birthday: Date | null;
  marketingOptedIn: string;
  marketingOptedInVia: string;
  marketingOptedInWording: string;
  phone: string;
  acceptedLegal: boolean;
  languagePreference: {
    value: SupportedLanguage;
    label: string;
  };
};

type Props = {
  onSubmit: (data: FormData) => void;
  defaultValues?: FormData;
  fetchBuildings: (campusId: string) => void;
  areBuildingsLoaded: boolean;
  buildingOptions: ObjectOption[];
  floorOptionsMap: Record<number, ObjectOption[]>;
  isLoading: boolean;
  fetchCompaniesByEmail: (qp: FetchCompaniesByEmailRequest) => AnyAction;
  isFetchingCompaniesLoading: boolean;
  companiesData: Map<string, any> | null;
  myEmail: string;
};

const formatCompanyOptions = (companiesData?: Map<string, any> | null) => {
  if (!companiesData) return [];
  const data = companiesData.get('results');
  return data
    .map((company) => ({
      value: company.get('id'),
      label: company.get('name'),
      campus: company.getIn(['campus', 'id']),
    }))
    .toJS();
};

const convertToOption = ({ id, name }) => ({ value: id, label: name });

interface ProfileFormProps extends Props {
  disableCompanySelect: boolean;
  disableFloorSelect: boolean;
  disableBuildingSelect: boolean;
}

const ProfileForm: FC<ProfileFormProps> = ({
  onSubmit,
  defaultValues,
  fetchBuildings,
  areBuildingsLoaded,
  buildingOptions,
  floorOptionsMap,
  isLoading,
  fetchCompaniesByEmail,
  companiesData,
  isFetchingCompaniesLoading,
  myEmail,
  disableCompanySelect,
  disableBuildingSelect,
  disableFloorSelect,
}) => {
  const { register, control, handleSubmit, errors, watch, formState } = useForm<FormData>({
    defaultValues,
    mode: 'onChange',
  });
  const intl = useIntl();
  const { locale } = useIntl();
  const { campus } = watch('company') || {};
  const selectedBuilding = watch('building');
  const floorOptions: ObjectOption[] = floorOptionsMap[selectedBuilding?.value] || [];
  const companyOptions = useMemo(() => formatCompanyOptions(companiesData), [companiesData]);
  const authenticated = useAuthenticated();

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    if (!myEmail) {
      return;
    }

    fetchCompaniesByEmail({ email: myEmail });
  }, [fetchCompaniesByEmail, myEmail, authenticated]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    if (!campus) {
      return;
    }

    fetchBuildings(campus);
  }, [campus, fetchBuildings, authenticated]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <OverlaySubHeader>
          <FormattedMessage {...Messages.oneLastStep} />
        </OverlaySubHeader>
        <OverlayText>
          <FormattedMessage {...Messages.addInformationAboutYou} />
        </OverlayText>

        <ControllerWrapper>
          <Controller
            as={Select}
            name="company"
            control={control}
            rules={{
              required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
            }}
            id="company"
            label={<FormattedMessage {...Messages.company} />}
            error={(errors?.company as any)?.message}
            options={companyOptions}
            isDisabled={isFetchingCompaniesLoading || disableCompanySelect}
            noOptionsMessage={() => null}
          />
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            as={Select}
            name="building"
            control={control}
            rules={{
              required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
            }}
            id="building"
            label={<FormattedMessage {...Messages.building} />}
            error={(errors?.building as any)?.message}
            options={buildingOptions.map(convertToOption)}
            isDisabled={!areBuildingsLoaded || disableBuildingSelect}
            defaultValue={defaultValues?.building}
          />
        </ControllerWrapper>
        <ControllerWrapper>
          <Controller
            as={Select}
            name="floor"
            control={control}
            rules={{
              required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
            }}
            id="floor"
            label={<FormattedMessage {...Messages.floor} />}
            error={(errors?.floor as any)?.message}
            options={floorOptions.map(convertToOption)}
            isDisabled={!areBuildingsLoaded || !selectedBuilding || disableFloorSelect}
            defaultValue={defaultValues?.floor}
          />
        </ControllerWrapper>

        <ControllerWrapper>
          <Input
            id="phone"
            name="phone"
            type="tel"
            autoComplete="tel"
            label={intl.formatMessage(Messages.phoneNumber)}
            error={errors?.phone?.message}
            innerRef={register({
              validate: (value) =>
                value ? isValidPhone(value) || <FormattedMessage {...Messages.invalidPhoneNumber} /> : undefined,
            })}
          />
        </ControllerWrapper>

        <ControllerWrapper>
          <Input
            id="jobTitle"
            name="jobTitle"
            autoComplete="organization-title"
            label={intl.formatMessage(Messages.jobTitle)}
            error={errors?.jobTitle?.message}
            innerRef={register()}
          />
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            as={DatePicker}
            name="birthday"
            control={control}
            valueName="selected"
            onChange={([selected]) => selected}
            id="birthday"
            label={<FormattedMessage {...Messages.birthday} />}
            error={(errors?.birthday as any)?.message}
            locale={locale}
            innerRef={register()}
            compact
          />
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            as={Select}
            name="languagePreference"
            control={control}
            rules={{
              required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
            }}
            id="languagePreference"
            label={<FormattedMessage {...Messages.languagePreference} />}
            error={(errors?.languagePreference as any)?.message}
            options={LocaleOptions}
          />
        </ControllerWrapper>

        <p>
          <FormattedMessage {...Messages.marketingOptedIn} />
        </p>

        <RadioButtons
          id="marketingOptedIn"
          name="marketingOptedIn"
          type="radio"
          items={[
            { label: <FormattedMessage {...Messages.optInYes} />, value: 'yes' },
            { label: <FormattedMessage {...Messages.optInNo} />, value: 'no' },
          ]}
          error={errors?.marketingOptedIn?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          })}
        />

        <Checkbox
          id="acceptedLegal"
          name="acceptedLegal"
          label={
            <>
              <FormattedMessage {...Messages.IAgree} />
              <StyledLink href={getUrlConstants({ locale }).privacyUrl} target="_blank">
                <FormattedMessage {...Messages.privacyPolicy} />
              </StyledLink>
            </>
          }
          error={errors?.acceptedLegal?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          })}
        />

        <StyledButton
          isLoading={isLoading}
          title={<FormattedMessage {...Messages.finish} />}
          type="submit"
          isDisabled={!formState.isValid}
        />
      </form>
    </Wrapper>
  );
};

const profileFormWrapperSelectors = createStructuredSelector({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  isSuccess: MeSelectors.isSuccess(),
  company: MeSelectors.myCompanyId(),
  companyName: MeSelectors.myCompanyName(),
  campus: MeSelectors.myCampusId(),
  building: MeSelectors.myBuildingId(),
  buildingName: MeSelectors.myBuildingName(),
  jobTitle: MeSelectors.myJobTitle(),
  floor: MeSelectors.myFloorId(),
  floorName: MeSelectors.myFloorLabel(),
  birthday: MeSelectors.myBirthday(),
  languagePreference: MeSelectors.myLanguagePreference(),
  phone: MeSelectors.myPhone(),
});

const ProfileFormWrapper: FC<Props> = ({ defaultValues: defaultValuesProp, ...rest }) => {
  const [defaultValues, setDefaultValues] = useState(defaultValuesProp);
  const {
    isSuccess,
    company,
    companyName,
    campus,
    building,
    buildingName,
    jobTitle,
    floor,
    floorName,
    birthday,
    languagePreference,
    phone,
  } = useSelector(profileFormWrapperSelectors);

  const [disableCompanySelect, setDisableCompanySelect] = useState(false);
  const [disableFloorSelect, setDisableFloorSelect] = useState(false);
  const [disableBuildingSelect, setDisableBuildingSelect] = useState(false);

  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    const newValues = { ...defaultValues, jobTitle, birthday: birthday ? new Date(birthday) : null, phone };

    if (company) {
      newValues.company = {
        value: company,
        label: companyName,
        campus,
      };

      setDisableCompanySelect(true);
    }

    if (floor) {
      newValues.floor = {
        value: floor,
        label: floorName,
      };

      setDisableFloorSelect(true);
    }

    if (building) {
      newValues.building = {
        value: building,
        label: buildingName,
      };

      setDisableBuildingSelect(true);
    }

    if (languagePreference) {
      const option = LocaleOptions.find(({ value }) => value === languagePreference);

      if (option) {
        newValues.languagePreference = option;
      }
    }

    setDefaultValues(newValues);

    setShouldRender(true);

    // We want to do this once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  if (shouldRender) {
    return (
      <ProfileForm
        defaultValues={defaultValues}
        disableCompanySelect={disableCompanySelect}
        disableBuildingSelect={disableBuildingSelect}
        disableFloorSelect={disableFloorSelect}
        {...rest}
      />
    );
  }

  return <LoadingIndicator />;
};

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.layoutPaddingSm};
`;

const StyledButton = styled(Button)`
  font-size: ${typeScale.font17};
`;

const ControllerWrapper = styled.div`
  margin: 1rem 0;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryColor};
  font-weight: 400;
`;

type ObjectOption = {
  id: number;
  name: string;
};

type StateProps = {
  areBuildingsLoaded: boolean;
  buildingOptions: ObjectOption[];
  floorOptionsMap: Record<number, ObjectOption[]>;
  companiesData: Map<string, any> | null;
  isFetchingCompaniesLoading: boolean;
  myEmail: string;
};

const mapStateToProps = createStructuredSelector<any, StateProps>({
  areBuildingsLoaded: BuildingsAndFloorsFetchSelectors.isSuccess(),
  buildingOptions: BuildingsAndFloorsFetchSelectors.getBuildingsAsOptions(),
  floorOptionsMap: BuildingsAndFloorsFetchSelectors.getFloorsAsMapOfOptions(),
  companiesData: FetchCompaniesByEmailSelectors.getData(),
  isFetchingCompaniesLoading: FetchCompaniesByEmailSelectors.isLoading(),
  myEmail: MeSelectors.myEmail(),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchBuildings: FetchBuildingsAndFloorsActions.request,
      fetchCompaniesByEmail: FetchCompaniesByEmailActions.request,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFormWrapper);
