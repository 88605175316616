/** External Dependencies */
import React, { FC } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';

/** Internal Dependencies */
import Messages from 'pages/AllFilterPage/Messages';

/** Data layer */
import MyService from 'zo-data-layer/dataobjects/MyService';

/** Styling */
import { mediaQueriesNumbers, mediaQueries } from 'styles/mediaQueries';
import { defaultTheme } from 'styles/themes';

/** Components */
import ServiceTile from '../ServiceTile';
import { CustomButtonGroup } from 'components/CustomCarousel/CustomCarousel';

const responsive = {
  desktop: {
    breakpoint: { max: mediaQueriesNumbers.desktop, min: mediaQueriesNumbers.s_tablet },
    items: 3,
  },
  stablet: {
    breakpoint: { max: mediaQueriesNumbers.s_tablet, min: mediaQueriesNumbers.m_mobile },
    items: 2,
  },
  mobile: {
    breakpoint: { max: mediaQueriesNumbers.m_mobile, min: 0 },
    items: 1,
  },
};

type Props = {
  carouselServices: List<MyService>;
  title?: string;
};

const TileCarousel: FC<Props> = ({ title, carouselServices }) => (
  <>
    <CarouselHeader>{title ? title : <FormattedMessage {...Messages.newServices} />}</CarouselHeader>
    <CarouselWrapper>
      <Carousel
        partialVisible={false}
        responsive={responsive}
        renderButtonGroupOutside
        arrows={false}
        customButtonGroup={<CustomButtonGroup />}
      >
        {carouselServices.map((service) => (
          <StyledTile service={service} key={service.serviceId} isCarouselTiles />
        ))}
      </Carousel>
    </CarouselWrapper>
  </>
);

const CarouselHeader = styled.h1`
  color: ${({ theme }) => theme.colors.primaryColorDark};
  margin-bottom: 0;
  padding: ${defaultTheme.spacing.smallScreenPadding};

  @media (min-width: ${mediaQueries.m_tablet}) {
    padding: 1rem 0 0 0;
  }
`;

const StyledTile = styled(ServiceTile)`
  @media (min-width: ${mediaQueries.m_mobile}) {
    width: 95%;
  }
  margin: 1.5rem auto;
`;

const CarouselWrapper = styled.div`
  position: relative;
  padding: 0 3rem;
  border-bottom: 1px solid ${defaultTheme.colors.divider};

  @media (min-width: ${mediaQueries.m_tablet}) {
    padding: 0;
  }
`;

export default TileCarousel;
