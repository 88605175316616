/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { defaultTheme } from 'styles/themes';
import { neutral } from 'styles/colors';

type Props = {
  width?: string;
  color?: string;
  padding?: string;
};

const SupportIcon: FC<Props> = ({ width = defaultTheme.size.iconSize, color = neutral.black, padding }) => (
  <div style={{ padding: padding }}>
    <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" stroke={color} />
      <path d="M12 14L12 12" stroke={color} strokeLinecap="round" />
      <path
        d="M12 12C13 12 15 11.4 15 8.99997C15 6.59997 13 5.99997 12 5.99997C11 5.99997 9 6.59997 9 8.99997"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse rx="1" ry="1" transform="matrix(1 0 0 -1 12 18)" fill={color} />
    </svg>
  </div>
);

export default SupportIcon;
