import React, { FC } from 'react';
import { defaultTheme, neutral } from 'styles';

type Props = {
  color?: string;
  width?: string;
};

const PeopleIcon: FC<Props> = ({ width = defaultTheme.size.iconSize, color = neutral.white }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3226 12.9743C11.9855 13.6372 12.8615 14 13.7969 14C14.7322 14 15.6082 13.6372 16.2712 12.9743C16.9341 12.3113 17.2969 11.4354 17.2969 10.5C17.2969 9.56463 16.9341 8.68866 16.2712 8.02571C15.6082 7.36276 14.7322 7 13.7969 7C12.8615 7 11.9855 7.36276 11.3226 8.02571C10.6596 8.68866 10.2969 9.56463 10.2969 10.5C10.2969 11.4354 10.6596 12.3113 11.3226 12.9743ZM10.6155 13.6814C11.4658 14.5317 12.596 15 13.7969 15C14.9977 15 16.128 14.5317 16.9783 13.6814C17.8286 12.8311 18.2969 11.7009 18.2969 10.5C18.2969 9.29913 17.8286 8.1689 16.9783 7.3186C16.128 6.46831 14.9977 6 13.7969 6C12.596 6 11.4658 6.46831 10.6155 7.3186C9.76519 8.1689 9.29688 9.29913 9.29688 10.5C9.29688 11.7009 9.76519 12.8311 10.6155 13.6814Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8183 12.7486C18.8837 12.8264 18.9529 12.9017 19.0256 12.9744C19.6869 13.6358 20.566 14 21.5 14C22.434 14 23.3131 13.6358 23.9744 12.9744C24.6358 12.3131 25 11.434 25 10.5C25 9.56599 24.6358 8.68692 23.9744 8.02558C23.3131 7.36424 22.434 7 21.5 7C20.566 7 19.6869 7.36424 19.0256 8.02558C18.9529 8.0983 18.8837 8.17364 18.8183 8.2514C18.9969 8.64958 19.1274 9.0687 19.2066 9.50119C19.3299 9.21852 19.5069 8.95849 19.7327 8.73269C20.2067 8.25869 20.8315 8 21.5 8C22.1685 8 22.7933 8.25869 23.2673 8.73269C23.7413 9.20669 24 9.83149 24 10.5C24 11.1685 23.7413 11.7933 23.2673 12.2673C22.7933 12.7413 22.1685 13 21.5 13C20.8315 13 20.2067 12.7413 19.7327 12.2673C19.5069 12.0415 19.3299 11.7815 19.2066 11.4988C19.1274 11.9313 18.9969 12.3504 18.8183 12.7486ZM18.1679 11.576C18.2531 11.2279 18.2969 10.8673 18.2969 10.5C18.2969 10.1327 18.2531 9.77208 18.1679 9.42402C18.0574 9.76724 18 10.1293 18 10.5C18 10.8707 18.0574 11.2328 18.1679 11.576Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.91352 7.96611C8.25959 7.34244 7.40562 7 6.5 7C5.56599 7 4.68692 7.36424 4.02558 8.02558C3.36424 8.68692 3 9.56599 3 10.5C3 11.434 3.36424 12.3131 4.02558 12.9744C4.68692 13.6358 5.56599 14 6.5 14C7.40562 14 8.25959 13.6576 8.91352 13.0339C8.74141 12.7032 8.60378 12.3558 8.5026 11.9966C8.43135 12.0914 8.35283 12.1818 8.26731 12.2673C7.79331 12.7413 7.16851 13 6.5 13C5.83149 13 5.20669 12.7413 4.73269 12.2673C4.25869 11.7933 4 11.1685 4 10.5C4 9.83149 4.25869 9.20669 4.73269 8.73269C5.20669 8.25869 5.83149 8 6.5 8C7.16851 8 7.79331 8.25869 8.26731 8.73269C8.35283 8.81821 8.43135 8.90865 8.5026 9.00336C8.60378 8.64416 8.74141 8.29684 8.91352 7.96611ZM9.59962 8.87182C9.4008 9.38428 9.29688 9.9343 9.29688 10.5C9.29688 11.0657 9.4008 11.6157 9.59962 12.1282C9.86128 11.6319 10 11.0764 10 10.5C10 9.92359 9.86128 9.36811 9.59962 8.87182Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.97813 23L7.00023 21.9784C7.04469 19.9229 7.73427 17.967 8.98835 16.4649C10.2901 14.9056 12.0665 14 14 14C15.9335 14 17.7099 14.9056 19.0116 16.4649C20.2657 17.967 20.9553 19.9229 20.9998 21.9784L21.0219 23H6.97813ZM8 22H20C19.9927 21.6621 19.966 21.3282 19.9206 21C19.7176 19.5317 19.1398 18.1787 18.244 17.1058C17.11 15.7474 15.604 15 14 15C12.396 15 10.89 15.7474 9.756 17.1058C8.86022 18.1787 8.28241 19.5317 8.07936 21C8.03397 21.3282 8.0073 21.6621 8 22Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7751 20H27.023L26.9998 18.9773C26.9648 17.4377 26.422 15.9708 25.435 14.8449C24.4103 13.6759 23.014 13 21.5 13C20.2409 13 19.0633 13.4675 18.113 14.2973C18.3917 14.489 18.6597 14.7 18.9157 14.9289C19.6673 14.3251 20.5613 14 21.5 14C22.703 14 23.8325 14.5339 24.683 15.5041C25.2934 16.2004 25.7069 17.0618 25.8917 18C25.9557 18.3253 25.9923 18.6599 26 19H21.5103C21.6152 19.3275 21.7035 19.6613 21.7751 20ZM18.2057 15.6356C17.9521 15.4123 17.6862 15.2098 17.4097 15.0295C17.1994 15.2897 17.0115 15.5662 16.8469 15.8561C17.1253 16.0363 17.3926 16.2438 17.6464 16.4773C17.8055 16.1784 17.9924 15.8964 18.2057 15.6356ZM19.3823 19H20.4543C20.5716 19.3255 20.6705 19.6595 20.7506 20H19.7199C19.6286 19.6567 19.5158 19.3226 19.3823 19Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.00028 18.9773L0.977051 20H6.225C6.29654 19.6613 6.38485 19.3275 6.4897 19H2.00002C2.00774 18.6599 2.0443 18.3253 2.10837 18C2.29313 17.0618 2.70666 16.2004 3.31702 15.5041C4.16752 14.5339 5.29702 14 6.50002 14C7.43876 14 8.33275 14.3251 9.08433 14.9289C9.34031 14.7 9.60831 14.489 9.88705 14.2973C8.93679 13.4675 7.7591 13 6.50002 13C4.98605 13 3.58976 13.6759 2.56504 14.8449C1.57806 15.9708 1.03524 17.4377 1.00028 18.9773ZM10.5904 15.0295C10.3139 15.2099 10.048 15.4123 9.79435 15.6356C10.0076 15.8964 10.1946 16.1784 10.3536 16.4774C10.6075 16.2438 10.8748 16.0363 11.1532 15.8561C10.9885 15.5662 10.8007 15.2897 10.5904 15.0295ZM8.28018 20C8.37142 19.6567 8.4842 19.3226 8.61772 19H7.54578C7.42847 19.3255 7.32956 19.6595 7.24947 20H8.28018Z"
      fill={color}
    />
  </svg>
);

export default PeopleIcon;
