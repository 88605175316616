/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useForm } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
/** Internal Dependencies */
import Messages from './Messages';
import { Modals } from '../../constants/Layouts';
/** Data layer **/
import { closeModal, displayToast, PromoCodeValidationActions } from 'zo-data-layer/actions';
import { EndUserValidPromoCodeSelectors } from 'zo-data-layer/selectors';
/** Components */
import Button from '../Button';
import Input from '../Input';
/** Styling */
import { mediaQueries } from '../../styles';
import { ToastTypes } from 'zo-data-layer/constants';
import { ImmutableState } from 'zo-data-layer/utils/selectors';

type Props = {
  onPromoCodeApplied: (promoCode: string, codeData: Map<string, any>) => void;
};

type SelectorProps = {
  isCheckSuccessful: boolean;
  isCheckLoading: boolean;
  isValid: boolean;
  codeData: any;
};

const mapStateToProps = createStructuredSelector<ImmutableState, SelectorProps>({
  isCheckSuccessful: EndUserValidPromoCodeSelectors.isSuccess(),
  isCheckLoading: EndUserValidPromoCodeSelectors.isLoading(),
  isValid: EndUserValidPromoCodeSelectors.isValid(),
  codeData: EndUserValidPromoCodeSelectors.getData(),
});

const PromocodeModal: FC<Props> = ({ onPromoCodeApplied }) => {
  const intl = useIntl();
  const { register, formState, handleSubmit, watch } = useForm({ mode: 'onChange' });
  const promoCode = watch('promocode');
  const dispatch = useDispatch();

  const { isValid, isCheckSuccessful, isCheckLoading, codeData } = useSelector(mapStateToProps);
  const close = () => dispatch(closeModal(Modals.Promocode));
  const resetValidation = () => dispatch(PromoCodeValidationActions.reset());

  useEffect(() => {
    if (promoCode) {
      if (isCheckSuccessful && isValid) {
        onPromoCodeApplied(promoCode, codeData);
        close();
      }
      if (isCheckSuccessful && !isValid) {
        dispatch(displayToast(Messages.invalidPromocode, ToastTypes.error));
        resetValidation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckSuccessful]);

  const onSubmit = (data: any) => {
    dispatch(PromoCodeValidationActions.request(data.promocode));
  };

  return (
    <Div>
      <HeaderSpan>
        <Header>
          <FormattedMessage {...Messages.promoCode} />
        </Header>
      </HeaderSpan>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Span>
          <Input
            name="promocode"
            id="promocode"
            placeholder={intl.formatMessage(Messages.enterPromoCode)}
            innerRef={register({
              required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
            })}
          />
        </Span>
        <ButtonWrapper>
          <Button title={<FormattedMessage {...Messages.cancel} />} onClick={close} className="clearBackground" />
          <Button
            title={<FormattedMessage {...Messages.apply} />}
            isDisabled={!formState.isValid}
            onClick={handleSubmit(onSubmit)}
            isLoading={isCheckLoading}
          />
        </ButtonWrapper>
      </Form>
    </Div>
  );
};

export default PromocodeModal;

const Div = styled.div``;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 30rem;
`;

const HeaderSpan = styled.span`
  display: inline-block;

  @media (min-width: ${mediaQueries.m_mobile}) {
    display: flex;
  }
`;

const Header = styled.h3`
  margin-top: 0;
  padding-right: 2rem;
`;

const Span = styled.span`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;

  button:first-child {
    margin-right: 1rem;
  }
`;
