import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import { MeSelectors } from 'zo-data-layer/selectors';

import AnalyticsReporter from './firebase.analytics';
import { MeAnalyticsType, ServiceAnalyticsType, CategoryAnalyticsType } from 'zo-data-layer/utils/analytics';
import AnalyticsEvents from 'zo-data-layer/constants/AnalyticsEvents';
import { ServiceType } from 'zo-data-layer/constants';
import { pathNameToSharePage } from 'constants/routes';
import { isPublicPath } from 'utils/navigation';

export interface IServiceAnalytics {
  enrollmentId: string;
  title: string;
  cta?: string;
  partner: string;
  paymentRequired: boolean;
  serviceType: ServiceType;
}

function serviceObjectToServiceAnalyticsType(service: IServiceAnalytics): ServiceAnalyticsType {
  return {
    id: service.enrollmentId,
    title: service.title,
    cta: service.cta,
    partner: { name: service.partner },
    payment_required: service.paymentRequired,
    type: service.serviceType,
  };
}

export class AnalyticsService {
  private myData: MeAnalyticsType;

  constructor(myData: Map<string, any>) {
    this.myData = myData.toJS() as MeAnalyticsType;
  }

  logServiceEvent = (eventName: AnalyticsEvents, service: IServiceAnalytics, { ...moreParams } = {}) =>
    AnalyticsReporter.logServiceEvent(eventName, serviceObjectToServiceAnalyticsType(service), this.myData, moreParams);

  logCategoryEvent = (category: CategoryAnalyticsType) => AnalyticsReporter.logCategoryEvent(category);

  logShareEvent = (service: IServiceAnalytics) => {
    this.logServiceEvent(AnalyticsEvents.ShareTapped, service, {
      sharePage: pathNameToSharePage(window.location.pathname),
      isPublic: isPublicPath(window.location.pathname),
    });
  };

  logServiceCtaClicked(service: IServiceAnalytics) {
    if (service.serviceType === ServiceType.Event) {
      this.logServiceEvent(AnalyticsEvents.ScheduledServiceCtaTapped, service);
    } else if (service.serviceType === ServiceType.Inventory) {
      this.logServiceEvent(AnalyticsEvents.InventoryServiceCtaTapped, service);
    }
  }

  logBookingEvent = (eventName: AnalyticsEvents, booking) => AnalyticsReporter.logBookingEvent(eventName, booking);

  logPaymentEvent = (eventName: AnalyticsEvents, service: IServiceAnalytics) =>
    AnalyticsReporter.logPaymentEvent(eventName, serviceObjectToServiceAnalyticsType(service), this.myData, 'stripe');
}

function useAnalytics(): AnalyticsService {
  const myData = useSelector(MeSelectors.myData()) as Map<string, any>;
  return new AnalyticsService(myData);
}

export default useAnalytics;
