/** External Dependencies **/
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, AnyAction } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useForm, Controller } from 'react-hook-form';
import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormattedMessage } from 'react-intl';

/** Internal Dependencies */
import Messages from './Messages';
import { Modals } from 'constants/Layouts';

/** Data layer **/
import { SetupIntentCreateActions, MyPaymentSourcesCreateActions, closeModal } from 'zo-data-layer/actions';
import { MeSelectors, SetupIntentSelectors } from 'zo-data-layer/selectors';

/** Components and Assets */
import Button from '../Button';
import AmericanExpress from 'assets/images/AmericanExpress.png';
import Diners from 'assets/images/Diners.png';
import Discover from 'assets/images/Discover.png';
import JCB from 'assets/images/JCB.png';
import MasterCard from 'assets/images/Mastercard.png';
import UnionPay from 'assets/images/UnionPay.png';
import VISA from 'assets/images/VISA.png';

/** Styling */
import { neutral, status } from '../../styles';
import { mediaQueries } from '../../styles';
import { CCBrands } from 'zo-data-layer/constants/stripe';

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '1rem',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: neutral.grey,
      },
    },
  },
};

type Props = {
  close: () => AnyAction;
  createSetupIntent: () => AnyAction;
  isSetupIntentLoaded: boolean;
  isSetupIntentLoading: boolean;
  resetSetupIntent: () => AnyAction;
  setupIntentSecret: string;
  fullName: string;
  createPaymentSource: (paymentMethod: string) => AnyAction;
};

const AddNewCardModal: FC<Props> = ({
  close,
  createSetupIntent,
  isSetupIntentLoaded,
  isSetupIntentLoading,
  resetSetupIntent,
  setupIntentSecret,
  fullName,
  createPaymentSource,
}) => {
  const { control } = useForm();
  const elements = useElements();
  const stripe = useStripe();
  const cardElement = elements.getElement(CardNumberElement);

  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    async function addCard() {
      try {
        const { setupIntent, error } = await stripe.confirmCardSetup(setupIntentSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: fullName,
            },
          },
        });
        if (error) {
          setErrorMessage(error.message);
        } else {
          close();
          createPaymentSource(setupIntent.payment_method);
          resetSetupIntent();
        }
      } catch (error) {
        close();
        resetSetupIntent();
      }
    }
    if (isSetupIntentLoaded) {
      addCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetupIntentLoaded]);

  const handleChange = (event) => {
    if (event[0].error) {
      setErrorMessage(event[0].error.message);
    } else {
      setErrorMessage(undefined);
    }
  };

  const onSubmit = () => {
    if (!stripe || !elements) {
      return;
    }
    createSetupIntent();
  };

  return (
    <div>
      <HeaderSpan>
        <Header>
          <FormattedMessage {...Messages.addCard} />
        </Header>
        <IconsRow>
          <CCDiv>
            <CCImage id={CCBrands.Visa} alt={CCBrands.Visa} src={VISA} />
          </CCDiv>
          <CCDiv>
            <CCImage id={CCBrands.Mastercard} alt={CCBrands.Mastercard} src={MasterCard} />
          </CCDiv>
          <CCDiv>
            <CCImage id={CCBrands.Amex} alt={CCBrands.Amex} src={AmericanExpress} />
          </CCDiv>
          <CCDiv>
            <CCImage id={CCBrands.Discover} alt={CCBrands.Discover} src={Discover} />
          </CCDiv>
          <CCDiv>
            <CCImage id={CCBrands.Unionpay} alt={CCBrands.Unionpay} src={UnionPay} />
          </CCDiv>
          <CCDiv>
            <CCImage id={CCBrands.Diners} alt={CCBrands.Diners} src={Diners} />
          </CCDiv>
          <CCDiv>
            <CCImage id={CCBrands.JCB} alt={CCBrands.JCB} src={JCB} />
          </CCDiv>
        </IconsRow>
      </HeaderSpan>
      <Form>
        <Label htmlFor="cardNumber">
          <FormattedMessage {...Messages.cardNumber} />
        </Label>
        <Span>
          <Controller
            as={CardNumberElement}
            name="cardNumber"
            id="cardNumber"
            control={control}
            options={CARD_OPTIONS}
            onChange={handleChange}
          />
        </Span>
        <InputRow>
          <InputSpan>
            <Label htmlFor="expiry">
              <FormattedMessage {...Messages.expiryDate} />
            </Label>
            <Span>
              <Controller
                as={CardExpiryElement}
                name="expiry"
                id="expiry"
                control={control}
                options={CARD_OPTIONS}
                onChange={handleChange}
              />
            </Span>
          </InputSpan>
          <InputSpan>
            <Label htmlFor="cvc">
              <FormattedMessage {...Messages.securityCode} />
            </Label>
            <Span>
              <Controller
                as={CardCvcElement}
                name="cvc"
                id="cvc"
                control={control}
                options={CARD_OPTIONS}
                onChange={handleChange}
              />
            </Span>
          </InputSpan>
        </InputRow>
        {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
        <ButtonWrapper>
          <Button title={<FormattedMessage {...Messages.cancel} />} onClick={close} className="clearBackground" />
          <Button
            title={<FormattedMessage {...Messages.addCard} />}
            isDisabled={errorMessage}
            onClick={onSubmit}
            isLoading={isSetupIntentLoading}
          />
        </ButtonWrapper>
      </Form>
    </div>
  );
};

type SelectorProps = {
  fullName: string;
  isSetupIntentLoaded: boolean;
  isSetupIntentLoading: boolean;
  setupIntentSecret: string;
};

const mapStateToProps = createStructuredSelector<any, SelectorProps>({
  fullName: MeSelectors.myFullName(),
  isSetupIntentLoaded: SetupIntentSelectors.isSuccess(),
  isSetupIntentLoading: SetupIntentSelectors.isLoading(),
  setupIntentSecret: SetupIntentSelectors.clientSecret(),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: () => closeModal(Modals.AddCard),
      createSetupIntent: SetupIntentCreateActions.request,
      createPaymentSource: MyPaymentSourcesCreateActions.request,
      resetSetupIntent: SetupIntentCreateActions.reset,
      resetPaymentSource: MyPaymentSourcesCreateActions.reset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCardModal);

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const HeaderSpan = styled.span`
  display: inline-block;

  @media (min-width: ${mediaQueries.m_mobile}) {
    display: flex;
  }
`;

const Header = styled.h3`
  margin-top: 0;
  width: 30%;
`;

const IconsRow = styled.span`
  width: 70%;
  display: flex;
  padding-right: 1rem;
`;

const Span = styled.span`
  border-bottom: 1px solid ${neutral.grey};
  padding-bottom: 1rem;
`;

const Label = styled.label`
  font-weight: 400;
  letter-spacing: 0.025em;
  margin-top: 16px;
  display: block;
  padding: 1rem 0;
`;

const InputRow = styled.div`
  display: flex;

  span:first-child {
    margin-right: 1rem;
  }
`;

const InputSpan = styled.span`
  width: 50%;
  border-bottom: 1px solid ${neutral.grey};
  padding-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 2rem;

  button:first-child {
    margin-right: 1rem;
  }
`;

const ErrorResult = styled.span`
  padding-top: 1rem;
  color: ${status.error};
`;

const CCDiv = styled.div`
  padding-right 0.2rem;
`;

const CCImage = styled.img`
  max-width: 100%;
  height: auto;
`;
