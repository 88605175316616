/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from '../../styles/colors';

type Props = {
  color?: string;
  width?: string;
  padding?: string;
};

const ChevronLeft: FC<Props> = ({ color = neutral.white, width = 8, padding }) => (
  <div style={{ padding: padding }}>
    <svg width={width} height={width} viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5424 3.5276C12.1301 2.93499 12.1526 1.95032 11.5927 1.32829C11.0328 0.706256 10.1025 0.682406 9.51477 1.27502C9.51477 1.27502 0.44563 10.5618 0.420893 10.5887C-0.158688 11.2305 -0.137011 12.248 0.46931 12.8615L9.51468 22.1193C10.1014 22.7129 11.0318 22.6907 11.5927 22.0697C12.1536 21.4487 12.1326 20.4639 11.5458 19.8703L4.0303 12.0453C3.84449 11.8518 3.84445 11.5462 4.03023 11.3526L11.5424 3.5276Z"
        fill={color}
      />
    </svg>
  </div>
);

export default ChevronLeft;
