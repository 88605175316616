/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from '../../styles';

type Props = {
  color?: string;
};

const ZoLogo: FC<Props> = ({ color = neutral.black }) => (
  <svg width="100%" height="100%" viewBox="0 0 78 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3957_7477)">
      <path
        d="M0.900436 21.6048L7.5013 18.195C7.36711 17.9843 7.24365 17.7844 7.09872 17.5522C7.16385 17.5614 7.22838 17.5744 7.29196 17.5912C8.47955 18.0072 9.6658 18.4258 10.8547 18.8378C10.9889 18.8848 11.0654 18.9451 11.0721 19.0968C11.088 19.2326 11.1136 19.3671 11.1486 19.4994C10.0013 19.1692 8.89823 18.8056 7.76834 18.4741L7.83677 18.6553L1.28422 22.308L1.12722 22.1161L0.881649 22.3845C0.798451 22.2369 0.747458 22.1161 0.87494 21.9886C1.00242 21.8611 1.05073 21.7645 0.900436 21.6048Z"
        fill={color}
      />
      <path
        d="M26.4918 15.8131L26.8944 16.4519C26.8328 16.4443 26.7719 16.4327 26.7119 16.417C25.5176 15.9996 24.3233 15.5783 23.1276 15.1636C23.0706 15.1531 23.019 15.1231 22.9816 15.0789C22.9442 15.0346 22.9232 14.9787 22.9223 14.9207C22.9143 14.7865 22.8807 14.6644 22.8525 14.4954L26.2207 15.5273L26.1684 15.3421L32.7142 11.6961L32.8659 11.8867L33.0913 11.6304C33.2255 11.8384 33.2255 11.8384 32.9947 12.1873L33.0712 12.4127L26.4918 15.8131Z"
        fill={color}
      />
      <path
        d="M11.6906 1.28294L11.8865 1.13667L11.6182 0.895126C11.7779 0.785089 11.9147 0.760935 12.0449 0.908545C12.1469 1.02127 12.2596 1.02529 12.4018 0.908545L15.8143 7.51075L16.4853 7.09341C16.445 7.21821 16.4249 7.28799 16.4007 7.3618C15.9982 8.51852 15.5862 9.67391 15.193 10.832C15.1367 10.9917 15.0669 11.0896 14.8844 11.0923C14.763 11.1048 14.6427 11.1268 14.5247 11.1581C14.667 10.5757 14.8589 10.0309 15.0253 9.47933C15.1917 8.92781 15.3648 8.36554 15.5406 7.78718L15.3581 7.84757C14.1325 5.65757 12.91 3.46936 11.6906 1.28294Z"
        fill={color}
      />
      <path
        d="M22.3022 32.7238L22.1117 32.858L22.3801 33.0968C22.1425 33.3075 21.999 33.1075 21.8299 32.9881L21.5843 33.0646L18.1866 26.5027L17.5371 26.9052C17.5666 26.7952 17.5787 26.7321 17.6002 26.6731C18.0095 25.5003 18.4228 24.3288 18.828 23.1546C18.8404 23.0894 18.875 23.0306 18.9259 22.988C18.9768 22.9454 19.0408 22.9218 19.1071 22.9211C19.2358 22.9074 19.3635 22.8859 19.4896 22.8567C19.346 23.4404 19.1568 23.9772 18.9931 24.522C18.8294 25.0668 18.6576 25.621 18.4805 26.1967L18.6589 26.1766L22.3022 32.7238Z"
        fill={color}
      />
      <path
        d="M16.4815 23.1345H17.1108C16.3057 24.7958 15.5207 26.4303 14.7222 28.0875L14.9021 28.109C15.4509 30.0574 15.9997 32.0126 16.558 34L15.8293 33.9678L14.3908 28.2392L13.7051 28.3733L16.4815 23.1345Z"
        fill={color}
      />
      <path
        d="M5.90441 14.7221L5.89233 14.9113L0 16.5686C0.0134191 16.3123 0.0241544 16.0855 0.0375735 15.8373L5.75948 14.4014C5.71788 14.1827 5.68031 13.976 5.62529 13.7117L10.8695 16.4867V17.1161L5.90441 14.7221Z"
        fill={color}
      />
      <path
        d="M19.277 5.91783L19.0892 5.89099C18.5403 3.93985 17.9915 1.98737 17.4332 0L18.1646 0.0429411C18.6436 1.9498 19.12 3.84592 19.6004 5.76217L20.2915 5.63468L17.5164 10.8695H16.8857L19.277 5.91783Z"
        fill={color}
      />
      <path
        d="M23.1221 17.5188V16.8921C24.7941 17.6865 26.4285 18.4903 28.0871 19.2847L28.1032 19.0928L33.9862 17.445C33.9862 17.5926 33.9862 17.6999 33.9862 17.8073C33.9862 17.9146 33.9687 18.0381 33.9593 18.1723L28.2294 19.6054C28.2723 19.8228 28.3126 20.0295 28.3636 20.2952L23.1221 17.5188Z"
        fill={color}
      />
      <path
        d="M15.4404 0.0656462L16.1932 0.0227051C16.2308 0.117981 16.2657 0.199837 16.2939 0.291087C16.9201 2.1644 17.5463 4.03815 18.1726 5.91235C18.2115 6.0291 18.2611 6.14182 18.3068 6.27333L16.2831 10.8989L15.6699 10.9553C15.7115 10.8399 15.7424 10.7486 15.7786 10.6587C16.2738 9.39327 16.7743 8.13053 17.2641 6.86243C17.2882 6.76953 17.335 6.68409 17.4004 6.61381C17.4658 6.54353 17.5476 6.49062 17.6385 6.45985C17.6824 6.44153 17.7242 6.41859 17.7633 6.39142L15.4404 0.0656462Z"
        fill={color}
      />
      <path
        d="M6.25819 15.6897L10.8958 17.7133C10.9146 17.9226 10.9307 18.1159 10.9495 18.3266C10.8475 18.289 10.7697 18.2621 10.6932 18.2326C9.42242 17.7334 8.15163 17.2316 6.87816 16.7404C6.78218 16.716 6.69383 16.668 6.62117 16.6008C6.54851 16.5335 6.49384 16.4491 6.46217 16.3553C6.44457 16.3149 6.42394 16.2758 6.40044 16.2385C5.34167 16.6076 4.29498 17.0075 3.24292 17.3899C2.19086 17.7723 1.15088 18.1628 0.0639338 18.5614L0.015625 17.8072C0.101507 17.775 0.175312 17.7442 0.251801 17.7187L5.93882 15.8158C6.03812 15.7823 6.13608 15.738 6.25819 15.6897Z"
        fill={color}
      />
      <path
        d="M27.7132 18.3049C26.2492 17.6662 24.7529 17.0113 23.2567 16.3605C23.202 16.3448 23.1544 16.3104 23.1223 16.2634C23.0902 16.2163 23.0756 16.1596 23.0809 16.1029C23.0795 15.9638 23.0701 15.8249 23.0527 15.6869C23.1212 15.7056 23.1762 15.715 23.2258 15.7338C24.5597 16.2572 25.8909 16.7845 27.2261 17.3025C27.2888 17.323 27.3458 17.3579 27.3924 17.4046C27.4391 17.4512 27.474 17.5082 27.4945 17.5709C27.5244 17.6404 27.5585 17.7081 27.5964 17.7735L33.9276 15.4507C33.9504 15.7191 33.9705 15.9485 33.9933 16.2089L27.7132 18.3049Z"
        fill={color}
      />
      <path
        d="M15.6826 27.7481L17.7129 23.0984L18.3034 23.0581C18.2917 23.1166 18.2769 23.1743 18.2591 23.2312C17.7357 24.5664 17.2097 25.9003 16.6904 27.2355C16.6721 27.2946 16.6405 27.3488 16.5979 27.3937C16.5553 27.4386 16.5029 27.4731 16.4448 27.4945C16.3689 27.5273 16.295 27.5645 16.2234 27.6058C16.9963 29.7113 17.7666 31.8114 18.5436 33.9316L17.7921 33.9907C17.7586 33.8994 17.7263 33.8162 17.6982 33.7317C17.0719 31.853 16.4426 29.9716 15.8101 28.0876C15.7792 27.9789 15.7309 27.8729 15.6826 27.7481Z"
        fill={color}
      />
      <path
        d="M31.6689 10.0293L31.5093 9.77973L31.9682 8.9397L32.1507 9.26981L31.7173 10.0387L32.152 10.6748C32.0876 10.7231 32.0326 10.7687 31.9722 10.809C29.9084 12.0856 27.8449 13.3626 25.782 14.6401C25.728 14.6775 25.6662 14.7019 25.6012 14.7114C25.5363 14.721 25.47 14.7154 25.4076 14.6952C24.581 14.4657 23.7517 14.2416 22.921 14.0242C22.8531 14.0151 22.79 13.9839 22.7415 13.9354C22.693 13.8869 22.6618 13.8238 22.6526 13.7558C22.6339 13.6498 22.5963 13.5478 22.5547 13.4029C22.662 13.419 22.7399 13.4257 22.8164 13.4418C23.3853 13.576 23.953 13.7008 24.5233 13.8243C24.6965 13.851 24.8558 13.9351 24.9755 14.0631C25.0466 14.145 25.1325 14.2121 25.2331 14.306L31.6689 10.0293Z"
        fill={color}
      />
      <path
        d="M20.6201 22.5535L20.2994 23.9866C20.2886 24.0349 20.2766 24.0832 20.2672 24.1329C20.1853 24.5355 20.1236 24.9635 19.6754 25.2064L23.9695 31.6744L24.2191 31.5188C24.3439 31.5845 24.4875 31.653 24.6217 31.7308C24.7559 31.8086 24.8901 31.8824 25.0578 31.9723C24.9692 32.0233 24.9035 32.0582 24.8431 32.1065C24.8087 32.1298 24.7679 32.1416 24.7264 32.1401C24.6849 32.1387 24.6449 32.1241 24.6123 32.0985C24.3989 31.9643 24.1802 31.8502 23.9641 31.7254L23.32 32.1656C23.2704 32.0945 23.2261 32.0314 23.1858 31.9764C21.9137 29.9188 20.6411 27.8612 19.3681 25.8036C19.3291 25.7463 19.3039 25.6807 19.2943 25.6121C19.2848 25.5434 19.2912 25.4735 19.3131 25.4077C19.5412 24.5891 19.7613 23.7683 19.9733 22.9453C20.0162 22.7816 20.086 22.6769 20.2618 22.6568C20.3833 22.6301 20.503 22.5956 20.6201 22.5535Z"
        fill={color}
      />
      <path
        d="M11.2818 20.0481C11.3355 20.2306 11.3878 20.4051 11.4509 20.6171C11.3408 20.597 11.2549 20.5849 11.1704 20.5648C10.5853 20.4306 10.0003 20.2964 9.41384 20.1703C9.28304 20.1488 9.16209 20.0874 9.06762 19.9945C8.97771 19.8992 8.87573 19.8133 8.76838 19.7113L2.3272 23.9853L2.48421 24.2322L2.02662 25.0736L1.84277 24.7435C1.98636 24.4899 2.13263 24.2295 2.27889 23.9679L1.84546 23.3331C1.91926 23.2808 1.97965 23.2365 2.03869 23.199C4.09629 21.9268 6.15389 20.6542 8.21148 19.3812C8.26935 19.3407 8.33601 19.3144 8.40599 19.3046C8.47597 19.2948 8.54728 19.3018 8.61406 19.3249C9.44067 19.5543 10.2713 19.7717 11.1006 19.9958C11.1543 20.0039 11.216 20.0267 11.2818 20.0481Z"
        fill={color}
      />
      <path
        d="M13.3785 11.452C13.4536 11.1138 13.5127 10.8213 13.5825 10.5301C13.6603 10.1798 13.7462 9.83227 13.816 9.48069C13.8437 9.29854 13.9344 9.13188 14.0723 9.00968C14.1514 8.93485 14.2262 8.85554 14.2964 8.77216L10.0237 2.33099L9.77814 2.49604L8.92871 2.03443L9.2709 1.85595L10.0344 2.28805L10.6786 1.8479C10.7309 1.92842 10.7779 1.99417 10.8208 2.06261C12.0894 4.11215 13.3579 6.16125 14.6265 8.2099C14.6659 8.267 14.6916 8.33242 14.7016 8.40108C14.7116 8.46974 14.7057 8.53979 14.6842 8.60576C14.456 9.43238 14.2324 10.2612 14.0132 11.0923C13.9783 11.2265 13.93 11.3164 13.7797 11.3406C13.644 11.3701 13.51 11.4073 13.3785 11.452Z"
        fill={color}
      />
      <path
        d="M11.2722 14.0002C11.2265 14.1908 11.1836 14.3733 11.138 14.5933C11.044 14.5343 10.9689 14.49 10.8964 14.4404C9.15642 13.2514 7.42133 12.0612 5.69116 10.8695C5.55015 10.7689 5.37807 10.7214 5.20539 10.7354C4.27276 10.7863 3.34013 10.8199 2.4075 10.8615C2.09886 10.8749 1.79022 10.891 1.48292 10.9031C1.37557 10.9031 1.26688 10.9031 1.12598 10.9031L1.3541 10.3328L5.47645 10.259L5.57039 9.78662L11.2722 14.0002Z"
        fill={color}
      />
      <path
        d="M28.5119 23.7517C28.4837 23.9114 28.4596 24.0483 28.4274 24.2281L22.7256 20.0078C22.7699 19.8199 22.8142 19.6307 22.8665 19.4106C22.9658 19.4751 23.0409 19.5207 23.1134 19.5717C24.8463 20.7597 26.5778 21.9486 28.3079 23.1385C28.4484 23.2391 28.6201 23.2867 28.7924 23.2727C30.0833 23.2096 31.3742 23.1532 32.6651 23.0955C32.7215 23.0955 32.7792 23.0955 32.8771 23.0955L32.645 23.6752L28.5119 23.7517Z"
        fill={color}
      />
      <path
        d="M11.004 15.2562C10.9799 15.4454 10.957 15.6332 10.9316 15.8439C10.8661 15.8198 10.802 15.7921 10.7397 15.7607C9.04617 14.7767 7.35178 13.7953 5.6565 12.8166C5.59209 12.7883 5.53809 12.7407 5.50201 12.6803C5.46593 12.62 5.44957 12.5499 5.45521 12.4798C5.45521 12.3737 5.44314 12.2677 5.43508 12.1335L0.537109 12.7253C0.600179 12.4959 0.655198 12.3013 0.7129 12.0933C0.920896 12.0705 1.11547 12.0477 1.31542 12.0289L4.75071 11.7095C4.94126 11.6921 5.14389 11.629 5.32102 11.6719C5.49816 11.7149 5.63637 11.8665 5.79472 11.9671L10.7866 15.1126C10.8591 15.1636 10.9262 15.2038 11.004 15.2562Z"
        fill={color}
      />
      <path
        d="M10.9083 32.8836L10.3206 32.6474C10.2951 31.2599 10.2696 29.8938 10.2454 28.5197L9.77441 28.4365L13.9961 22.7334L14.5905 22.8756C14.5301 22.9696 14.4859 23.0447 14.4362 23.1158C13.2526 24.8415 12.0673 26.5668 10.8801 28.2916C10.7629 28.4535 10.7077 28.652 10.7245 28.8512C10.7795 29.875 10.8184 30.9003 10.8587 31.9255C10.8761 32.2207 10.8909 32.52 10.9083 32.8836Z"
        fill={color}
      />
      <path
        d="M12.7159 33.4631L12.0771 33.2793C11.9295 31.724 11.7832 30.1741 11.6396 28.6564C11.7322 28.5303 11.8101 28.435 11.8745 28.3316C12.948 26.6265 14.0242 24.9201 15.1031 23.2123C15.1461 23.1425 15.193 23.0781 15.248 22.9895L15.8532 23.07C15.7969 23.172 15.758 23.2445 15.7191 23.3156C14.7623 24.9675 13.8015 26.6167 12.8514 28.2726C12.7427 28.4618 12.626 28.5638 12.4045 28.541C12.3118 28.5415 12.2192 28.5505 12.1281 28.5678C12.3227 30.1996 12.5173 31.8032 12.7159 33.4631Z"
        fill={color}
      />
      <path
        d="M19.4004 11.1299C19.4836 11.0065 19.5426 10.9166 19.603 10.828C20.7785 9.11571 21.9545 7.40388 23.1309 5.6925C23.2312 5.55134 23.2787 5.37938 23.2651 5.20673C23.202 3.92252 23.1457 2.63965 23.088 1.35679C23.088 1.29237 23.088 1.22259 23.088 1.12598L23.6663 1.35947C23.6918 2.7403 23.716 4.10636 23.7415 5.48182L24.1695 5.55831C24.1118 5.7341 20.3572 10.8481 19.9788 11.2668L19.4004 11.1299Z"
        fill={color}
      />
      <path
        d="M22.9834 18.7532C23.0102 18.5478 23.0344 18.368 23.0612 18.1614C23.1404 18.1963 23.1954 18.2164 23.2531 18.2473C24.9475 19.2313 26.6432 20.2127 28.3403 21.1914C28.4042 21.2202 28.4575 21.2684 28.4926 21.329C28.5277 21.3897 28.543 21.4598 28.5362 21.5296C28.5362 21.6356 28.5483 21.7416 28.5577 21.8704L33.457 21.28L33.2799 21.916L28.6838 22.3495L22.9834 18.7532Z"
        fill={color}
      />
      <path
        d="M21.2763 0.536865L21.9137 0.716681C22.06 2.2733 22.2063 3.82321 22.3498 5.34627C22.2653 5.46168 22.1915 5.55024 22.1311 5.64686C21.0576 7.34662 19.9841 9.04637 18.9105 10.7461C18.8582 10.8307 18.8032 10.9139 18.7441 11.0065L18.1523 10.9326C18.1872 10.8535 18.2074 10.7985 18.2382 10.7394C19.2223 9.04503 20.2064 7.3493 21.1904 5.65223C21.2198 5.58858 21.2679 5.53543 21.3284 5.49993C21.3888 5.46443 21.4587 5.44827 21.5286 5.45363C21.6346 5.45363 21.7406 5.44289 21.8681 5.43618C21.6695 3.80308 21.4709 2.19278 21.2763 0.536865Z"
        fill={color}
      />
      <path
        d="M29.1017 5.05908C29.2075 5.15674 29.3053 5.26264 29.3943 5.37577C29.4196 5.44279 29.4196 5.51676 29.3943 5.58377C29.2386 6.12053 29.0776 6.6573 28.9179 7.19406C28.9045 7.24103 28.8937 7.28934 28.8763 7.35778L29.5378 7.84757L23.5865 12.9227L23.8763 13.1508C23.8357 13.1546 23.7948 13.1546 23.7542 13.1508C23.3369 13.0716 22.9195 12.9871 22.5022 12.9133C22.457 12.9099 22.4141 12.8924 22.3794 12.8633C22.3447 12.8341 22.3201 12.7948 22.309 12.7509C22.266 12.6261 22.211 12.5067 22.1426 12.3389C22.4848 12.3738 22.7907 12.3685 23.0672 12.5939L28.8105 7.3457C28.7126 7.26921 28.6294 7.21151 28.5287 7.12965C28.7166 6.46406 28.9018 5.78506 29.1017 5.05908Z"
        fill={color}
      />
      <path
        d="M5.17667 26.6597L5.46384 26.8596C5.27731 27.5306 5.08944 28.2217 4.89084 28.949L4.55 28.5921L5.12568 26.6557L4.4668 26.1672L10.4021 21.0948L10.0907 20.8264C10.6034 20.9217 11.0609 21.0062 11.5185 21.0948C11.5428 21.0979 11.5662 21.1058 11.5874 21.118C11.6086 21.1302 11.6271 21.1465 11.642 21.1659C11.7145 21.3189 11.7762 21.4799 11.8513 21.6705C11.5065 21.6208 11.1938 21.6302 10.9214 21.4155L5.17667 26.6597Z"
        fill={color}
      />
      <path
        d="M26.6384 28.8753L26.1553 29.5382L21.0855 23.6043L20.8359 23.8861C20.8453 23.7962 20.8467 23.7519 20.8561 23.6982C20.9272 23.3131 21.005 22.928 21.0694 22.5428C21.0712 22.4888 21.0905 22.4369 21.1244 22.3948C21.1583 22.3527 21.2049 22.3227 21.2573 22.3093C21.3821 22.2718 21.5002 22.2141 21.6599 22.1497C21.6102 22.4905 21.6317 22.8032 21.3995 23.0742L26.6478 28.8176L26.8651 28.5318L28.9048 29.0927C28.8968 29.1222 28.8968 29.1424 28.8861 29.1531C28.6177 29.4523 28.6177 29.4523 28.2352 29.3383L26.8209 28.9183C26.7645 28.9022 26.7041 28.8914 26.6384 28.8753Z"
        fill={color}
      />
      <path
        d="M7.34972 5.13556L7.83683 4.4646L12.9146 10.4106C12.9951 10.3126 13.0609 10.2335 13.1253 10.153L13.1629 10.1691C13.1387 10.3113 13.1173 10.4535 13.0904 10.5958C13.0327 10.8991 12.9696 11.201 12.9173 11.5043C12.9143 11.5453 12.8986 11.5844 12.8724 11.6161C12.8462 11.6479 12.8108 11.6707 12.771 11.6814C12.6369 11.727 12.5027 11.7834 12.3443 11.8491C12.3779 11.511 12.3846 11.205 12.5912 10.9246L7.34838 5.18923L7.13367 5.47237L5.05371 4.89938L5.41066 4.56256L7.34972 5.13556Z"
        fill={color}
      />
      <path
        d="M22.3164 21.2372L22.5284 20.6602L28.2316 25.2468C28.1645 25.4991 28.0974 25.7527 28.0222 26.0265L31.1824 26.3874C31.0777 26.5391 30.9959 26.6558 30.914 26.7806C30.8456 26.8786 30.7463 26.8356 30.6618 26.8249C30.3061 26.7806 29.9505 26.7283 29.5963 26.68C29.0931 26.6102 28.5885 26.5458 28.0853 26.472C28.0182 26.4591 27.9519 26.4425 27.8867 26.4223L27.9739 26.1217L22.3164 21.2372Z"
        fill={color}
      />
      <path
        d="M11.675 12.7684L11.4643 13.3454C9.55073 11.8089 7.65863 10.2845 5.76251 8.75873L5.97051 7.97908L2.81836 7.61811L3.13639 7.15381L6.11275 7.55638L6.03895 7.89186L11.675 12.7684Z"
        fill={color}
      />
      <path
        d="M26.4359 6.12181L26.1098 6.03861L21.2346 11.6746L20.6562 11.464L25.2416 5.77023L26.0239 5.9742C26.1433 4.92751 26.2601 3.90363 26.3795 2.8623C26.5701 2.90927 26.6828 3.04615 26.8492 3.1374C26.7123 4.12638 26.5768 5.11269 26.4359 6.12181Z"
        fill={color}
      />
      <path
        d="M8.75214 28.2418L7.96981 28.0284C7.84903 29.0805 7.73229 30.1097 7.60883 31.1833L7.14453 30.8639C7.27872 29.8736 7.41291 28.8859 7.55516 27.8808L7.88392 27.9694L12.7645 22.3213L13.3401 22.544C11.809 24.4496 10.2846 26.339 8.75214 28.2418Z"
        fill={color}
      />
      <path
        d="M11.5628 21.7725L12.1076 22.0476L6.90633 27.591L6.6715 27.5346C6.50644 28.3867 6.34407 29.2254 6.17096 30.1111C6.03677 30.0011 5.9321 29.9165 5.82877 29.8266C5.72545 29.7367 5.79523 29.6522 5.81267 29.569C5.89721 29.1664 5.9898 28.7544 6.08105 28.3478C6.15352 28.015 6.22464 27.6769 6.2971 27.3427L6.10655 27.2085L11.5628 21.7725Z"
        fill={color}
      />
      <path
        d="M6.41171 6.91075L6.46136 6.68128L3.8916 6.18075C3.97948 6.06004 4.07734 5.94693 4.18414 5.84259C4.25525 5.80562 4.33773 5.79699 4.41495 5.81843C5.05101 5.95262 5.68574 6.08681 6.32046 6.23308L6.65325 6.30286L6.76061 6.15928L12.2302 11.5564C12.1806 11.6584 12.139 11.7483 12.096 11.8356L11.9525 12.1039L6.41171 6.91075Z"
        fill={color}
      />
      <path
        d="M21.7617 22.4354L22.0382 21.8987L27.5802 27.0999C27.5641 27.1737 27.548 27.2435 27.5293 27.3281L30.1044 27.8286L29.7756 28.2312L27.3414 27.7051L27.1938 27.8997C25.3835 26.0747 23.5787 24.2618 21.7617 22.4354Z"
        fill={color}
      />
      <path
        d="M22.4323 12.2328L21.8955 11.9644L27.0968 6.42233L27.3276 6.47064C27.4926 5.62121 27.6563 4.78118 27.8268 3.90625C27.9193 3.97737 27.9824 4.02568 28.0441 4.07533C28.1059 4.12498 28.1555 4.17463 28.228 4.23904C28.0549 5.04419 27.8818 5.84934 27.706 6.65448L27.8965 6.80612L22.4323 12.2328Z"
        fill={color}
      />
      <path
        d="M13.3826 9.81349C11.7616 7.74158 10.1621 5.69784 8.55981 3.64069L8.78794 3.37231L7.6084 2.82615L7.94119 2.61011C8.36658 2.7443 8.71279 3.04489 9.19856 3.10393L13.7611 9.35455L13.3826 9.81349Z"
        fill={color}
      />
      <path
        d="M9.35173 20.2388C9.51008 20.373 9.64695 20.487 9.80664 20.6212L3.63385 25.4427L3.35473 25.2092L2.81797 26.3874L2.58984 26.0466C2.74148 25.7285 2.89177 25.4226 3.03133 25.1072C3.06469 25.0058 3.08895 24.9016 3.1038 24.7959L9.35173 20.2388Z"
        fill={color}
      />
      <path
        d="M20.2305 24.6495C20.3539 24.5019 20.4707 24.361 20.6116 24.1946L25.4331 30.3674L25.2036 30.6358L26.3429 31.1551C26.1577 31.3966 26.0745 31.4235 25.8329 31.3054C25.5914 31.1873 25.3378 31.0652 25.0868 30.9538C24.9886 30.9242 24.8881 30.9031 24.7863 30.8907C23.2927 28.851 21.7643 26.7523 20.2305 24.6495Z"
        fill={color}
      />
      <path
        d="M30.3598 8.56265L30.6362 8.78944L31.1515 7.66357C31.3917 7.84205 31.4199 7.93196 31.3005 8.1735C31.1811 8.41504 31.075 8.63914 30.9717 8.87532C30.9349 8.98534 30.9062 9.09791 30.8858 9.21214L24.6473 13.7652L24.1924 13.3828L30.3598 8.56265Z"
        fill={color}
      />
      <path
        d="M13.5254 0.50582C13.6718 0.447724 13.8232 0.402815 13.9776 0.371629C14.1252 0.355526 14.1843 0.140821 14.3802 0.228045L14.2809 0.335398L17.0009 6.77657L16.5554 7.04495C16.4279 6.90405 13.72 1.05466 13.5254 0.50582Z"
        fill={color}
      />
      <path
        d="M6.78898 17.0034C6.88157 17.1698 6.96343 17.3134 7.05736 17.4865L0.506151 20.4937C0.457842 20.3273 0.424294 20.1824 0.37196 20.0442C0.319625 19.906 0.249846 19.7933 0.200195 19.6416C0.323651 19.7597 0.424294 19.6859 0.530305 19.6416L6.53267 17.1094L6.78898 17.0034Z"
        fill={color}
      />
      <path
        d="M27.2097 16.9993L26.9346 16.5189L33.4777 13.5171C33.5059 13.5922 33.5301 13.6513 33.5556 13.7237C33.6213 13.8888 33.6119 14.0847 33.7689 14.2135C33.7971 14.2364 33.785 14.3061 33.8011 14.4215C33.6669 14.235 33.5609 14.3249 33.4576 14.3692L27.933 16.7014C27.6968 16.8007 27.4593 16.8974 27.2097 16.9993Z"
        fill={color}
      />
      <path
        d="M20.4736 33.4969C20.3304 33.5547 20.1815 33.5974 20.0294 33.6244C19.8764 33.6365 19.8187 33.8592 19.6269 33.7733C19.7409 33.6834 19.6819 33.5895 19.6416 33.4929C19.0082 31.9953 18.3757 30.4977 17.7441 29.0002C17.5268 28.4849 17.3098 27.97 17.0933 27.4556C17.0611 27.3805 17.0329 27.304 16.998 27.2127L17.4395 26.9604C17.5791 27.1188 20.0281 32.3885 20.4736 33.4969Z"
        fill={color}
      />
      <path
        d="M0.168945 14.6108L0.268247 13.9291L5.51512 13.03L5.59161 13.5279C3.79345 13.8942 2.002 14.2471 0.168945 14.6108Z"
        fill={color}
      />
      <path
        d="M14.604 33.8362L13.9196 33.7289C13.6217 31.9844 13.3238 30.2533 13.0205 28.4874L13.521 28.4109C13.8807 30.2158 14.2376 32.0018 14.604 33.8362Z"
        fill={color}
      />
      <path
        d="M28.4862 20.9754C28.458 20.8063 28.4338 20.6601 28.4043 20.4803C30.2025 20.1085 31.9966 19.7597 33.831 19.3933C33.7921 19.6402 33.7612 19.8482 33.7263 20.0763L28.4862 20.9754Z"
        fill={color}
      />
      <path
        d="M20.971 5.51805L20.4759 5.59857C20.1082 3.80443 19.7566 2.01298 19.3916 0.174561L20.072 0.27252C20.3725 2.02371 20.6651 3.75746 20.971 5.51805Z"
        fill={color}
      />
      <path
        d="M32.1022 24.8253C32.0042 25.0091 31.9183 25.1688 31.8338 25.3365L28.2764 25.1245L28.3529 24.7219C28.538 24.6656 31.5345 24.7407 32.1022 24.8253Z"
        fill={color}
      />
      <path
        d="M24.7974 1.89087L25.3261 2.17401C25.2563 3.35221 25.1919 4.52236 25.1141 5.72337L24.6699 5.64285C24.7115 4.40158 24.7545 3.17239 24.7974 1.89087Z"
        fill={color}
      />
      <path
        d="M9.32208 28.3614C9.28048 29.6067 9.23889 30.8359 9.18789 32.1094L8.65918 31.8343C8.7303 30.652 8.79337 29.4819 8.8712 28.2742L9.32208 28.3614Z"
        fill={color}
      />
      <path
        d="M5.7247 8.88744C5.6925 9.04981 5.66969 9.17058 5.64016 9.32222C4.40024 9.30611 3.16568 9.24036 1.88281 9.20413L2.16193 8.67676L5.7247 8.88744Z"
        fill={color}
      />
      <path d="M6.30664 3.78278L6.66761 3.50098L8.16116 4.07531L7.93035 4.36651L6.30664 3.78278Z" fill={color} />
      <path
        d="M3.48926 27.34C3.68383 26.8341 3.86902 26.355 4.06628 25.8438L4.35613 26.0759C4.16558 26.6127 3.97771 27.128 3.77508 27.6862L3.48926 27.34Z"
        fill={color}
      />
      <path
        d="M25.8359 29.9245L26.0735 29.636L27.6837 30.217C27.5933 30.298 27.4984 30.3737 27.3993 30.4438C27.3754 30.4571 27.349 30.4655 27.3219 30.4685C27.2947 30.4715 27.2671 30.469 27.2409 30.4613C26.7847 30.2949 26.3284 30.1164 25.8359 29.9245Z"
        fill={color}
      />
      <path
        d="M29.9243 8.16288L29.6357 7.92939C29.8236 7.4047 30.0115 6.88404 30.2141 6.31909C30.3107 6.43584 30.3926 6.52306 30.4597 6.62102C30.4771 6.66405 30.4771 6.71219 30.4597 6.75521C30.2919 7.21549 30.1148 7.67174 29.9243 8.16288Z"
        fill={color}
      />
      <path
        d="M1.58486 22.9119L1.74455 23.1735L1.38089 23.7331C1.32453 23.6083 1.28293 23.5184 1.2373 23.4204L1.58486 22.9119Z"
        fill={color}
      />
      <path d="M10.2695 1.39029L10.5822 1.2561L11.08 1.59427L10.8331 1.76469L10.2695 1.39029Z" fill={color} />
      <path d="M23.1682 32.2461L23.7291 32.6124L23.4218 32.7533L22.9092 32.4138L23.1682 32.2461Z" fill={color} />
      <path
        d="M32.4082 11.0908L32.2432 10.8345L32.6082 10.2668C32.6659 10.401 32.7048 10.4883 32.7424 10.5849L32.4082 11.0908Z"
        fill={color}
      />
      <path
        d="M0.504473 21.0921C0.440062 20.9579 0.389069 20.8452 0.547414 20.7485C0.681606 20.9404 0.681606 20.9404 0.504473 21.0921Z"
        fill={color}
      />
      <path
        d="M20.7422 33.4271C20.9448 33.3291 20.9448 33.3291 21.0817 33.4794C20.8737 33.5801 20.8737 33.5801 20.7422 33.4271Z"
        fill={color}
      />
      <path
        d="M13.2566 0.572896C13.0634 0.670855 13.0634 0.670855 12.9131 0.536664C13.1171 0.417234 13.1171 0.417234 13.2566 0.572896Z"
        fill={color}
      />
      <path
        d="M33.4911 12.908C33.5514 13.0596 33.593 13.1763 33.4307 13.2595C33.3233 13.0596 33.3233 13.0596 33.4911 12.908Z"
        fill={color}
      />
      <path
        d="M38.4512 24.353H39.5328C39.7771 24.3374 40.0178 24.4178 40.2037 24.5771C40.3088 24.6846 40.3671 24.8293 40.3661 24.9797V24.9864C40.3686 25.1142 40.3312 25.2397 40.259 25.3453C40.1869 25.4509 40.0836 25.5314 39.9635 25.5755C40.2654 25.6654 40.5083 25.8438 40.5083 26.1887V26.1954C40.5083 26.6315 40.142 26.8892 39.5851 26.8892H38.4565L38.4512 24.353ZM40.0709 25.0239C40.0709 24.7743 39.8709 24.6106 39.5073 24.6106H38.7343V25.4856H39.4858C39.8307 25.4856 40.0709 25.3299 40.0709 25.032V25.0239ZM39.5341 25.7405H38.7289V26.6369H39.5864C39.9716 26.6369 40.2104 26.4665 40.2104 26.1833V26.1766C40.2118 25.8989 39.9796 25.7405 39.5368 25.7405H39.5341Z"
        fill={color}
      />
      <path
        d="M41.7606 25.8895L40.7031 24.353H41.052L41.9095 25.6238L42.7737 24.353H43.1078L42.0504 25.8855V26.8946H41.7606V25.8895Z"
        fill={color}
      />
      <path d="M45.3271 24.6173H44.4736V24.3489H46.4717V24.6173H45.6169V26.8985H45.3271V24.6173Z" fill={color} />
      <path d="M47.0342 24.353H47.3214V26.8946H47.0342V24.353Z" fill={color} />
      <path
        d="M47.8984 26.5242L48.0769 26.3135C48.1907 26.4289 48.3266 26.5202 48.4765 26.582C48.6263 26.6438 48.7871 26.6748 48.9492 26.6731C49.29 26.6731 49.5154 26.492 49.5154 26.241V26.2343C49.5154 25.9981 49.388 25.8639 48.8539 25.7512C48.2701 25.617 48.0004 25.4345 48.0004 25.0172V25.0105C48.0004 24.6079 48.3533 24.3167 48.8364 24.3167C49.1625 24.3073 49.4806 24.4181 49.7301 24.628L49.5624 24.8495C49.3589 24.6723 49.0982 24.5746 48.8284 24.5744C48.4983 24.5744 48.2916 24.7555 48.2916 24.985V24.9917C48.2916 25.2319 48.4258 25.3661 48.9814 25.4855C49.5369 25.605 49.8107 25.817 49.8107 26.2048V26.2128C49.8107 26.6476 49.447 26.9321 48.9424 26.9321C48.5547 26.937 48.1802 26.7906 47.8984 26.5242Z"
        fill={color}
      />
      <path
        d="M50.3916 24.353H50.6774V25.4816H52.1455V24.353H52.4327V26.8946H52.1455V25.7513H50.6774V26.8946H50.3916V24.353Z"
        fill={color}
      />
      <path
        d="M53.1514 24.353H53.4426L54.3685 25.7406L55.2944 24.353H55.5856V26.8946H55.2984V24.8361L54.3712 26.1982H54.3577L53.4305 24.8388V26.8946H53.1514V24.353Z"
        fill={color}
      />
      <path
        d="M57.2458 24.3342H57.5142L58.6736 26.8946H58.3637L58.0657 26.2236H56.6822L56.3803 26.8946H56.0918L57.2458 24.3342ZM57.9544 25.962L57.376 24.6684L56.795 25.962H57.9544Z"
        fill={color}
      />
      <path
        d="M59.1787 24.353H59.4471L61.0493 26.39V24.353H61.3258V26.8946H61.0963L59.4551 24.8106V26.8946H59.1787V24.353Z"
        fill={color}
      />
      <path
        d="M62.9668 26.5241L63.1453 26.3135C63.2591 26.4289 63.395 26.5202 63.5448 26.582C63.6947 26.6438 63.8554 26.6748 64.0175 26.6731C64.3584 26.6731 64.5838 26.4919 64.5838 26.241V26.2343C64.5838 25.9981 64.4563 25.8639 63.9222 25.7512C63.3372 25.617 63.0688 25.4345 63.0688 25.0172V25.0105C63.0688 24.6079 63.4217 24.3167 63.9048 24.3167C64.2308 24.3074 64.5489 24.4182 64.7985 24.628L64.6308 24.8494C64.4273 24.6723 64.1666 24.5746 63.8967 24.5744C63.5666 24.5744 63.36 24.7555 63.36 24.985V24.9917C63.36 25.2319 63.4942 25.3661 64.0497 25.4855C64.6053 25.6049 64.8777 25.817 64.8777 26.2048V26.2128C64.8777 26.6476 64.5154 26.9321 64.0108 26.9321C63.623 26.9369 63.2486 26.7906 62.9668 26.5241Z"
        fill={color}
      />
      <path
        d="M65.458 24.353H66.4108C66.9838 24.353 67.3622 24.6576 67.3622 25.1582C67.3622 25.707 66.9046 25.9902 66.3625 25.9902H65.7452V26.8866H65.458V24.353ZM66.3745 25.7365C66.7959 25.7365 67.071 25.5111 67.071 25.177V25.1703C67.071 24.8066 66.8026 24.6174 66.388 24.6174H65.7452V25.7365H66.3745Z"
        fill={color}
      />
      <path
        d="M67.8857 24.353H69.7228V24.6214H68.1689V25.4883H69.5564V25.7567H68.1689V26.6477H69.7376V26.908H67.8857V24.353Z"
        fill={color}
      />
      <path
        d="M71.1215 25.8895L70.0654 24.353H70.413L71.2705 25.6238L72.136 24.353H72.4634L71.406 25.8855V26.8946H71.1215V25.8895Z"
        fill={color}
      />
      <path
        d="M72.9209 24.353H74.758V24.6214H73.2081V25.4883H74.5956V25.7567H73.2081V26.6477H74.7768V26.908H72.9209V24.353Z"
        fill={color}
      />
      <path
        d="M75.3535 24.3531H76.4472C76.7123 24.3386 76.9724 24.4298 77.1705 24.6067C77.2345 24.6733 77.2848 24.752 77.3184 24.8381C77.352 24.9242 77.3683 25.0161 77.3664 25.1086C77.3664 25.5299 77.0765 25.7795 76.6766 25.8493L77.4576 26.8879H77.1047L76.368 25.9003H75.6407V26.8879H75.3535V24.3531ZM76.427 25.6493C76.8081 25.6493 77.0819 25.4534 77.0819 25.126V25.1193C77.0819 24.8066 76.8417 24.6174 76.4311 24.6174H75.6461V25.6493H76.427Z"
        fill={color}
      />
      <path
        d="M38.4512 20.2763L47.45 8.55339H38.7705V7.67041H48.8349V8.21523L39.8414 19.9382H48.8456V20.8211H38.4512V20.2763Z"
        fill={color}
      />
      <path
        d="M56.1494 7.44482C54.8044 7.44482 53.4895 7.84368 52.3711 8.59097C51.2527 9.33825 50.3811 10.4004 49.8663 11.6431C49.3516 12.8858 49.2169 14.2532 49.4793 15.5724C49.7417 16.8916 50.3894 18.1034 51.3405 19.0545C52.2917 20.0056 53.5034 20.6533 54.8227 20.9158C56.1419 21.1782 57.5093 21.0435 58.752 20.5288C59.9947 20.014 61.0568 19.1423 61.8041 18.024C62.5514 16.9056 62.9502 15.5907 62.9502 14.2456C62.9502 12.4419 62.2337 10.7121 60.9583 9.43673C59.6829 8.16134 57.9531 7.44482 56.1494 7.44482ZM56.1494 20.1621C54.9789 20.1621 53.8347 19.815 52.8615 19.1647C51.8883 18.5144 51.1298 17.5901 50.6819 16.5086C50.2341 15.4272 50.117 14.2373 50.3454 13.0893C50.5739 11.9413 51.1376 10.8868 51.9654 10.0593C52.7931 9.23171 53.8477 8.66819 54.9957 8.44001C56.1438 8.21182 57.3337 8.3292 58.415 8.77731C59.4963 9.22543 60.4205 9.98414 61.0706 10.9575C61.7207 11.9309 62.0675 13.0751 62.0673 14.2456C62.0669 15.8149 61.4433 17.3198 60.3335 18.4293C59.2237 19.5388 57.7187 20.1621 56.1494 20.1621Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_3957_7477">
        <rect width="77.4578" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ZoLogo;
