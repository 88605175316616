import { defineMessages, MessageDescriptor } from 'react-intl';

const virtualEvent: MessageDescriptor = {
  id: 'serviceInfo.virtualEvent',
  defaultMessage: 'Virtual Event',
};

const onWaitlist: MessageDescriptor = {
  id: 'serviceInfo.onWaitlist',
  defaultMessage: 'On Waitlist',
};

export default defineMessages({
  virtualEvent,
  onWaitlist,
  booked: {
    id: 'serviceInfo.booked',
    defaultMessage: 'Booked',
  },
});
