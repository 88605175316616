import { history } from 'compositionRoot';
import { AppLinks } from 'constants/routes';
import { matchPath } from 'react-router-dom';
import { Location } from 'history';

export function redirectToLogin(): void {
  history.push({
    pathname: AppLinks.login,
  });
}

export function redirectToHome(): void {
  history.push(AppLinks.discover);
}

export function redirectToLeaderView(): void {
  history.push(AppLinks.leaderView);
}

export function goBack(): void {
  history.goBack();
}

export function redirectToPath(path: string): void {
  history.push(path);
}

export const navigateToAvailabilities = () => {
  const availabilitiesElement = document.getElementById('availabilities');
  window.scrollTo({ top: availabilitiesElement.offsetTop, behavior: 'smooth' });
};

export const isLeaderRoute = (location: Location) => !!matchPath(location.pathname, { path: AppLinks.leaderView });
export const isLeaderHome = (location: Location) =>
  !!matchPath(location.pathname, { path: AppLinks.leaderView, exact: true });
export const isFilterRoute = (location: Location) => !!matchPath(location.pathname, { path: AppLinks.filter });
// Specifically only for the Service Details page
export const isServiceDetailsRoute = (location: Location) =>
  !!matchPath(location.pathname, { path: `${AppLinks.serviceInfo}:id`, exact: true }) ||
  !!matchPath(location.pathname, { path: `${AppLinks.leaderView}${AppLinks.serviceInfo}:id`, exact: true });
// A Service Details page with a category ID
export const isServiceDetailsCategoryRoute = (location: Location) =>
  !!matchPath(location.pathname, { path: `${AppLinks.serviceInfo}:id/:categoryId`, exact: true }) ||
  !!matchPath(location.pathname, { path: `${AppLinks.leaderView}${AppLinks.serviceInfo}:id/:categoryId`, exact: true });
// All subsequent paths along the service booking flow
export const isServiceRoute = (location: Location) =>
  !!matchPath(location.pathname, { path: `${AppLinks.serviceInfo}` }) ||
  !!matchPath(location.pathname, { path: `${AppLinks.leaderView}${AppLinks.serviceInfo}` });
// One of three success page routes
export const isServiceBookedSuccessRoute = (location: Location) =>
  !!matchPath(location.pathname, { path: `${AppLinks.serviceInfo}:type/success/:id`, exact: true }) ||
  !!matchPath(location.pathname, {
    path: `${AppLinks.leaderView}${AppLinks.serviceInfo}:type/success/:id`,
    exact: true,
  });
// Waitlist success page. To render an orange banner on success screen
export const isWaitlistBookedSuccessRoute = (location: Location) =>
  !!matchPath(location.pathname, { path: `${AppLinks.serviceInfo}waitlist/success/:id`, exact: true }) ||
  !!matchPath(location.pathname, {
    path: `${AppLinks.leaderView}${AppLinks.serviceInfo}waitlist/success/:id`,
    exact: true,
  });
export const isDiscoverRoute = (location: Location) => location.pathname === AppLinks.discover;
export const isLoginPage = (location: Location) => !!matchPath(location.pathname, { path: AppLinks.login });
export const isRegisterPage = (location: Location) => !!matchPath(location.pathname, { path: AppLinks.register });
export const isPublicPage = (location: Location) => isPublicPath(location.pathname);
export const isPublicPath = (pathname: string) => !!matchPath(pathname, { path: AppLinks.publicServiceInfo });
