import { MessageDescriptor, defineMessages } from 'react-intl';

const welcomeBack: MessageDescriptor = {
  id: 'forms.welcomeBack',
  defaultMessage: 'Welcome!',
};

const emailAddress: MessageDescriptor = {
  id: 'forms.emailAddress',
  defaultMessage: 'Email address',
};

const password: MessageDescriptor = {
  id: 'forms.password',
  defaultMessage: 'Password',
};

const newPassword: MessageDescriptor = {
  id: 'forms.newPassword',
  defaultMessage: 'New password',
};

const confirmPassword: MessageDescriptor = {
  id: 'forms.confirmPassword',
  defaultMessage: 'Confirm password',
};

const login: MessageDescriptor = {
  id: 'forms.login',
  defaultMessage: 'Log in',
};

const resetMyPassword: MessageDescriptor = {
  id: 'forms.resetMyPassword',
  defaultMessage: 'Reset my password',
};

const resetYourPassword: MessageDescriptor = {
  id: 'forms.resetYourPassword',
  defaultMessage: 'Reset your password',
};

const pleaseEnterNewPassword: MessageDescriptor = {
  id: 'forms.pleaseEnterNewPassword',
  defaultMessage: 'Hi {name}, please enter your new password below.',
};

const currentPassword = {
  id: 'forms.currentPassword',
  defaultMessage: 'Current Password',
};

const confirmNewPassword = {
  id: 'forms.confirmNewPassword',
  defaultMessage: 'Confirm New Password',
};

const passwordsDoNotMatch: MessageDescriptor = {
  id: 'forms.passwordsDoNotMatch',
  defaultMessage: 'Passwords do not match.',
};

const atLeastEightCharacters: MessageDescriptor = {
  id: 'forms.atLeastEightCharacters',
  defaultMessage: 'Password must have at least 8 characters.',
};

const invalidEmail: MessageDescriptor = {
  id: 'forms.invalidEmail',
  defaultMessage: 'Invalid email address',
};

const fieldRequired: MessageDescriptor = {
  id: 'forms.fieldRequired',
  defaultMessage: 'Field required',
};

const welcomeUser: MessageDescriptor = {
  id: 'forms.welcomeUser',
  defaultMessage: 'Welcome {name}!',
};

const makeAccountSecure: MessageDescriptor = {
  id: 'forms.makeAccountSecure',
  defaultMessage: 'Make your account secure',
};

const phoneNumber: MessageDescriptor = {
  id: 'forms.phoneNumber',
  defaultMessage: 'Phone number (optional)',
};

const invalidPhoneNumber: MessageDescriptor = {
  id: 'forms.invalidPhoneNumber',
  defaultMessage: 'Invalid phone number',
};

const languagePreference: MessageDescriptor = {
  id: 'forms.languagePreference',
  defaultMessage: 'Language preference',
};

const continueButton: MessageDescriptor = {
  id: 'forms.continue',
  defaultMessage: 'Continue',
};

const security: MessageDescriptor = {
  id: 'forms.security',
  defaultMessage: 'Security',
};

const profile: MessageDescriptor = {
  id: 'forms.profile',
  defaultMessage: 'Profile',
};

const addPasswordForSecurity: MessageDescriptor = {
  id: 'forms.addPasswordForSecurity',
  defaultMessage: 'Add a password for security',
};

const company: MessageDescriptor = {
  id: 'forms.company',
  defaultMessage: 'Company',
};

const companyNotFound: MessageDescriptor = {
  id: 'forms.companyNotFound',
  defaultMessage: 'No company found for this email address',
};

const building: MessageDescriptor = {
  id: 'forms.building',
  defaultMessage: 'Building',
};

const floor: MessageDescriptor = {
  id: 'forms.floor',
  defaultMessage: 'Floor',
};

const jobTitle: MessageDescriptor = {
  id: 'forms.jobTitle',
  defaultMessage: 'Job title (optional)',
};

const oneLastStep: MessageDescriptor = {
  id: 'forms.oneLastStep',
  defaultMessage: 'One last step!',
};

const addInformationAboutYou: MessageDescriptor = {
  id: 'forms.addInformationAboutYou',
  defaultMessage: 'Add information about yourself',
};

const marketingOptedIn: MessageDescriptor = {
  id: 'forms.marketingOptedIn',
  defaultMessage:
    'Don’t miss a thing! Subscribe to the ZO newsletter to stay up to date on exciting events, programs, special offers and building information.',
};

const marketingOptedInShort: MessageDescriptor = {
  id: 'forms.marketingOptedInShort',
  defaultMessage: 'Receive marketing communications',
};

const optInYes: MessageDescriptor = {
  id: 'forms.optInYes',
  defaultMessage: 'Yes, add me!',
};

const optInNo: MessageDescriptor = {
  id: 'forms.optInNo',
  defaultMessage: 'No, thanks.',
};

const IAgree: MessageDescriptor = {
  id: 'forms.IAgree',
  defaultMessage: 'I agree to the ',
};

const privacyPolicy: MessageDescriptor = {
  id: 'forms.privacyPolicy',
  defaultMessage: 'ZO privacy policy',
};

const finish: MessageDescriptor = {
  id: 'forms.finish',
  defaultMessage: 'Finish',
};

const register: MessageDescriptor = {
  id: 'forms.register',
  defaultMessage: 'Register',
};

const enterInformationBelow: MessageDescriptor = {
  id: 'forms.enterInformationBelow',
  defaultMessage: 'Please enter your information below.',
};

const firstName: MessageDescriptor = {
  id: 'forms.firstName',
  defaultMessage: 'First Name',
};

const lastName: MessageDescriptor = {
  id: 'forms.lastName',
  defaultMessage: 'Last Name',
};

const companyEmailAddress: MessageDescriptor = {
  id: 'forms.companyEmailAddress',
  defaultMessage: 'Company Email Address',
};

const registerDescriptionHeader: MessageDescriptor = {
  id: 'forms.registerDescriptionHeader',
  defaultMessage: 'Please register using your work email address',
};

const registerDescription: MessageDescriptor = {
  id: 'forms.registerDescription',
  defaultMessage:
    'By registering for the Tishman Speyer ZO service, your information will be treated in accordance with its privacy policy, such as being shared with Tishman Speyer ZO vendors and other third parties.',
};

const getStarted: MessageDescriptor = {
  id: 'forms.getStarted',
  defaultMessage: 'Get Started',
};

const emailVerification: MessageDescriptor = {
  id: 'forms.emailVerification',
  defaultMessage: 'Email Verification',
};

const emailVerificationSent: MessageDescriptor = {
  id: 'forms.emailVerificationSent',
  defaultMessage: "We've sent an email verication link to the following email:",
};

const emailVerificationInstructions: MessageDescriptor = {
  id: 'forms.emailVerificationInstructions',
  defaultMessage:
    "Please click the link in the email to continue registering for ZO and we'll be waiting for you in the meantime.",
};

const buildingCampus: MessageDescriptor = {
  id: 'myProfile.buildingCampus',
  defaultMessage: 'Building Campus',
};

const save: MessageDescriptor = {
  id: 'forms.save',
  defaultMessage: 'Save',
};

const cancel: MessageDescriptor = {
  id: 'forms.cancel',
  defaultMessage: 'Cancel',
};

const birthday: MessageDescriptor = {
  id: 'forms.birthday',
  defaultMessage: 'Birthday (optional)',
};

export default defineMessages({
  welcomeBack,
  emailAddress,
  password,
  newPassword,
  confirmPassword,
  login,
  resetMyPassword,
  resetYourPassword,
  pleaseEnterNewPassword,
  passwordsDoNotMatch,
  atLeastEightCharacters,
  invalidEmail,
  fieldRequired,
  welcomeUser,
  makeAccountSecure,
  phoneNumber,
  invalidPhoneNumber,
  languagePreference,
  continueButton,
  security,
  profile,
  addPasswordForSecurity,
  company,
  companyNotFound,
  building,
  floor,
  jobTitle,
  oneLastStep,
  addInformationAboutYou,
  marketingOptedIn,
  marketingOptedInShort,
  optInYes,
  optInNo,
  IAgree,
  privacyPolicy,
  finish,
  register,
  enterInformationBelow,
  firstName,
  lastName,
  companyEmailAddress,
  registerDescriptionHeader,
  registerDescription,
  getStarted,
  emailVerification,
  emailVerificationSent,
  emailVerificationInstructions,
  buildingCampus,
  save,
  cancel,
  birthday,
  currentPassword,
  confirmNewPassword,
});
