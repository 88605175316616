/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import type { MessageDescriptor } from '@formatjs/intl';

/** Interal Dependencies */
import Messages from './Messages';
import { AppLinks } from 'constants/routes';

/** Data layer */
import { displayToast, loginAction, DisplayToastType, LogoutActions } from 'zo-data-layer/actions';
import { LoginSelectors } from 'zo-data-layer/selectors';
import { ToastTypes } from 'zo-data-layer/constants';

/** Components */
import Button from 'components/Button';
import Overlay, { OverlayDivider, Size as OverlaySize } from 'components/Overlay';
import { LoginForm } from 'components/Forms/LoginForm/LoginForm';
import { AccessToken } from 'utils/authentication';

/** Styles */
import { mediaQueries } from 'styles';

type Props = {
  login: ({ email, password }) => AnyAction;
  displayToast: DisplayToastType;
  logoutImmediately: () => AnyAction;
  isLoading: boolean;
  loginRedirect?: () => void;
};

const LoginPage: FC<Props> = ({ login, isLoading, logoutImmediately, displayToast, loginRedirect }) => {
  // if the user has an invalid token they are sent to the login page
  // but we need to clear their data and their token
  useEffect(() => {
    if (AccessToken.isNotNull() && AccessToken.isExpired()) {
      logoutImmediately();
      AccessToken.destroy();
      displayToast(Messages.sessionExpired as MessageDescriptor, ToastTypes.error);
    }
  }, [displayToast, logoutImmediately]);

  const handleLogin = (loginParams) => {
    login({ ...loginParams, loginRedirect });
  };

  return (
    <>
      <Overlay size={OverlaySize.Small}>
        <Wrapper>
          <LoginForm login={handleLogin} isLoading={isLoading} />
          <Link to={AppLinks.forgotPassword}>
            <StyledButton title={<FormattedMessage {...Messages.forgotPassword} />} buttonType="link" />
          </Link>
          <OverlayDivider />
          <Footer>
            <FormattedMessage {...Messages.noAccount} />
            &nbsp;
            <FooterLink to={AppLinks.register} className="bold">
              <FormattedMessage {...Messages.signUpNow} />
            </FooterLink>
          </Footer>
        </Wrapper>
      </Overlay>
    </>
  );
};

type SelectorProps = {
  isLoading: boolean;
};

const mapStateToProps = createStructuredSelector<any, SelectorProps>({
  isLoading: LoginSelectors.isLoading(),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      login: loginAction,
      displayToast,
      logoutImmediately: LogoutActions.success,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  padding: 1rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 0;
  }
`;

const Footer = styled.div`
  text-align: center;
  margin: 2rem 0;
`;

const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryColor};
`;

const StyledButton = styled(Button)`
  font-size: 1rem;
`;
