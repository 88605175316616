import styled from 'styled-components';

import { mediaQueries, typeScale, neutral } from '../../styles';
import CloudinaryImage from '../CloudinaryImage';

const Tile = styled.div`
  @media (min-width: ${mediaQueries.m_mobile}) {
    z-index: ${({ theme }) => theme.zIndex.z3};
    margin: 1rem 1rem 3rem 0;
    width: ${({ theme }) => theme.size.serviceTileWidth};
  }
  width: 100%;
  margin: 1rem 0;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgroundColor};
`;

export default Tile;

export type StyledTileProps = {
  cursor?: 'pointer' | 'auto';
  isOnWaitlist?: boolean;
};

export const TileImage = styled(CloudinaryImage)`
  cursor: pointer;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

export const TileInfo = styled.div<StyledTileProps>`
  padding: 1rem 0;
`;

export const TileSubInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
`;

export const TileTitle = styled.h5`
  width: 100%;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.3rem;
  font-size: ${typeScale.font18};
  color: ${({ theme }) => theme.colors.primaryColor};
`;

export const InfoPoint = styled.span`
  font-size: ${typeScale.font14};
  display: flex;
  align-items: center;
  padding-bottom: 0.4rem;
  color: ${neutral.darkGrey};
`;
