import { defineMessages, MessageDescriptor } from 'react-intl';

const close: MessageDescriptor = {
  id: 'common.button.close',
  defaultMessage: 'Close',
};

const signOut: MessageDescriptor = {
  id: 'nav.signOut',
  defaultMessage: 'Sign Out',
};

const bookings: MessageDescriptor = {
  id: 'myPages.bookings',
  defaultMessage: 'Bookings',
};

const yourBookings: MessageDescriptor = {
  id: 'myPages.yourBookings',
  defaultMessage: 'Your Bookings',
};

const your: MessageDescriptor = {
  id: 'myPages.your',
  defaultMessage: 'Your',
};

const profile: MessageDescriptor = {
  id: 'myPages.profile',
  defaultMessage: 'Profile',
};

const changePassword = {
  id: 'myPages.changePassword',
  defaultMessage: 'Change Password',
};

const yourAccount: MessageDescriptor = {
  id: 'myPages.yourAccount',
  defaultMessage: 'Your Account',
};

const edit: MessageDescriptor = {
  id: 'forms.edit',
  defaultMessage: 'Edit',
};

const paymentMethods: MessageDescriptor = {
  id: 'myPages.paymentMethods',
  defaultMessage: 'Payment Methods',
};

const manageOrEditPayment: MessageDescriptor = {
  id: 'myPages.manageOrEditPayment',
  defaultMessage: 'Manage or edit your payment methods',
};

const remove: MessageDescriptor = {
  id: 'myPages.remove',
  defaultMessage: 'Remove',
};

const addNewCard: MessageDescriptor = {
  id: 'myPages.addNewCard',
  defaultMessage: 'Add New Card',
};

const upcoming: MessageDescriptor = {
  id: 'discover.upcoming',
  defaultMessage: 'Upcoming',
};

const noUpcomingBookings = {
  id: 'myPages.noUpcomingBookings',
  defaultMessage: 'You currently have no upcoming bookings.',
};

const back: MessageDescriptor = {
  id: 'myBookingDetails.back',
  defaultMessage: 'Back',
};

const livestream: MessageDescriptor = {
  id: 'myBookingDetails.livestream',
  defaultMessage: 'LIVESTREAM',
};

const zoomEvent: MessageDescriptor = {
  id: 'myBookingDetails.zoomEvent',
  defaultMessage: 'ZOOM EVENT',
};

const yourBooking: MessageDescriptor = {
  id: 'myBookingDetails.yourBooking',
  defaultMessage: 'Your Booking',
};

const seeAllEventDetails: MessageDescriptor = {
  id: 'myBookingDetails.seeAllEventDetails',
  defaultMessage: 'See all event details',
};

const providedBy: MessageDescriptor = {
  id: 'bookingDetails.providedBy',
  defaultMessage: 'Provided by',
};

const actions: MessageDescriptor = {
  id: 'bookingDetails.actions',
  defaultMessage: 'Actions',
};

const addToCalendar: MessageDescriptor = {
  id: 'addToCalendar.header',
  defaultMessage: 'Add to Calendar',
};

const cancelBooking: MessageDescriptor = {
  id: 'forms.cancelBooking',
  defaultMessage: 'Cancel Booking',
};

const join: MessageDescriptor = {
  id: 'myPages.join',
  defaultMessage: 'Join',
};

const alerts: MessageDescriptor = {
  id: 'myPages.alerts',
  defaultMessage: 'Alerts',
};

const noNewAlerts: MessageDescriptor = {
  id: 'myPages.noNewAlerts',
  defaultMessage: 'No new alerts',
};

const optInYes: MessageDescriptor = {
  id: 'forms.yes',
  defaultMessage: 'Yes',
};

const optInNo: MessageDescriptor = {
  id: 'forms.no',
  defaultMessage: 'No',
};

const marketingOptedInShort: MessageDescriptor = {
  id: 'forms.marketingOptedInShort',
  defaultMessage: 'Receive marketing communications',
};

export default defineMessages({
  close,
  signOut,
  bookings,
  your,
  profile,
  changePassword,
  yourAccount,
  edit,
  paymentMethods,
  manageOrEditPayment,
  remove,
  addNewCard,
  upcoming,
  noUpcomingBookings,
  back,
  livestream,
  zoomEvent,
  yourBooking,
  seeAllEventDetails,
  providedBy,
  actions,
  addToCalendar,
  cancelBooking,
  join,
  alerts,
  noNewAlerts,
  yourBookings,
  sent: {
    id: 'myPages.sent',
    defaultMessage: 'Sent: ',
  },
  seeMore: {
    id: 'myPages.seeMore',
    defaultMessage: 'See More',
  },
  support: {
    id: 'myPages.support',
    defaultMessage: 'Support',
  },
  optInNo,
  optInYes,
  marketingOptedInShort,
});
