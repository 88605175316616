/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from '../../styles/colors';

type Props = {
  color?: string;
  width?: string;
  padding?: string;
};

const ChevronRight: FC<Props> = ({ color = neutral.darkGrey, width = 10, padding }) => (
  <div style={{ padding: padding }}>
    <svg width={width} height={width} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.00034 5.00003L0.859337 1.58003C0.730485 1.4499 0.658203 1.27417 0.658203 1.09103C0.658203 0.9079 0.730485 0.732168 0.859337 0.602033C0.92261 0.537783 0.998031 0.48676 1.08121 0.451932C1.16439 0.417105 1.25366 0.39917 1.34384 0.39917C1.43401 0.39917 1.52329 0.417105 1.60646 0.451932C1.68964 0.48676 1.76506 0.537783 1.82834 0.602033L5.65834 4.51003C5.78701 4.64046 5.85915 4.81631 5.85915 4.99953C5.85915 5.18275 5.78701 5.3586 5.65834 5.48903L1.82834 9.39703C1.76506 9.46128 1.68964 9.51231 1.60646 9.54713C1.52329 9.58196 1.43401 9.5999 1.34384 9.5999C1.25366 9.5999 1.16439 9.58196 1.08121 9.54713C0.998031 9.51231 0.92261 9.46128 0.859337 9.39703C0.730485 9.2669 0.658203 9.09117 0.658203 8.90803C0.658203 8.7249 0.730485 8.54917 0.859337 8.41903L4.00034 5.00003Z"
        fill={color}
      />
    </svg>
  </div>
);

export default ChevronRight;
