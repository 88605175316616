/** External Dependencies **/
import React, { FC } from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';

/** Internal Dependencies */
import Messages from './Messages';
import { Modals } from 'constants/Layouts';
import useQuery from 'hooks/useQuery';
import { useBooleanEffect } from 'hooks';
import PaymentSourceObject from 'utils/PaymentSourceObject';
import { HiddenCardNumbers } from 'constants/Stripe';

/** Data layer **/
import { MyPaymentSourcesDeleteActions, closeModal } from 'zo-data-layer/actions';
import { DeletePaymentSourceSelectors, ListPaymentSourcesSelectors } from 'zo-data-layer/selectors';

/** Components */
import Button from '../Button';
import CreditCardIcon from './CreditCardIcon';

/** Styling */
import { mediaQueries } from 'styles';

type SelectorProps = {
  paymentSources: List<any>;
  isSuccess: boolean;
  isLoading: boolean;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  paymentSources: ListPaymentSourcesSelectors.getData(),
  isSuccess: DeletePaymentSourceSelectors.isSuccess(),
  isLoading: DeletePaymentSourceSelectors.isLoading(),
});

const ConfirmDeleteCardModal: FC = () => {
  const { paymentSources, isSuccess, isLoading } = useSelector(mapReduxState);

  const dispatch = useDispatch();
  const close = () => dispatch(closeModal(Modals.ConfirmDeleteCard));
  const deletePaymentSource = (id: string) => dispatch(MyPaymentSourcesDeleteActions.request(id));
  const resetDelete = () => dispatch(MyPaymentSourcesDeleteActions.reset());

  const history = useHistory();
  const query = useQuery();
  const id = query.get('id');

  const handleSubmit = () => {
    deletePaymentSource(id);
  };

  const handleClose = () => {
    close();
    history.replace({ search: '' });
    resetDelete();
  };

  useBooleanEffect(isSuccess, () => {
    handleClose();
  });

  const cardData = paymentSources.find((paymentSource) => paymentSource.get('id') === id);
  const cardObject = new PaymentSourceObject(cardData);

  return (
    <div>
      <HeaderSpan>
        <Header>
          <FormattedMessage {...Messages.removeCard} />
        </Header>
      </HeaderSpan>
      <Form>
        <p className="darkGrey">
          <FormattedMessage {...Messages.areYouSure} />
        </p>
        <CardDetails>
          <CreditCardIcon brand={cardObject.brand} />{' '}
          <span className="darkGrey">
            {HiddenCardNumbers} {cardObject.last4}
          </span>
        </CardDetails>
        <ButtonWrapper>
          <Button title={<FormattedMessage {...Messages.cancel} />} className="clearBackground" onClick={handleClose} />
          <Button title={<FormattedMessage {...Messages.yesRemove} />} onClick={handleSubmit} isLoading={isLoading} />
        </ButtonWrapper>
      </Form>
    </div>
  );
};

export default ConfirmDeleteCardModal;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const HeaderSpan = styled.span`
  display: inline-block;

  @media (min-width: ${mediaQueries.m_mobile}) {
    display: flex;
  }
`;

const Header = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding-right: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 2rem;

  button:first-child {
    margin-right: 1rem;
  }
`;

const CardDetails = styled.span`
  padding-top: 3rem;
  display: flex;
  align-items: center;
`;
