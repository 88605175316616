/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const LocationIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9545 5.72727C11.9545 7.36388 11.0969 8.95364 10.0066 10.3029C8.92367 11.6431 7.66195 12.6833 6.96485 13.2114C6.82164 13.3199 6.63291 13.3199 6.4897 13.2114C5.7926 12.6833 4.53088 11.6431 3.44792 10.3029C2.35763 8.95364 1.5 7.36388 1.5 5.72727C1.5 4.34091 2.05073 3.01134 3.03103 2.03103C4.01134 1.05073 5.34091 0.5 6.72727 0.5C8.11363 0.5 9.44321 1.05073 10.4235 2.03103C11.4038 3.01134 11.9545 4.34091 11.9545 5.72727Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.72727 7.63636C7.78163 7.63636 8.63636 6.78163 8.63636 5.72727C8.63636 4.67291 7.78163 3.81818 6.72727 3.81818C5.67291 3.81818 4.81818 4.67291 4.81818 5.72727C4.81818 6.78163 5.67291 7.63636 6.72727 7.63636Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default LocationIcon;
