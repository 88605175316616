/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { defaultTheme } from '../../styles/themes';
import { status } from '../../styles';

type Props = {
  color?: string;
  width?: string;
};

const WaitlistIcon: FC<Props> = ({ width = defaultTheme.size.iconSize, color = status.waitlist }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <circle cx="10" cy="5.5" r="3.5" stroke={color} />
        <line x1="0.5" y1="4" x2="4.5" y2="4" stroke={color} strokeLinecap="round" />
        <line x1="0.5" y1="8" x2="4.5" y2="8" stroke={color} strokeLinecap="round" />
        <line x1="0.5" y1="12" x2="9.5" y2="12" stroke={color} strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default WaitlistIcon;
