import EventEmitter from 'eventemitter3';

import { AuthToken } from 'zo-data-layer/selectors';
import Storage from './Storage';
import { useState, useEffect } from 'react';
import { Token } from 'auth';

/**
 * Representation of an access token just with null values.
 *
 * @type {{access_token: *, token_type: *, expires_in: *, refresh_token: *, created_at: *}}
 */
const nullToken: AuthToken = {
  access_token: null,
  token_type: null,
  expires_in: null,
  refresh_token: null,
  created_at: null,
};

/**
 * AccessToken is an interface for saving and interacting with the user's
 * access token.
 */
class AccessToken {
  tokenStorageKey: string;
  tokenStorageBackupKey: string;
  storage: Storage;
  token: AuthToken;
  emitter = new EventEmitter();

  constructor(token = nullToken) {
    /**
     * The identifying key for persisted storage.
     */
    this.tokenStorageKey = 'zoToken';
    this.tokenStorageBackupKey = 'zoTokenBackup';

    /**
     * The storage used to persist data.
     */
    this.storage = new Storage();

    /**
     * Responsible for holding the access tokens information.
     */
    this.token = token;
  }

  /**
   * Checks if the access token has expired.
   */
  isExpired(): boolean {
    return this.expiredAt() <= Date.now() / 1000;
  }

  /**
   * Checks if the access token is NOT expired.
   */
  isNotExpired(): boolean {
    return !this.isExpired();
  }

  /**
   * Checks if the access token is NOT null.
   */
  isNotNull(): boolean {
    return this.get().access_token !== null;
  }

  /**
   * Checks if the access token is an authenticated token.
   */
  isAuthenticated(): boolean {
    return this.isNotNull() && this.isNotExpired();
  }

  /**
   * Calculates the expired at time for the access token by adding the
   * created_at and expires_in properties.
   *
   * @returns {number}
   */
  expiredAt(): number {
    if (this.token.created_at && this.token.expires_in) {
      return this.token.created_at + this.token.expires_in;
    }
    return 0;
  }

  /**
   * Get the token object.
   */
  get(): AuthToken {
    // If the in memory access_token is null, then we try to pull it from localStorage.
    if (this.token.access_token === null) {
      this.token = this.getFromStorage() || this.token;
    }
    return this.token;
  }

  get accessToken(): string | null {
    return this.get()?.access_token;
  }

  get refreshToken(): string | null {
    return this.get().refresh_token;
  }

  /**
   * Get the backup token object.
   *
   * @returns {this.token}
   */
  getBackupToken(): AuthToken {
    return this.storage.get(this.tokenStorageBackupKey);
  }

  get createdAt(): number | null {
    return this.get().created_at;
  }

  get expiresIn(): number | null {
    return this.get().expires_in;
  }

  get tokenType(): string | null {
    return this.get().token_type;
  }

  /**
   * Sets the token property, and runs a call back which by default will
   * persist the token to Storage.
   */
  public set(token: AuthToken) {
    this.token = token;
    this.persist();
    this.emitter.emit('token-set', this.token);
  }

  public onTokenSet(callback) {
    this.emitter.on('token-set', callback);
  }

  public offTokenSet(callback) {
    this.emitter.removeListener('token-set', callback);
  }

  public onTokenDestroy(callback) {
    this.emitter.on('token-destroyed', callback);
  }

  public offTokenDestroy(callback) {
    this.emitter.removeListener('token-destroyed', callback);
  }

  /**
   * Removes the token.
   */
  destroy(): void {
    this.token = nullToken;
    this.storage.remove(this.tokenStorageKey);
    this.emitter.emit('token-destroyed');
  }

  /**
   * Persists the token to Storage. When successfully persisting to Storage,
   * the function will return true. If it fails to persist to storage, null
   * is returned.
   */
  private persist(): boolean | null {
    return this.storage.set(this.tokenStorageKey, this.token);
  }

  private getFromStorage(): AuthToken | null {
    return this.storage.get(this.tokenStorageKey);
  }
}

const accessToken = new AccessToken();

export const useAuthenticated = () => {
  const [authenticated, setAuthenticated] = useState(() => Token.isAuthenticated());
  useEffect(() => {
    const onSetListener = () => setAuthenticated(Token.isAuthenticated());
    const onDestroyListener = () => setAuthenticated(false);

    Token.onTokenSet(onSetListener);
    Token.onTokenDestroy(onDestroyListener);

    return () => {
      Token.offTokenDestroy(onDestroyListener);
      Token.offTokenSet(onSetListener);
    };
  }, []);

  return authenticated;
};

export default accessToken;
