/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from '../../styles';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const ShareIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7227 12.3892C13.9449 10.8336 12.9371 10.1214 12.3604 9.80627C11.7836 9.49116 11.1458 9.30378 10.4903 9.25683C9.83472 9.20988 9.17674 9.30446 8.56096 9.53417C7.94518 9.76387 7.38601 10.1233 6.92136 10.5881L4.17136 13.3381C3.33647 14.2026 2.8745 15.3603 2.88494 16.5621C2.89538 17.7638 3.37741 18.9133 4.22719 19.7631C5.07698 20.6129 6.22654 21.0949 7.42827 21.1054C8.63001 21.1158 9.78777 20.6539 10.6522 19.819L13.7753 16.6958"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.10782 11.7392C9.88564 13.2948 10.8934 14.007 11.4702 14.3221C12.047 14.6373 12.6848 14.8246 13.3403 14.8716C13.9958 14.9185 14.6538 14.824 15.2696 14.5943C15.8854 14.3645 16.4446 14.0051 16.9092 13.5403L19.6592 10.7903C20.4941 9.92586 20.9561 8.7681 20.9456 7.56636C20.9352 6.36463 20.4532 5.21507 19.6034 4.36528C18.7536 3.5155 17.604 3.03347 16.4023 3.02303C15.2006 3.01259 14.0428 3.47456 13.1784 4.30945L10.0552 7.43259"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShareIcon;
