/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const EditIcon: FC<Props> = ({ color = neutral.white, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3333 2.00001C11.5084 1.82491 11.7163 1.68602 11.9451 1.59126C12.1738 1.4965 12.419 1.44772 12.6666 1.44772C12.9143 1.44772 13.1595 1.4965 13.3882 1.59126C13.617 1.68602 13.8249 1.82491 14 2.00001C14.1751 2.1751 14.314 2.38297 14.4087 2.61175C14.5035 2.84052 14.5523 3.08572 14.5523 3.33334C14.5523 3.58096 14.5035 3.82616 14.4087 4.05494C14.314 4.28371 14.1751 4.49158 14 4.66668L4.99998 13.6667L1.33331 14.6667L2.33331 11L11.3333 2.00001Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default EditIcon;
