/** External Dependencies */
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
/** Interal dependencies */
import Messages from './Messages';
import { AppLinks } from 'constants/routes';
import { Modals } from 'constants/Layouts';
/** Data layer */
import {
  MyBookingsGetActions,
  MyScheduledServiceBookingGetActions,
  MyWaitlistGetActions,
  openModal,
} from 'zo-data-layer/actions';
import {
  MyBookingsGetSelectors,
  MyScheduledServiceBookingGetSelectors,
  MyWaitlistGetSelectors,
} from 'zo-data-layer/selectors';
import GenericTimeBasedBooking from 'zo-data-layer/dataobjects/GenericTimeBasedBooking';
import { BooleanSelector, ImmutableStateOutputSelector } from 'zo-data-layer/utils/selectors';
import { BookingType } from 'zo-data-layer/constants/booking';
import EventBooking from 'zo-data-layer/dataobjects/EventBooking';
/** Components */
import LoadingIndicator from 'components/LoadingIndicator';
import InfoPointList, { InfoPoint } from 'components/InfoPointList';
import { CalendarIcon, CancelIcon, PlayIcon, VirtualIcon } from 'components/Icons';
import BookingInfoBlock from 'components/ServiceBookingFlow/BookingInfoBlock';
import ModalContainer from 'components/ModalContainer';
import AddToCalendar from 'components/AddToCalendar';
import CancelBookingModal from 'components/CancelBookingModal';
import VideoModal from 'components/VideoModal';
import { RouteWrapper } from './MyPageContainer';
import ChevronRight from 'components/Icons/ChevronRight';
import { useAnalytics } from 'analytics';
/** Styling */
import { brand, defaultTheme, mediaQueries, miscColors, neutral } from 'styles';
import { isAfter, isBefore, sub } from 'date-fns';
import Button from 'components/Button';
import AnalyticsEvents from 'zo-data-layer/constants/AnalyticsEvents';
import ShareButton from 'components/ShareButton';

const isBookingLoadingSelector = MyBookingsGetSelectors.isLoading();
const bookingSelector = MyBookingsGetSelectors.getBooking();
const isWaitlistLoadingSelector = MyWaitlistGetSelectors.isLoading();
const waitlistSelector = MyWaitlistGetSelectors.getWaitlist();
const isScheduledBookingLoadingSelector = MyScheduledServiceBookingGetSelectors.isLoading();
const scheduledSelector = MyScheduledServiceBookingGetSelectors.getBooking();

type RouteParams = {
  bookingType: BookingType;
  bookingId: string;
};

const getLoadingSelector = (bookingType: BookingType): BooleanSelector => {
  if (bookingType === BookingType.Waitlist) {
    return isWaitlistLoadingSelector;
  } else if (bookingType === BookingType.ScheduledService) {
    return isScheduledBookingLoadingSelector;
  } else {
    return isBookingLoadingSelector;
  }
};

const getBookingSelector = (bookingType: BookingType): ImmutableStateOutputSelector<GenericTimeBasedBooking> => {
  if (bookingType === BookingType.Waitlist) {
    return waitlistSelector;
  } else if (bookingType === BookingType.ScheduledService) {
    return scheduledSelector;
  } else {
    return bookingSelector;
  }
};

const MyBookingDetails = () => {
  const { bookingType, bookingId } = useParams<RouteParams>();
  const analytics = useAnalytics();

  const dispatch = useDispatch();
  useEffect(() => {
    if (bookingId) {
      if (bookingType === BookingType.Waitlist) {
        dispatch(MyWaitlistGetActions.request(parseInt(bookingId, 10)));
      } else if (bookingType === BookingType.ScheduledService) {
        dispatch(MyScheduledServiceBookingGetActions.request(parseInt(bookingId, 10)));
      } else if (bookingType === BookingType.Booking) {
        dispatch(MyBookingsGetActions.request(parseInt(bookingId, 10)));
      }
    }
  }, [bookingId, bookingType, dispatch]);

  const isBookingLoading = useSelector(getLoadingSelector(bookingType));
  const bookingData = useSelector(getBookingSelector(bookingType));

  if (isBookingLoading || !bookingData) {
    return (
      <RouteWrapper>
        <LoadingIndicator />
      </RouteWrapper>
    );
  }

  const handleClickCancel = () => {
    dispatch(openModal(Modals.CancelBooking));
  };

  const handleClickAddToCalendar = () => {
    dispatch(openModal(Modals.Calendar));
  };

  const handleClickJoinEvent = () => {
    dispatch(openModal(Modals.Video));
  };

  const logVideoAnalytics = () => {
    analytics.logServiceEvent(AnalyticsEvents.VideoViewed, bookingData);
  };

  const onWaitlist = bookingType === BookingType.Waitlist;

  const currentTime = new Date();
  const joinTime = sub(bookingData.startTime, { minutes: 15 });
  const isLivestreamAvailable =
    bookingData.isVirtual() &&
    isAfter(currentTime, joinTime) &&
    isBefore(currentTime, bookingData.endTime) &&
    !onWaitlist;
  const eventBooking = bookingData as EventBooking;
  const isLeaderSection = eventBooking.isAdminOnly;
  const isCancelled = eventBooking.status === 'cancelled';
  const isRefundPending = eventBooking.status === 'refund_pending';
  const isRefunded = eventBooking.status === 'refunded';

  return (
    <RouteWrapper>
      <TopSection>
        {bookingData.isVirtual() && (
          <LocationTypeText className="bold purple">
            <VirtualIcon width={defaultTheme.size.serviceDetailIconSize} color="black" />
            {bookingData.isZoom ? (
              <FormattedMessage {...Messages.zoomEvent} />
            ) : (
              <FormattedMessage {...Messages.livestream} />
            )}
          </LocationTypeText>
        )}
        <TitleHeader>
          <TitleSection>
            <H2>{bookingData.title}</H2>
            <FormattedMessage {...Messages.providedBy} /> {bookingData.partner}
          </TitleSection>
          <ShareButton service={bookingData} />
        </TitleHeader>
      </TopSection>
      <Columns>
        <LeftColumn>
          <BookingInfoWrapper>
            <BookingInfoBlock booking={bookingData} />
          </BookingInfoWrapper>
          {bookingData.enrollmentId !== undefined && bookingData.enrollmentId !== null && (
            <StyledLink
              to={
                isLeaderSection
                  ? `${AppLinks.leaderView}${AppLinks.serviceInfo}${bookingData.enrollmentId}`
                  : `${AppLinks.serviceInfo}${bookingData.enrollmentId}`
              }
            >
              <FormattedMessage {...Messages.seeAllEventDetails} />
              <ChevronRight width="1rem" color={brand.brightGreen} padding="0 0 0 1rem" />
            </StyledLink>
          )}
        </LeftColumn>
        <RightColumn>
          {!isCancelled && !isRefundPending && !isRefunded && (
            <InfoHeader>
              <FormattedMessage {...Messages.actions} />
            </InfoHeader>
          )}
          <InfoPointList>
            {bookingData.isEventBooking() && bookingData.isVirtual() && (
              <StyledInfoPoint>
                <ButtonContainer>
                  <StyledButton
                    buttonType={!bookingData.isVimeoLivestream ? 'a' : undefined}
                    icon={<PlayIcon color={neutral.white} fill={neutral.white} />}
                    title={<FormattedMessage {...Messages.join} />}
                    isDisabled={!isLivestreamAvailable}
                    linkTo={isLivestreamAvailable ? bookingData.joiningEvent() : null}
                    onClick={handleClickJoinEvent}
                  />
                </ButtonContainer>
              </StyledInfoPoint>
            )}
            {!isCancelled && !isRefundPending && !isRefunded && (
              <>
                <StyledInfoPoint className="bold purple" onClick={handleClickAddToCalendar}>
                  <CalendarIcon width={defaultTheme.size.serviceDetailIconSize} color={brand.brightGreen} />
                  <FormattedMessage {...Messages.addToCalendar} />
                </StyledInfoPoint>
                <StyledInfoPoint className="bold cancelOrange" onClick={handleClickCancel}>
                  <CancelIcon width={defaultTheme.size.serviceDetailIconSize} color={miscColors.cancelIcon} />
                  <FormattedMessage {...Messages.cancelBooking} />
                </StyledInfoPoint>
              </>
            )}
          </InfoPointList>
        </RightColumn>
      </Columns>
      <ModalContainer name={Modals.Calendar}>
        <AddToCalendar booking={bookingData} />
      </ModalContainer>
      <ModalContainer name={Modals.CancelBooking}>
        <CancelBookingModal backOnCancel bookingType={bookingType} bookingId={bookingData.id.toString()} />
      </ModalContainer>
      {bookingData.isEventBooking() && (
        <ModalContainer name={Modals.Video} fullModal opacity={0.9} onOpen={logVideoAnalytics}>
          <VideoModal videoId={bookingData.eventVimeoVideoId} hasLivestreamChat={bookingData.hasLivestreamChat} />
        </ModalContainer>
      )}
    </RouteWrapper>
  );
};

export default MyBookingDetails;

const Columns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${mediaQueries.m_mobile}) {
    flex-direction: row;
  }
`;

const LeftColumn = styled.div`
  width: 100%;
  padding: 0;

  @media (min-width: ${mediaQueries.m_mobile}) {
    width: 50%;
    padding: 3rem ${defaultTheme.size.twoColumnGapWidth} 3rem 0;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  padding: 0;

  @media (min-width: ${mediaQueries.m_mobile}) {
    width: 50%;
    padding: 3rem 0 3rem ${defaultTheme.size.twoColumnGapWidth};
  }
`;

const TitleHeader = styled.div`
  display: flex;
`;

const TopSection = styled.div`
  padding-bottom: 2rem;
  border-bottom: 1px solid ${miscColors.divider};
`;

const TitleSection = styled.div`
  flex-grow: 1;
`;

const BookingInfoWrapper = styled.div`
  padding-bottom: 3rem;
`;

const H2 = styled.h2`
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const StyledLink = styled(Link)`
  color: ${brand.brightGreen};
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-self: flex-end;
  padding-bottom: 1rem;
`;

const StyledInfoPoint = styled(InfoPoint)`
  margin: 1.2rem -1rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 20em;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const InfoHeader = styled.div`
  font-weight: 400;
  margin-bottom: 1.5rem;
`;

const LocationTypeText = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  & > svg {
    margin-right: ${({ theme }) => theme.spacing.layoutPaddingSm};
  }
`;
