import { defineMessages, MessageDescriptor } from 'react-intl';

const newServices: MessageDescriptor = {
  id: 'discover.newServices',
  defaultMessage: 'Trending',
};

const todayServices: MessageDescriptor = {
  id: 'discover.todayServices',
  defaultMessage: "Today's Services",
};

const upcomingServices: MessageDescriptor = {
  id: 'discover.upcomingServices',
  defaultMessage: 'Upcoming Services',
};

const allServices: MessageDescriptor = {
  id: 'discover.allServices',
  defaultMessage: 'All Services',
};

const discover: MessageDescriptor = {
  id: 'discover.discover',
  defaultMessage: 'Events & Services',
};

const hello: MessageDescriptor = {
  id: 'discover.hello',
  defaultMessage: 'Hello',
};

const workDay: MessageDescriptor = {
  id: 'discover.workDay',
  defaultMessage: 'work day.',
};

export default defineMessages({
  discover,
  newServices,
  todayServices,
  upcomingServices,
  allServices,
  hello,
  workDay,
});
