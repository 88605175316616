import { defineMessages, MessageDescriptor } from 'react-intl';

const signOut: MessageDescriptor = {
  id: 'nav.signOut',
  defaultMessage: 'Sign Out',
};

const signIn: MessageDescriptor = {
  id: 'forms.signIn',
  defaultMessage: 'Sign In',
};

const yourZO: MessageDescriptor = {
  id: 'nav.yourZo',
  defaultMessage: 'Your ZO',
};

const zoBusiness: MessageDescriptor = {
  id: 'nav.zoBusiness',
  defaultMessage: 'Zo. for Business',
};

const home: MessageDescriptor = {
  id: 'nav.home',
  defaultMessage: 'Home',
};

const signUp: MessageDescriptor = {
  id: 'forms.signUpToday',
  defaultMessage: 'Sign Up for ZO',
};

export default defineMessages({
  signOut,
  signIn,
  signUp,
  yourZO,
  zoBusiness,
  home,
});
