/** External Dependencies */
import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { FieldElement, Message } from 'react-hook-form';

/** Styling */
import { typeScale } from '../../styles';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | JSX.Element;
  error?: Message;
  innerRef?: (ref: FieldElement<any>) => void;
};

const Checkbox: FC<Props> = ({ id, label, error, innerRef, ...inputProps }) => (
  <Div>
    <Wrapper>
      <Input id={id} ref={innerRef} {...inputProps} type="checkbox" />
      <Label htmlFor={id}>{label}</Label>
    </Wrapper>
    <ErrorDiv>{error}</ErrorDiv>
  </Div>
);

const Wrapper = styled.div`
  display: flex;
`;

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.status.error};
  font-size: ${typeScale.helperText};
  height: ${typeScale.helperText};
  padding-top: 0.2rem;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
`;

const Label = styled.label`
  font-size: ${typeScale.helperText};
  margin-right: 1rem;
`;

const Input = styled.input`
  font-size: ${typeScale.paragraph};
  margin-right: 0.5rem;
`;

export default Checkbox;
