import { useEffect } from 'react';

/**
 * A helper method for 'useEffect' which accepts a boolean
 * argument.  The effectFunc will run when `booleanValue` is truthy.
 * @param booleanValue
 * @param effectFunc
 */
const useBooleanEffect = (booleanValue: boolean, effectFunc: () => unknown) => {
  useEffect(() => {
    if (booleanValue) {
      effectFunc();
    }
    // eslint-disable-next-line
  }, [booleanValue]);
};

export default useBooleanEffect;
