/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { defaultTheme, brand } from 'styles';

type Props = {
  color?: string;
  width?: string;
  filled?: boolean;
};

const SuccessIcon: FC<Props> = ({ color = brand.success, filled = false, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg
      width={width}
      height={width}
      viewBox="0 0 14 14"
      fill={filled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="6.5" stroke={color} />
      <path d="M4 7.25L5.84615 9.5L10 4.5" stroke={filled ? 'white' : color} strokeLinecap="round" />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default SuccessIcon;
