/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** Internal dependencies */
import Messages from './Messages';

/** Styling */
import { defaultTheme } from '../../styles/themes';

/** Components */
import { PlusButtonIcon, MinusButtonIcon, QuantityIcon } from '../Icons';

/** Data layer */
import MyService from 'zo-data-layer/dataobjects/MyService';

type Props = {
  service: MyService;
  quantity: number;
  setQuantity: (quantity: number) => void;
};

const QuantityPicker: FC<Props> = ({ service, quantity, setQuantity }) => {
  const isQuantityMaxed = quantity === service.maxBookingsPerUser;
  const isQuantityMin = quantity === 1;

  const handleIncrementQuantity = () => {
    !isQuantityMaxed && setQuantity(Math.min(quantity + 1, service.maxBookingsPerUser));
  };

  const handleDecrementQuantity = () => {
    !isQuantityMin && setQuantity(Math.max(quantity - 1, 1));
  };

  return (
    <QuantityCounter>
      <QuantityLabel>
        <QuantityIcon
          width={defaultTheme.size.quantityIconSize}
          color={isQuantityMaxed ? defaultTheme.colors.darkGrey : defaultTheme.colors.black}
        />
        <QuantityText isDisabled={isQuantityMaxed}>
          <FormattedMessage {...Messages.quantity} />
          <QuantitySubText isDisabled={isQuantityMaxed}>
            <FormattedMessage {...Messages.maxAvailable} />
          </QuantitySubText>
        </QuantityText>
      </QuantityLabel>

      <QuantityButtons>
        <MinusButtonIcon
          width={defaultTheme.size.buttonIconSize}
          color={isQuantityMin ? defaultTheme.colors.darkGrey : defaultTheme.colors.black}
          onClick={() => handleDecrementQuantity()}
        />
        <Quantity isDisabled={isQuantityMaxed}>{quantity}</Quantity>
        <PlusButtonIcon
          width={defaultTheme.size.buttonIconSize}
          color={isQuantityMaxed ? defaultTheme.colors.darkGrey : defaultTheme.colors.black}
          onClick={() => handleIncrementQuantity()}
        />
      </QuantityButtons>
    </QuantityCounter>
  );
};

type CounterProps = {
  isDisabled: boolean;
};

const Quantity = styled.span<CounterProps>`
  color: ${(props) => (props.isDisabled ? defaultTheme.colors.darkGrey : defaultTheme.colors.black)};
`;

const QuantityCounter = styled.span`
  color: ${(props) => props.theme.colors.black};
  display: flex;
  justify-content: space-between;
  padding: 3rem 0;
`;

const QuantityLabel = styled.span`
  display: flex;
  flex-direction: row;
  line-height: 1.5rem;
  width: 50%;
  padding-bottom: 1rem;
`;

const QuantityText = styled.span<CounterProps>`
  display: flex;
  flex-direction: column;
  color: ${(props) => (props.isDisabled ? defaultTheme.colors.darkGrey : defaultTheme.colors.black)};
`;

const QuantitySubText = styled.sub<CounterProps>`
  display: ${(props) => (props.isDisabled ? 'flex' : 'none')};
  color: ${(props) => (props.isDisabled ? defaultTheme.colors.darkGrey : defaultTheme.colors.black)};
  padding-top: 0.5rem;
`;

const QuantityButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  line-height: 2rem;
  width: ${defaultTheme.size.twoColumnGapWidth};
`;

export default QuantityPicker;
