/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from '../../styles/colors';

type Props = {
  color?: string;
  width?: number;
  className?: string;
};

const CheckIcon: FC<Props> = ({ color = neutral.grey, width = 14, className }) => (
  <svg
    className={className}
    width={width}
    height={width * 0.8}
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path opacity="0.5" d="M1 4.54545L3.67893 7L9 1" stroke={color} />
  </svg>
);

export default CheckIcon;
