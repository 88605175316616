/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/** Internal Dependencies */
import useBooleanEffect from 'hooks/useBooleanEffect';

/** Data layer */
import { MyBookingsGetActions, MyWaitlistGetActions } from 'zo-data-layer/actions';
import { MyWaitlistGetSelectors } from 'zo-data-layer/selectors';
import { BookingType } from 'zo-data-layer/constants/booking';
import EventWaitlist from 'zo-data-layer/dataobjects/EventWaitlist';

/** Components */
import SuccessPage from './SuccessPage';

type RouteParams = {
  bookingId: string;
};

type SelectorProps = {
  isWaitlistSuccess: boolean;
  waitlist: EventWaitlist;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  isWaitlistSuccess: MyWaitlistGetSelectors.isSuccess(),
  waitlist: MyWaitlistGetSelectors.getWaitlist(),
});

const WaitlistSuccessPage: FC = () => {
  const { isWaitlistSuccess, waitlist } = useSelector(mapReduxState);
  const { bookingId } = useParams<RouteParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MyWaitlistGetActions.request(parseInt(bookingId, 10)));
  }, [bookingId, dispatch]);

  useBooleanEffect(isWaitlistSuccess, () => {
    dispatch(MyBookingsGetActions.reset());
  });

  return (
    <SuccessPage
      currentBooking={waitlist}
      bookingType={BookingType.Waitlist}
      successId={bookingId}
      isSuccess={isWaitlistSuccess}
    />
  );
};

export default WaitlistSuccessPage;
