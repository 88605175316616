/** External dependencies */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { MeSelectors } from 'zo-data-layer/selectors';

/** Internal dependencies */
import { useAuthenticated } from '../../auth';
import { AppLinks } from '../../constants/routes';

type SelectorProps = {
  isMeLoaded: boolean;
  isStaff: boolean;
  isLeader: boolean;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  isMeLoaded: MeSelectors.isSuccess(),
  isStaff: MeSelectors.isStaff(),
  isLeader: MeSelectors.isLeader(),
});

const AdminRoute: FC<RouteProps> = ({ children, ...props }) => {
  const { isMeLoaded, isStaff, isLeader } = useSelector(mapReduxState);
  const authenticated = useAuthenticated();

  if (!authenticated) return <Redirect to={AppLinks.login} />;
  if (isMeLoaded && !(isStaff || isLeader)) return <Redirect to={AppLinks.discover} />;

  return <Route {...props}>{children}</Route>;
};

export default AdminRoute;
