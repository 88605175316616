import LoadingIndicatorComponent from 'components/LoadingIndicator';
import React from 'react';
import styled from 'styled-components';

export const LoadingIndicator = () => (
  <LoadingIndicatorContainer>
    <LoadingIndicatorComponent />
  </LoadingIndicatorContainer>
);

const LoadingIndicatorContainer = styled.div`
  padding: 2rem 0;
`;
