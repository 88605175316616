import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

import { typeScale } from './typography';
import { neutral, brand, miscColors } from 'styles';
import animations from '../styles/animations';

export const GlobalStyle = createGlobalStyle`
${normalize()}

html {
  box-sizing: border-box;
  font-size: 16px;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  font-family: Questrial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  width: 90%;
  margin: 0 auto;
}

h1, h2, h3 {
  letter-spacing: -1px;
}

h1, h2 {
  font-weight: 400;

  &.hero {
    font-size: ${typeScale.hero};
    font-weight: 400;
  }

  &.h0 {
    font-size: ${typeScale.header0};
  }
}

h3, h4, h5 {
  font-weight: 400;
}

h1 {
  font-size: ${typeScale.header1}
}

h2 {
  font-size: ${typeScale.header2}
}

h3 {
  font-size: ${typeScale.header3}
}

h4 {
  font-size: ${typeScale.header4}
}

h5 {
  font-size: ${typeScale.header5}
}

p {
  font-size: ${typeScale.paragraph}
}

a {
  color: black;
  text-decoration: none;
}

mark {
  background-color: transparent;
}

.darkGrey {
  color: ${neutral.darkGrey};
}

.purple {
  color: ${brand.brightGreen};
}

.cancelOrange {
  color: ${miscColors.cancelIcon};
}

.bold {}

${({ theme }) => animations(theme)}
`;
