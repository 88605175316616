import { defineMessages, MessageDescriptor } from 'react-intl';

const forgotPassword: MessageDescriptor = {
  id: 'loginPage.forgotPassword',
  defaultMessage: 'Forgot password?',
};

const learnMore: MessageDescriptor = {
  id: 'loginPage.learnMore',
  defaultMessage: 'Learn More',
};

const noAccount: MessageDescriptor = {
  id: 'loginPage.noAccount',
  defaultMessage: 'No account?',
};

const signUpNow: MessageDescriptor = {
  id: 'loginPage.signUpNow',
  defaultMessage: 'Sign Up now',
};

const sessionExpired: MessageDescriptor = {
  id: 'loginPage.sessionExpired',
  defaultMessage: 'Your session has expired. Please login.',
};

export default defineMessages({
  forgotPassword,
  learnMore,
  noAccount,
  signUpNow,
  sessionExpired,
});
