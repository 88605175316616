/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  className?: string;
  color?: string;
  width?: string;
};

const CancelIcon: FC<Props> = ({ className, color = neutral.darkBlue, width = defaultTheme.size.iconSize }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="18.7692" y="4" width="1.5" height="20.8868" rx="0.75" transform="rotate(45 18.7692 4)" fill={color} />
    <rect
      width="1.5"
      height="20.8868"
      rx="0.75"
      transform="matrix(-0.707107 0.707107 0.707107 0.707107 5.23077 4)"
      fill={color}
    />
  </svg>
);

export default CancelIcon;
