import { defineMessages, MessageDescriptor } from 'react-intl';

const selectDateTime: MessageDescriptor = {
  id: 'scheduledAvailabilityPicker.selectDateTime',
  defaultMessage: 'Select Date and Time',
};

const minute: MessageDescriptor = {
  id: 'scheduledAvailabilityPicker.minute',
  defaultMessage: '{minute} min',
};

const book: MessageDescriptor = {
  id: 'scheduledAvailabilityPicker.book',
  defaultMessage: 'Book',
};

const noAvailability: MessageDescriptor = {
  id: 'scheduledAvailabilityPicker.noAvailability',
  defaultMessage: 'No Availability on this date, please choose another date',
};

export default defineMessages({
  selectDateTime,
  minute,
  book,
  noAvailability,
});
