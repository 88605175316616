/** External Dependencies */
import React, { FC, useContext } from 'react';
import styled from 'styled-components';

/** Interal Dependencies */
import { LocaleContext } from 'containers/IntlContainer';

/** Components */
import ZoLogo from 'components/Icons/ZoLogo';
import NotReadIcon from 'components/Icons/NotReadIcon';
import CloudinaryImage from 'components/CloudinaryImage';
import { StyledTileProps } from '../Tile';

/** Data layer */
import UserActivity from 'zo-data-layer/dataobjects/UserActivity';
import { CloudinaryImageConfigs } from 'zo-data-layer/constants/cloudinary';

/** Styling */
import { brand, typeScale, miscColors } from 'styles';

type Props = {
  alert: UserActivity;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const AlertTile: FC<Props> = ({ alert, onClick }) => {
  const { currentLocale: locale } = useContext(LocaleContext);

  const renderImage = () => {
    if (alert.cloudinaryImageId)
      return (
        <StyledCloudinaryImage publicId={alert.cloudinaryImageId} transformation={CloudinaryImageConfigs.serviceTile} />
      );
    return (
      <LogoWrapper>
        <ZoLogo color={brand.brightGreen} />
      </LogoWrapper>
    );
  };

  return (
    <Tile onClick={onClick} cursor={onClick ? 'pointer' : 'auto'}>
      <LeftSide>
        <IconDiv>{!alert.isRead && <NotReadIcon />}</IconDiv>
        <Image>{renderImage()}</Image>
        <DetailsText>
          <Title>{alert.headingForLanguage(locale) || alert.defaultHeading}</Title>
          <Details className="darkGrey">{alert.contentForLanguage(locale) || alert.defaultContent}</Details>
        </DetailsText>
      </LeftSide>
      <RightSide>
        <DateTime className="darkGrey">{alert.formattedCreatedTime(locale)}</DateTime>
        <DateTime className="darkGrey">{alert.formattedCreatedDate(locale)}</DateTime>
      </RightSide>
    </Tile>
  );
};

const Tile = styled.div<StyledTileProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid ${miscColors.divider};
  padding: 1.5rem 0;
  cursor: ${({ cursor }) => cursor};
`;

const StyledCloudinaryImage = styled(CloudinaryImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px 0 0 4px;
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  margin-left: -1.5rem;
`;

const DetailsText = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

const RightSide = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const DateTime = styled.div`
  padding-top: 1rem;
  font-size: 0.75rem;
`;

const Image = styled.div`
  border-radius: 8px;
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${miscColors.backgroundGrey};
`;

const LogoWrapper = styled.div`
  width: 2.5625rem;
`;

const Title = styled.p`
  margin: 0 0 1rem 0;
`;

const Details = styled.div`
  width: 100%;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: ${typeScale.font14};
`;

const IconDiv = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  max-height: 5rem;
  width: 1.5rem;
`;

export default AlertTile;
