import { useEffect, useState, useCallback, useRef } from 'react';
import debounce from 'debounce-promise';

const useSticky = () => {
  const [isSticky, setSticky] = useState(false);
  const elementRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (elementRef.current) {
      window.scrollY > elementRef.current.getBoundingClientRect().bottom ? setSticky(true) : setSticky(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', debounce(handleScroll, 10));
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [handleScroll]);

  return { isSticky, elementRef };
};

export default useSticky;
