/** External Dependencies */
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Image, Transformation } from 'cloudinary-react';

/** Internal Dependencies */
import ZoLogo from 'components/ZoLogo';

/** Styling */
import { brand, miscColors } from 'styles';

type Props = {
  isTile?: boolean;
  isVideo?: boolean;
  publicId?: string;
  className?: string;
  maxWidth?: string;
  width?: string | number;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  renderPlaceholder?: () => JSX.Element;
  transformation?: {
    aspectRatio?: number;
    quality?: string;
    crop?: string;
    width?: string | number;
    height?: string | number;
  };
  colorTransformation?: {
    effect: 'colorize';
    color?: string;
  };
  formatTransformation?: {
    fetchFormat?: 'jpg' | 'png';
  };
};

const CloudinaryImage: FC<Props> = ({
  publicId,
  className,
  transformation,
  colorTransformation,
  formatTransformation,
  width,
  renderPlaceholder = () => <DefaultPlaceholder />,
}) => {
  const [showPlaceholderImg, setShowPlaceholderImg] = useState(!publicId);

  if (showPlaceholderImg) {
    return renderPlaceholder();
  }

  return (
    <>
      <Image className={className} publicId={publicId} secure onError={() => setShowPlaceholderImg(true)} width={width}>
        <Transformation {...transformation} />
        <Transformation {...colorTransformation} />
        <Transformation {...formatTransformation} />
      </Image>
    </>
  );
};

const PlaceholderImg = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: ${miscColors.backgroundGrey};
  display: flex;
  justify-content: center;
  align-items: center;

  &.roundUpperCorners {
    border-radius: 4px 4px 0 0;
  }
`;

const LogoWrapper = styled.div`
  width: ${({ theme }) => theme.size.maxLogoSize};
`;

const DefaultPlaceholder = () => (
  <PlaceholderImg>
    <LogoWrapper>
      <ZoLogo color={brand.brightGreen} linkHome={false} />
    </LogoWrapper>
  </PlaceholderImg>
);

export default CloudinaryImage;
