import { defineMessages, MessageDescriptor } from 'react-intl';

const accessHundreds: MessageDescriptor = {
  id: 'bookingDetails.accessHundreds',
  defaultMessage: 'Access hundreds of events and services through your ZO Membership',
};

const continueBrowsing: MessageDescriptor = {
  id: 'bookingDetails.continueBrowsing',
  defaultMessage: 'Continue Browsing',
};

const booking: MessageDescriptor = {
  id: 'bookingDetails.booking',
  defaultMessage: 'Booking...',
};

const pleaseWait: MessageDescriptor = {
  id: 'bookingDetails.pleaseWait',
  defaultMessage: 'Please wait a moment while we secure your spot!',
};

const youreBooked: MessageDescriptor = {
  id: 'bookingDetails.youreBooked',
  defaultMessage: "You're Booked!",
};

const youreOnTheWaitlist: MessageDescriptor = {
  id: 'bookingDetails.youreOnTheWaitlist',
  defaultMessage: "You're on the Waitlist!",
};

const providedBy: MessageDescriptor = {
  id: 'bookingDetails.providedBy',
  defaultMessage: 'Provided by',
};

const actions: MessageDescriptor = {
  id: 'bookingDetails.actions',
  defaultMessage: 'Actions',
};

const pleaseCheckEmail: MessageDescriptor = {
  id: 'bookingDetails.pleaseCheckEmail',
  defaultMessage: 'This service has already been booked. Please check your email for more information.',
};

const addToCalendar: MessageDescriptor = {
  id: 'addToCalendar.header',
  defaultMessage: 'Add to Calendar',
};

const cancelBooking: MessageDescriptor = {
  id: 'forms.cancelBooking',
  defaultMessage: 'Cancel Booking',
};

const join: MessageDescriptor = {
  id: 'bookingDetails.join',
  defaultMessage: 'Join',
};

export default defineMessages({
  accessHundreds,
  continueBrowsing,
  booking,
  pleaseWait,
  youreBooked,
  youreOnTheWaitlist,
  providedBy,
  actions,
  pleaseCheckEmail,
  addToCalendar,
  cancelBooking,
  join,
  livestream: {
    id: 'myBookingDetails.livestream',
    defaultMessage: 'LIVESTREAM',
  },
  zoomEvent: {
    id: 'myBookingDetails.zoomEvent',
    defaultMessage: 'ZOOM EVENT',
  },
});
