/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
  onClick?: () => void;
};

const PlusButtonIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize, onClick }) => (
  <StyledSpan onClick={onClick}>
    <svg width={width} height={width} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.3" cx="20" cy="20" r="19.5" stroke={color} />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 13C21 12.4477 20.5523 12 20 12C19.4477 12 19 12.4477 19 13V19H13C12.4477 19 12 19.4477 12 20C12 20.5523 12.4477 21 13 21H19V27C19 27.5523 19.4477 28 20 28C20.5523 28 21 27.5523 21 27V21H27C27.5523 21 28 20.5523 28 20C28 19.4477 27.5523 19 27 19H21V13Z"
        fill={color}
      />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  cursor: pointer;
`;

export default PlusButtonIcon;
