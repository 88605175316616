import { defineMessages, MessageDescriptor } from 'react-intl';

const problem: MessageDescriptor = {
  id: 'browserUnsupported.problem',
  defaultMessage: 'Your browser is not supported.',
};

const solution: MessageDescriptor = {
  id: 'browserUnsupported.solution',
  defaultMessage: 'Please use a supported browser:',
};

export default defineMessages({
  problem,
  solution,
});
