/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** InteralDependencies */
import Messages from './Messages';

/** Components */
import InfoIcon from 'components/Icons/InfoIcon';

/** Styling */
import { miscColors, typeScale, defaultTheme, mediaQueries } from 'styles';

const RegisterFooterBlock: FC = () => (
  <GreyFooterWrapper>
    <IconWrapper>
      <InfoIcon width={defaultTheme.size.registerInfoIcon} />
    </IconWrapper>
    <RegisterMessage className="darkGrey">
      <FormattedMessage {...Messages.registerFooterNote} />
    </RegisterMessage>
  </GreyFooterWrapper>
);

const GreyFooterWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${miscColors.backgroundGrey};
  align-items: center;
  justify-content: center;
  font-size: ${typeScale.font12};
  padding: 3rem 1rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 3.5rem 28% 2rem;
    margin-top: 3rem;
  }
`;

const IconWrapper = styled.div`
  padding-right: 0.5rem;
`;

const RegisterMessage = styled.div`
  text-align: start;
  line-height: 1rem;
`;

export default RegisterFooterBlock;
