/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

/** Internal Dependencies */
import { useAuthenticated } from 'auth';
import { isServiceRoute, isLoginPage } from 'utils/navigation';

/** Components */
import Header from '../Header';
import Footer from '../Footer';
import Toast from '../Toast';

/** Styling */
import { mediaQueries, defaultTheme } from 'styles';

type Props = {
  children?: React.ReactNode;
};

const PagesContainer: FC<Props> = ({ children }) => {
  const location = useLocation();
  const isLoggedIn = useAuthenticated();

  const getContentPadding = () => {
    if (isLoginPage(location)) {
      return defaultTheme.size.pageContainerLoginVerticalPadding;
    }
    if (!isLoggedIn && !isLoginPage(location) && !isServiceRoute(location)) {
      return defaultTheme.size.pageContainerRegisterVerticalPadding;
    }
    if (!isLoggedIn && isServiceRoute(location)) {
      return defaultTheme.size.pageContainerPublicServiceVerticalPadding;
    }
    if (isLoggedIn && isServiceRoute(location)) {
      return defaultTheme.size.pageContainerServiceVerticalPadding;
    }
    return defaultTheme.size.pageContainerVerticalPadding;
  };

  const getMobileContentPadding = () => {
    if (isLoginPage(location)) {
      return defaultTheme.size.pageContainerMobileLoginVerticalPadding;
    }
    if (!isLoggedIn && !isLoginPage(location) && !isServiceRoute(location)) {
      return defaultTheme.size.pageContainerMobileRegisterVerticalPadding;
    }
    if (!isLoggedIn && isServiceRoute(location)) {
      return defaultTheme.size.pageContainerMobilePublicServiceVerticalPadding;
    }
    if (isServiceRoute(location)) {
      return defaultTheme.size.pageContainerMobileServiceVerticalPadding;
    }
    return defaultTheme.size.pageContainerMobileVerticalPadding;
  };

  return (
    <Wrapper>
      <Toast />
      <Header isLoggedIn={isLoggedIn} />
      <Content
        showAnimation={isLoggedIn}
        contentPadding={getContentPadding()}
        mobileContentPadding={getMobileContentPadding()}
      >
        {children}
      </Content>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

type ContentProps = {
  showAnimation: boolean;
  contentPadding: string;
  mobileContentPadding: string;
};

const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ mobileContentPadding }) => mobileContentPadding};

  @media (min-width: ${mediaQueries.m_mobile}) {
    flex-direction: row;
    justify-content: center;
    padding: ${({ contentPadding }) => contentPadding};
  }
`;

export default PagesContainer;
