import styled from 'styled-components';
import { mediaQueries } from 'styles';

const Aside = styled.aside`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primaryColor};

  @media (min-width: ${mediaQueries.m_mobile}) {
    min-width: 32.25rem;
  }
`;

export default Aside;

export const AsideRightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
`;

export const AsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
`;

export const AsideContent = styled.div`
  padding: 1rem 2rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: ${({ theme }) => theme.spacing.asideRightContentPadding};
  }
`;

export const AsideHeader = styled.h1`
  display: flex;
  align-items: flex-end;
  margin: 0;

  @media (min-width: ${mediaQueries.m_mobile}) {
    margin: 3rem 0 4rem;
  }
`;

export const AsideFooter = styled(AsideContent)`
  padding: 0rem 2rem 2rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: ${({ theme }) => theme.spacing.asideRightContentPadding};
  }
`;
