/** External Dependencies **/
import React, { FC, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';

/** Interal Dependencies */
import { Modals } from 'constants/Layouts';

/** Data layer */
import { MyServicesGetActions } from 'zo-data-layer/actions';
import { MyServicesGetSelectors } from 'zo-data-layer/selectors';
import MyService from 'zo-data-layer/dataobjects/MyService';
import { toDateStr } from 'zo-data-layer/utils/time/DateStr';

/** Components */
import Overlay, { Size as OverlaySize } from 'components/Overlay';
import EventServiceDetails from 'components/ServiceBookingFlow/EventServiceDetails';
import LoadingIndicator from 'components/LoadingIndicator';
import ModalContainer from 'components/ModalContainer';
import AddNewCardModal from 'components/CreditCard/AddNewCardModal';
import PromocodeModal from 'components/CreditCard/PromocodeModal';
import InventoryServiceDetails from 'components/ServiceBookingFlow/InventoryServiceDetails';
import { useBooleanEffect, useQuery } from 'hooks';
import { CurrentServiceContext } from 'contexts';
import { redirectToHome } from 'utils/navigation';
import ScheduledServiceDetails from 'components/ServiceBookingFlow/ScheduledServiceDetails';

type RouteParams = {
  enrollmentId: string;
  slotId: string;
};

type SelectorProps = {
  service: MyService;
  isSuccess: boolean;
};

const memoizedSelectors = createStructuredSelector<any, SelectorProps>({
  service: MyServicesGetSelectors.getService(),
  isSuccess: MyServicesGetSelectors.isSuccess(),
});

const ServiceDetailsPage: FC = () => {
  const [promocode, setPromoCode] = useState();
  const [codeData, setCodeData] = useState();
  const dispatch = useDispatch();
  const { enrollmentId, slotId } = useParams<RouteParams>();
  const { service, isSuccess } = useSelector(memoizedSelectors);

  const handlePromoCodeApplied = useCallback((newPromoCode, newCodeData) => {
    setCodeData(newCodeData);
    setPromoCode(newPromoCode);
  }, []);
  const enrollmentIdIsValid = /^[0-9a-zA-Z\-_]{12}$/.test(enrollmentId) || !isNaN(parseInt(enrollmentId, 10));

  useBooleanEffect(!enrollmentIdIsValid, redirectToHome);

  useEffect(() => {
    if (!isSuccess && enrollmentIdIsValid) {
      dispatch(MyServicesGetActions.request(enrollmentId));
    }
  }, [dispatch, enrollmentId, isSuccess, enrollmentIdIsValid]);

  const query = useQuery();
  const isLeaderSection = query.get('leader') === 'true';
  const date = query.get('date');
  const start_time = query.get('start_time');
  const end_time = query.get('end_time');
  const scheduledAvailability =
    date && start_time && end_time ? { date: toDateStr(date), start_time, end_time } : undefined;

  function renderLoaded() {
    if (service.isEvent) {
      return <EventServiceDetails promocode={promocode} promocodeData={codeData} slotId={slotId} />;
    }
    if (service.isScheduledService) {
      return (
        <ScheduledServiceDetails promocode={promocode} promocodeData={codeData} availability={scheduledAvailability} />
      );
    }
    return <InventoryServiceDetails promocode={promocode} promocodeData={codeData} />;
  }

  function renderLoading() {
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <Overlay size={OverlaySize.Large}>
        <CurrentServiceContext.Provider value={{ service: service, isLeaderSection: isLeaderSection }}>
          {isSuccess && renderLoaded()}
          {!isSuccess && renderLoading()}
        </CurrentServiceContext.Provider>
      </Overlay>
      <ModalContainer name={Modals.AddCard}>
        <div>
          <AddNewCardModal />
        </div>
      </ModalContainer>
      <ModalContainer name={Modals.Promocode}>
        <div>
          <PromocodeModal onPromoCodeApplied={handlePromoCodeApplied} />
        </div>
      </ModalContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default ServiceDetailsPage;
