import Button from 'components/Button';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { brand, mediaQueries, miscColors, typeScale } from 'styles';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';

import { ImmutableState } from 'zo-data-layer/utils/selectors';
import { MyServiceGetScheduledAvailabilityActions } from 'zo-data-layer/actions';
import { MyService } from 'zo-data-layer/dataobjects';
import { MyServiceGetScheduledAvailabilitySelectors } from 'zo-data-layer/selectors';
import { formatTimeStr, getDateFormatString } from 'zo-data-layer/utils/time';
import { toDateStr } from 'zo-data-layer/utils/time/DateStr';
import ScheduledAvailabilityCollection, {
  ScheduledAvailability,
} from 'zo-data-layer/dataobjects/ScheduledAvailabilityCollection';
import { URIEncode } from 'zo-data-layer/services';

import { history } from 'compositionRoot';
import LoadingIndicator from 'components/LoadingIndicator';
import { generateServiceDetailsLink } from 'constants/routes';
import { LocaleContext } from 'containers/IntlContainer';

import Messages from './Messages';

interface Props {
  duration: number;
  service: MyService;
}

type SelectorProps = {
  isLoading: boolean;
  isSuccess: boolean;
  scheduledAvailability: ScheduledAvailabilityCollection;
};

const memoizedSelectors = createStructuredSelector<ImmutableState, SelectorProps>({
  isLoading: MyServiceGetScheduledAvailabilitySelectors.isLoading(),
  isSuccess: MyServiceGetScheduledAvailabilitySelectors.isSuccess(),
  scheduledAvailability: MyServiceGetScheduledAvailabilitySelectors.getScheduledAvailability(),
});

const ScheduledAvailabilityPicker: React.FC<Props> = ({ duration, service }) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const dateStr = toDateStr(date);
  const { isSuccess, isLoading, scheduledAvailability } = useSelector(memoizedSelectors);
  const { locale } = useContext(LocaleContext);
  const now = new Date();
  useEffect(() => {
    dispatch(
      MyServiceGetScheduledAvailabilityActions.request({
        id: service.id,
        duration: duration,
        from: dateStr,
        to: dateStr,
      })
    );
  }, [service, duration, dateStr, dispatch]);

  const handleScheduledAvailabilityBooking = (availability: ScheduledAvailability) => () => {
    history.push({
      pathname: generateServiceDetailsLink(service),
      search: URIEncode({ ...availability }),
    });
  };

  const renderNoAvailability = () => (
    <Header>
      <FormattedMessage {...Messages.noAvailability} />
    </Header>
  );

  const renderAvailabilityRow = (availability: ScheduledAvailability, index: number) => (
    <Wrapper key={index}>
      <Row>
        <Column>{formatTimeStr(availability.start_time, service.timezone, locale)}</Column>
        <Column>
          <DurationText>{service.formattedDuration(duration)}</DurationText>
          <StyledButton
            title={<FormattedMessage {...Messages.book} />}
            className="clearBackground"
            onClick={handleScheduledAvailabilityBooking(availability)}
          />
        </Column>
      </Row>
    </Wrapper>
  );

  const filteredAvailabilities = useMemo(() => {
    const isAfterNow = (availability) => {
      const datetime = DateTime.fromISO(`${dateStr}T${availability.start_time}`, {
        zone: service.timezone as string,
        setZone: true,
      });
      return datetime.diffNow().milliseconds > 0;
    };

    return scheduledAvailability.availabilityForDate(dateStr).filter(isAfterNow);
  }, [scheduledAvailability, dateStr, service.timezone]);

  const renderAvailability = () => {
    if (filteredAvailabilities.length === 0) {
      return renderNoAvailability();
    }
    return filteredAvailabilities.map(renderAvailabilityRow);
  };

  if (!isSuccess || isLoading) return <LoadingIndicator />;

  return (
    <Container>
      <Header>
        <FormattedMessage {...Messages.selectDateTime} />
      </Header>
      <DatePicker
        selected={date}
        onChange={(newDate) => setDate(newDate)}
        minDate={now}
        closeOnScroll={true}
        dateFormat={getDateFormatString(locale)}
        locale={locale}
      />
      <TimetableContainer>{renderAvailability()}</TimetableContainer>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 1rem;
  padding-right: 1rem;
  border-top: 1px solid ${miscColors.divider};
  max-width: 14rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    max-width: 55%;
  }
`;

const TimetableContainer = styled.div`
  margin-top: 2rem;
`;

const Wrapper = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid ${miscColors.divider};

  :last-child {
    border-bottom: none;
  }
`;

const Row = styled.div`
  padding: 0.5rem;
  border-left: 4px ${brand.success} solid;
  display: flex;
  justify-content: space-between;
  font-size: ${typeScale.font12};

  @media (min-width: ${mediaQueries.m_mobile}) {
    font-size: ${typeScale.font14};
  }
`;

const Column = styled.div`
  display: flex;
  align-items: center;
`;

const DurationText = styled.span`
  color: ${brand.brightGreen};
  margin-right: 1rem;
`;

const StyledButton = styled(Button)`
  padding: 0.8rem;
  width: 7.5rem;
  margin: 0;
  border: 1px grey solid;
`;

const Header = styled.h3``;

export default ScheduledAvailabilityPicker;
