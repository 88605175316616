import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import Messages from './Messages';
import { CalendarType, buildUrl, formatCalendarEventFromBooking } from './helper';
import CalendarButton from './CalendarButton';
import { Apple, Google, Outlook, Yahoo } from '../Icons';
import Row from '../Row';
import { IServiceAnalytics } from 'analytics/useAnalytics';
import GenericTimeBasedBooking from 'zo-data-layer/dataobjects/GenericTimeBasedBooking';

/** Styling */
import { mediaQueries } from '../../styles';

const calendarLinks = [
  { label: 'Apple', type: CalendarType.Apple, logo: Apple },
  { label: 'Google', type: CalendarType.Google, logo: Google },
  { label: 'Outlook', type: CalendarType.Outlook, logo: Outlook },
  { label: 'Outlook.com', type: CalendarType.Outlookcom, logo: Outlook },
  { label: 'Yahoo', type: CalendarType.Yahoo, logo: Yahoo },
];

type Props = {
  booking: GenericTimeBasedBooking & IServiceAnalytics;
};

const AddToCalendar: FC<Props> = ({ booking }) => {
  const intl = useIntl();
  const event = formatCalendarEventFromBooking(booking, intl);

  return (
    <div>
      <HeaderSpan>
        <Header>
          <FormattedMessage {...Messages.header} />
        </Header>
        <Subheader>
          <p className="darkGrey">
            <FormattedMessage {...Messages.subHeader} />
          </p>
        </Subheader>
      </HeaderSpan>

      <Row marginSize="s">
        {calendarLinks.map(({ label, type, logo }) => {
          const { isBlob, url } = buildUrl(event, type);
          return (
            <CalendarButton
              key={label}
              label={label}
              logo={logo}
              isBlob={isBlob}
              url={url}
              type={type}
              booking={booking}
            />
          );
        })}
      </Row>
    </div>
  );
};

const HeaderSpan = styled.span`
  display: inline-block;

  @media (min-width: ${mediaQueries.m_mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.h3`
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

const Subheader = styled.div`
  margin-bottom: 2rem;
`;

export default AddToCalendar;
