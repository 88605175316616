/** External Dependencies */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';

/** Internal Dependencies */
import { ResultSetNames, ServicePageSizes } from 'constants/Services';
import { allDefaultSelectors, trendingCarouselCategory, carouselServiceSelector } from 'utils/selectors';

/** Data layer */
import { MyServicesListActions } from 'zo-data-layer/actions';
import MyService from 'zo-data-layer/dataobjects/MyService';
import { trendingCarouselSelectors, trendingCarouselFilters } from 'utils/selectors';

const memoizedSelectors = {
  carouselServices: carouselServiceSelector,
  isNewLoaded: trendingCarouselSelectors.isSuccess(),
  isAllLoaded: allDefaultSelectors.isSuccess(),
};

const { REACT_APP_SERVICES_CATEGORIES_IDS } = process.env;
const servicesCarouselCategory = REACT_APP_SERVICES_CATEGORIES_IDS
  ? REACT_APP_SERVICES_CATEGORIES_IDS
  : null

type UseCarouselServicesType = {
  carouselServices: List<MyService>;
  isNewLoaded: boolean;
  isAllLoaded: boolean;
  category: string | number;
};

export function useCarouselServices(): UseCarouselServicesType {
  const dispatch = useDispatch();

  const carouselServices = useSelector(memoizedSelectors.carouselServices);
  const isAllLoaded = useSelector(memoizedSelectors.isAllLoaded);
  const isNewLoaded = useSelector(memoizedSelectors.isNewLoaded);

  const defaultFilters = [
    {
      name: ResultSetNames.all,
      filter: {
        all: true,
        pageSize: ServicePageSizes.carousel,
        category: servicesCarouselCategory,
        resultSetName: ResultSetNames.allDefault,
        enrolled: true,
      },
      isLoaded: isAllLoaded,
    },
    {
      name: ResultSetNames.new,
      filter: trendingCarouselFilters,
      isLoaded: isNewLoaded,
    },
  ];

  useEffect(() => {
    defaultFilters.forEach((filterConfig) => {
      dispatch(MyServicesListActions.request(filterConfig.filter));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { carouselServices, isNewLoaded, isAllLoaded, category: trendingCarouselCategory };
}
