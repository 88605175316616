/** External Dependencies */
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

/** Internal Dependencies */
import Messages from './Messages';
import { generateServiceLink } from '../../constants/routes';

/** Data layer */
import { CloudinaryImageConfigs } from 'zo-data-layer/constants/cloudinary';
import { CapacitySeverity, ServiceType } from 'zo-data-layer/constants/services';
import MyService from 'zo-data-layer/dataobjects/MyService';

/** Components */
import { CalendarIcon, ClockIcon, CostIcon, InfoIcon, WaitlistIcon, SuccessIcon, AlertIcon, SeatIcon } from '../Icons';
import Tile, { InfoPoint, TileImage, TileInfo, TileSubInfo, TileTitle } from '../Tile';
import AspectRatioContainer from '../AspectRatioContainer';
import { defaultTheme } from 'styles';
import { LocaleContext } from '../../containers/IntlContainer';

interface IServiceTile {
  service: MyService;
  className?: string;
  categoryId?: string;
  isCarouselTiles?: boolean;
  isLeaderSection?: boolean;
  showLocation?: boolean;
}

const ServiceTile: FC<IServiceTile> = ({
  service,
  className,
  categoryId,
  isCarouselTiles = false,
  isLeaderSection = false,
  showLocation = false,
}) => {
  const { locale } = useContext(LocaleContext);
  const timeSlot = service.firstAvailableOccurrence;

  const renderMultipleDates = () => {
    if (!service.isMultipleDates) {
      return (
        <div>
          <div>
            <InfoPoint className="darkGrey">
              <CalendarIcon />
              {service.formattedDay(locale)}
            </InfoPoint>
          </div>
          <div>
            <InfoPoint className="darkGrey">
              <ClockIcon />
              {service.formattedTime(locale)}
            </InfoPoint>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <InfoPoint className="darkGrey">
            <CalendarIcon />
            <FormattedMessage {...Messages.multipleTimes} />
          </InfoPoint>
        </div>
      );
    }
  };

  const renderEventTile = () => {
    const isWaitlistSpotAvailable = timeSlot && timeSlot.remainingCapacity > 0;

    if (!service.isSoldOut) {
      return (
        <div>
          {renderMultipleDates()}
          {service.isWaitlist && !isWaitlistSpotAvailable && (
            <div>
              <InfoPoint className="darkGrey">
                <WaitlistIcon />
                <b>
                  <FormattedMessage {...Messages.waitList} />
                </b>
              </InfoPoint>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <InfoPoint className="darkGrey">
            <InfoIcon />
            <FormattedMessage {...Messages.soldOut} />
          </InfoPoint>
        </div>
      );
    }
  };

  const renderInventoryTile = () => {
    if (service.isSoldOut) {
      return (
        <div>
          <InfoPoint className="darkGrey">
            <b>
              <FormattedMessage {...Messages.soldOut} />
            </b>
          </InfoPoint>
        </div>
      );
    }
  };

  const renderVirtualTile = () => (
    <div>
      <div>
        <InfoPoint className="darkGrey">
          <CostIcon />
          <FormattedMessage {...Messages.free} />
        </InfoPoint>
      </div>
      <div>
        <InfoPoint className="darkGrey">
          <ClockIcon />
          <FormattedMessage {...Messages.accessibleAnytime} />
        </InfoPoint>
      </div>
    </div>
  );

  const renderSpaceTile = () => (
    <div>
      <InfoPoint className="darkGrey">
        <ClockIcon />
        <FormattedMessage values={{ operatingHours: service.operatingHours }} {...Messages.openOperatingHours} />
      </InfoPoint>
    </div>
  );

  const renderScheduledServiceTile = () => (
    <div>
      <div>
        <InfoPoint className="darkGrey">
          <SeatIcon />
          <FormattedMessage values={{ numberOfSeats: service.capacity }} {...Messages.seat} />
        </InfoPoint>
      </div>
    </div>
  );

  const renderLocation = () => (
    <div>
      <InfoPoint className="darkGrey">{service.campus.name}</InfoPoint>
      <InfoPoint className="darkGrey">{service.region.name}</InfoPoint>
    </div>
  );

  const renderTileInfo = () => {
    switch (service.serviceType) {
      case ServiceType.Event:
        return renderEventTile();
      case ServiceType.Inventory:
        return renderInventoryTile();
      case ServiceType.Video:
        return renderVirtualTile();
      case ServiceType.Space:
        return renderSpaceTile();
      case ServiceType.Scheduled:
        return renderScheduledServiceTile();
      default:
        return;
    }
  };

  const getDensityBannerIcon = (severity: CapacitySeverity) => {
    switch (severity) {
      case CapacitySeverity.Low:
        return <SuccessIcon filled />;
      case CapacitySeverity.Medium:
        return <AlertIcon color={defaultTheme.status.warning} />;
      case CapacitySeverity.High:
        return <AlertIcon color={defaultTheme.status.error} />;
    }
  };

  const getDensityBannerMessage = (service: MyService) => {
    if (service.capacitySeverity() === CapacitySeverity.High) return <FormattedMessage {...Messages.spaceFull} />;
    return <FormattedMessage values={{ percent: service.occupancyPercent() }} {...Messages.percentOccupancy} />;
  };

  const renderDensityBanner = () => {
    if (service.hasDensityData()) {
      return (
        <DensityBanner>
          <InfoPoint>
            <IconContainer>{getDensityBannerIcon(service.capacitySeverity())}</IconContainer>
            <b>{getDensityBannerMessage(service)}</b>
          </InfoPoint>
        </DensityBanner>
      );
    }
  };

  const CloudinaryConfigs = isCarouselTiles ? CloudinaryImageConfigs.tileCarousel : CloudinaryImageConfigs.serviceTile;

  return (
    <Tile className={className}>
      <Link to={generateServiceLink(service, categoryId)}>
        <AspectRatioContainer ratio={CloudinaryConfigs.aspectRatio} isCenter>
          <TileImage
            isTile={true}
            isVideo={service.isVirtual()}
            publicId={service.cloudinaryPublicId}
            transformation={CloudinaryConfigs}
          />
          {renderDensityBanner()}
        </AspectRatioContainer>
        <TileInfo>
          <TileTitle>{service.title}</TileTitle>
          <TileSubInfo>
            {showLocation && renderLocation()}
            {renderTileInfo()}
          </TileSubInfo>
        </TileInfo>
      </Link>
    </Tile>
  );
};

const DensityBanner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.9em 0.5em 0.5em 0.5em;
  background-color: white;
  border-radius: 0 10px 0 0;
`;

const IconContainer = styled.span`
  padding-right: 0.5em;
`;

export default ServiceTile;
