import React from 'react';
import styled from 'styled-components';
import { CheckboxIcon } from 'components/Icons';

interface Props {
  id: string;
  label: string;
  checked: boolean;
  onChange(newChecked: boolean): void;
}

export const FilterCheckbox = ({ id, label, checked, onChange }: Props) => (
  <Li>
    <Input type="checkbox" name={id} id={id} checked={checked} onChange={(e) => onChange(e.target.checked)} />
    <Label htmlFor={id}>
      <CheckboxIcon checked={checked} />
      <LabelText>{label}</LabelText>
    </Label>
  </Li>
);

const Li = styled.li`
  display: flex;
`;

const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 0.5em 1.5em;
`;

const LabelText = styled.span`
  padding-left: 1em;
`;

const Input = styled.input`
  appearance: none;
  background-color: #fff;
  margin: 0;
  cursor: pointer;
`;
