/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { status } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const TrashIcon: FC<Props> = ({ color = status.error, width = defaultTheme.size.serviceDetailIconSize }) => (
  <svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2 1C9.7293 1 9.2469 1.16867 8.9058 1.51608C8.5656 1.86167 8.4 2.353 8.4 2.83333V3.75H3V5.58333H3.9V20.25C3.9 21.7579 5.1195 23 6.6 23H17.4C18.8805 23 20.1 21.7579 20.1 20.25V5.58333H21V3.75H15.6V2.83333C15.6 2.35392 15.4344 1.86258 15.0933 1.51517C14.754 1.16867 14.2707 1 13.8 1H10.2ZM10.2 2.83333H13.8V3.75H10.2V2.83333ZM5.7 5.58333H18.3V20.25C18.3 20.7588 17.8995 21.1667 17.4 21.1667H6.6C6.1005 21.1667 5.7 20.7588 5.7 20.25V5.58333ZM7.5 8.33333V18.4167H9.3V8.33333H7.5ZM11.1 8.33333V18.4167H12.9V8.33333H11.1ZM14.7 8.33333V18.4167H16.5V8.33333H14.7Z"
      fill={color}
    />
  </svg>
);

export default TrashIcon;
