/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const ExternalLinkIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 12L12 2" stroke={color} strokeLinecap="round" />
      <path d="M12 9V2H4.99997" stroke={color} strokeLinecap="round" />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
  :hover {
    cursor: pointer;
  }
`;

export default ExternalLinkIcon;
