/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'immutable';

/** Interal Dependencies */
import { ResultSetNames, PageSize } from '../../constants/Services';
import Messages from './Messages';
import { todayListSelectors } from '../../utils/selectors';
import { usePagination } from 'hooks';

/** Data layer */
import { MyServicesListActions, MyServicesListRequest } from 'zo-data-layer/actions';
import { ImmutableState } from 'zo-data-layer/utils/selectors';
import { MyService } from 'zo-data-layer/dataobjects';

/** Components */
import TileSection from '../../components/TileSection';
import FilterPagesContainer from '../../components/FilterPagesContainer';
import FilterPagesLoadingIndicator from 'components/FilterPagesLoadingIndicator';

type Props = SelectorProps;

const TodayFilterPage: FC<Props> = ({ services, totalCount, isLoading }) => {
  const { fetchFirstPage, fetchNextPage, hasNext } = usePagination<MyServicesListRequest>(
    MyServicesListActions.request,
    { today: true, resultSetName: ResultSetNames.today, showHidden: true },
    { totalCount, pageSize: PageSize.filterPageSize }
  );

  const isLoaded = totalCount > 0;

  useEffect(fetchFirstPage, []);

  return (
    <FilterPagesContainer name="today" isLoaded={isLoaded}>
      <InfiniteScroll
        dataLength={services.size}
        next={fetchNextPage}
        hasMore={hasNext || isLoading}
        loader={<FilterPagesLoadingIndicator />}
      >
        <TileSection title={<FormattedMessage {...Messages.todayServices} />} tiles={services} isLoaded={isLoaded} />
      </InfiniteScroll>
    </FilterPagesContainer>
  );
};

type SelectorProps = {
  totalCount: number;
  services: List<MyService>;
  isLoading: boolean;
};

const mapStateToProps = createStructuredSelector<ImmutableState, SelectorProps>({
  totalCount: todayListSelectors.getCount(),
  services: todayListSelectors.getServices(),
  isLoading: todayListSelectors.isLoading(),
});

export default connect(mapStateToProps)(TodayFilterPage);
