/** External Dependencies */
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
/** Internal dependencies */
import { history } from '../../compositionRoot';
import { useBooleanEffect } from '../../hooks';
import { generateServiceLink, generateSuccessPageLink } from '../../constants/routes';
import { IPromoCodeData } from 'interfaces';
/** Data layer */
import { ImmutableState } from 'zo-data-layer/utils/selectors';
/** Components */
import ServiceDetails from './ServiceDetails';
import { CurrentServiceContext, CurrentServiceInfo } from '../../contexts';
import { BookingType } from 'zo-data-layer/constants';
import { ScheduledAvailability } from 'zo-data-layer/dataobjects/ScheduledAvailabilityCollection';
import { redirectToPath } from 'utils/navigation';
import { MyScheduledServiceBookingCreateSelectors } from 'zo-data-layer/selectors';
import ScheduledServiceBooking from 'zo-data-layer/dataobjects/ScheduledServiceBooking';

type SelectorProps = {
  isSuccess: boolean;
  booking: ScheduledServiceBooking;
};

interface Props {
  availability?: ScheduledAvailability;
  promocode?: string;
  promocodeData?: Map<string, IPromoCodeData>;
}

const structuredSelector = createStructuredSelector<ImmutableState, SelectorProps>({
  isSuccess: MyScheduledServiceBookingCreateSelectors.isSuccess(),
  booking: MyScheduledServiceBookingCreateSelectors.getBooking(),
});

const ScheduledServiceDetails: FC<Props> = ({ promocodeData, promocode, availability }) => {
  const { service } = useContext<CurrentServiceInfo>(CurrentServiceContext);
  const { isSuccess, booking } = useSelector(structuredSelector);
  const [selectedCardID, setSelectedCardID] = useState(undefined);

  useEffect(() => {
    if (!availability) {
      redirectToPath(generateServiceLink(service));
    }
  }, [availability, service]);

  const navigateToSuccessPage = useCallback(
    (bookingId, bookingType) => {
      history.push(generateSuccessPageLink(service, bookingId, bookingType));
    },
    [service]
  );

  useBooleanEffect(isSuccess, () => {
    navigateToSuccessPage(booking.id, BookingType.ScheduledService);
  });

  return (
    <ServiceDetails
      selectedCardID={selectedCardID}
      setSelectedCardID={setSelectedCardID}
      quantity={1}
      availability={availability}
      promocode={promocode}
      promocodeData={promocodeData}
    />
  );
};

export default ScheduledServiceDetails;
