/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { miscColors } from 'styles/colors';
import { defaultTheme } from 'styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const NotReadIcon: FC<Props> = ({ color = miscColors.notReadIcon, width = defaultTheme.size.notReadIconSize }) => (
  <svg width={width} height={width} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="4" fill={color} />
  </svg>
);

export default NotReadIcon;
