/** External Dependencies **/
import React, { FC } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';

/** Internal Dependencies */
import Messages from './Messages';
import { ServicePageSizes } from 'constants/Services';
import { AppLinks } from 'constants/routes';

/** Components */
import TileSection from 'components/TileSection';
import FilterPagesContainer from 'components/FilterPagesContainer';

import { allDefaultSelectors, reduceSelectors, trendingCarouselSelectors } from 'utils/selectors';

type Props = {
  areServicesLoaded: boolean;
  allDefaultServices: List<any>;
};

const DiscoverPage: FC<Props> = ({ areServicesLoaded, allDefaultServices }) => (
  <FilterPagesContainer name="discover" isLoaded={areServicesLoaded}>
    <TileSection
      name="all"
      title={<FormattedMessage {...Messages.discover} />}
      tiles={allDefaultServices.take(ServicePageSizes.section)}
      pathname={AppLinks.allFilter}
      isLoaded={areServicesLoaded}
    />
  </FilterPagesContainer>
);

type SelectorProps = {
  areServicesLoaded: boolean;
  allServicesCount: number;
  allDefaultServices: List<any>;
};

const mapStateToProps = createStructuredSelector<any, SelectorProps>({
  areServicesLoaded: reduceSelectors(
    (areAllLoaded: boolean, isLoaded: boolean) => areAllLoaded && isLoaded,
    allDefaultSelectors.isSuccess(),
    trendingCarouselSelectors.isSuccess()
  ),
  allServicesCount: allDefaultSelectors.getCount(),
  allDefaultServices: allDefaultSelectors.getServices(),
});

export default connect(mapStateToProps, null)(DiscoverPage);
