/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** InteralDependencies */
import Messages from './Messages';

/** Components */
import ZOLogo from '../ZoLogo';
import { BlockLink } from '../Block';

/** Styling */
import { miscColors, brand, defaultTheme, mediaQueries } from 'styles';

const LoginFooterBlock: FC = () => (
  <GreyFooterWrapper>
    <LogoWrapper>
      <ZOLogo color={brand.brightGreen} />
    </LogoWrapper>
    <WeAreHere>
      <FormattedMessage {...Messages.weAreHere} />
    </WeAreHere>
    <WelcomeMessage className="darkGrey">
      <FormattedMessage {...Messages.welcomeMessage} />
    </WelcomeMessage>
    <BlockLink href="https://findyourzo.com/" target="_blank">
      <FormattedMessage {...Messages.learnMore} />
    </BlockLink>
  </GreyFooterWrapper>
);

const GreyFooterWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${miscColors.backgroundGrey};
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 3.5rem 28% 2rem;
    margin-top: 3rem;
  }
`;

const LogoWrapper = styled.div`
  width: ${defaultTheme.size.footerLogoSize};
  padding-bottom: 0.4rem;
`;

const WelcomeMessage = styled.div`
  line-height: 1.5rem;
  text-align: center;
  padding-bottom: 2rem;
`;

const WeAreHere = styled.h3`
  margin-bottom: 1rem;
`;

export default LoginFooterBlock;
