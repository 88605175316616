/** External Dependencies */
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** Interal dependencies */
import Messages from './Messages';

/** Styling */
import { defaultTheme, neutral } from 'styles';

/** Components */
import { CalendarIcon, ClockIcon, CostIcon, LocationIcon, QuantityIcon, InstructionsIcon } from '../Icons';
import WaitlistOnIcon from '../Icons/WaitlistOnIcon';
import FormattedPrice from '../FormattedPrice';

/** Data layer */
import { BookingStatus } from 'zo-data-layer/constants';
import GenericTimeBasedBooking from 'zo-data-layer/dataobjects/GenericTimeBasedBooking';
import { LocaleContext } from '../../containers/IntlContainer';
import { InventoryBooking } from 'zo-data-layer/dataobjects';

// TODO: Convert this to a GenericBooking class
type Props = {
  booking: GenericTimeBasedBooking | InventoryBooking;
};

const BookingInfoBlock: FC<Props> = ({ booking }) => {
  const { locale } = useContext(LocaleContext);

  const renderFormattedPrice = (price, currency) => {
    const pricetoNumber = Number(price.replace(/[^0-9.-]+/g, ''));
    return <FormattedPrice price={pricetoNumber} currency={currency} />;
  };

  const localizedFinalPrice = booking.isFree ? (
    <FormattedMessage {...Messages.free} />
  ) : (
    renderFormattedPrice(booking.finalPrice, booking.currency)
  );

  const renderOnWaitlistOrAvailableWaitlist = () =>
    booking.status === BookingStatus.Waiting && (
      <InfoPoint className="darkGrey" style={{ paddingTop: '1rem' }}>
        <WaitlistOnIcon width={defaultTheme.size.serviceDetailIconSize} />
        <WaitlistSpan>
          <FormattedMessage {...Messages.onWaitlist} />
        </WaitlistSpan>
      </InfoPoint>
    );

  const renderDateAndTime = () => {
    if (!booking.isInventoryBooking()) {
      return (
        <>
          <InfoPoint className="darkGrey">
            <CalendarIcon width={defaultTheme.size.serviceDetailIconSize} />
            <IconPoint>{booking.formattedDay(locale)}</IconPoint>
          </InfoPoint>
          <InfoPoint className="darkGrey">
            <ClockIcon width={defaultTheme.size.serviceDetailIconSize} />
            <IconPoint>{booking.formattedTime(locale)}</IconPoint>
          </InfoPoint>
        </>
      );
    }
  };

  const renderPrice = () => (
    <InfoPoint className="darkGrey">
      <CostIcon width={defaultTheme.size.serviceDetailIconSize} />
      <IconPoint>{localizedFinalPrice}</IconPoint>
    </InfoPoint>
  );

  const renderLocation = () => {
    if (booking.isEventBooking() && !booking.isVirtual()) {
      return (
        <InfoContent>
          <LocationPoint className="darkGrey">
            <LocationIcon width={defaultTheme.size.serviceDetailIconSize} />
            <IconPoint>{booking.locationName}</IconPoint>
          </LocationPoint>
          <SubLocationPoint>{booking.locationAddress && <span>{booking.locationAddress}</span>}</SubLocationPoint>
          <SubLocationPoint>{booking.locationFloor && <span>{booking.locationFloor}</span>}</SubLocationPoint>
          <SubLocationPoint>{booking.locationRoom && <span>{booking.locationRoom}</span>}</SubLocationPoint>
        </InfoContent>
      );
    }
  };

  const renderQuantity = () => {
    if (booking.quantity > 0) {
      return (
        <InfoPoint className="darkGrey">
          <QuantityIcon width={defaultTheme.size.serviceDetailIconSize} />
          <IconPoint>{booking.quantity}</IconPoint>
        </InfoPoint>
      );
    }
  };

  const renderAccessInstructions = () => {
    if ('instructions' in booking && booking.instructions) {
      return (
        <InstructionsInfoPoint className="darkGrey">
          <InstructionsIcon width={defaultTheme.size.serviceDetailIconSize} />
          <IconPoint>
            <InstructionsContainer dangerouslySetInnerHTML={{ __html: booking.instructions }} />
          </IconPoint>
        </InstructionsInfoPoint>
      );
    }
  };

  return (
    <>
      {renderDateAndTime()}
      {renderPrice()}
      {renderQuantity()}
      {renderLocation()}
      {renderOnWaitlistOrAvailableWaitlist()}
      {renderAccessInstructions()}
    </>
  );
};

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const LocationPoint = styled.span`
  display: flex;
  align-items: center;
`;

const IconPoint = styled.span`
  padding-left: 0.5rem;
`;

const InfoPoint = styled(LocationPoint)`
  padding-bottom: 0.5rem;
`;

const InstructionsInfoPoint = styled.span`
  display: flex;
  padding-bottom: 0.5rem;
`;

const SubLocationPoint = styled(InfoPoint)`
  padding-left: 2.2rem;
  padding-bottom: 0.3rem;
  color: ${neutral.darkGrey};
`;

const WaitlistSpan = styled.span`
  padding-left: 0.5rem;
  font-weight: 400;
`;

const InstructionsContainer = styled.div`
  & > p:first-child {
    margin-top: 0;
  }

  & > p:last-child {
    margin-bottom: 0;
  }
`;

export default BookingInfoBlock;
