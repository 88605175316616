import { List } from 'immutable';
import {
  CombinedServiceResultsSelector,
  MyBookingsCreateResultsSelectors,
  MyScheduledServiceBookingsCreateResultsSelectors,
  MyServicesCreateResultsSelectors,
  MyWaitlistCreateResultsSelectors,
  TrendingCategoryServiceResultsSelector,
} from 'zo-data-layer/selectors';
import { ResultSetNames, ServicePageSizes } from '../constants/Services';
import PaymentSourceObject from './PaymentSourceObject';

export const newDefaultSelectors = MyServicesCreateResultsSelectors(ResultSetNames.newDefault);
export const todayDefaultSelectors = MyServicesCreateResultsSelectors(ResultSetNames.todayDefault);
export const upcomingDefaultSelectors = MyServicesCreateResultsSelectors(ResultSetNames.upcomingDefault);
export const allDefaultSelectors = MyServicesCreateResultsSelectors(ResultSetNames.allDefault);

export const newListSelectors = MyServicesCreateResultsSelectors(ResultSetNames.new);
export const todayListSelectors = MyServicesCreateResultsSelectors(ResultSetNames.today);
export const upcomingListSelectors = MyServicesCreateResultsSelectors(ResultSetNames.upcoming);
export const allListSelectors = MyServicesCreateResultsSelectors(ResultSetNames.all);
export const servicesByCategoryListSelectors = MyServicesCreateResultsSelectors(ResultSetNames.byCategory);
export const leaderServices = MyServicesCreateResultsSelectors(ResultSetNames.leader);

export const mapSelector = (selectorFn, mapFn) => (state) => (selectorFn(state) || List()).map(mapFn);
export const groupBySelector = (selectorFn, groupByFn) => (state) => (selectorFn(state) || List()).groupBy(groupByFn);

export const reduceSelectors = (reducerFn, ...selectors) => (state) =>
  selectors.reduce((accumulatedResult, selector) => reducerFn(accumulatedResult, selector(state)));

// my bookings
export const upcomingBookingsSelectors = MyBookingsCreateResultsSelectors(ResultSetNames.upcoming);
export const upcomingWaitlistSelectors = MyWaitlistCreateResultsSelectors(ResultSetNames.upcoming);
export const upcomingScheduledServiceBookingsSelectors = MyScheduledServiceBookingsCreateResultsSelectors(
  ResultSetNames.upcomingScheduled
);

// my payment sources
export const mapPaymentSource = (paymentData) => new PaymentSourceObject(paymentData);

// Trending carousel

const { REACT_APP_TRENDING_CATEGORY_ID } = process.env;

export const trendingCarouselCategory = REACT_APP_TRENDING_CATEGORY_ID
  ? parseInt(REACT_APP_TRENDING_CATEGORY_ID,10)
  : null;

export const [trendingCarouselSelectors, trendingCarouselFilters, carouselServiceSelector] = trendingCarouselCategory
  ? [
      servicesByCategoryListSelectors,
      {
        all: true,
        category: trendingCarouselCategory,
        pageSize: ServicePageSizes.carousel,
        resultSetName: ResultSetNames.byCategory,
        enrolled: false,
        showHidden: true,
      },
      TrendingCategoryServiceResultsSelector({
        servicesSelector: servicesByCategoryListSelectors.getServices(),
      }),
    ]
  : [
      newDefaultSelectors,
      {
        new_today: true,
        pageSize: ServicePageSizes.carousel,
        resultSetName: ResultSetNames.newDefault,
        enrolled: true,
      },
      CombinedServiceResultsSelector({
        primarySelector: newDefaultSelectors.getServices(),
        secondarySelector: allDefaultSelectors.getServices(),
        size: ServicePageSizes.carousel,
      }),
    ];
