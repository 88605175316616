import { defineMessages, MessageDescriptor } from 'react-intl';

const backToLogin: MessageDescriptor = {
  id: 'loginPage.backToLogin',
  defaultMessage: 'Back to Log In',
};

export default defineMessages({
  backToLogin,
});
