/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const OutlookIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <svg width={width} height={width} viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5543 2.97158V16.5138H37.4605V0.00439453L19.5543 2.97158ZM19.5543 33.0747L37.4605 35.9903V19.4903H19.5543V33.0747ZM0.630859 16.5091H15.7668V3.46377L0.630859 5.97158V16.5091ZM0.630859 30.1216L15.7668 32.5872V19.4903H0.630859V30.1216Z"
      fill={color}
    />
  </svg>
);

export default OutlookIcon;
