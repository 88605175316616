/** External Dependencies **/
import React, { FC } from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { MeSelectors } from 'zo-data-layer/selectors';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

/** Interal Dependencies */
import { AppLinks, getUrlConstants } from '../../constants/routes';
import { useAuthenticated } from '../../auth';

/** Components */
import { ZoLogo as ZoLogoNoTrademark } from '../Icons';

type StateProps = {
  isUSUser?: boolean;
};

type Props = StateProps & {
  color?: string;
  maxWidth?: string;
  linkHome?: boolean;
};

const Wrapper = styled.span<Props>`
  cursor: ${({ linkHome }) => (linkHome ? 'pointer' : 'auto')};
  max-width: ${({ theme, maxWidth }) => maxWidth || theme.size.maxLogoSize};
`;

const ZoLogo: FC<Props> = ({ isUSUser, color, maxWidth, linkHome = true }) => {
  const { locale } = useIntl();
  const history = useHistory();
  const authenticated = useAuthenticated();
  const handleClick = () => {
    if (!linkHome) return;
    authenticated ? history.push(AppLinks.discover) : window.open(getUrlConstants({ locale }).baseUrl);
  };

  return (
    <Wrapper onClick={handleClick} maxWidth={maxWidth} linkHome={linkHome}>
      {/* TODO: Trademark icon? */}
      {isUSUser ? <ZoLogoNoTrademark color={color} /> : <ZoLogoNoTrademark color={color} />}
    </Wrapper>
  );
};

const mapStateToProps = createStructuredSelector<any, StateProps>({
  isUSUser: MeSelectors.isUSUser(),
});

export default connect(mapStateToProps)(ZoLogo);
