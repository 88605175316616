/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

/** InteralDependencies */
import Messages from './Messages';

/** Components */
import Button from '../Button';
import { isLeaderRoute } from 'utils/navigation';
import { AppLinks } from '../../constants/routes';

/** Styling */
import { miscColors } from 'styles';

const SeeAllServicesFooterBlock: FC = () => {
  const location = useLocation();
  const homeLink = isLeaderRoute(location) ? AppLinks.leaderView : AppLinks.discover;

  return (
    <GreyFooterWrapper>
      <LinkBack className="bold">
        <FormattedMessage {...Messages.linkBack} />
      </LinkBack>
      <Link to={homeLink}>
        <AllServiceButton
          buttonType="link"
          className="withBorder"
          title={<FormattedMessage {...Messages.seeAllServices} />}
          fullWidth={false}
        />
      </Link>
    </GreyFooterWrapper>
  );
};

const GreyFooterWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${miscColors.backgroundGrey};
  flex-direction: column;
  padding: 5rem 15%;
  align-items: flex-start;
  height: ${({ theme }) => theme.size.seeAllServicesBlockHeight};
`;

const LinkBack = styled.div`
  padding-bottom: 2rem;
`;

const AllServiceButton = styled(Button)`
  margin-top: 0;
  padding: ${({ theme }) => theme.spacing.footerPadding};
`;

export default SeeAllServicesFooterBlock;
