/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const PrivacyIcon: FC<Props> = ({ color = neutral.white, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7498 20.9993L21.7479 12.9993C21.7477 12.0329 20.9642 11.2497 19.9979 11.2497H8C7.0335 11.2497 6.25 12.0332 6.25 12.9997V20.9997C6.25 21.9662 7.0335 22.7497 8 22.7497H19.9998C20.9664 22.7497 21.75 21.9659 21.7498 20.9993Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M9.75 10.4997V11.2497H10.5H17.5H18.25V10.4997V7.74966C18.25 5.66086 16.1653 4.24966 14 4.24966C11.8347 4.24966 9.75 5.66086 9.75 7.74966V10.4997Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default PrivacyIcon;
