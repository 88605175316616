import { defineMessages } from 'react-intl';

export default defineMessages({
  linkBack: {
    id: 'serviceInfo.linkBack',
    defaultMessage: 'Access hundred of events and services through your ZO Membership',
  },
  seeAllServices: {
    id: 'serviceInfo.seeAllServices',
    defaultMessage: 'See all Services',
  },
  weAreHere: {
    id: 'loginPage.weAreHere',
    defaultMessage: 'We are here for you.',
  },
  welcomeMessage: {
    id: 'loginPage.welcomeMessage',
    defaultMessage:
      'A collection of services, amenities, spaces & perks created by Tishman Speyer to help our customers make the most of their workday',
  },
  learnMore: {
    id: 'loginPage.learnMore',
    defaultMessage: 'Learn More',
  },
  registerFooterNote: {
    id: 'forms.registerFooterNote',
    defaultMessage:
      'Please note that, in order to register for ZO, the company you work for must be a Tishman Speyer customer.',
  },
});
