import * as Sentry from '@sentry/browser';
import { isProduction, env } from 'zo-data-layer/constants/env';
import { version } from '../../package.json';

import { SentryToken } from '../constants';
import { IErrorReporter } from 'zo-data-layer/transformers';

Sentry.init({
  dsn: SentryToken,
  debug: !isProduction(),
  maxBreadcrumbs: 50,
  environment: env(),
  release: version,
  attachStacktrace: true,
  tracesSampleRate: 1.0,
});

class ErrorReporter implements IErrorReporter {
  reporter: typeof RavenErrorReporter | typeof DevErrorReporter;

  constructor() {
    switch (process.env.APP_ENV) {
      case 'production':
        this.reporter = RavenErrorReporter;
        break;
      case 'staging':
        this.reporter = RavenErrorReporter;
        break;
      default:
        this.reporter = DevErrorReporter;
        break;
    }
  }

  captureMessage(msg: string) {
    this.reporter.captureMessage(msg, {});
  }

  captureException(error: Error) {
    this.reporter.captureException(error, {});
  }

  setUserContext(context: Sentry.User | null) {
    this.reporter.setUserContext(context);
  }

  addContext(key: string, value: string) {
    this.reporter.addContext(key, value);
  }
}

class RavenErrorReporter {
  static captureMessage(msg: string, context: { [key: string]: string }) {
    Sentry.withScope((scope) => {
      scope.setExtras(context);
      Sentry.captureMessage(msg);
    });
  }

  static captureException(err: Error, context: { [key: string]: string }) {
    Sentry.withScope((scope) => {
      scope.setExtras(context);
      Sentry.captureException(err);
    });
  }

  static setUserContext(context: Sentry.User | null) {
    Sentry.setUser(context);
  }

  static addContext(key: string, value: string) {
    Sentry.setExtra(key, value);
  }
}

class DevErrorReporter {
  static captureMessage(msg: string, context: { [key: string]: string }) {
    console.log('CaptureMessage: ', msg);
  }

  static captureException(error: Error, context: { [key: string]: string }) {
    console.log('CaptureException: ', error, context);
  }

  static setUserContext(context: Sentry.User | null) {
    console.log('SetUserContext: ', context);
  }

  static addContext(context: string) {
    console.log('ExtraContext: ', context);
  }
}

export default new ErrorReporter();
