/*
 *
 * Layout reducer
 *
 */
/* Absolute Imports */
import { fromJS } from 'immutable';

/* Relative Imports */
import { DISMISS_TOAST, NETWORK_ERROR, TOAST, MODAL } from 'zo-data-layer/constants/actions';

const initialState = fromJS({
  toast: {
    display: false,
    message: '',
    type: '',
  },
});

/** Reducer **/
export default function (state = initialState, action) {
  switch (action.type) {
    case MODAL: {
      const { name, open } = action.payload;
      return state.setIn(['modal', name, 'open'], open);
    }
    case NETWORK_ERROR:
      return state
        .setIn(['toast', 'display'], true)
        .setIn(['toast', 'message'], action.payload.message)
        .setIn(['toast', 'type'], action.payload.type);
    case TOAST:
      return state
        .setIn(['toast', 'display'], true)
        .setIn(['toast', 'message'], action.payload.message)
        .setIn(['toast', 'type'], action.payload.type);
    case DISMISS_TOAST:
      return state.setIn(['toast', 'display'], false).setIn(['toast', 'message'], '');
    default:
      return state;
  }
}
