export const Modals = {
  Calendar: 'Calendar',
  AddCard: 'AddCard',
  Promocode: 'Promocode',
  CancelBooking: 'CancelBooking',
  DeleteAccount: 'DeleteAccount',
  ConfirmDeleteCard: 'ConfirmDeleteCard',
  Video: 'Video',
  SignIn: 'SignIn',
  BrowserUnsupported: 'BrowserUnsupported',
};
