/** External Dependencies */
import React, { FC, useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

/** Internal Dependencies */
import Messages from './Messages';
import { AppLinks, generateCategoryLink, generateServiceDetailsLink, generateServiceLink } from 'constants/routes';
import { useAnalytics } from 'analytics';
import { Modals } from 'constants/Layouts';
import { LocaleContext } from 'containers/IntlContainer';

/** Data layer */
import { CapacitySeverity, ServiceType } from 'zo-data-layer/constants/services';
import MyService from 'zo-data-layer/dataobjects/MyService';
import { addHttps } from 'zo-data-layer/utils/patterns';
import { openModal, MyServiceListAvailabilitiesActions } from 'zo-data-layer/actions';
import { Timeslot } from 'zo-data-layer/dataobjects';

/** Styling */
import { defaultTheme, mediaQueries, typeScale } from 'styles';
import { neutral } from 'styles/colors';

/** Components */
import Button from '../Button';
import DurationPicker from './DurationPicker';
import ServiceInfoCTA from './ServiceInfoCTA';
import ServiceInfoBlock from './ServiceInfoBlock';
import CloudinaryImage from '../CloudinaryImage';
import AspectRatioContainer from '../AspectRatioContainer';
import AvailabilityPicker from '../AvailabilityPicker';
import PlayIcon from 'components/Icons/PlayIcon';
import VirtualIcon from 'components/Icons/VirtualIcon';
import { MyCategory } from 'zo-data-layer/dataobjects';
import { navigateToAvailabilities } from 'utils/navigation';
import { AlertIcon, ClockIcon, SuccessIcon } from 'components/Icons';
import ShareButton from 'components/ShareButton';
import ScheduledAvailabilityPicker from 'components/ScheduledAvailabilityPicker';

interface IServiceInfo {
  service: MyService;
  elementRef: React.MutableRefObject<any>;
  isPublic?: boolean;
  category?: MyCategory;
  isLeaderSection?: boolean;
  timeSlot?: Timeslot;
}

type RouteParams = {
  enrollmentId: string;
  slotId: string;
  categoryId?: string;
};

const ServiceInfoWrapper: FC<IServiceInfo> = ({ service, elementRef, isPublic, category, timeSlot }) => {
  const dispatch = useDispatch();
  const { currentLocale: locale } = useContext(LocaleContext);
  const [selectedDuration, setSelectedDuration] = useState<number | null>(null);
  const { categoryId } = useParams<RouteParams>();

  useEffect(() => {
    if (service && service.serviceType === ServiceType.Event) {
      dispatch(MyServiceListAvailabilitiesActions.request({ id: service.enrollmentId }));
    }
  }, [dispatch, service]);

  const renderLinks = () => (
    <span>
      <BreadCrumbLink to={service.isAdminOnly ? AppLinks.leaderView : AppLinks.discover}>
        <FormattedMessage {...(service.isAdminOnly ? Messages.businessHome : Messages.home)} />
      </BreadCrumbLink>
      {category && categoryId && (
        <>
          <Dash>/</Dash>
          <BreadCrumbLink to={generateCategoryLink(category.id)}>{category.translationFor(locale)}</BreadCrumbLink>
        </>
      )}
      <Dash>/</Dash>
      <BreadCrumbLink className="bold" to={generateServiceLink(service, category && category.id)}>
        {service.title}
      </BreadCrumbLink>
    </span>
  );
  const analytics = useAnalytics();

  const handleOnCtaClick = () => {
    analytics.logServiceCtaClicked(service);
  };

  const handleClickWatch = () => {
    dispatch(openModal(Modals.Video));
  };

  const handlePublicClick = () => {
    dispatch(openModal(Modals.SignIn));
  };

  const renderCTAlink = () => {
    if (service.isSoldOut) return <ServiceInfoCTA service={service} fullWidth={true} />;
    if (isPublic) return <ServiceInfoCTA service={service} fullWidth={true} onClick={handlePublicClick} />;

    return (
      <Link to={generateServiceDetailsLink(service, service.firstAvailableOccurrenceId)} onClick={handleOnCtaClick}>
        <ServiceInfoCTA service={service} fullWidth={true} timeSlot={timeSlot} />
      </Link>
    );
  };

  const renderRightColumnButton = () => {
    if (isPublic && ![ServiceType.Event, ServiceType.Video].includes(service.serviceType)) {
      return <ServiceInfoCTA service={service} fullWidth={true} onClick={handlePublicClick} />;
    }

    switch (service.serviceType) {
      case ServiceType.SSO:
      case ServiceType.Link:
        return (
          <a href={addHttps(service.link)} target="_blank" rel="noopener noreferrer">
            <ServiceInfoCTA service={service} fullWidth={true} />
          </a>
        );
      case ServiceType.Event:
        if (service.isMultipleDates) {
          return (
            <Button
              title={<FormattedMessage {...Messages.checkAvailabilites} />}
              onClick={isPublic ? handlePublicClick : navigateToAvailabilities}
            />
          );
        }
        return renderCTAlink();

      case ServiceType.Inventory:
        if (service.isSoldOut) {
          return <ServiceInfoCTA service={service} fullWidth={true} />;
        }
        return (
          <Link to={generateServiceDetailsLink(service)} onClick={handleOnCtaClick}>
            <ServiceInfoCTA service={service} fullWidth={true} />
          </Link>
        );
      case ServiceType.Video:
        return (
          <Button
            title={
              <StyledMessage>
                <FormattedMessage {...Messages.watch} />
              </StyledMessage>
            }
            icon={<PlayIcon color={neutral.white} fill={neutral.white} />}
            onClick={isPublic ? handlePublicClick : handleClickWatch}
          />
        );
      default:
        return;
    }
  };

  const renderSpaceInfoBlock = () => (
    <InfoContent>
      <FormattedMessage {...Messages.spaceInformation} />
      <InfoContent>
        <InfoLine>
          <ClockIcon width={defaultTheme.size.serviceDetailIconSize} />
          <span>{service.operatingHours}</span>
        </InfoLine>
      </InfoContent>
    </InfoContent>
  );

  const renderRightColumn = () => {
    switch (service.serviceType) {
      case ServiceType.SSO:
      case ServiceType.Link:
        return <InfoContent>{renderRightColumnButton()}</InfoContent>;
      case ServiceType.Event:
      case ServiceType.Inventory:
      case ServiceType.Video:
        return (
          <InfoContent>
            {renderRightColumnButton()}
            <InfoContent>
              <ServiceInfoBlock service={service} showMultipleTimeSlots={service.isMultipleDates} />
            </InfoContent>
          </InfoContent>
        );
      case ServiceType.Space:
        return renderSpaceInfoBlock();
      case ServiceType.Scheduled:
        return renderScheduledServiceBlock();
      default:
        return;
    }
  };

  const renderScheduledServiceBlock = () => {
    if (isPublic) {
      return renderRightColumnButton();
    }
    return <DurationPicker service={service} setSelectedDuration={setSelectedDuration} />;
  };

  const renderMobileServiceInfoBlock = () => {
    switch (service.serviceType) {
      case ServiceType.Event:
      case ServiceType.Inventory:
      case ServiceType.Video:
        return (
          <MobileServiceBlockContainer>
            <ServiceInfoBlock service={service} showMultipleTimeSlots={service.isMultipleDates} hideAvailability />
          </MobileServiceBlockContainer>
        );
      default:
        return;
    }
  };

  const renderNavLinks = () => {
    if (!isPublic) {
      return <NavLinks>{renderLinks()}</NavLinks>;
    }
  };

  const renderBottomButton = () => {
    if (service.cta || service.serviceType === ServiceType.Video) {
      return <BottomCtaContainer>{renderRightColumnButton()}</BottomCtaContainer>;
    }
  };

  const getDensityBannerIcon = (severity: CapacitySeverity) => {
    switch (severity) {
      case CapacitySeverity.Low:
        return <SuccessIcon filled color={defaultTheme.status.success} width="20px" />;
      case CapacitySeverity.Medium:
        return <AlertIcon color={defaultTheme.status.warning} width="20px" />;
      case CapacitySeverity.High:
        return <AlertIcon color={defaultTheme.status.error} width="20px" />;
    }
  };

  const getDensityBannerBackground = (severity: CapacitySeverity) => {
    switch (severity) {
      case CapacitySeverity.Low:
        return defaultTheme.statusBackground.success;
      case CapacitySeverity.Medium:
        return defaultTheme.statusBackground.warning;
      case CapacitySeverity.High:
        return defaultTheme.statusBackground.error;
    }
  };

  const renderDensityBanner = () => {
    if (service.hasDensityData()) {
      return (
        <DensityBanner background={getDensityBannerBackground(service.capacitySeverity())}>
          <IconContainer>{getDensityBannerIcon(service.capacitySeverity())}</IconContainer>
          <InfoContainer>
            <TileHeader>
              <OccupancyBoldText severity={service.capacitySeverity()}>
                <FormattedMessage values={{ percent: service.occupancyPercent() }} {...Messages.occupancy} />
              </OccupancyBoldText>
            </TileHeader>
            <TileSubheader>
              <FormattedMessage values={{ date: service.formattedLastDensityUpdate(locale) }} {...Messages.updatedAt} />
            </TileSubheader>
          </InfoContainer>
        </DensityBanner>
      );
    }
  };

  // TODO: Update all these in the data-layer once styling is finalized
  const CloudinaryConfigs = { aspectRatio: 2.6, quality: 'auto:best', crop: 'crop' };

  return (
    <Wrapper>
      {renderNavLinks()}
      <Div ref={elementRef}>
        <LeftColumn>
          {service.isVirtual() && (
            <LiveStream className="bold purple">
              <IconWrapper>
                <VirtualIcon color={neutral.black} />
              </IconWrapper>
              {service.isZoom ? (
                <FormattedMessage {...Messages.zoomEvent} />
              ) : (
                <FormattedMessage {...Messages.livestream} />
              )}
            </LiveStream>
          )}
          <ServiceHeader>
            <ServiceTitle>{service.title}</ServiceTitle>
            <ShareButton service={service} />
          </ServiceHeader>
          <PartnerText>
            <FormattedMessage values={{ partner: service.partner }} {...Messages.providedByPartner} />
          </PartnerText>
          <OrderContainer order={-1}>
            <AspectRatioContainer ratio={2.6} isCenter>
              <ServiceImage publicId={service.cloudinaryPublicId} transformation={CloudinaryConfigs} />
              {renderDensityBanner()}
            </AspectRatioContainer>
          </OrderContainer>
          {renderMobileServiceInfoBlock()}
          <DescriptionContent>
            <DescriptionText className="darkGrey">
              <div dangerouslySetInnerHTML={{ __html: service.description }} />
            </DescriptionText>
          </DescriptionContent>
        </LeftColumn>
        <RightColumn>{renderRightColumn()}</RightColumn>
      </Div>
      {service.isMultipleDates && !isPublic && <AvailabilityPicker service={service} />}
      {selectedDuration && <ScheduledAvailabilityPicker duration={selectedDuration} service={service} />}
      {isPublic && renderBottomButton()}
    </Wrapper>
  );
};

interface OrderProps {
  order: number;
}

const OrderContainer = styled.div<OrderProps>`
  @media (max-width: ${mediaQueries.m_mobile}) {
    order: ${({ order }) => order};
  }
`;

const BottomCtaContainer = styled.div`
  display: none;

  @media (max-width: ${mediaQueries.m_mobile}) {
    display: flex;
    position: sticky;
    bottom: 0;
    padding: 1em;
    background: white;
  }
`;

const NavLinks = styled.div`
  position: absolute;
  font-weight: 400;
  top: -5rem;
  z-index: ${({ theme }) => theme.zIndex.navbar};
  margin-left: 1rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    margin-left: 0;
  }
`;

const ServiceHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ServiceTitle = styled.h1`
  flex-grow: 1;
  margin: 1rem 0 2rem;
  font-size: 2.5rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    font-size: 3.5rem;
  }
  @media (max-width: ${mediaQueries.m_mobile}) {
    margin: 1rem;
  }
`;

const PartnerText = styled.div`
  font-size: ${typeScale.font12};
  padding-bottom: 2rem;

  @media (max-width: ${mediaQueries.m_mobile}) {
    margin: 1rem;
    padding: 0;
  }
`;

const MobileServiceBlockContainer = styled.div`
  display: none;

  @media (max-width: ${mediaQueries.m_mobile}) {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
`;

const BreadCrumbLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
`;

const Dash = styled.mark`
  color: ${({ theme }) => theme.colors.darkGrey};
  padding: 0 0.5rem;
`;

type DivProps = {
  ref: React.MutableRefObject<any>;
};

const Div = styled.div<DivProps>`
  display: flex;
  padding-bottom: 2rem;

  @media (max-width: ${mediaQueries.m_mobile}) {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  animation: opacityChange 1s ease-in-out both;
  overflow: hidden;
  width: 100%;

  @media (max-width: ${mediaQueries.m_mobile}) {
    overflow: inherit;
  }
`;

const LiveStream = styled.div`
  display: flex;
  vertical-align: center;

  @media (max-width: ${mediaQueries.m_mobile}) {
    margin: 1rem 1rem 0;
  }
`;

const IconWrapper = styled.div`
  padding-right: 0.5rem;
  padding-top: 0.1rem;
`;

const ServiceImage = styled(CloudinaryImage)`
  width: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
`;

const LeftColumn = styled.div`
  width: 70%;
  padding-right: 0.5rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding-right: 15%;
  }
  @media (max-width: ${mediaQueries.m_mobile}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0;
  }
`;

const RightColumn = styled.div`
  width: 30%;
  padding: 0;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 0 2rem;
  }
  @media (max-width: ${mediaQueries.m_mobile}) {
    display: none;
  }
`;

const DescriptionContent = styled.div`
  padding-bottom: 2rem;

  @media (max-width: ${mediaQueries.m_mobile}) {
    margin: 0 1rem;
  }
`;

const DescriptionText = styled.div`
  line-height: 1.5rem;
  margin-top: 3rem;

  @media (max-width: ${mediaQueries.m_mobile}) {
    margin-top: 0;
  }
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`;

const StyledMessage = styled.div`
  padding-left: 0.5rem;
  letter-spacing: 0.15em;
`;

const InfoLine = styled.div`
  display: flex;
  align-items: center;
  color: ${neutral.darkGrey};
`;
interface DensityBannerProps {
  background?: string;
}
const DensityBanner = styled.div<DensityBannerProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.65em 1em 0.25em;
  background-color: white;
  display: flex;
  align-items: center;
  background: ${({ background }) => background};
`;

const TileHeader = styled.span`
  font-size: ${typeScale.font14};
  display: flex;
  align-items: center;
  padding-bottom: 0.4rem;
  color: ${neutral.darkGrey};
`;

const TileSubheader = styled(TileHeader)`
  font-size: ${typeScale.font12};
`;

const IconContainer = styled.span`
  margin-right: 1em;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface OccupancyTextProps {
  severity?: CapacitySeverity;
}
const OccupancyBoldText = styled.b<OccupancyTextProps>`
  color: ${({ severity }) => (severity === CapacitySeverity.High ? defaultTheme.status.error : 'auto')};
`;

export default ServiceInfoWrapper;
