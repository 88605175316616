/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from 'styles/colors';
import { defaultTheme } from 'styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const InstructionsIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 18 18" fill="none">
      <path
        d="M9.36195 2.0747C10.4139 3.12667 10.4139 4.83226 9.36195 5.88424C8.30998 6.93621 6.60439 6.93621 5.55241 5.88424C4.50043 4.83226 4.50043 3.12667 5.55241 2.0747C6.60439 1.02272 8.30998 1.02272 9.36195 2.0747Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8239 9.61388C9.82184 9.21352 8.64622 8.9929 7.45825 8.9929C4.33559 8.9929 1.28699 10.5103 1.28699 12.8438V13.6152C1.28699 14.041 1.63258 14.3866 2.05839 14.3866H9.31966"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8589 16.7138C10.7291 16.7138 9.00189 14.9874 9.00189 12.8567C9.00189 10.7708 10.7761 8.99738 12.862 8.99969C14.9911 9.00123 16.716 10.7276 16.716 12.8567C16.716 14.9866 14.9896 16.7138 12.8589 16.7138Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3673 11.4931L13.7312 12.8569L12.3673 14.2208"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default InstructionsIcon;
