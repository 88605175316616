import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { isValidPhone } from 'zo-data-layer/schema/helpers';
import { LocaleOptions } from 'zo-data-layer/constants';

import Input from '../../Input';
import Messages from '../Messages';
import Select from '../../Select';
import Button from '../../Button';
import DatePicker from '../../DatePicker';

export type FormData = {
  firstName: string;
  lastName: string;
  phone?: string;
  languagePreference?: { value: string; label: string };
  jobTitle?: string;
  birthday?: Date;
  marketingOptedIn: { label: JSX.Element; value: string };
};

type Props = {
  onSubmit: (data: FormData) => void;
  isLoading?: boolean;
  defaultValues?: FormData;
  onClickCancel: () => void;
  marketingOptions: { label: JSX.Element; value: string }[];
  readonlyValues?: {
    email: string;
    companyName?: string;
    floor?: string;
    campusName?: string;
  };
};

const MyProfileEditForm: FC<Props> = ({
  onSubmit,
  isLoading,
  defaultValues,
  readonlyValues,
  onClickCancel,
  marketingOptions,
}) => {
  const { register, control, handleSubmit, errors } = useForm<FormData>({ defaultValues });
  const intl = useIntl();
  const { locale } = useIntl();
  const { email, campusName, companyName, floor } = readonlyValues;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field>
        <Input
          id="firstName"
          name="firstName"
          label={intl.formatMessage(Messages.firstName)}
          error={errors?.firstName?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          })}
        />
      </Field>
      <Field>
        <Input
          id="lastName"
          name="lastName"
          label={intl.formatMessage(Messages.lastName)}
          error={errors?.lastName?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          })}
        />
      </Field>
      <Field>
        <Input
          id="phone"
          name="phone"
          type="tel"
          label={intl.formatMessage(Messages.phoneNumber)}
          error={errors?.phone?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
            validate: (value) =>
              (!!value && isValidPhone(value)) || <FormattedMessage {...Messages.invalidPhoneNumber} />,
          })}
        />
      </Field>
      <Field>
        <Input id="email" value={email} label={intl.formatMessage(Messages.emailAddress)} disabled />
      </Field>
      <Field>
        <Input id="company" value={companyName} label={intl.formatMessage(Messages.company)} disabled />
      </Field>
      <Field>
        <Controller
          as={Select}
          name="languagePreference"
          control={control}
          rules={{
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          }}
          id="languagePreference"
          label={<FormattedMessage {...Messages.languagePreference} />}
          error={(errors?.languagePreference as any)?.message}
          options={LocaleOptions}
        />
      </Field>
      <Field>
        <Input
          id="jobTitle"
          name="jobTitle"
          label={intl.formatMessage(Messages.jobTitle)}
          error={errors?.jobTitle?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          })}
        />
      </Field>
      <Field>
        <Input id="campus" value={campusName} label={intl.formatMessage(Messages.buildingCampus)} disabled />
      </Field>
      <Input id="floor" value={floor} label={intl.formatMessage(Messages.floor)} disabled />
      <Field>
        <Controller
          as={DatePicker}
          name="birthday"
          control={control}
          valueName="selected"
          onChange={([selected]) => selected}
          id="birthday"
          label={<FormattedMessage {...Messages.birthday} />}
          error={(errors?.birthday as any)?.message}
          locale={locale}
          innerRef={register()}
          compact
        />
      </Field>

      <Field>
        <Controller
          as={Select}
          name="marketingOptedIn"
          control={control}
          rules={{
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          }}
          id="marketingOptedIn"
          label={<FormattedMessage {...Messages.marketingOptedInShort} />}
          error={(errors?.marketingOptedIn as any)?.message}
          options={marketingOptions}
        />
      </Field>

      <Buttons>
        <Button
          className="withBorder"
          isLoading={isLoading}
          title={<FormattedMessage {...Messages.cancel} />}
          buttonType="link"
          onClick={onClickCancel}
        />
        <Button isLoading={isLoading} title={<FormattedMessage {...Messages.save} />} type="submit" />
      </Buttons>
    </Form>
  );
};

const Form = styled.form`
  padding: 2rem 0;
`;

const Buttons = styled.div`
  display: flex;
  > button:first-child {
    margin-right: 1rem;
  }
`;

const Field = styled.div`
  padding: 0.5rem 0;
`;

export default MyProfileEditForm;
