import { usePagination } from 'hooks';
import { List, Set } from 'immutable';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CampusesListActions, CampusesListRequest } from 'zo-data-layer/actions';
import { Campus } from 'zo-data-layer/dataobjects';
import { CampusesListSelectors } from 'zo-data-layer/selectors';
import { useCampusFilters } from './CampusFilters';
import { FilterButton } from './FilterButton';
import { FilterCheckbox } from './FilterCheckbox';
import { FilterList } from './FilterList';
import { FilterListContainer } from './FilterListContainer';
import { LoadingIndicator } from './LoadingIndicator';
import Messages from './Messages';

interface StateProps {
  totalCount: number;
  campuses: List<Campus>;
}

interface CampusFiltersSelectCampusPageProps extends StateProps {
  goBack(): void;
}

const CampusFiltersSelectCampusPageComponent = ({
  campuses,
  totalCount,
  goBack,
}: CampusFiltersSelectCampusPageProps) => {
  const {
    campuses: prevSelectedCampuses,
    regions: selectedRegions,
    category,
    setCampuses,
    clearCampuses,
  } = useCampusFilters();
  const [selectedCampuses, setSelectedCampuses] = useState(Set(prevSelectedCampuses.map((campus) => campus.id)));

  const { fetchFirstPage, fetchNextPage, hasNext } = usePagination<CampusesListRequest>(
    CampusesListActions.request,
    { regions: selectedRegions.map((r) => r.id), category: category },
    { totalCount, pageSize: 20 }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchFirstPage, []);

  const renderButtons = () => {
    const hasPrevSelection =
      prevSelectedCampuses.length > 0 && Set(prevSelectedCampuses.map((campus) => campus.id)).equals(selectedCampuses);

    const hasNewSelection = !Set(prevSelectedCampuses.map((campus) => campus.id)).equals(selectedCampuses);

    if (hasNewSelection) {
      return (
        <FilterButton
          onClick={() => {
            setCampuses(Array.from(campuses.filter((campus) => selectedCampuses.has(campus.id))));
            goBack();
          }}
          titleMessage={Messages.apply}
        />
      );
    }

    if (hasPrevSelection) {
      return (
        <FilterButton
          onClick={() => {
            clearCampuses();
            goBack();
          }}
          titleMessage={Messages.clearSelection}
        />
      );
    }
  };

  return (
    <FilterListContainer>
      <FilterList id="campus-list">
        <InfiniteScroll
          scrollableTarget="campus-list"
          dataLength={campuses.size}
          next={fetchNextPage}
          hasMore={campuses.size === 0 || hasNext}
          loader={<LoadingIndicator />}
        >
          {campuses.map((campus) => {
            const id = `campus-${campus.id}`;
            return (
              <FilterCheckbox
                key={id}
                id={id}
                label={campus.name}
                checked={selectedCampuses.has(campus.id)}
                onChange={(checked) => {
                  if (checked) {
                    setSelectedCampuses(selectedCampuses.add(campus.id));
                  } else {
                    setSelectedCampuses(selectedCampuses.delete(campus.id));
                  }
                }}
              />
            );
          })}
        </InfiniteScroll>
      </FilterList>

      {renderButtons()}
    </FilterListContainer>
  );
};

const mapStateToProps = createStructuredSelector<any, StateProps>({
  campuses: CampusesListSelectors.getCampuses(),
  totalCount: CampusesListSelectors.getCount(),
});

export const CampusFiltersSelectCampusPage = connect(mapStateToProps)(CampusFiltersSelectCampusPageComponent);
