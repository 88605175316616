/** External Dependencies */
import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { FieldElement, Message } from 'react-hook-form';

/** Styling */
import { typeScale, miscColors } from 'styles';

/** Assets */
import HiddenPNG from 'assets/images/Hidden.png';
import VisiblePNG from 'assets/images/Visible.png';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | JSX.Element;
  error?: Message;
  hideable?: boolean;
  innerRef?: (ref: FieldElement<any>) => void;
};

const Input: FC<Props> = ({ id, label, error, hideable, innerRef, ...inputProps }) => {
  const toggleVisibility = (inputId, iconId) => {
    const pwInput = document.getElementById(inputId);
    const visibilityIcon = document.getElementById(iconId);

    if (pwInput.getAttribute('type') === 'password') {
      visibilityIcon.removeAttribute('src');
      visibilityIcon.setAttribute('src', HiddenPNG);
      pwInput.removeAttribute('type');
      pwInput.setAttribute('type', 'text');
    } else {
      pwInput.removeAttribute('type');
      pwInput.setAttribute('type', 'password');
      visibilityIcon.removeAttribute('src');
      visibilityIcon.setAttribute('src', VisiblePNG);
    }
  };
  return (
    <Div>
      <StyledInput id={id} ref={innerRef} placeholder={typeof label === 'string' ? label : ''} {...inputProps} />
      <Label htmlFor={id}>{label}</Label>
      {hideable && <Img id={`icon-${id}`} src={VisiblePNG} onClick={() => toggleVisibility(id, `icon-${id}`)} />}
      <ErrorDiv>{error}</ErrorDiv>
    </Div>
  );
};

export const Img = styled.img`
  position: absolute;
  top: 0.4rem;
  right: 0.5rem;
  :hover {
    cursor: pointer;
  }
`;

export const ErrorDiv = styled.div`
  color: ${(props) => props.theme.status.error};
  font-size: ${typeScale.helperText};
  height: ${typeScale.helperText};
  padding: 0.2rem 0;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1.4rem;
`;

export interface Compactable {
  compact?: boolean;
}

export const Label = styled.label<Compactable>`
  font-size: ${typeScale.helperText};
  color: ${(props) => props.theme.colors.black};
  position: absolute;
  margin-bottom: 1rem;
  transition: all 0.1s ease;
  bottom: ${(props) => (props.compact ? null : '2.2rem')};
  width: 100%;
`;

export const StyledInput = styled.input`
  width: 100%;
  font-size: ${typeScale.paragraph};
  border: none;
  border-bottom: 1px solid ${miscColors.divider};
  transition: border-color 1s ease, color 1s ease;
  padding: 0.5rem 0;
  :active {
    color: ${(props) => props.theme.colors.black};
  }
  :hover:enabled {
    border-bottom: 1px solid ${(props) => props.theme.colors.black};
  }
  :focus {
    outline: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.black};
    font-size: ${typeScale.paragraph} !important;
  }
  :focus,
  :not(:placeholder-shown) ~ ${Label} {
  }
  :placeholder-shown ~ ${Label} {
    opacity: 0;
    bottom: 1rem;
  }

  :disabled {
    background: none;
    opacity: 0.5;

    + label {
      opacity: 0.5;
    }
  }
`;

export default Input;
