/** External Dependencies */
import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/** Interal dependencies */
import Messages from './Messages';
import { goBack } from 'utils/navigation';
import { LocaleContext } from 'containers/IntlContainer';

/** Data layer */
import { MyActivityLookupActions, MyActivityMarkReadActions } from 'zo-data-layer/actions';
import { MyActivityLookupSelectors } from 'zo-data-layer/selectors';

/** Components */
import LoadingIndicator from 'components/LoadingIndicator';
import { RouteWrapper } from './MyPageContainer';
import AspectRatioContainer from 'components/AspectRatioContainer';
import CloudinaryImage from 'components/CloudinaryImage';
import Button from 'components/Button';

/** Styling */
import { typeScale, neutral } from 'styles';

const memoizedSelectors = {
  activity: MyActivityLookupSelectors.getActivity(),
  isLoaded: MyActivityLookupSelectors.isSuccess(),
};

const MyAlertDetails = () => {
  const { currentLocale: locale } = useContext(LocaleContext);
  const { alertId } = useParams<{ alertId: string }>();
  const dispatch = useDispatch();
  const isLoaded = useSelector(memoizedSelectors.isLoaded);
  const alert = useSelector(memoizedSelectors.activity);
  const today = new Date();
  const hasAlertEndTime = alert && alert.endTime;
  const futureEvent = hasAlertEndTime && new Date(alert.endTime) > today;
  const showSeeMoreButton = !hasAlertEndTime || futureEvent;

  useEffect(() => {
    if (alertId) {
      dispatch(MyActivityLookupActions.request(parseInt(alertId, 10)));
    } else {
      goBack();
    }
  }, [alertId, dispatch]);

  useEffect(() => {
    if (isLoaded && !alert.isRead) {
      dispatch(MyActivityMarkReadActions.request(parseInt(alertId, 10)));
    }
  }, [dispatch, alert, alertId, isLoaded]);

  if (!isLoaded) {
    return (
      <RouteWrapper>
        <Columns>
          <LoadingIndicator />
        </Columns>
      </RouteWrapper>
    );
  }

  // TODO: Update all these in the data-layer once styling is finalized
  const CloudinaryConfigs = { aspectRatio: 2.5, quality: 'auto:best', crop: 'crop' };

  return (
    <RouteWrapper>
      <Columns>
        <H3>{alert.headingForLanguage(locale) || alert.defaultHeading}</H3>
        <DateSent>
          <FormattedMessage {...Messages.sent} />
          {alert.formattedCreatedFullDate(locale)}
        </DateSent>
        <ImageContainer>
          <AspectRatioContainer ratio={2.5} isCenter>
            <AlertImage
              className="roundUpperCorners"
              publicId={alert.cloudinaryImageId}
              transformation={CloudinaryConfigs}
            />
          </AspectRatioContainer>
        </ImageContainer>
        <ContentText>{alert.contentForLanguage(locale) || alert.defaultContent}</ContentText>
        {alert.url && showSeeMoreButton && (
          <StyledButton
            title={<FormattedMessage {...Messages.seeMore} />}
            buttonType="a"
            linkTo={alert.webUrl || alert.url}
          />
        )}
      </Columns>
    </RouteWrapper>
  );
};

export default MyAlertDetails;

const Columns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const H3 = styled.h3`
  margin-top: 0;
  margin-bottom: 1rem;
`;

const DateSent = styled.div`
  font-size: ${typeScale.font12};
`;

const ImageContainer = styled.div`
  padding: 2rem 0;
  max-width: 23.25rem;
`;

const AlertImage = styled(CloudinaryImage)`
  width: 100%;
  object-fit: cover;
`;

const ContentText = styled.div`
  padding-bottom: 3rem;
  line-height: 1.5rem;
  color: ${neutral.darkGrey};
`;

const StyledButton = styled(Button)`
  width: 18.5625rem;
`;
