import { defineMessages, MessageDescriptor } from 'react-intl';

const paymentMethod: MessageDescriptor = {
  id: 'creditCard.paymentMethod',
  defaultMessage: 'Payment Method',
};

const useDifferentCard: MessageDescriptor = {
  id: 'creditCard.useDifferentCard',
  defaultMessage: 'Use a different card',
};

const addNewCard: MessageDescriptor = {
  id: 'creditCard.addNewCard',
  defaultMessage: 'Add New Card',
};

const cardNumber: MessageDescriptor = {
  id: 'creditCard.cardNumber',
  defaultMessage: 'Card Number',
};

const expiryDate: MessageDescriptor = {
  id: 'creditCard.expiryDate',
  defaultMessage: 'Expiration Date',
};

const securityCode: MessageDescriptor = {
  id: 'creditCard.securityCode',
  defaultMessage: 'Security Code',
};

const promoCode: MessageDescriptor = {
  id: 'creditCard.promoCode',
  defaultMessage: 'Promo Code',
};

const addCard = {
  id: 'creditCard.addCard',
  defaultMessage: 'Add Card',
};

const yesRemove = {
  id: 'creditCard.yesRemove',
  defaultMessage: 'Yes, Remove',
};

const removeCard = {
  id: 'creditCard.removeCard',
  defaultMessage: 'Remove Card',
};

const areYouSure = {
  id: 'creditCard.areYouSure',
  defaultMessage: 'Are you sure you would like to remove this card?',
};

const fieldRequired: MessageDescriptor = {
  id: 'forms.fieldRequired',
  defaultMessage: 'Field required',
};

const cancel = {
  id: 'forms.cancel',
  defaultMessage: 'Cancel',
};

const apply = {
  id: 'forms.apply',
  defaultMessage: 'Apply',
};

const invalidPromocode = {
  id: 'creditCard.promoCodeInvalid',
  defaultMessage: 'That promo code is no longer valid',
};

const enterPromoCode = {
  id: 'creditCard.enterPromoCode',
  defaultMessage: 'Insert Promo Code',
};

export default defineMessages({
  paymentMethod,
  useDifferentCard,
  addNewCard,
  cardNumber,
  securityCode,
  expiryDate,
  promoCode,
  addCard,
  removeCard,
  areYouSure,
  fieldRequired,
  cancel,
  yesRemove,
  apply,
  invalidPromocode,
  enterPromoCode,
});
