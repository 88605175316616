import { DefaultTheme } from 'styled-components';

const animations = (theme: DefaultTheme) => `
  @keyframes bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes bodyRise {
    0% {
      opacity: 0;
      padding-top: 10rem;
    }
    100% {
      opacity: 1;
      padding-top: ${theme.size.pageContainerVerticalPadding};
    }
  }

  @keyframes headerLower {
    0% {
      padding-top: 0;
    }
    100% {
      padding-top: ${theme.size.extendedNavbarHeightLg};
    }
  }

  @keyframes opacityChange {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes subHeaderLower{
    0% {
      top: 0
    }
    100% {
      top: ${theme.size.navbarHeight};
    }
  }
`;

export default animations;
