/** External Dependencies **/
import React, { useEffect, FC } from 'react';
import { Dispatch, bindActionCreators, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

/** Data layer **/
import { dismissToast } from 'zo-data-layer/actions';
import { makeSelectToastState } from 'zo-data-layer/selectors';
import { DismissTimeout } from 'zo-data-layer/constants/toast';

/** Styling */
import { mediaQueries } from '../../styles';
import { defaultTheme } from '../../styles/themes';

/** Components **/
import InfoIcon from '../Icons/InfoIcon';
import SuccessIcon from '../Icons/SuccessIcon';
import CancelIcon from '../Icons/CancelIcon';

interface IToast {
  toastType: string;
  toastMessage: { defaultMessage: string; id: string };
  isToastDisplay: boolean;
  dismissToast: () => AnyAction;
}

type ToastProps = {
  isDisplay: string;
  background: string;
};

const getColor = (toastType: string) => defaultTheme.status[toastType];

const getBackgroundColor = (toastType: string): string => defaultTheme.statusBackground[toastType];

const getAnimation = (isToastDisplay: boolean): string =>
  isToastDisplay ? defaultTheme.animations.display : defaultTheme.animations.hide;

const ErrorMessage = ({ toastType, toastMessage, dismissToast }) => (
  <>
    <StyledMessage>
      {toastType === 'success' ? (
        <SuccessIcon color={getColor(toastType)} width="1rem" />
      ) : (
        <InfoIcon color={getColor(toastType)} width="1rem" />
      )}
      <StyledText>
        <FormattedMessage {...toastMessage} />
      </StyledText>
    </StyledMessage>
    <StyledCloseIcon onClick={dismissToast}>
      <CancelIcon />
    </StyledCloseIcon>
  </>
);

const Toast: FC<IToast> = ({ toastType, toastMessage, isToastDisplay, dismissToast }) => {
  useEffect(() => {
    const timer = setTimeout(dismissToast, DismissTimeout);
    return () => clearTimeout(timer);
  }, [isToastDisplay, toastMessage, dismissToast]);

  return (
    <StyledToast
      color={getColor(toastType)}
      background={getBackgroundColor(toastType)}
      isDisplay={getAnimation(isToastDisplay)}
    >
      {toastMessage.id && (
        <ErrorMessage toastType={toastType} toastMessage={toastMessage} dismissToast={dismissToast} />
      )}
    </StyledToast>
  );
};

const StyledMessage = styled.span`
  @media (min-width: ${mediaQueries.m_mobile}) {
    display: inline-block;
    text-align: center;
  }
  display: contents;
  flex-grow: 1;
`;

const StyledText = styled.span`
  flex-grow: 1;
`;

const StyledCloseIcon = styled.span`
  flex-grow: 0;
  cursor: pointer;
`;

const StyledToast = styled.div.attrs((props: ToastProps) => ({
  background: props.background,
  isDisplay: props.isDisplay,
}))`
  @media (min-width: ${mediaQueries.m_mobile}) {
    margin: 0;
    height: ${({ theme }) => theme.size.toastHeight};
    border-top: 3px solid ${(props) => props.color};
    border-left: none;
    border-radius: 0px;
    align-items: center;
  }
  display: flex;
  align-items: flex-start;
  z-index: ${({ theme }) => theme.zIndex.toast};
  background: ${(props) => props.background};
  border-top: none;
  border-left: ${(props) => (props.isDisplay ? `6px solid ${props.color}` : 'none')};
  border-radius: 10px;
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  color: ${({ theme }) => theme.colors.primaryColorDark};
  height: ${({ theme }) => theme.size.toastHeightMobile};
  transform: ${(props) => props.isDisplay};
  padding: 1rem;
  overflow: hidden;
  transition: transform 0.7s;
  margin: 2rem 0.5rem;
`;

const memoizedSelectors = {
  makeSelectToastState: makeSelectToastState(),
};

const mapStateToProps = (state: { [key: string]: any }) => ({
  toastType: memoizedSelectors.makeSelectToastState(state).get('type'),
  toastMessage: memoizedSelectors.makeSelectToastState(state).get('message'),
  isToastDisplay: memoizedSelectors.makeSelectToastState(state).get('display'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      dismissToast,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
