import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'react-intl';

import { MeSelectors } from 'zo-data-layer/selectors';
import { isProduction } from 'zo-data-layer/constants/env';
import { useLocation } from 'react-router';
import { isPublicPage } from 'utils/navigation';

const zendeskProdKey = '5902e62c-016e-4a97-82d8-dc68b100673a';
const zendeskTestKey = '5b60db74-95f8-4f76-ad0d-b66ffa967099';

const zendeskKey = isProduction() ? zendeskProdKey : zendeskTestKey;

const memoizedSelectors = createStructuredSelector({
  zenDeskWidgetJwtToken: MeSelectors.zendeskWidgetJwtToken(),
  email: MeSelectors.myEmail(),
  fullName: MeSelectors.myFullName(),
  phone: MeSelectors.myPhone(),
});

declare global {
  interface Window {
    zE: any;
    zESettings: any;
  }
}

const ZendeskWidget = () => {
  const { zenDeskWidgetJwtToken, email, fullName, phone } = useSelector(memoizedSelectors);
  const intl = useIntl();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const location = useLocation();

  const resetWidget = useCallback(() => {
    window.zE('webWidget', isPublicPage(location) ? 'hide' : 'show');
    if (!zenDeskWidgetJwtToken) {
      window.zE('webWidget', 'logout');
    } else {
      window.zE('webWidget', 'close');
      window.zE('webWidget', 'clear');
      window.zE('webWidget', 'helpCenter:setSuggestions', { url: true });
      window.zE('webWidget', 'identify', {
        name: fullName,
        email: email,
      });
      window.zE('webWidget', 'setLocale', intl.locale);
      window.zE('webWidget', 'prefill', {
        name: {
          value: fullName,
        },
        email: {
          value: email,
        },
        phone: {
          value: phone,
        },
      });
    }
  }, [intl, fullName, email, phone, zenDeskWidgetJwtToken, location]);

  // setup zendesk authorization with the jwt token
  useEffect(() => {
    window.zESettings = {
      webWidget: {
        offset: {
          horizontal: '55px',
        },
        authenticate: {
          jwtFn: (callback) => {
            if (zenDeskWidgetJwtToken) {
              callback(zenDeskWidgetJwtToken || '');
            }
          },
        },
      },
    };
  }, [zenDeskWidgetJwtToken]);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.addEventListener('load', () => setIsScriptLoaded(true));
    document.body.appendChild(script);
  }, [setIsScriptLoaded]);

  useEffect(() => {
    if (isScriptLoaded) {
      if (window.zE) {
        resetWidget();
      } else {
        // Sometimes the widget isn't finished loading when resetWidget is run.
        // Thus, we add a timeout to ensure it runs again when it becomes available.
        setTimeout(resetWidget, 1000);
      }
    }
  }, [isScriptLoaded, resetWidget]);

  return null;
};

export default ZendeskWidget;
