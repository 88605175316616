import styled from 'styled-components';

const InfoPointList = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.layoutPadding};
`;

export default InfoPointList;

export const InfoPoint = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.layoutPaddingSm} -${({ theme }) => theme.spacing.layoutPaddingSm};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  * {
    vertical-align: middle;
  }

  & > * {
    padding: 0;
    margin: 0 ${({ theme }) => theme.spacing.layoutPaddingSm};
  }
`;
