/** External Dependencies */
import React, { FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
/** Internal dependencies */
import { history } from '../../compositionRoot';
import { useBooleanEffect } from '../../hooks';
import { generateSuccessPageLink } from '../../constants/routes';
import { IPromoCodeData } from '../../interfaces';
/** Data layer */
import { CreateInventoryBookingSelectors } from 'zo-data-layer/selectors';
import { InventoryBooking } from 'zo-data-layer/dataobjects';
import { ImmutableState } from 'zo-data-layer/utils/selectors';
/** Components */
import ServiceDetails from './ServiceDetails';
import { CurrentServiceContext, CurrentServiceInfo } from '../../contexts';
import { BookingType } from 'zo-data-layer/constants';

type InventoryServiceDetails = {
  promocode?: string;
  promocodeData?: Map<string, IPromoCodeData>;
};

type SelectorProps = {
  isInventoryBookingSuccess: boolean;
  inventoryBooking: InventoryBooking;
};

const structuredSelector = createStructuredSelector<ImmutableState, SelectorProps>({
  isInventoryBookingSuccess: CreateInventoryBookingSelectors.isSuccess(),
  inventoryBooking: CreateInventoryBookingSelectors.getData(),
});

const InventoryServiceDetails: FC<InventoryServiceDetails> = ({ promocodeData, promocode }) => {
  const { service } = useContext<CurrentServiceInfo>(CurrentServiceContext);
  const { isInventoryBookingSuccess, inventoryBooking } = useSelector(structuredSelector);
  const [selectedCardID, setSelectedCardID] = useState(undefined);

  useBooleanEffect(isInventoryBookingSuccess, () => {
    history.push(generateSuccessPageLink(service, inventoryBooking.id, BookingType.Inventory));
  });

  return (
    <ServiceDetails
      selectedCardID={selectedCardID}
      setSelectedCardID={setSelectedCardID}
      // Inventory bookings only have quantity 1
      quantity={1}
      promocode={promocode}
      promocodeData={promocodeData}
    />
  );
};

export default InventoryServiceDetails;
