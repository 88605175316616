/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const GoogleIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <svg width={width} height={width} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.2973 14.7375H18.3613V21.6984H28.0363C27.6191 23.9484 26.3535 25.8516 24.4457 27.1266C22.8332 28.2047 20.7754 28.8422 18.3566 28.8422C13.6738 28.8422 9.71289 25.6781 8.29727 21.4266C7.94102 20.3484 7.73477 19.1953 7.73477 18.0094C7.73477 16.8234 7.94102 15.6703 8.29727 14.5922C9.71758 10.3453 13.6785 7.18125 18.3613 7.18125C21.0004 7.18125 23.3676 8.09063 25.2332 9.87188L30.3895 4.71094C27.2723 1.80469 23.2082 0.0234375 18.3613 0.0234375C11.3348 0.0234375 5.25508 4.05469 2.29727 9.93281C1.07852 12.3609 0.384766 15.1078 0.384766 18.0141C0.384766 20.9203 1.07852 23.6625 2.29727 26.0906C5.25508 31.9687 11.3348 36 18.3613 36C23.2176 36 27.2863 34.3875 30.2582 31.6406C33.6566 28.5094 35.6207 23.8969 35.6207 18.4172C35.6207 17.1422 35.5082 15.9187 35.2973 14.7375Z"
      fill={color}
    />
  </svg>
);

export default GoogleIcon;
