import { usePagination } from 'hooks';
import { List, Set } from 'immutable';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RegionsListActions, RegionsListRequest } from 'zo-data-layer/actions';
import { Region } from 'zo-data-layer/dataobjects';
import { RegionsListSelectors } from 'zo-data-layer/selectors';
import { useCampusFilters } from './CampusFilters';
import { FilterButton } from './FilterButton';
import { FilterCheckbox } from './FilterCheckbox';
import { FilterList } from './FilterList';
import { FilterListContainer } from './FilterListContainer';
import { LoadingIndicator } from './LoadingIndicator';
import Messages from './Messages';

interface StateProps {
  totalCount: number;
  regions: List<Region>;
}

interface CampusFiltersSelectRegionPageProps extends StateProps {
  goBack(): void;
}

const CampusFiltersSelectRegionPageComponent = ({
  totalCount,
  regions,
  goBack,
}: CampusFiltersSelectRegionPageProps) => {
  const { regions: prevSelectedRegions, setRegions, clearRegions, category } = useCampusFilters();
  const [selectedRegions, setSelectedRegions] = useState(Set(prevSelectedRegions.map((region) => region.id)));
  const { fetchFirstPage, fetchNextPage, hasNext } = usePagination<RegionsListRequest>(
    RegionsListActions.request,
    { category },
    { totalCount, pageSize: 20 }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchFirstPage, []);

  const renderButtons = () => {
    const hasPrevSelection =
      prevSelectedRegions.length > 0 && Set(prevSelectedRegions.map((region) => region.id)).equals(selectedRegions);

    const hasNewSelection = !Set(prevSelectedRegions.map((region) => region.id)).equals(selectedRegions);

    if (hasNewSelection) {
      return (
        <FilterButton
          onClick={() => {
            setRegions(Array.from(regions.filter((region) => selectedRegions.has(region.id))));
            goBack();
          }}
          titleMessage={Messages.apply}
        />
      );
    }

    if (hasPrevSelection) {
      return (
        <FilterButton
          onClick={() => {
            clearRegions();
            goBack();
          }}
          titleMessage={Messages.clearSelection}
        />
      );
    }
  };

  return (
    <FilterListContainer>
      <FilterList id="region-list">
        <InfiniteScroll
          scrollableTarget="region-list"
          dataLength={regions.size}
          next={fetchNextPage}
          hasMore={regions.size === 0 || hasNext}
          loader={<LoadingIndicator />}
        >
          {regions.map((region) => {
            const id = `region-${region.id}`;
            return (
              <FilterCheckbox
                key={id}
                id={id}
                label={region.name}
                checked={selectedRegions.has(region.id)}
                onChange={(checked) => {
                  if (checked) {
                    setSelectedRegions(selectedRegions.add(region.id));
                  } else {
                    setSelectedRegions(selectedRegions.delete(region.id));
                  }
                }}
              />
            );
          })}
        </InfiniteScroll>
      </FilterList>

      {renderButtons()}
    </FilterListContainer>
  );
};

const mapStateToProps = createStructuredSelector<any, StateProps>({
  totalCount: RegionsListSelectors.getCount(),
  regions: RegionsListSelectors.getRegions(),
});

export const CampusFiltersSelectRegionPage = connect(mapStateToProps)(CampusFiltersSelectRegionPageComponent);
