/** External Dependencies */
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

/** Internal Dependencies */
import { useQuery } from '../../../hooks';

/** Components */
import { OverlayHeader, OverlayText } from '../../Overlay';
import Button from '../../Button';
import Messages from '../Messages';
import Input from '../../Input';

export function ResetPasswordForm({ setPassword }) {
  const { handleSubmit, register, errors, watch } = useForm();
  const intl = useIntl();

  const password = useRef({});
  password.current = watch('password', '');

  const query = useQuery();
  const token = query.get('token');

  return (
    <form onSubmit={handleSubmit((data) => setPassword({ password: data.password, token }))}>
      <OverlayHeader>
        <FormattedMessage {...Messages.resetYourPassword} />
      </OverlayHeader>
      <OverlayText>
        <FormattedMessage values={{ name: query.get('name') }} {...Messages.pleaseEnterNewPassword} />
      </OverlayText>

      <Input
        id="password"
        name="password"
        type="password"
        label={intl.formatMessage(Messages.newPassword)}
        error={errors?.password?.message}
        hideable
        innerRef={register({
          required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          minLength: {
            value: 8,
            message: <FormattedMessage {...Messages.atLeastEightCharacters} />,
          },
        })}
      />

      <Input
        id="passwordConfirm"
        name="passwordConfirm"
        type="password"
        label={intl.formatMessage(Messages.confirmPassword)}
        error={errors?.passwordConfirm?.message}
        hideable
        innerRef={register({
          required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          minLength: {
            value: 8,
            message: <FormattedMessage {...Messages.atLeastEightCharacters} />,
          },
          validate: (value) => value === watch('password') || <FormattedMessage {...Messages.passwordsDoNotMatch} />,
        })}
      />

      <Button type="submit" title={<FormattedMessage {...Messages.resetMyPassword} />} />
    </form>
  );
}
