/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { brand } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const PlusSignIcon: FC<Props> = ({ color = brand.blue, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="22.4434"
        y="11.1297"
        width="1.5"
        height="20.8868"
        rx="0.75"
        transform="rotate(90 22.4434 11.1297)"
        fill={color}
      />
      <rect width="1.5" height="20.8868" rx="0.75" transform="matrix(-1 0 0 1 12.8703 1.55658)" fill={color} />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 1rem;
`;

export default PlusSignIcon;
