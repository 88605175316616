import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { EditPasswordSelectors } from 'zo-data-layer/selectors';
import { EditPasswordActions } from 'zo-data-layer/actions';

import FormMessages from 'components/Forms/Messages';
import Button from 'components/Button';
import Input from 'components/Input';
import { useBooleanEffect } from '../../hooks';
import { RouteWrapper } from './MyPageContainer';

type SelectorProps = {
  isLoading: boolean;
  isSuccess: boolean;
};

const mapReduxState = createStructuredSelector<any, SelectorProps>({
  isLoading: EditPasswordSelectors.isLoading(),
  isSuccess: EditPasswordSelectors.isSuccess(),
});

type FormData = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const ChangeMyPasswordSection: FC = () => {
  const { isLoading, isSuccess } = useSelector(mapReduxState);
  const intl = useIntl();
  const { register, watch, handleSubmit, errors, reset } = useForm<FormData>();
  const dispatch = useDispatch();

  function handleChangePassword(data: FormData) {
    dispatch(EditPasswordActions.request(data.newPassword, data.currentPassword));
  }

  useBooleanEffect(isSuccess, () => {
    dispatch(EditPasswordActions.reset());
    reset();
  });

  const currentPassword = watch('currentPassword');
  const newPassword = watch('newPassword');
  const confirmNewPassword = watch('confirmNewPassword');

  const isSubmitButtonDisabled = !currentPassword || !newPassword || !confirmNewPassword;

  return (
    <RouteWrapper>
      <Form onSubmit={handleSubmit(handleChangePassword)}>
        <Input
          id="currentPassword"
          name="currentPassword"
          type="password"
          hideable
          autoComplete="current-password"
          label={intl.formatMessage(FormMessages.currentPassword)}
          error={errors?.currentPassword?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...FormMessages.fieldRequired} /> },
          })}
        />
        <Input
          id="newPassword"
          name="newPassword"
          type="password"
          hideable
          autoComplete="new-password"
          label={intl.formatMessage(FormMessages.newPassword)}
          error={errors?.newPassword?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...FormMessages.fieldRequired} /> },
            minLength: {
              value: 8,
              message: <FormattedMessage {...FormMessages.atLeastEightCharacters} />,
            },
          })}
        />
        <Input
          id="confirmNewPassword"
          name="confirmNewPassword"
          type="password"
          hideable
          autoComplete="new-password"
          label={intl.formatMessage(FormMessages.confirmNewPassword)}
          error={errors?.confirmNewPassword?.message}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...FormMessages.fieldRequired} /> },
            validate: (value) => value === newPassword || <FormattedMessage {...FormMessages.passwordsDoNotMatch} />,
          })}
        />
        <Buttons>
          <Button
            isLoading={isLoading}
            isDisabled={isSubmitButtonDisabled}
            title={<FormattedMessage {...FormMessages.save} />}
            type="submit"
          />
        </Buttons>
      </Form>
    </RouteWrapper>
  );
};

export default ChangeMyPasswordSection;

const Form = styled.form`
  padding-top: 2rem;
`;

const Buttons = styled.div`
  display: flex;
  max-width: 18.5625rem;
  * {
    margin: 0;
  }
`;
