import { defineMessages, MessageDescriptor } from 'react-intl';

const header: MessageDescriptor = {
  id: 'addToCalendar.header',
  defaultMessage: 'Add to Calendar',
};

const subHeader: MessageDescriptor = {
  id: 'addToCalendar.subHeader',
  defaultMessage: 'Click any of the links below to add to your calendar',
};

const floorLabel: MessageDescriptor = {
  id: 'addToCalendar.floor',
  defaultMessage: 'Floor',
};

const roomLabel: MessageDescriptor = {
  id: 'addToCalendar.room',
  defaultMessage: 'Room',
};

export default defineMessages({
  header,
  subHeader,
  floorLabel,
  roomLabel,
});
