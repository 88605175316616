/** External Dependencies */
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { List } from 'immutable';
import { components } from 'react-select';

/** Internal dependencies */
import Messages from './Messages';
import { Modals } from 'constants/Layouts';
import { HiddenCardNumbers } from 'constants/Stripe';
import { mapSelector, mapPaymentSource } from 'utils/selectors';
import PaymentSourceObject from 'utils/PaymentSourceObject';
import useBooleanEffect from 'hooks/useBooleanEffect';

/** Data layer */
import { ListPaymentSourcesSelectors } from 'zo-data-layer/selectors';
import { openModal, MyPaymentSourcesListActions } from 'zo-data-layer/actions';

/** Components */
import Select from '../Select';
import PlusSignIcon from '../Icons/PlusSignIcon';
import CreditCardIcon from './CreditCardIcon';

/** Styling */
import { neutral, typeScale, brand } from 'styles';

type Props = {
  selectedCardID: string;
  setSelectedCardID: (id: string) => void;
  loadPaymentSources: () => AnyAction;
  paymentSources: List<PaymentSourceObject>;
  isPaymentSourcesLoaded: boolean;
  openModal: (modalName: string) => AnyAction;
};

const { Option } = components;

const paymentSourcesToOptions = (paymentSources) => {
  const options = paymentSources.map((paymentSource: PaymentSourceObject) => ({
    value: paymentSource.id,
    label: (
      <CCNumber>
        {HiddenCardNumbers} {paymentSource.last4}
      </CCNumber>
    ),
    icon: <CreditCardIcon brand={paymentSource.brand} />,
  }));

  const allOptions = options.push({
    custom: true,
    value: 'new',
    label: <FormattedMessage {...Messages.useDifferentCard} />,
  });
  return allOptions;
};

const CustomSelectOption = (props: any) => {
  if (props.data.custom) {
    return (
      <Option {...props}>
        <CustomLabel>
          <PlusSignIcon />
          {props.data.label}
        </CustomLabel>
      </Option>
    );
  }
  return (
    <Option {...props}>
      <Span>
        {props.data.icon}
        {props.data.label}
      </Span>
    </Option>
  );
};

const CustomSingleValue = ({ data }) => (
  <div>
    <Span>
      <SelectedLabel>
        <FormattedMessage {...Messages.paymentMethod} />
      </SelectedLabel>
      {data.value !== 'new' && data.icon}
      {data.value !== 'new' && data.label}
    </Span>
  </div>
);

const CreditCardSelect: FC<Props> = ({
  loadPaymentSources,
  paymentSources,
  isPaymentSourcesLoaded,
  openModal,
  setSelectedCardID,
}) => {
  useBooleanEffect(!isPaymentSourcesLoaded, () => {
    loadPaymentSources();
  });

  const paymentSourceOptions = useMemo(() => paymentSourcesToOptions(paymentSources), [paymentSources]);

  const handleSelectChange = (option, action) => {
    if (option.value === 'new') {
      openModal(Modals.AddCard);
    }
    setSelectedCardID(option.value);
  };

  const customStyles = {
    option: (styles, { data, isFocused, isSelected }) => ({
      ...styles,
      borderTop: data.custom && `0px solid ${neutral.lightGrey}`,
      // eslint-disable-next-line multiline-ternary
      padding: data.custom ? '1rem' : '8px 12px',
      background: isSelected ? brand.lightGreen : isFocused ? brand.mintGreen : null,
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      border: 'none',
      borderColor: isFocused ? brand.brightGreen : null,
      boxShadow: isFocused ? `0 0 0 1px ${brand.brightGreen}` : null,
    }),
    menu: (styles) => ({
      ...styles,
      boxShadow: 'none',
      position: 'relative',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    valueContainer: (styles) => ({
      ...styles,
      flexWrap: 'nowrap',
    }),
  };

  return (
    <SelectWrapper>
      <Select
        placeholder={<FormattedMessage {...Messages.paymentMethod} />}
        name="creditCard"
        id="creditCard"
        onChange={handleSelectChange}
        options={paymentSourceOptions}
        styles={customStyles}
        components={{ Option: CustomSelectOption, SingleValue: CustomSingleValue }}
      />
    </SelectWrapper>
  );
};

type SelectorProps = {
  paymentSources: List<any>;
  isPaymentSourcesLoaded: boolean;
};

const mapStateToProps = createStructuredSelector<any, SelectorProps>({
  paymentSources: mapSelector(ListPaymentSourcesSelectors.getData(), mapPaymentSource),
  isPaymentSourcesLoaded: ListPaymentSourcesSelectors.isSuccess(),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loadPaymentSources: MyPaymentSourcesListActions.request,
      openModal: (modalName) => openModal(modalName),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardSelect);

const SelectWrapper = styled.div`
  padding: 1.5rem 0;
`;

const Span = styled.span`
  display: flex;
  align-items: center;
`;

const CustomLabel = styled.label`
  color: ${({ theme }) => theme.colors.primaryColor};
  font-weight: 400;
  font-size: 1rem;
`;

const SelectedLabel = styled.div`
  font-weight: 400;
  font-size: ${typeScale.font14};
  padding-right: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CCNumber = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${neutral.darkGrey};
  font-size: ${typeScale.font14};
`;
