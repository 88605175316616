/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useParams, Link } from 'react-router-dom';

/** Internal Dependencies */
import { AppLinks } from 'constants/routes';
import Messages from './Messages';
import { Modals } from 'constants/Layouts';
import { myAccountPageNames } from 'constants/routes';

/** Components */
import FullScreenContainer from 'components/FullScreenContainer';
import ZoLogo from 'components/ZoLogo';
import { CancelIcon, ChevronLeft } from 'components/Icons';
import Aside, { AsideRightSide, AsideHeader, AsideContent, AsideFooter, AsideWrapper } from 'components/Aside';
import DeleteAccountModal from 'components/DeleteAccountModal';
import AddNewCardModal from 'components/CreditCard/AddNewCardModal';
import ModalContainer from 'components/ModalContainer';
import ConfirmDeleteCardModal from 'components/CreditCard/ConfirmDeleteCardModal';

/** Styling */
import { mediaQueries, neutral } from 'styles';

type Props = {
  sideHeader: JSX.Element;
  sideNavLinks: JSX.Element;
  sideFooter?: JSX.Element;
  routes: JSX.Element;
};

type RouteParams = {
  myAccountRoute: string;
  myAccountSubRoute?: string;
};

const myPageTitle = (myPageRoute: string, myPageSubRoute?: string) => {
  if (myPageSubRoute) return;

  switch (myPageRoute) {
    case myAccountPageNames.myAlerts:
      return (
        <H1>
          <FormattedMessage {...Messages.alerts} />
        </H1>
      );
    case myAccountPageNames.myBookings:
      return (
        <H1>
          <FormattedMessage {...Messages.yourBookings} />
        </H1>
      );
    case myAccountPageNames.myPaymentMethods:
      return (
        <PaymentHeader>
          <H1>
            <FormattedMessage {...Messages.paymentMethods} />
          </H1>
          <p className="darkGrey">
            <FormattedMessage {...Messages.manageOrEditPayment} />
          </p>
        </PaymentHeader>
      );
    case myAccountPageNames.myPassword:
      return (
        <H1>
          <FormattedMessage {...Messages.changePassword} />
        </H1>
      );
    default:
      return;
  }
};

const MyPageContainer: FC<Props> = ({ sideHeader, sideNavLinks, sideFooter, routes }) => {
  const { myAccountRoute, myAccountSubRoute } = useParams<RouteParams>();

  // My Profile has an edit button in its header, so the styling for this will be different;
  const isCustomHeader = myAccountRoute === myAccountPageNames.myProfile;

  return (
    <FullScreenContainer>
      <LeftToRightLayoutWrapper>
        <Aside>
          <UpperLeftLogoNav>
            <ZoLogo color="white" />
          </UpperLeftLogoNav>
          <AsideWrapper>
            <AsideContent>
              <AsideHeader>{sideHeader}</AsideHeader>
              {sideNavLinks}
            </AsideContent>
            <AsideFooter>{sideFooter}</AsideFooter>
          </AsideWrapper>
        </Aside>
        <AsideRightSide>
          <FixedRightHeader isCustomHeader={isCustomHeader}>
            {!myAccountSubRoute ? (
              <MyPageTitle>{myPageTitle(myAccountRoute, myAccountSubRoute)}</MyPageTitle>
            ) : (
              <BackLink to={`${AppLinks.myAccount}${myAccountRoute}`}>
                <ChevronLeft color="black" width="1rem" padding="0 1rem 0 0" />
                <FormattedMessage {...Messages.back} />
              </BackLink>
            )}
            <CloseButton to={AppLinks.discover}>
              <FormattedMessage {...Messages.close} /> &nbsp;
              <CancelIcon width="1.2rem" />
            </CloseButton>
          </FixedRightHeader>
          <Content>{routes}</Content>
        </AsideRightSide>
      </LeftToRightLayoutWrapper>
      <ModalContainer name={Modals.DeleteAccount}>
        <DeleteAccountModal />
      </ModalContainer>
      <ModalContainer name={Modals.AddCard}>
        <AddNewCardModal />
      </ModalContainer>
      <ModalContainer name={Modals.ConfirmDeleteCard}>
        <ConfirmDeleteCardModal />
      </ModalContainer>
    </FullScreenContainer>
  );
};

const PaymentHeader = styled.div`
  margin-bottom: -3rem;
`;

const H1 = styled.h1`
  margin-bottom: 0;
`;

const LeftToRightLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: none;

  @media (min-width: ${mediaQueries.m_mobile}) {
    flex-direction: row;
  }
`;

const CloseButton = styled(Link)`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  font-weight: 400;
  color: ${neutral.darkGrey};
`;

const MyPageTitle = styled.div`
  display: flex;
  align-self: flex-end;
  margin-bottom 0;
`;

const BackLink = styled(Link)`
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-self: flex-end;
  padding-bottom: 1rem;
  margin-left: -2rem;
`;

const UpperLeftLogoNav = styled.nav`
  padding: ${({ theme }) => `${theme.spacing.layoutPaddingSm} ${theme.spacing.layoutPadding}`};
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.primaryColor};
  width: 100%;
  height: ${({ theme }) => theme.size.navbarHeight};
  z-index: ${({ theme }) => theme.zIndex.navbar};
`;

type FixedHeaderProps = {
  isCustomHeader?: boolean;
};

const FixedRightHeader = styled.div<FixedHeaderProps>`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: ${({ theme }) => theme.zIndex.navbar};
  height: ${({ isCustomHeader }) => (!isCustomHeader ? '11rem' : '0')};
  background: white;

  @media (min-width: ${mediaQueries.m_mobile}) {
    height: ${({ isCustomHeader }) => (!isCustomHeader ? '15rem' : '0')};
    padding: 3rem;
    padding-left: ${({ theme }) => theme.spacing.routeWrapperSidePadding}}
  }
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
  background: white;
`;

export const RouteWrapper = styled.div`
  width: 100%;
  padding: 1rem;

  > div:first-child {
    margin-top: 0;
  }

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 0 ${({ theme }) => theme.spacing.routeWrapperSidePadding}}
  }
`;

export default MyPageContainer;
