import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
import type { MessageDescriptor } from '@formatjs/intl';

import { displayToast } from 'zo-data-layer/actions';
import { ToastTypes } from 'zo-data-layer/constants';
import { MyService } from 'zo-data-layer/dataobjects';
import GenericTimeBasedBooking from 'zo-data-layer/dataobjects/GenericTimeBasedBooking';

import { useAnalytics } from 'analytics';
import { ShareIcon } from 'components/Icons';
import { defaultTheme, miscColors, brand } from 'styles';

import Messages from './Messages';
import { isMobile } from 'utils/helpers';

interface Props {
  width?: string;
  service: MyService | GenericTimeBasedBooking;
}

const ShareButton: React.FC<Props> = ({ service, width = defaultTheme.size.serviceIconSize }) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const handleClick = () => {
    navigator.clipboard.writeText(service.branchioLink).then(() => {
      dispatch(displayToast(Messages.copiedToClipboard as MessageDescriptor, ToastTypes.success));
      analytics.logShareEvent(service);
    });
  };

  return (
    <ButtonContainer onClick={handleClick} data-tip="copyLink">
      <ShareIcon width={width} />
      <ReactTooltip
        place="bottom"
        backgroundColor="transparent"
        effect="solid"
        disable={isMobile()}
        offset={{ top: 10 }}
      >
        <TooltipContainer>
          <TooltipText>
            <FormattedMessage {...Messages.copyLink} />
          </TooltipText>
        </TooltipContainer>
      </ReactTooltip>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button`
  background: none;
  border: none;
  padding: 0.5em;
  cursor: pointer;
  margin-left: 1em;
`;

const TooltipContainer = styled.div`
  align-items: center;
  background-color: ${miscColors.tooltip};
  border-radius: 2px;
  display: flex;
  padding: 0.25em 0.5em;
  justify-content: center;
`;

const TooltipText = styled.mark`
  color: ${brand.teal};
  font-size: 12px;
`;

export default ShareButton;
