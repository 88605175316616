/** External Dependencies */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';

/** Internal Dependencies */
import Messages from './Messages';

/** Components */
import ServiceTile from '../ServiceTile';

/** Data layer */
import MyService from 'zo-data-layer/dataobjects/MyService';

/** Styling */
import { mediaQueries } from 'styles';
interface ITileSection {
  name?: string;
  tiles?: List<MyService>;
  title?: JSX.Element;
  subtitle?: JSX.Element;
  pathname?: string;
  isLoaded: boolean;
  // categoryId's that get passed through this component come from RouteParams
  categoryId?: string;
  // Slightly different styling
  isLeaderSection?: boolean;
}

const renderSeeMoreLink = (name: string) => {
  switch (name) {
    case 'today':
      return <FormattedMessage {...Messages.seeMoreToday} />;
    case 'upcoming':
      return <FormattedMessage {...Messages.seeMoreUpcoming} />;
    case 'all':
      return <FormattedMessage {...Messages.seeMoreAll} />;
  }
};

type TileListProps = {
  tiles: List<MyService>;
  categoryId: string;
  isLeaderSection?: boolean;
};

const TileList: FC<TileListProps> = ({ tiles, categoryId, isLeaderSection = false }) => {
  if (!tiles || tiles.size < 1)
    return (
      <Tiles>
        <SorryMessage>
          <FormattedMessage {...Messages.sorryThereAreNone} />
        </SorryMessage>
      </Tiles>
    );
  return (
    <Tiles>
      {tiles.map((tile) => (
        <ServiceTile
          service={tile}
          key={tile.serviceId}
          categoryId={categoryId}
          showLocation={!!categoryId}
          isLeaderSection={isLeaderSection}
        />
      ))}
    </Tiles>
  );
};

const TileSection: FC<ITileSection> = ({
  tiles,
  title,
  subtitle,
  pathname,
  name,
  isLoaded,
  categoryId,
  isLeaderSection = false,
}) =>
  isLoaded && (
    <TileSectionWrapper>
      <HeaderDiv>
        {title && <SectionTitle isLeaderSection={isLeaderSection}>{title}</SectionTitle>}
        {pathname && (
          <Link to={`${pathname}/#results`}>
            <SectionLink className="bold purple">{renderSeeMoreLink(name)}</SectionLink>
          </Link>
        )}
      </HeaderDiv>
      {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
      <TileList tiles={tiles} categoryId={categoryId} isLeaderSection={isLeaderSection} />
    </TileSectionWrapper>
  );

const TileSectionWrapper = styled.div``;

type HeaderProps = {
  isLeaderSection: boolean;
};

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const SectionLink = styled.p`
  transition: color 1s ease;
  padding-right: 3rem;
`;

const SectionTitle = styled.h1<HeaderProps>`
  color: ${({ theme }) => theme.colors.primaryColorDark};
  margin: 3rem 0 2rem;
`;

const SectionSubtitle = styled.h3`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.primaryColorDark};
`;

const SorryMessage = styled.h4`
  margin-top: 3rem;
`;

export const Tiles = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${mediaQueries.m_mobile}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export default TileSection;
