import React, { FC } from 'react';
import styled from 'styled-components';

import { CalendarType } from './helper';
import { isIE } from 'utils/helpers';
import { neutral } from '../../styles';
import AnalyticsEvents from 'zo-data-layer/constants/AnalyticsEvents';
import useAnalytics, { IServiceAnalytics } from 'analytics/useAnalytics';
import GenericTimeBasedBooking from 'zo-data-layer/dataobjects/GenericTimeBasedBooking';

type Props = {
  booking: GenericTimeBasedBooking & IServiceAnalytics;
  type: CalendarType;
  label: string;
  url: string;
  isBlob?: boolean;
  logo?: FC<any>;
};

const CalendarButton: FC<Props> = ({ booking, type, label, isBlob = false, url, logo = null }) => {
  const analytics = useAnalytics();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    analytics.logServiceEvent(AnalyticsEvents.BookingAddedToCalendar, booking, { calendarType: type });
    if (isBlob) {
      const filename = 'event.ics';
      const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

      if (isIE()) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      window.open(url, '_blank');
    }
  };

  const Logo = logo;

  return (
    <Button onClick={handleClick}>
      {Logo && <Logo width="2rem" color={neutral.darkGrey} />}
      <span>{label}</span>
    </Button>
  );
};

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span {
    margin-top: 1rem;
  }
`;

export default CalendarButton;
