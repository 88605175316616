/** External Dependencies */
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';

/** Data layer */
import {
  MyCategoriesListAllSelectors,
  MyCategoriesListAdminOnlySelectors,
  MyCategoriesResultSets,
} from 'zo-data-layer/selectors';
import { MyCategoriesListActions } from 'zo-data-layer/actions';
import { MyCategory } from 'zo-data-layer/dataobjects';

const allCategoriesSelectors = {
  categories: MyCategoriesListAllSelectors.getCategories(),
  isLoaded: MyCategoriesListAllSelectors.isSuccess(),
};

const adminOnlySelectors = {
  categories: MyCategoriesListAdminOnlySelectors.getCategories(),
  isLoaded: MyCategoriesListAdminOnlySelectors.isSuccess(),
};

export function useCategories(adminOnly = false) {
  const dispatch = useDispatch();
  const categoriesSelectors = useMemo(() => (adminOnly ? adminOnlySelectors : allCategoriesSelectors), [adminOnly]);

  const categories: List<MyCategory> = useSelector(categoriesSelectors.categories);
  const isCategoriesLoaded: boolean = useSelector(categoriesSelectors.isLoaded);

  useEffect(() => {
    if (!isCategoriesLoaded)
      dispatch(
        MyCategoriesListActions.request({
          pageSize: 100,
          adminOnly: adminOnly,
          resultSetName: adminOnly ? MyCategoriesResultSets.AdminOnly : MyCategoriesResultSets.All,
        })
      );
  }, [dispatch, isCategoriesLoaded, adminOnly]);

  return { categories, isCategoriesLoaded };
}
