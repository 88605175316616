import { useEffect, useState } from 'react';

const useDevicePixelRatio = (): number => {
  const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio);

  useEffect(() => {
    const mqString = `(resolution: ${devicePixelRatio}dppx)`;
    const media = matchMedia(mqString);
    const updatePixelRatio = () => {
      setDevicePixelRatio(window.devicePixelRatio);
    };

    media.addEventListener('change', updatePixelRatio);
    return () => {
      media.removeEventListener('change', updatePixelRatio);
    };
  }, [devicePixelRatio]);

  return devicePixelRatio;
};

export default useDevicePixelRatio;
