/** External Dependencies **/
import React, { FC } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, AnyAction } from 'redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { createStructuredSelector } from 'reselect';

/** Internal Dependencies */
import Messages from './Messages';
import { Modals } from '../../constants/Layouts';

/** Data layer **/
import { closeModal, DeleteMyAccountActions } from 'zo-data-layer/actions';
import { DeleteMyAccountSelectors } from 'zo-data-layer/selectors';

/** Components */
import Button from '../Button';
import Input from '../Input';

/** Styling */
import { mediaQueries } from '../../styles';

type Props = {
  deleteMyAccount: () => AnyAction;
  isDeleteLoading: boolean;
  isDeleteSuccess: boolean;
};

const DeleteAccountModal: FC<Props> = ({ deleteMyAccount, isDeleteLoading }) => {
  const intl = useIntl();
  const onSubmit = () => {
    deleteMyAccount();
  };

  const { register, watch } = useForm({ mode: 'onChange' });
  const inputText = watch('inputText');
  const translate = (id: string) => intl.formatMessage(Messages[id]);
  const isDisabled = inputText !== translate('deleteText');

  return (
    <ModalWrapper>
      <HeaderSpan>
        <Header>
          <FormattedMessage {...Messages.deleteYourAccount} />
        </Header>
        <b>
          <FormattedMessage {...Messages.warningDeleteAccount} />
        </b>
      </HeaderSpan>
      <InputWrapper>
        <Input
          id="inputText"
          name="inputText"
          label={intl.formatMessage(Messages.pleaseTypeDelete)}
          innerRef={register({
            required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          })}
        />
      </InputWrapper>
      <ButtonWrapper>
        <Button
          title={<FormattedMessage {...Messages.yesDeleteAccount} />}
          onClick={onSubmit}
          isDisabled={isDisabled}
          isLoading={isDeleteLoading}
        />
      </ButtonWrapper>
    </ModalWrapper>
  );
};

type SelectorProps = {
  isDeleteLoading: boolean;
  isDeleteSuccess: boolean;
};

const mapStateToProps = createStructuredSelector<any, SelectorProps>({
  isDeleteLoading: DeleteMyAccountSelectors.isLoading(),
  isDeleteSuccess: DeleteMyAccountSelectors.isSuccess(),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: () => closeModal(Modals.DeleteAccount),
      deleteMyAccount: DeleteMyAccountActions.request,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountModal);

const ModalWrapper = styled.div``;

const HeaderSpan = styled.span`
  display: inline-block;
  padding: 1rem 0;

  @media (min-width: ${mediaQueries.m_mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.h3`
  margin-top: 0;
  padding-right: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 2rem;

  button:first-child {
    margin-right: 1rem;
  }
`;

const InputWrapper = styled.div`
  padding-top: 3rem;
  height: 6rem;
`;
