/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from '../../styles';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const SeatIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 5.5C0.947715 5.5 0.5 5.94772 0.5 6.5V9.5V10C0.5 10.8284 1.17157 11.5 2 11.5H12C12.8284 11.5 13.5 10.8284 13.5 10V9.5V6.5C13.5 5.94772 13.0523 5.5 12.5 5.5C11.9477 5.5 11.5 5.94772 11.5 6.5V9H2.5V6.5C2.5 5.94772 2.05228 5.5 1.5 5.5Z"
        stroke={color}
        strokeLinejoin="round"
      />
      <line x1="2.5" y1="11.5" x2="2.5" y2="13.5" stroke={color} strokeLinecap="round" />
      <line x1="11.5" y1="11.5" x2="11.5" y2="13.5" stroke={color} strokeLinecap="round" />
      <path
        d="M2.5 4C2.5 3.17157 3.17157 2.5 4 2.5H10C10.8284 2.5 11.5 3.17157 11.5 4V9H2.5V4Z"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default SeatIcon;
