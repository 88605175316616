import { defineMessages, MessageDescriptor } from 'react-intl';

const termsAndConditions: MessageDescriptor = {
  id: 'footer.terms',
  defaultMessage: 'Terms and Conditions',
};

const privacyPolicy: MessageDescriptor = {
  id: 'footer.privacy',
  defaultMessage: 'Privacy Policy',
};

const support: MessageDescriptor = {
  id: 'footer.support',
  defaultMessage: 'Support',
};

export default defineMessages({ termsAndConditions, privacyPolicy, support });
