/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from 'styles/colors';
import { defaultTheme } from 'styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const BellIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 13.7981C7 9.42307 7.875 4.17307 14 4.17307C20.125 4.17307 21 9.42307 21 13.7981C21 18.1731 23.625 20.7981 23.625 20.7981H4.375C4.375 20.7981 7 18.1731 7 13.7981Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1539 21.5385H16.9039C16.9039 21.1242 16.5681 20.7885 16.1539 20.7885H11.8462C11.4319 20.7885 11.0962 21.1242 11.0962 21.5385H11.8462C11.0962 21.5385 11.0962 21.5392 11.0962 21.5399L11.0962 21.5414L11.0962 21.5446L11.0963 21.5511L11.0966 21.5657C11.097 21.5761 11.0975 21.5877 11.0983 21.6004C11.1 21.6259 11.103 21.6563 11.1079 21.6908C11.1177 21.7596 11.1357 21.8461 11.1683 21.9439C11.2344 22.1421 11.3588 22.381 11.5851 22.6072C12.0446 23.0667 12.8075 23.3654 14 23.3654C15.1925 23.3654 15.9555 23.0667 16.415 22.6072C16.6412 22.381 16.7656 22.1421 16.8317 21.9439C16.8643 21.8461 16.8823 21.7596 16.8921 21.6908C16.897 21.6563 16.9 21.6259 16.9017 21.6004C16.9025 21.5877 16.9031 21.5761 16.9034 21.5657L16.9038 21.5511L16.9038 21.5446L16.9038 21.5414L16.9039 21.5399C16.9039 21.5392 16.9039 21.5385 16.1539 21.5385Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default BellIcon;
