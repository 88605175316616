import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { PublicServicesGetActions, openModal } from 'zo-data-layer/actions';
import { AnalyticsEvents, ServiceType } from 'zo-data-layer/constants';
import { MyService } from 'zo-data-layer/dataobjects';
import { PublicServicesGetSelectors } from 'zo-data-layer/selectors';
import { ImmutableState } from 'zo-data-layer/utils/selectors';

import { useAnalytics } from 'analytics';
import { useAuthenticated } from 'auth';
import Button from 'components/Button';
import { PlayIcon } from 'components/Icons';
import LoadingIndicator from 'components/LoadingIndicator';
import ModalContainer from 'components/ModalContainer';
import { Modals } from 'constants/Layouts';
import Overlay, { Size as OverlaySize } from 'components/Overlay';
import { AppLinks } from 'constants/routes';
import ServiceInfoCTA from 'components/ServiceBookingFlow/ServiceInfoCTA';
import ServiceInfoWrapper from 'components/ServiceBookingFlow/ServiceInfoWrapper';
import { useBooleanEffect, usePrevious, useSticky } from 'hooks';
import LoginPage from 'pages/LoginPage';
import { mediaQueries, neutral } from 'styles';
import Messages from './Messages';
import { redirectToPath } from 'utils/navigation';

type RouteParams = {
  publicUuid: string;
};

type SelectorProps = {
  service: MyService;
  isSuccess: boolean;
};

const mapReduxState = createStructuredSelector<ImmutableState, SelectorProps>({
  service: PublicServicesGetSelectors.getService(),
  isSuccess: PublicServicesGetSelectors.isSuccess(),
});

const PublicServiceInfoPage: FC = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { publicUuid } = useParams<RouteParams>();
  const { service, isSuccess } = useSelector(mapReduxState);
  const prevIsSuccess = usePrevious(isSuccess);
  const authenticated = useAuthenticated();

  useEffect(() => {
    dispatch(PublicServicesGetActions.request(publicUuid));
  }, [dispatch, publicUuid]);

  useBooleanEffect(prevIsSuccess === false && isSuccess && !authenticated, () =>
    analytics.logServiceEvent(AnalyticsEvents.ServiceViewed, service, { isPublic: true })
  );

  if (authenticated && service && isSuccess) {
    return <Redirect to={`${AppLinks.serviceInfo}${service.id}`} />;
  }

  const LoadingState = () => (
    <ContentWrapper>
      <Overlay size={OverlaySize.Large}>
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      </Overlay>
    </ContentWrapper>
  );

  const handleLoginRedirect = () => {
    redirectToPath(`${AppLinks.serviceInfo}${service?.id}`);
  };

  return (
    <>
      {service && isSuccess ? <ServiceInfo service={service} /> : LoadingState()}
      <ModalContainer name={Modals.SignIn} opacity={0.3} centered>
        <LoginPage loginRedirect={handleLoginRedirect} />
      </ModalContainer>
    </>
  );
};

const ServiceInfo = ({ service }) => {
  const dispatch = useDispatch();
  const { isSticky, elementRef } = useSticky();
  const handlePublicClick = () => {
    dispatch(openModal(Modals.SignIn));
  };

  const renderCTA = () => {
    if (service.isSoldOut) return <ServiceInfoCTA service={service} fullWidth={true} />;

    switch (service.serviceType) {
      case ServiceType.SSO:
      case ServiceType.Link:
      case ServiceType.Inventory:
        return <ServiceInfoCTA service={service} fullWidth={true} onClick={handlePublicClick} />;
      case ServiceType.Event:
        if (service.isMultipleDates)
          return <Button title={<FormattedMessage {...Messages.checkAvailabilites} />} onClick={handlePublicClick} />;

        return <ServiceInfoCTA service={service} fullWidth={true} onClick={handlePublicClick} />;
      case ServiceType.Video:
        return (
          <Button
            title={<FormattedMessage {...Messages.watch} />}
            icon={<PlayIcon color={neutral.white} fill={neutral.white} />}
            onClick={handlePublicClick}
          />
        );
      default:
        return;
    }
  };

  const renderSubHeaderButton = () => {
    if (service.cta || service.serviceType === ServiceType.Video) {
      return renderCTA();
    }
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <SubHeader className={isSticky ? 'sticky' : ''}>
          <LeftColumn>
            <ServiceTitle>{service.title}</ServiceTitle>
          </LeftColumn>
          <RightColumn>{renderSubHeaderButton()}</RightColumn>
        </SubHeader>
        <Overlay size={OverlaySize.Large}>
          <ServiceInfoWrapper service={service} elementRef={elementRef} isPublic={true} />
        </Overlay>
      </ContentWrapper>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mediaQueries.m_mobile}) {
    align-items: stretch;
  }
`;

const SubHeader = styled.nav`
  position: fixed;
  display: none;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundColor};
  width: 100%;
  height: ${({ theme }) => theme.size.navbarHeight};
  z-index: ${({ theme }) => theme.zIndex.z4};
  padding: ${({ theme }) => `${theme.spacing.layoutPaddingSm} 1rem`};

  &.sticky {
    display: flex;
    animation: subHeaderLower 0.5s ease-in-out both;
  }

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: ${({ theme }) => `${theme.spacing.layoutPaddingSm} 14%`};
  }
`;

const LeftColumn = styled.div`
  width: 70%;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: ${mediaQueries.m_mobile}) {
    width: 100%;
  }
`;

const RightColumn = styled.div`
  width: 30%;

  @media (max-width: ${mediaQueries.m_mobile}) {
    display: none;
  }
`;

const ServiceTitle = styled.h3`
  padding-right: 0.5rem;
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default PublicServiceInfoPage;
