import { AccessToken, useAuthenticated } from './utils/authentication';

/**
 * Authorization factory.
 */
const Allow: { authenticated: boolean } = {
  /**
   * Verify a user is authenticated.
   */
  get authenticated(): boolean {
    return AccessToken.isAuthenticated();
  },
};

const Token = AccessToken;

export { Allow, Token, useAuthenticated };
