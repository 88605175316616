/** External Dependencies **/
import React, { FC } from 'react';
import { Dispatch, compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/** Data layer */
import { Actions } from 'zo-data-layer';
import { makeSelectPasswordResetSuccessState } from 'zo-data-layer/selectors';

/** Components */
import Overlay, { Size as OverlaySize } from '../../components/Overlay';
import { ResetPasswordForm } from '../../components/Forms/ResetPasswordForm/ResetPasswordForm';

type Props = {
  setPassword: ({ password, token }: { password: string; token: string }) => any;
};

const ResetPasswordPage: FC<Props> = ({ setPassword }) => (
  <>
    <Overlay size={OverlaySize.Medium}>
      <ResetPasswordForm setPassword={setPassword} />
    </Overlay>
  </>
);

const mapStateToProps = createStructuredSelector({
  resetSuccess: makeSelectPasswordResetSuccessState(),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setPassword: Actions.setPassword,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(ResetPasswordPage);
