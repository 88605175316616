import { defineMessages, MessageDescriptor } from 'react-intl';

const copiedToClipboard: MessageDescriptor = {
  id: 'shareEvent.copiedToClipboard',
  defaultMessage: 'Link copied to clipboard',
};

const copyLink: MessageDescriptor = {
  id: 'shareEvent.copyLink',
  defaultMessage: 'Copy link',
};

export default defineMessages({
  copiedToClipboard,
  copyLink,
});
