import { defineMessages, MessageDescriptor } from 'react-intl';

const corporateResourcesForLeader: MessageDescriptor = {
  id: 'leader.corporateResourcesForLeader',
  defaultMessage: 'Corporate resources for leaders like you',
};

const servicesAndInformation: MessageDescriptor = {
  id: 'leader.servicesAndInformation',
  defaultMessage: 'Services And Information',
};

const seeWhatsHappeningAtYourBuilding: MessageDescriptor = {
  id: 'leader.seeWhatsHappeningAtYourBuilding',
  defaultMessage: 'See what’s happening at your building',
};

const manageUsers: MessageDescriptor = {
  id: 'leader.manageUsers',
  defaultMessage: 'Manage Users',
};

export default defineMessages({
  corporateResourcesForLeader,
  servicesAndInformation,
  seeWhatsHappeningAtYourBuilding,
  manageUsers,
});
