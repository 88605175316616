/** External Dependencies **/
import { FC, useEffect, useState, useContext } from 'react';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';

/** Data layer **/
import { MeSelectors } from 'zo-data-layer/selectors';
import { ImmutableState } from 'zo-data-layer/utils/selectors';
import { apiUrl } from 'zo-data-layer/services/service.helpers';
import { isProduction } from 'zo-data-layer/constants/env';
import { MyActivityCreateActions } from 'zo-data-layer/actions';
import { CountryCode } from 'zo-data-layer/constants';

/** Internal Dependencies */
import AccessToken from '../utils/authentication/AccessToken';
import { LocaleContext } from 'containers/IntlContainer';
import ErrorReporter from 'analytics/error.analytics';

type StateProps = {
  oneSignalAppId?: string;
  userId?: number;
  isLoaded: boolean;
  company?: string;
  campus?: string;
  building?: string;
  floor?: string;
  countryCode?: CountryCode;
  region?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
};

type OneSignalInitParams = {
  appId: string;
  allowLocalhostAsSecureOrigin: boolean;
  notifyButton: {
    enable: boolean;
  };
  webhooks: {
    cors: boolean;
    'notification.displayed': string;
    'notification.clicked': string;
  };
};

type Notification = {
  id: string;
  heading: string;
  content: string;
  data: any;
  url?: string;
  app_url?: string;
  web_url?: string;
  icon: string;
  end_time?: string;
};

type OneSignalSDK = {
  push: (cb: () => void) => void;
  init: (opts: OneSignalInitParams) => void;
  on: (eventName: string, cb: (notification: Notification) => void) => void;
  setExternalUserId: (id: number) => void;
  sendTags: (tags: Record<string, string>) => void;
};

declare global {
  interface Window {
    OneSignal?: OneSignalSDK;
  }
}

/**
 * OneSignalService initializes the one signal service with the current user's information.
 */
const OneSignalService: FC = () => {
  const {
    isLoaded,
    oneSignalAppId,
    userId,
    company,
    campus,
    building,
    floor,
    countryCode,
    region,
    firstName,
    lastName,
    fullName,
  } = useSelector(mapStateToProps);
  const [isInitialized, setInitialized] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { currentLocale: locale } = useContext(LocaleContext);

  // Initialize OneSignal
  useEffect(() => {
    const handleTokenUpdate = () => {
      console.debug('OneSignalService: Reloading due to token refresh.');
      if (isInitialized) {
        // due to the way that the web SDK works init can only be called once.
        // this presents a big problem when the auth token is refreshed because we need
        // a valid token in order to persist the incoming notifications
        // to get around this we will force a page reload if the token is refreshed.
        // this is a really ugly workaround. A better solution will have to be designed later.
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        return;
      }
    };
    AccessToken.onTokenSet(handleTokenUpdate);
    AccessToken.onTokenDestroy(handleTokenUpdate);
    return () => {
      AccessToken.offTokenDestroy(handleTokenUpdate);
      AccessToken.offTokenSet(handleTokenUpdate);
    };
  }, [isInitialized]);

  useEffect(() => {
    if (isLoaded && oneSignalAppId) {
      const OneSignal = window.OneSignal;
      if (OneSignal) {
        OneSignal.push(() => {
          OneSignal.init({
            appId: oneSignalAppId,
            allowLocalhostAsSecureOrigin: !isProduction(),
            notifyButton: {
              enable: true,
            },
            webhooks: {
              cors: true,
              'notification.displayed': apiUrl(`/myactivities/webhook?auth_token=${AccessToken.accessToken}`),
              'notification.clicked': apiUrl(`/myactivities/webhook?auth_token=${AccessToken.accessToken}`),
            },
          });
          setInitialized(true);
          console.debug('OneSignalService: Initialized');
        });
      }
    }
  }, [isLoaded, oneSignalAppId, setInitialized]);

  // Listeners
  useEffect(() => {
    const handleNotification = (notification: Notification, isRead: boolean) => {
      const { content, heading, id, url, web_url, app_url, end_time } = notification;

      dispatch(
        MyActivityCreateActions.request({
          externalId: id,
          headings: {
            [locale]: heading,
          },
          contents: {
            [locale]: content,
          },
          url: url,
          webUrl: web_url || url,
          appUrl: app_url,
          isRead,
          endTime: end_time,
        })
      );
    };

    if (isLoaded && isInitialized) {
      const OneSignal = window.OneSignal;
      if (OneSignal) {
        OneSignal.push(() => {
          OneSignal.on('notificationDisplay', (notification: Notification) => {
            console.debug('OneSignalService - Notification displayed: ', notification);
            handleNotification(notification, false);
          });
        });
      }
    }
  }, [dispatch, isInitialized, isLoaded, locale]);

  // Send properties
  useEffect(() => {
    if (isLoaded && isInitialized) {
      const OneSignal = window.OneSignal;
      if (OneSignal) {
        OneSignal.push(() => {
          OneSignal.setExternalUserId(userId);
          OneSignal.sendTags({
            real_name: fullName,
            first_name: firstName,
            last_name: lastName,
            company,
            campus,
            building,
            floor,
            countryCode,
            region,
          });
        });
      } else {
        ErrorReporter.captureMessage('Unable to initialize One Signal');
      }
    }
  }, [
    building,
    campus,
    company,
    countryCode,
    firstName,
    floor,
    fullName,
    isInitialized,
    isLoaded,
    lastName,
    region,
    userId,
  ]);

  return null;
};

const mapStateToProps = createStructuredSelector<ImmutableState, StateProps>({
  oneSignalAppId: MeSelectors.myOneSignalAppId(),
  isLoaded: MeSelectors.isSuccess(),
  userId: MeSelectors.myId(),
  company: MeSelectors.myCompanyName(),
  campus: MeSelectors.myCampusName(),
  building: MeSelectors.myBuildingName(),
  floor: MeSelectors.myFloorLabel(),
  countryCode: MeSelectors.myCountryCode(),
  region: MeSelectors.myRegionName(),
  fullName: MeSelectors.myFullName(),
  firstName: MeSelectors.myFirstName(),
  lastName: MeSelectors.myLastName(),
});

export default OneSignalService;
