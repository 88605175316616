/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

/** Styling */
import { typeScale, mediaQueries } from 'styles';

/** Components */
import LoadingIndicator from '../LoadingIndicator';
import NotReadIcon from 'components/Icons/NotReadIcon';

type Props = {
  className?: string;
  buttonType?: 'link' | 'counter' | 'a';
  title?: string | JSX.Element; // @deprecated use titleMessage instead
  titleMessage?: MessageDescriptor;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  fullWidth?: boolean;
  count?: any;
  icon?: JSX.Element;
  isIconRight?: boolean;
  isDisabled?: boolean;
  isUppercase?: boolean;
  linkTo?: string;
  showDot?: boolean;
};

const Button: FC<Props> = ({
  className,
  buttonType,
  title,
  titleMessage,
  onClick,
  type = 'button',
  isLoading,
  fullWidth = true,
  count,
  linkTo,
  icon,
  isIconRight,
  isDisabled,
  isUppercase,
  showDot = false,
}) => {
  const theTitle = title ? title : <FormattedMessage {...titleMessage} />;
  const titleElement = linkTo ? <Link to={linkTo}>{theTitle}</Link> : <span>{theTitle}</span>;

  if (buttonType === 'link') {
    return (
      <LinkButton
        className={className}
        disabled={isLoading || isDisabled}
        onClick={onClick}
        fullWidth={fullWidth}
        isUppercase={isUppercase}
        icon={icon}
        isIconRight={isIconRight}
      >
        <>
          {isIconRight ? null : icon}
          <TitleSpan>{theTitle}</TitleSpan>
          {isIconRight ? icon : null}
        </>
      </LinkButton>
    );
  }
  if (buttonType === 'a') {
    return (
      <a href={linkTo} target="_blank" rel="noopener noreferrer">
        <StyledButton
          className={className}
          disabled={isLoading || isDisabled}
          fullWidth={fullWidth}
          isUppercase={isUppercase}
          icon={icon}
          isIconRight={isIconRight}
        >
          <>
            {isIconRight ? null : icon}
            <TitleSpan>{theTitle}</TitleSpan>
            {isIconRight ? icon : null}
          </>
        </StyledButton>
      </a>
    );
  }
  if (buttonType === 'counter') {
    return (
      <CounterButton
        className={className}
        disabled={isLoading || isDisabled}
        onClick={onClick}
        fullWidth={fullWidth}
        isUppercase={isUppercase}
        count={count}
      >
        <TitleSpan>
          {theTitle} <mark>| {count}</mark>
        </TitleSpan>
        {count && showDot ? <NotReadIcon /> : null}
      </CounterButton>
    );
  }
  return (
    <StyledButton
      className={className}
      disabled={isLoading || isDisabled}
      type={type}
      onClick={onClick}
      fullWidth={fullWidth}
      isIconRight={isIconRight}
      icon={icon}
      isUppercase={isUppercase}
    >
      {isLoading ? (
        <LoadingIndicator isInverted />
      ) : (
        <>
          {isIconRight ? null : icon}
          <TitleSpan>{titleElement}</TitleSpan>
          {isIconRight ? icon : null}
        </>
      )}
    </StyledButton>
  );
};

type ButtonProps = {
  fullWidth: boolean;
  count?: number;
  icon?: JSX.Element;
  isIconRight?: boolean;
  isDisabled?: boolean;
  isUppercase?: boolean;
};

const StyledButton = styled.button<ButtonProps>`
  display: flex;
  background-color: ${(props) => props.theme.colors.primaryColor};
  color: ${(props) => props.theme.colors.white};
  text-transform: ${(props) => (props.isUppercase ? 'uppercase' : 'none')};
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 1rem 0.75rem;
  }

  &.clearBackground {
    color: ${(props) => props.theme.colors.primaryColor};
    background: none;
    border: 1px solid ${(props) => props.theme.colors.primaryColor};
  }
  &:disabled {
    opacity: 0.7;
    background-color: ${(props) => props.theme.colors.darkGrey};
    color: ${(props) => props.theme.colors.white};
    cursor: not-allowed;

    &:hover {
      opacity: 0.9;
    }
  }
  border-radius: 4px;
  width: ${(props) => (props.fullWidth ? '100%' : 'max-content')};
  height: 3.25rem;
  cursor: pointer;
  transition: ${(props) => props.theme.animations.transition};
  &:hover {
    opacity: 0.9;
  }
`;

export const LinkButton = styled(StyledButton)`
  color: ${(props) => props.theme.colors.primaryColor};
  background: none;
  &.primaryColor {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primaryColor};
  }
  &.withBorder {
    border: 1px solid ${(props) => props.theme.colors.primaryColor};
  }
  &:disabled {
    opacity: 0.7;
    background-color: ${(props) => props.theme.colors.darkGrey};
    color: ${(props) => props.theme.colors.white};
    cursor: not-allowed;
  }
`;

export const CounterButton = styled(StyledButton)`
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.lightGrey};
  padding: 0.5rem 1rem;
  height: 2.1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  &:disabled {
    border: 0.125rem solid ${(props) => props.theme.colors.disabled};
    color: ${(props) => props.theme.colors.disabled};
    cursor: not-allowed;
  }
  &:focus,
  &.active,
  &:hover {
    background: ${(props) => props.theme.colors.primaryColor};
    color: ${(props) => props.theme.colors.white};
    mark {
      color: ${(props) => props.theme.colors.primaryColorLight};
    }
  }
  mark {
    background-color: transparent;
    color: ${(props) => props.theme.colors.darkGrey};
  }
`;

const TitleSpan = styled.span`
  font-size: ${typeScale.font14};
  padding: 0 0.5rem;
  overflow: hidden;

  @media (min-width: ${mediaQueries.m_mobile}) {
    font-size: ${typeScale.buttonText};
  }
`;

export default Button;
