import { CancelIcon, ChevronLeft } from 'components/Icons';
import { usePrevious } from 'hooks';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { defaultTheme, mediaQueries, neutral, typeScale } from 'styles';
import { Campus, Region } from 'zo-data-layer/dataobjects';
import { CampusFiltersProvider } from './CampusFilters';
import { CampusFiltersSelectCampusPage } from './CampusFiltersSelectCampusPage';
import { CampusFiltersSelectFilterPage } from './CampusFiltersSelectFilterPage';
import { CampusFiltersSelectRegionPage } from './CampusFiltersSelectRegionPage';
import { LinkButton } from './LinkButton';
import { FormattedMessage } from 'react-intl';
import Messages from './Messages';

interface Props {
  isOpen: boolean;
  onClose(): void;
  category: number;
  onClear(): void;
  onApply(regions: Region[], campuses: Campus[]): void;
  initialRegions: Region[];
  initialCampuses: Campus[];
}

enum Page {
  SelectFilter = 'SelectFilter',
  SelectRegion = 'SelectRegion',
  SelectCampus = 'SelectCampus',
}

export const CampusFiltersModal = ({
  isOpen,
  onClose,
  onClear,
  onApply,
  category,
  initialRegions,
  initialCampuses,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState(Page.SelectFilter);

  useOnPressEsc(onClose);
  useOnClickOutside(containerRef, onClose);

  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (isOpen !== prevIsOpen && isOpen) {
      setPage(Page.SelectFilter);
    }

    // Hide Zendesk widget
    window.zE?.('webWidget', isOpen ? 'hide' : 'show');

    // Hide OneSignal's Bell
    const oneSignalBell = document.getElementById('onesignal-bell-container');
    if (oneSignalBell) {
      oneSignalBell.style.display = isOpen ? 'none' : 'block';
    }
  }, [isOpen, prevIsOpen]);

  if (!isOpen) {
    return null;
  }

  const renderPage = () => {
    switch (page) {
      case Page.SelectFilter:
        return (
          <CampusFiltersSelectFilterPage
            close={onClose}
            selectRegions={() => setPage(Page.SelectRegion)}
            selectCampuses={() => setPage(Page.SelectCampus)}
          />
        );
      case Page.SelectRegion:
        return <CampusFiltersSelectRegionPage goBack={() => setPage(Page.SelectFilter)} />;

      case Page.SelectCampus:
        return <CampusFiltersSelectCampusPage goBack={() => setPage(Page.SelectFilter)} />;
    }
  };

  const renderTitle = () => {
    switch (page) {
      case Page.SelectFilter:
        return <FormattedMessage {...Messages.title} />;
      case Page.SelectRegion:
        return <FormattedMessage {...Messages.region} />;

      case Page.SelectCampus:
        return <FormattedMessage {...Messages.campus} />;
    }
  };

  const canGoBack = page !== Page.SelectFilter;

  return (
    <FixedWrapper>
      <Container ref={containerRef}>
        <TitleBarWrapper>
          <TitleBar>
            {canGoBack ? (
              <LinkButton onClick={() => setPage(Page.SelectFilter)}>
                <ChevronLeft color={neutral.darkGrey} width="1rem" />
              </LinkButton>
            ) : (
              <LinkButton onClick={onClear}>
                <FormattedMessage {...Messages.clear} />
              </LinkButton>
            )}
            <Title>{renderTitle()}</Title>
            <LinkButton onClick={onClose}>
              <CancelIcon color={neutral.darkGrey} width={defaultTheme.size.closeIcon} />
            </LinkButton>
          </TitleBar>
        </TitleBarWrapper>
        <CampusFiltersProvider
          category={category}
          onApply={onApply}
          onClear={onClear}
          initialRegions={initialRegions}
          initialCampuses={initialCampuses}
        >
          <PageWrapper>
            <PageContainer>{renderPage()}</PageContainer>
          </PageWrapper>
        </CampusFiltersProvider>
      </Container>
    </FixedWrapper>
  );
};

const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: (ev: MouseEvent | TouchEvent) => void) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

const useOnPressEsc = (onClose: () => void) => {
  useEffect(() => {
    const closeOnEsc = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    document.addEventListener('keydown', closeOnEsc);
    return () => document.removeEventListener('keydown', closeOnEsc);
  }, [onClose]);
};

const PageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const PageContainer = styled.div`
  flex: 1 0 0;
  min-height: 0px;
`;

const TitleBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleBarWrapper = styled.div`
  padding: 1.5em 1.5em 1em 1.5em;
`;

const Title = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: ${typeScale.font17};
`;

const FixedWrapper = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.modal};
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Container = styled.div`
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: white;
  box-shadow: 0px 10px 62px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.size.navbarHeight};

  @media (min-width: ${mediaQueries.m_mobile}) {
    border-top-left-radius: 4px;
    left: unset;
    top: unset;
    width: 25rem;
    height: 66%;
  }
`;
