/** External dependencies */
import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Internal dependencies */
import { useAuthenticated } from 'auth';
import { AppLinks } from '../../constants/routes';
import { MeSelectors } from 'zo-data-layer/selectors';
import LoadingIndicator from 'components/LoadingIndicator';

const memoizedSelectors = {
  profileLoaded: MeSelectors.isSuccess(),
  completedProfile: MeSelectors.completedProfile(),
};

const AuthRoute: FC<RouteProps> = ({ children, ...props }) => {
  const profileLoaded = useSelector(memoizedSelectors.profileLoaded);
  const completedProfile = useSelector(memoizedSelectors.completedProfile);
  const authenticated = useAuthenticated();

  if (!authenticated) {
    return <Redirect to={AppLinks.login} />;
  }

  if (!profileLoaded) {
    return <LoadingIndicator />;
  }

  if (!completedProfile) {
    return <Redirect to={AppLinks.confirmRegister} />;
  }

  return <Route {...props}>{children}</Route>;
};

export default AuthRoute;
