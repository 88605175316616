/** External Dependencies */
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

/** Data layer */
import { validEmailRule } from 'zo-data-layer/utils/patterns';

/** Components */
import { OverlayHeader } from '../../Overlay';
import Button from '../../Button';
import Messages from '../Messages';
import Input from '../../Input';

/** Styling */
import { typeScale } from 'styles';

type FormData = {
  email: string;
  password: string;
};

export function LoginForm({ login, isLoading }) {
  const intl = useIntl();
  const { handleSubmit, register, errors } = useForm<FormData>();

  return (
    <form onSubmit={handleSubmit(login)}>
      <OverlayHeader>
        <FormattedMessage {...Messages.welcomeBack} />
      </OverlayHeader>
      <SubHeader>
        <FormattedMessage {...Messages.login} />
      </SubHeader>

      <Input
        id="email"
        name="email"
        type="email"
        label={intl.formatMessage(Messages.emailAddress)}
        error={errors?.password?.message}
        innerRef={register({
          required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          pattern: {
            value: validEmailRule,
            message: <FormattedMessage {...Messages.invalidEmail} />,
          },
        })}
      />

      <Input
        id="password"
        name="password"
        type="password"
        label={intl.formatMessage(Messages.password)}
        error={errors?.password?.message}
        hideable
        innerRef={register({
          required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
        })}
      />

      <StyledButton type="submit" title={<FormattedMessage {...Messages.login} />} isLoading={isLoading} />
    </form>
  );
}

const StyledButton = styled(Button)`
  margin: 2rem 0 1rem;
  font-size: ${typeScale.font17};
`;

const SubHeader = styled.h3``;
