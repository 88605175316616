/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
  onClick?: () => void;
};

const MinusButtonIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize, onClick }) => (
  <StyledSpan onClick={onClick}>
    <svg width={width} height={width} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.3" cx="20" cy="20" r="19.5" stroke={color} />
      <rect opacity="0.4" x="12" y="19" width="16" height="2" rx="1" fill={color} />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  cursor: pointer;
`;

export default MinusButtonIcon;
