/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  isInverted?: boolean;
};

const LoadingIndicator: FC<Props> = ({ isInverted }) => (
  <Spinner isInverted={isInverted}>
    <Bounce delayMultiplier={2} />
    <Bounce delayMultiplier={1} />
    <Bounce delayMultiplier={0} />
  </Spinner>
);

const Spinner = styled.div<Props>`
  text-align: center;
  & > div {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.25rem;
    background: ${({ isInverted, theme }) => (isInverted ? theme.colors.backgroundColor : theme.colors.primaryColor)};
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1.4s infinite ease-in-out both;
  }
`;

type BounceProps = {
  delayMultiplier: number;
};

const Bounce = styled.div<BounceProps>`
  animation-delay: -${({ delayMultiplier }) => delayMultiplier * 0.16}s !important;
`;

export default LoadingIndicator;
