/** External dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  ratio: number;
  className?: string;
  isCenter?: boolean;
  backgroundColor?: string;
};

const AspectRatioContainer: FC<Props> = ({ children, ratio, className, isCenter, backgroundColor }) => {
  const paddingTop = ratio === 0 ? 100 : 100 / ratio;
  return (
    <Wrapper className={className} paddingTop={paddingTop} backgroundColor={backgroundColor}>
      <Content isCenter={isCenter}>{children}</Content>
    </Wrapper>
  );
};

type WrapperProps = {
  paddingTop: number;
  backgroundColor?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  padding-top: ${({ paddingTop }) => paddingTop}%;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
`;

type ContentProps = {
  isCenter?: boolean;
};

const Content = styled.div<ContentProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  ${({ isCenter }) =>
    isCenter &&
    `
      align-items: center;
      justify-content: center;
    `}
`;

export default AspectRatioContainer;
