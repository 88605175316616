import React, { FC } from 'react';
import { FormattedNumber } from 'react-intl';
interface IFormattedPrice {
  price: number;
  currency: string;
}

const FormattedPrice: FC<IFormattedPrice> = ({ price, currency }) => (
  /* eslint-disable react/style-prop-object */
  <FormattedNumber value={price} style="currency" currency={currency} />
);

export default FormattedPrice;
