import htmlToText from 'html-to-text';
import { DateTime, Duration } from 'luxon';
import { IntlFormatters } from 'react-intl';
import 'url-search-params-polyfill'; // polyfill for IE11

import GenericTimeBasedBooking from 'zo-data-layer/dataobjects/GenericTimeBasedBooking';

import Messages from './Messages';
import { isMobile, isIE } from 'utils/helpers';

export const formatTime = (date) => DateTime.fromISO(date).toUTC().toFormat("yyyyMMdd'T'HHmmss'Z'");

export const formatDuration = (duration) => Duration.fromISO(duration).toFormat('hhmm');

export const formatCalendarEventFromBooking = (booking: GenericTimeBasedBooking, intl: IntlFormatters): Event => {
  const { title, description, startTime, endTime } = booking;

  const duration = Duration.fromObject({ minutes: booking.durationInMinutes }).toISO();
  let detailDescription = description;
  let locationAddress = '';
  if (booking.isEventBooking()) {
    if (booking.locationRoom) {
      detailDescription = `<b>${intl.formatMessage(Messages.roomLabel)}: ${
        booking.locationRoom
      }</b><br>${detailDescription}`;
    }
    if (booking.locationFloor) {
      detailDescription = `<b>${intl.formatMessage(Messages.floorLabel)}: ${
        booking.locationFloor
      }</b><br>${detailDescription}`;
    }
    locationAddress = booking.locationAddress || '';
  }

  return {
    title,
    description: detailDescription,
    location: locationAddress,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    duration,
  };
};

// Event Link Documentation: https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/tree/master/services

export type Event = {
  title: string;
  startTime: string;
  endTime: string;
  duration?: string;
  location?: string;
  description?: string;
};

export enum CalendarType {
  Apple = 'Apple',
  Google = 'Google',
  Outlook = 'Outlook',
  Outlookcom = 'Outlookcom',
  Yahoo = 'Yahoo',
}

export const buildUrl = (event: Event, type: CalendarType) => {
  const { title, location, description, startTime, endTime, duration } = event;
  switch (type) {
    case CalendarType.Google: {
      const searchParams = new URLSearchParams({
        action: 'TEMPLATE', // required,
        text: title, // required,
        dates: `${formatTime(startTime)}/${formatTime(endTime)}`, // required
        location,
        details: description,
      });
      return { isBlob: false, url: `https://calendar.google.com/calendar/render?${searchParams.toString()}` };
    }
    case CalendarType.Yahoo: {
      const searchParams = new URLSearchParams({
        v: '60', // required,
        title, // required
        st: formatTime(startTime), // required
        dur: formatDuration(duration),
        in_loc: location,
        desc: description,
      });
      return { isBlob: false, url: `https://calendar.yahoo.com/?${searchParams.toString()}` };
    }
    case CalendarType.Outlookcom: {
      const searchParams = new URLSearchParams({
        path: '/calendar/action/compose', // required
        rru: 'addevent', // required
        subject: title, // required
        startdt: startTime, // required
        enddt: endTime, // required
        allday: 'false',
        location,
        body: description,
      });
      return { isBlob: false, url: `https://outlook.live.com/calendar/0/deeplink/compose/?${searchParams.toString()}` };
    }
    default: {
      let calendarUrl = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `URL:${document.URL}`,
        `DTSTART:${formatTime(startTime)}`,
        `DTEND:${formatTime(endTime)}`,
        `SUMMARY:${title}`,
        `DESCRIPTION:${htmlToText.fromString(description)}`.replace(/\n/g, '\\n'),
        `LOCATION:${location}`,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');

      if (!isIE() && isMobile()) {
        calendarUrl = encodeURI(`data:text/calendar;charset=utf8,${calendarUrl}`);
      }

      return { isBlob: true, url: calendarUrl };
    }
  }
};
