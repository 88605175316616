import { defineMessages, MessageDescriptor } from 'react-intl';

const name: MessageDescriptor = {
  id: 'myProfile.name',
  defaultMessage: 'Name',
};

const emailAddress: MessageDescriptor = {
  id: 'myProfile.emailAddress',
  defaultMessage: 'Email Address',
};

const languagePreference: MessageDescriptor = {
  id: 'myProfile.languagePreference',
  defaultMessage: 'Language Preference',
};

const buildingCampus: MessageDescriptor = {
  id: 'myProfile.buildingCampus',
  defaultMessage: 'Building Campus',
};

const phoneNumber: MessageDescriptor = {
  id: 'myProfile.phoneNumber',
  defaultMessage: 'Phone Number',
};

const company: MessageDescriptor = {
  id: 'myProfile.company',
  defaultMessage: 'Company',
};

const jobTitle: MessageDescriptor = {
  id: 'myProfile.jobTitle',
  defaultMessage: 'Job Title',
};

const floor: MessageDescriptor = {
  id: 'myProfile.floor',
  defaultMessage: 'Floor',
};

const birthday: MessageDescriptor = {
  id: 'myProfile.birthday',
  defaultMessage: 'Birthday',
};

const deleteMyAccount: MessageDescriptor = {
  id: 'myProfile.deleteMyAccount',
  defaultMessage: 'Delete my Account',
};

const notSpecified: MessageDescriptor = {
  id: 'myProfile.notSpecified',
  defaultMessage: 'Not specified',
};

const marketingOptedIn: MessageDescriptor = {
  id: 'myProfile.marketingOptedIn',
  defaultMessage: 'Not specified',
};

const optInYes: MessageDescriptor = {
  id: 'forms.yes',
  defaultMessage: 'Yes',
};

const optInNo: MessageDescriptor = {
  id: 'forms.no',
  defaultMessage: 'No',
};

export default defineMessages({
  name,
  emailAddress,
  languagePreference,
  buildingCampus,
  phoneNumber,
  company,
  jobTitle,
  floor,
  birthday,
  deleteMyAccount,
  notSpecified,
  marketingOptedIn,
  optInYes,
  optInNo,
});
