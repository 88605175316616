/** External Dependencies **/
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

/** Interal Dependencies */
import { ResultSetNames, PageSize } from 'constants/Services';
import { servicesByCategoryListSelectors } from 'utils/selectors';
import { usePagination } from 'hooks';

/** Data layer */
import { MyServicesListActions, MyServicesListRequest } from 'zo-data-layer/actions';

/** Styling */
import { mediaQueries } from 'styles';
import { defaultTheme } from 'styles/themes';

/** Components */
import TileSection from 'components/TileSection';
import CategoryNavigationBar from 'components/CategoryNavigationBar';
import LoadingIndicator from 'components/LoadingIndicator';
import FilterPagesLoadingIndicator from 'components/FilterPagesLoadingIndicator';

type RouteParams = {
  categoryId: string;
};

const memoizedSelectors = {
  isSuccess: servicesByCategoryListSelectors.isSuccess(),
  isLoading: servicesByCategoryListSelectors.isLoading(),
  services: servicesByCategoryListSelectors.getServices(),
  totalCount: servicesByCategoryListSelectors.getCount(),
};

const LeaderCategoryFilterPage: FC = () => {
  const { categoryId } = useParams<RouteParams>();

  const isSuccess = useSelector(memoizedSelectors.isSuccess);
  const services = useSelector(memoizedSelectors.services);
  const totalCount = useSelector(memoizedSelectors.totalCount);
  const isLoading = useSelector(memoizedSelectors.isLoading);
  const isLoaded = isSuccess || totalCount > 0;

  const { fetchFirstPage, fetchNextPage, hasNext } = usePagination<MyServicesListRequest>(
    MyServicesListActions.request,
    {
      all: true,
      category: parseInt(categoryId),
      resultSetName: ResultSetNames.byCategory,
      leader: true,
      showHidden: true,
    },
    { totalCount, pageSize: PageSize.filterPageSize }
  );

  useEffect(fetchFirstPage, [fetchFirstPage]);

  const renderContent = () => {
    if (isLoaded) {
      return (
        <InfiniteScroll
          dataLength={services.size}
          next={fetchNextPage}
          hasMore={hasNext || isLoading}
          loader={<FilterPagesLoadingIndicator />}
        >
          <TileSection tiles={services} isLoaded={isLoaded} categoryId={categoryId} isLeaderSection />
        </InfiniteScroll>
      );
    }

    return (
      <CenteredLoadingIndicator>
        <LoadingIndicator />
      </CenteredLoadingIndicator>
    );
  };

  return (
    <Wrapper>
      <CategoryNavigationBar adminOnly />
      {renderContent()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: ${({ theme }) => theme.zIndex.z3};
  width: 100%;
  max-width: ${({ theme }) => theme.size.containerMaxWidth};
  padding: ${defaultTheme.spacing.smallScreenPadding};

  @media (min-width: ${mediaQueries.m_tablet}) {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 0;
  }
`;

const CenteredLoadingIndicator = styled.div`
  padding: 2rem 0;
`;

export default LeaderCategoryFilterPage;
