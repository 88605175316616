/** External Dependencies */
import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { FieldElement, Message } from 'react-hook-form';

/** Styling */
import { typeScale } from '../../styles';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  error?: Message;
  items: { label: JSX.Element; value: string | number }[];
  innerRef?: (ref: FieldElement<any>) => void;
};

const RadioButtons: FC<Props> = ({ id, items, error, innerRef, ...inputProps }) => (
  <Div>
    <RadioGroup>
      {items.map(({ label, value }) => {
        const btnId = `${id}_${value}`;
        return (
          <RadioButton key={btnId}>
            <Input id={btnId} value={value} ref={innerRef} {...inputProps} type="radio" />
            <Label htmlFor={btnId}>{label}</Label>
          </RadioButton>
        );
      })}
    </RadioGroup>
    <ErrorDiv>{error}</ErrorDiv>
  </Div>
);

const RadioGroup = styled.div`
  display: flex;
`;

const RadioButton = styled.div`
  display: flex;
  align-items: center;
`;

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.status.error};
  font-size: ${typeScale.helperText};
  height: ${typeScale.helperText};
  padding-top: 0.2rem;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
`;

const Label = styled.label`
  font-size: ${typeScale.helperText};
  margin-right: 1rem;
`;

const Input = styled.input`
  font-size: ${typeScale.paragraph};
  margin-right: 0.5rem;
`;

export default RadioButtons;
