/** External Dependencies */
import React, { FC } from 'react';
import styled from 'styled-components';

/** Styling */
import { neutral } from '../../styles/colors';
import { defaultTheme } from '../../styles/themes';

type Props = {
  color?: string;
  width?: string;
};

const CalendarIcon: FC<Props> = ({ color = neutral.black, width = defaultTheme.size.iconSize }) => (
  <StyledSpan>
    <svg width={width} height={width} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1" fill={color}>
        <rect x="3.23077" y="4.30768" width="21.5385" height="19.3846" rx="1" />
      </mask>
      <rect
        x="3.23077"
        y="4.30768"
        width="21.5385"
        height="19.3846"
        rx="1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M3.73077 5.30768C3.73077 5.03154 3.95462 4.80768 4.23077 4.80768H23.7692C24.0454 4.80768 24.2692 5.03154 24.2692 5.30768V10.2692H3.73077V5.30768Z"
        stroke={color}
        strokeLinejoin="round"
      />
      <line
        x1="18.6346"
        y1="6.78845"
        x2="18.6346"
        y2="2.90384"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="9.36539"
        y1="2.90384"
        x2="9.36539"
        y2="6.78845"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </StyledSpan>
);

const StyledSpan = styled.span`
  padding-right: 0.5rem;
`;

export default CalendarIcon;
