/** External Dependencies */
import React, { FC } from 'react';

/** Styling */
import { defaultTheme } from '../../styles/themes';

type Props = {
  width?: string;
};

const SuccessfulWaitlistIcon: FC<Props> = ({ width = defaultTheme.size.successIconSize }) => (
  <svg width={width} height={width} viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.2" cx="33" cy="33" r="33" fill="#F0943D" />
    <circle cx="32.9999" cy="33.0004" r="22.7143" fill="#F0943D" />
    <path d="M32.1431 31.1934L35.0661 34.8577L41.6431 26.7148" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <line x1="25" y1="29.1074" x2="29.7857" y2="29.1074" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <line x1="25" y1="34.5361" x2="29.7857" y2="34.5361" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <line x1="25" y1="39.9648" x2="36.5714" y2="39.9648" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default SuccessfulWaitlistIcon;
