/** External Dependencies */
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

/** Components */
import { OverlayHeader } from '../../Overlay';
import Button from '../../Button';
import Messages from '../Messages';
import Input from '../../Input';

/** Data layer */
import { validEmailRule } from 'zo-data-layer/utils/patterns';

/** Styling */
import { typeScale, mediaQueries } from 'styles';

type FormData = {
  email: string;
  isLoading: boolean;
};

export function ForgotPasswordForm({ requestForgotPassword, isLoading }) {
  const { handleSubmit, register, errors } = useForm<FormData>();
  const intl = useIntl();

  return (
    <StyledForm onSubmit={handleSubmit(requestForgotPassword)}>
      <OverlayHeader>
        <FormattedMessage {...Messages.resetYourPassword} />
      </OverlayHeader>
      <Input
        id="email"
        name="email"
        type="email"
        label={intl.formatMessage(Messages.emailAddress)}
        error={errors?.email?.message}
        innerRef={register({
          required: { value: true, message: <FormattedMessage {...Messages.fieldRequired} /> },
          pattern: {
            value: validEmailRule,
            message: <FormattedMessage {...Messages.invalidEmail} />,
          },
        })}
      />
      <StyledButton type="submit" title={<FormattedMessage {...Messages.resetMyPassword} />} isLoading={isLoading} />
    </StyledForm>
  );
}

const StyledForm = styled.form`
  padding: 1rem;

  @media (min-width: ${mediaQueries.m_mobile}) {
    padding: 0;
  }
`;

const StyledButton = styled(Button)`
  margin: 2rem 0 1rem;
  font-size: ${typeScale.font17};
`;
