/** External Dependencies **/
import React, { FC } from 'react';

/** Components */
import Overlay, { Size as OverlaySize } from '../../components/Overlay';
import ConfirmRegisterForm from '../../components/Forms/ConfirmRegisterForm/ConfirmRegisterForm';

const ConfirmRegisterPage: FC = () => (
  <Overlay size={OverlaySize.Small}>
    <ConfirmRegisterForm />
  </Overlay>
);

export default ConfirmRegisterPage;
