export const brand = {
  blue: '#20566B',
  hello: '#25312B',
  brightGreen: '#00743D',
  darkGreen: '#3D4F47',
  lightGreen: '#C9E1C7',
  mintGreen: '#EBFFF5',
  success: '#85CCAB',
  orange: '#EC953A',
  teal: '#3D454F',
  yellow: '#F0D656',
};
export const neutral = {
  white: '#ffffff',
  lightGrey: '#EBEBEB',
  grey: '#C4C4C4',
  darkGrey: '#5F6160',
  lightBlue: '#77819D',
  darkBlue: '#3A444F',
  black: '#000000',
};
export const status = {
  info: '#00743D',
  success: '#49AB7F',
  warning: '#DE8F3C',
  error: '#D63232',
  loading: '#85CCAB',
  waitlist: '#F0943D',
};

export const statusBackground = {
  info: '#FFFFFF',
  success: 'linear-gradient(0deg, rgba(73, 171, 127, 0.1), rgba(73, 171, 127, 0.1)), #FFFFFF;',
  warning: 'linear-gradient(0deg, rgba(222, 143, 60, 0.1), rgba(222, 143, 60, 0.1)), #FFFFFF',
  error: 'linear-gradient(0deg, rgba(214, 50, 50, 0.1), rgba(214, 50, 50, 0.1)), #FFFFFF;',
};

export const miscColors = {
  cancelIcon: '#CA711B',
  notReadIcon: '#85CCAB',
  divider: '#EBEBEB',
  backgroundGrey: '#FEFDF8',
  inactiveGrey: '#9B9E9D',
  tooltip: '#E5E8F2',
};

export const stateColors = {
  hover: '#747F9C',
  selected: '#616E8E',
  buttonHover: '#F2F3F6',
};
