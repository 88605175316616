import { defineMessages, MessageDescriptor } from 'react-intl';

const deleteYourAccount: MessageDescriptor = {
  id: 'forms.deleteYourAccount',
  defaultMessage: 'Delete Your Account',
};

const warningDeleteAccount: MessageDescriptor = {
  id: 'forms.warningDeleteAccount',
  defaultMessage:
    'Warning: This is a non reversible action. Your ZO account will be removed and your personal data deleted. All your bookings will be cancelled. You will be immediately logged out. If you would like to use ZO again, you will have to register as a new user.',
};

const yesDeleteAccount: MessageDescriptor = {
  id: 'forms.yesDeleteAccount',
  defaultMessage: 'Yes, delete my account',
};

const pleaseTypeDelete: MessageDescriptor = {
  id: 'forms.typeDelete',
  defaultMessage: 'Please type DELETE below to confirm',
};

const deleteText: MessageDescriptor = {
  id: 'forms.deleteText',
  defaultMessage: 'DELETE',
};

const fieldRequired: MessageDescriptor = {
  id: 'forms.fieldRequired',
  defaultMessage: 'Field required',
};

export default defineMessages({
  deleteYourAccount,
  warningDeleteAccount,
  yesDeleteAccount,
  pleaseTypeDelete,
  fieldRequired,
  deleteText,
});
