/** External Dependencies */
import React, { Component } from 'react';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';

/** Internal Dependencies */
import Messages from './Messages';

/** Data later */
import { MeSelectors, ProfileData, UpdateMyAccountSelectors } from 'zo-data-layer/selectors';
import { UpdateMyAccountActions } from 'zo-data-layer/actions';
import { LocaleOptions } from 'zo-data-layer/constants';

/** Components */
import MyProfileEditForm, { FormData } from 'components/Forms/MyProfileEditForm';
import MyProfile from 'components/MyProfile';
import Button from 'components/Button';
import { RouteWrapper } from './MyPageContainer';
import EditIcon from 'components/Icons/EditIcon';

/** Styling */
import { brand } from 'styles';

type Props = {
  updateMyAccount: (
    data: {
      first_name?: string;
      last_name?: string;
      job_title?: string;
      phone?: string;
      language_preference?: string;
      birthday: string | null;
      marketing_email_opted_in?: string;
      marketing_email_opted_in_via?: string;
      marketing_email_opted_in_wording?: string;
    },
    { displayToastOnSuccess }: { displayToastOnSuccess: boolean }
  ) => AnyAction;
  updateMyAccountReset: () => AnyAction;
};

type SelectorProps = {
  myProfileData: ProfileData;
  isUpdatingMyAccount: boolean;
  isUpdatingMyAccountSuccess: boolean;
};

type State = {
  isEditable: boolean;
};

class MyProfileSection extends Component<Props & SelectorProps & { intl: IntlShape }, State> {
  state = {
    isEditable: false,
  };

  componentDidUpdate(prevProps) {
    const prevIsUpdatingMyAccountSuccess = prevProps.isUpdatingMyAccountSuccess;
    const { isUpdatingMyAccountSuccess } = this.props;
    const { isEditable } = this.state;

    if (!prevIsUpdatingMyAccountSuccess && isUpdatingMyAccountSuccess) {
      if (isEditable) this.toggleIsEditable();
      this.props.updateMyAccountReset();
    }
  }

  toggleIsEditable = () => {
    const { isEditable } = this.state;
    this.setState({ isEditable: !isEditable });
  };

  handleSubmitEditProfileForm = (data: FormData) => {
    const { intl, myProfileData } = this.props;
    const { firstName, lastName, jobTitle, languagePreference, phone, birthday, marketingOptedIn } = data;
    const baseData = {
      first_name: firstName,
      last_name: lastName,
      job_title: jobTitle,
      phone,
      language_preference: languagePreference.value,
      birthday: birthday ? DateTime.fromJSDate(birthday).toFormat('yyyy-MM-dd') : null,
    };

    const currentMarketingOptedIn = myProfileData.marketingOptedIn;
    const newMarketingOptedIn = marketingOptedIn.value === 'yes';

    const marketingOptedInWording = `${intl.formatMessage(Messages.marketingOptedInShort)} - ${intl.formatMessage(
      newMarketingOptedIn ? Messages.optInYes : Messages.optInNo
    )}`;

    const marketingData =
      currentMarketingOptedIn !== newMarketingOptedIn
        ? {
            marketing_email_opted_in: marketingOptedIn.value,
            marketing_email_opted_in_via: 'my profile form',
            marketing_email_opted_in_wording: marketingOptedInWording,
          }
        : {};

    this.props.updateMyAccount({ ...baseData, ...marketingData }, { displayToastOnSuccess: true });
  };

  render() {
    const { isEditable } = this.state;
    const { isUpdatingMyAccount, myProfileData } = this.props;

    const {
      firstName,
      lastName,
      phone,
      languagePreference = 'en',
      jobTitle,
      email,
      companyName,
      campusName,
      floor,
      birthday,
      marketingOptedIn,
    } = myProfileData;

    const marketingOptions = [
      { label: <FormattedMessage {...Messages.optInYes} />, value: 'yes' },
      { label: <FormattedMessage {...Messages.optInNo} />, value: 'no' },
    ];

    const defaultValues = {
      firstName,
      lastName,
      phone,
      jobTitle,
      birthday: birthday ? new Date(birthday) : null,
      languagePreference: LocaleOptions.find((opt) => opt.value === languagePreference),
      marketingOptedIn: marketingOptions.find((opt) => opt.value === (marketingOptedIn ? 'yes' : 'no')),
    };

    defaultValues.birthday = birthday ? DateTime.fromISO(birthday).toJSDate() : null;
    const readonlyValues = {
      email,
      companyName,
      campusName,
      floor,
    };

    return (
      <RouteWrapper>
        <Header>
          <H1>
            <FormattedMessage {...Messages.profile} />
          </H1>

          {!isEditable && (
            <EditButton
              buttonType="link"
              title={<FormattedMessage {...Messages.edit} />}
              onClick={this.toggleIsEditable}
              fullWidth={false}
              icon={<EditIcon color={brand.brightGreen} />}
              isIconRight
            />
          )}
        </Header>
        {isEditable ? (
          <MyProfileEditForm
            onSubmit={this.handleSubmitEditProfileForm}
            defaultValues={defaultValues}
            readonlyValues={readonlyValues}
            isLoading={isUpdatingMyAccount}
            onClickCancel={this.toggleIsEditable}
            marketingOptions={marketingOptions}
          />
        ) : (
          <MyProfile profileData={myProfileData} />
        )}
      </RouteWrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector<any, SelectorProps>({
  myProfileData: MeSelectors.myProfileData(),
  isUpdatingMyAccount: UpdateMyAccountSelectors.isLoading(),
  isUpdatingMyAccountSuccess: UpdateMyAccountSelectors.isSuccess(),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      updateMyAccount: UpdateMyAccountActions.request,
      updateMyAccountReset: UpdateMyAccountActions.reset,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyProfileSection));

const H1 = styled.h1``;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  padding: 1.5rem 0;
`;

const EditButton = styled(Button)`
  margin: 0;
`;
